/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
//CORE_REFERENCE_IMPORTS
//append_imports_start

import {
  Component,
  Injector,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ElementRef,
  HostListener,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core'; //_splitter_
import { SDBaseService } from 'app/n-services/SDBaseService'; //_splitter_
import { SDPageCommonService } from 'app/n-services/sd-page-common.service'; //_splitter_
import { __NEU_ServiceInvokerService__ } from 'app/n-services/service-caller.service'; //_splitter_
import { Router, ActivatedRoute } from '@angular/router'; //_splitter_
//append_imports_end

@Component({
  selector: 'bh-Review',
  templateUrl: './Review.template.html',
  providers: [
    //appendnew_element_providers
  ],
})
export class ReviewComponent {
  page: any = { dep: {} };
  constructor(
    private __page_injector__: Injector,
    private sdService: SDBaseService,
    public __serviceInvoker__: __NEU_ServiceInvokerService__
  ) {
    this.__page_injector__.get(SDPageCommonService).addPageDefaults(this.page);
    this.registerListeners();
    //appendnew_element_inject
  }

  ngOnInit() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    {
      this.sd_OEbYXoUPvsPNn83G(bh);
    }
  }

  private registerListeners() {
    let bh = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);

    //append_listeners
  }

  sd_OEbYXoUPvsPNn83G(bh) {
    try {
      bh = this.sd_VibCssJ2S5irJUYW(bh);
      //appendnew_next_sd_OEbYXoUPvsPNn83G
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_OEbYXoUPvsPNn83G');
    }
  }

  editReview(...others) {
    try {
      var bh: any = this.__page_injector__
        .get(SDPageCommonService)
        .constructFlowObject(this);
      bh.input = {};
      bh.local = {};
      bh = this.navigateToReview(bh);
      //appendnew_next_editReview
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_9W5kLgBLdqAgHVwr');
    }
  }

  //appendnew_flow_ReviewComponent_start

  sd_VibCssJ2S5irJUYW(bh) {
    try {
      bh = this.routerDep(bh);
      //appendnew_next_sd_VibCssJ2S5irJUYW
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_VibCssJ2S5irJUYW');
    }
  }

  routerDep(bh) {
    try {
      this.page.router = this.__page_injector__.get(Router);
      this.page.activatedRoute = this.__page_injector__.get(ActivatedRoute);
      //appendnew_next_routerDep
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_2U9cHSPoy9RtX4nj');
    }
  }

  navigateToReview(bh) {
    try {
      const page = this.page; // this.page.router.navigate(['home','edit-review']);

      this.page.router.navigate(['edit-review'], {
        relativeTo: this.page.activatedRoute.parent,
      });
      //appendnew_next_navigateToReview
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_5oKfcwA1LTfqy6AS');
    }
  }

  //appendnew_node

  ngOnDestroy() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    this.__page_injector__.get(SDPageCommonService).deletePageFromMap(this);
  }

  errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;
    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_ReviewComponent_Catch
}
