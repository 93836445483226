/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
//CORE_REFERENCE_IMPORTS
//append_imports_start

import {
  Component,
  Injector,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ElementRef,
  HostListener,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core'; //_splitter_
import { SDBaseService } from 'app/n-services/SDBaseService'; //_splitter_
import { SDPageCommonService } from 'app/n-services/sd-page-common.service'; //_splitter_
import { __NEU_ServiceInvokerService__ } from 'app/n-services/service-caller.service'; //_splitter_
import { staticinfoemploymentService } from 'app/services/staticinfoemployment/staticinfoemployment.service'; //_splitter_
import { staticinfopersonalService } from 'app/services/staticinfopersonal/staticinfopersonal.service'; //_splitter_
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms'; //_splitter_
//append_imports_end

@Component({
  selector: 'bh-EmploymentDetails',
  templateUrl: './EmploymentDetails.template.html',
  providers: [
    //appendnew_element_providers
  ],
})
export class EmploymentDetailsComponent {
  page: any = { dep: {} };
  constructor(
    private __page_injector__: Injector,
    private sdService: SDBaseService,
    public __serviceInvoker__: __NEU_ServiceInvokerService__
  ) {
    this.__page_injector__.get(SDPageCommonService).addPageDefaults(this.page);
    this.registerListeners();
    this.page.dep.FormBuilder = this.__page_injector__.get(FormBuilder); //FormBuilder
    //appendnew_element_inject
  }

  ngOnInit() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    {
      this.sd_KmBsFmhIs6NhNFXL(bh);
    }
  }

  private registerListeners() {
    let bh = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);

    //append_listeners
  }

  sd_KmBsFmhIs6NhNFXL(bh) {
    try {
      bh = this.sd_G1BKoqydIFOmr9Rt(bh);
      bh = this.sd_1F4i1VkOjDBPRvft(bh);
      bh = this.sd_wJTpL5dSfIMdHHzO(bh);
      bh = this.sd_FkmwYnvDUMpWS49f(bh);
      //appendnew_next_sd_KmBsFmhIs6NhNFXL
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_KmBsFmhIs6NhNFXL');
    }
  }

  //appendnew_flow_EmploymentDetailsComponent_start

  sd_G1BKoqydIFOmr9Rt(bh) {
    try {
      //appendnew_next_sd_G1BKoqydIFOmr9Rt
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_G1BKoqydIFOmr9Rt');
    }
  }

  sd_1F4i1VkOjDBPRvft(bh) {
    try {
      this.page.staticinfoemployment = this.__page_injector__.get(
        staticinfoemploymentService
      );
      bh = this.sd_fD48TfiwyYNDyuiU(bh);
      //appendnew_next_sd_1F4i1VkOjDBPRvft
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_1F4i1VkOjDBPRvft');
    }
  }

  sd_fD48TfiwyYNDyuiU(bh) {
    try {
      const page = this.page;
      console.log(page.staticinfoemployment);
      //appendnew_next_sd_fD48TfiwyYNDyuiU
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_fD48TfiwyYNDyuiU');
    }
  }

  sd_wJTpL5dSfIMdHHzO(bh) {
    try {
      this.page.staticinfopersonal = this.__page_injector__.get(
        staticinfopersonalService
      );
      //appendnew_next_sd_wJTpL5dSfIMdHHzO
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_wJTpL5dSfIMdHHzO');
    }
  }

  sd_FkmwYnvDUMpWS49f(bh) {
    try {
      bh = this.sd_v7MZptl59lieKajx(bh);
      //appendnew_next_sd_FkmwYnvDUMpWS49f
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_FkmwYnvDUMpWS49f');
    }
  }

  sd_v7MZptl59lieKajx(bh) {
    try {
      const page = this.page;
      page.pform = new FormGroup({
        worktype: new FormControl('Salaried'),
        nameofbusiness: new FormControl('Media'),
        employment: new FormControl('Bliss Media', Validators.required),
        yearsinserv: new FormControl('3 years, 2 months', Validators.required),
        naturbusiness: new FormControl('NB66'),
        education: new FormControl('DIP'),
        officeNum: new FormControl('1192840089', [Validators.required]),
      });

      //appendnew_next_sd_v7MZptl59lieKajx
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_v7MZptl59lieKajx');
    }
  }

  //appendnew_node

  ngOnDestroy() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    this.__page_injector__.get(SDPageCommonService).deletePageFromMap(this);
  }

  errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;
    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_EmploymentDetailsComponent_Catch
}
