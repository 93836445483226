/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
//CORE_REFERENCE_IMPORTS
//append_imports_start

import {
  Component,
  Injector,
  Input,
  Output,
  EventEmitter,
  ViewContainerRef,
  AfterViewInit,
  ViewChild,
  ViewChildren,
} from '@angular/core'; //_splitter_
import { SDBaseService } from 'app/n-services/SDBaseService'; //_splitter_
import { SDPageCommonService } from 'app/n-services/sd-page-common.service'; //_splitter_
import { __NEU_ServiceInvokerService__ } from 'app/n-services/service-caller.service'; //_splitter_
import { MatSort, Sort } from '@angular/material/sort'; //_splitter_
import { MatTableDataSource } from '@angular/material/table'; //_splitter_
import { Router, ActivatedRoute } from '@angular/router'; //_splitter_
//append_imports_end

@Component({
  selector: 'bh-MyInfoCheck',
  templateUrl: './MyInfoCheck.template.html',
  providers: [
    //appendnew_element_providers
  ],
})
export class MyInfoCheckComponent implements AfterViewInit {
  @ViewChild(MatSort)
  public MatSort: any = null;
  page: any = { dep: {} };
  constructor(
    private __page_injector__: Injector,
    private sdService: SDBaseService,
    public __serviceInvoker__: __NEU_ServiceInvokerService__
  ) {
    this.__page_injector__.get(SDPageCommonService).addPageDefaults(this.page);
    this.registerListeners();
    //appendnew_element_inject
  }

  ngOnInit() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    {
      this.sd_ggfMHdQ3IC8Pf6rG(bh);
    }
  }

  private registerListeners() {
    let bh = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);

    //append_listeners
  }

  sd_ggfMHdQ3IC8Pf6rG(bh) {
    try {
      bh = this.sd_gRPVgqfi5pzMqYlg(bh);
      bh = this.sd_iV4MvBbAde83ie5R(bh);
      bh = this.sd_0Hch1ojFzxDojwsC(bh);
      bh = this.sd_poPHRSaBLwR5EUPt(bh);
      bh = this.routerDep(bh);
      //appendnew_next_sd_ggfMHdQ3IC8Pf6rG
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_ggfMHdQ3IC8Pf6rG');
    }
  }

  next(...others) {
    try {
      var bh: any = this.__page_injector__
        .get(SDPageCommonService)
        .constructFlowObject(this);
      bh.input = {};
      bh.local = {};
      bh = this.navigateToReview(bh);
      //appendnew_next_next
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_7pb8ISUFcjc1h8yj');
    }
  }

  ngAfterViewInit() {
    try {
      var bh: any = this.__page_injector__
        .get(SDPageCommonService)
        .constructFlowObject(this);
      bh = this.sd_SWhtJiqQaud4imgj(bh);
      //appendnew_next_ngAfterViewInit
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_Seo8zmNYqJCl7Kij');
    }
  }

  showFullTable(...others) {
    try {
      var bh: any = this.__page_injector__
        .get(SDPageCommonService)
        .constructFlowObject(this);
      bh.input = {};
      bh.local = {};
      bh = this.sd_BLheFvbkhnSWirqK(bh);
      //appendnew_next_showFullTable
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_GMqoZQO4p0BorQMC');
    }
  }

  //appendnew_flow_MyInfoCheckComponent_start

  sd_gRPVgqfi5pzMqYlg(bh) {
    try {
      bh = this.sd_JyvrzsYiaAuFKJDe(bh);
      //appendnew_next_sd_gRPVgqfi5pzMqYlg
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_gRPVgqfi5pzMqYlg');
    }
  }

  sd_JyvrzsYiaAuFKJDe(bh) {
    try {
      const page = this.page;
      this.page.myInfoData = [
        { leftText: 'NRIC / FIN', rightText: 'F1612350K' },
        { leftText: 'Principal Name', rightText: 'John Smith' },
        { leftText: 'Alias Name', rightText: 'Johnny Smith' },
        { leftText: 'Sex', rightText: 'Male' },
        { leftText: 'Marital Status', rightText: 'Single' },
        { leftText: 'Date of Birth', rightText: '05-07-2000' },
        { leftText: 'Nationality', rightText: 'British' },
        { leftText: 'Residential Status', rightText: 'Permanent Resident' },
        { leftText: 'Country of Birth', rightText: 'United Kingdom' },
        { leftText: 'Passport Number', rightText: 'D374412' },
        { leftText: 'Passport Expiry Date', rightText: '24/03/2029' },
        { leftText: 'Mobile Number', rightText: '6158462152' },
        { leftText: 'Email Address', rightText: 'Jhonny.e.smith@gmail.com' },
        {
          leftText: 'Registered Address',
          rightText: '92b TELOK BLANGAH STREET 31 #12-192 SG 101092',
        },
        { leftText: 'Type of HDB', rightText: '5-ROOM FLAT (HDB)' },
        { leftText: 'Type of Housing', rightText: 'Apartment' },
        {
          leftText: 'Ownership of Private Residential Property',
          rightText: '  Yes',
        },
      ];
      bh = this.sd_CfskXAhZMgK0YJKW(bh);
      //appendnew_next_sd_JyvrzsYiaAuFKJDe
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_JyvrzsYiaAuFKJDe');
    }
  }

  sd_CfskXAhZMgK0YJKW(bh) {
    try {
      const page = this.page;
      this.page.propertyData = [
        {
          leftText: 'HDB Ownership - Address',
          rightText: '  698A JURONG WEST CENTRAL EAST 3 #04-191 SG 642698',
        },
        {
          leftText: 'HDB Ownership - Type of HDB Dwelling',
          rightText: '  5-ROOM FLAT (HDB)',
        },
        {
          leftText: 'Ownership of Private Residential Property',
          rightText: '  Yes',
        },
      ];
      bh = this.cpfData(bh);
      //appendnew_next_sd_CfskXAhZMgK0YJKW
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_CfskXAhZMgK0YJKW');
    }
  }

  cpfData(bh) {
    try {
      const page = this.page;

      this.page.cpfData = [
        {
          leftText: 'HDB Ownership - Address',
          rightText: '  698A JURONG WEST CENTRAL EAST 3 #04-191 SG 642698',
          titleText: '',
        },
        {
          leftText: 'HDB Ownership - Address',
          rightText: '  698A JURONG WEST CENTRAL EAST 3 #04-191 SG 642698',
          titleText: '',
        },
        {
          leftText: 'HDB Ownership - Address',
          rightText: '  698A JURONG WEST CENTRAL EAST 3 #04-191 SG 642698',
          titleText: '',
        },
      ];

      this.page.cpfData = [];
      const formatter = function (el) {
        if (el) {
          el = el.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          return el;
        }
        return '';
      };

      let cpfBalances = {
        ma: {
          value: 11470.71,
        },
        oa: {
          value: 1581.48,
        },
        sa: {
          value: 21967.09,
        },
        ra: {
          value: 0.01,
        },
        classification: 'C',
        source: '1',
        lastupdated: '2019-03-26',
      };

      this.page.cpfData.push(
        {
          leftText: 'Ordinary Account ( OA )',
          rightText: formatter(cpfBalances.oa.value),
          titleText: 'CPF Account Balance',
        },
        {
          leftText: 'Special Account ( SA )',
          rightText: formatter(cpfBalances.sa.value),
          titleText: '',
        },
        {
          leftText: 'Medisave Account ( MA )',
          rightText: formatter(cpfBalances.ma.value),
          titleText: '',
        }
      );

      bh = this.tableData(bh);
      //appendnew_next_cpfData
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_uDnfYJIXok3Ajxj7');
    }
  }

  tableData(bh) {
    try {
      const page = this.page;
      const ELEMENT_DATA = [
        { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
        { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
        { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
        { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
        { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
        { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
        { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
        { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
        { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
        { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
      ];

      const formatter = function (el) {
        // wont return with '$' sign
        if (el) {
          el = el.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          return el.replace('$', '');
        }
        return '';
      };

      var cpfcontributions = {
        history: [
          {
            date: {
              value: '2016-12-01',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2016-11',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2016-12-12',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2016-12',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2016-12-21',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2016-12',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-01-01',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2016-12',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-01-12',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-01',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-01-21',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-01',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-02-01',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-01',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-02-12',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-02',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-02-21',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-02',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-03-01',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-02',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-03-12',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-03',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-03-21',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-03',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-04-01',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-03',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-04-12',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-04',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-04-21',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-04',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-05-01',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-04',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-05-12',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-05',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-05-21',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-05',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-06-01',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-05',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-06-12',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-06',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-06-21',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-06',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-07-01',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-06',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-07-12',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-07',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-07-21',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-07',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-08-01',
            },
            amount: {
              value: 500,
            },
            month: {
              value: '2017-07',
            },
            employer: {
              value: 'Crystal Horse Invest Pte Ltd',
            },
          },
          {
            date: {
              value: '2017-08-12',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-08',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-08-21',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-08',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-09-01',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-08',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-09-12',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-09',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-09-21',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-09',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-10-01',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-09',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-10-12',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-10',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-10-21',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-10',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-11-01',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-10',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-11-12',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-11',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-11-21',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-11',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-12-01',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-11',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-12-12',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-12',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2017-12-21',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-12',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2018-01-01',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2017-12',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2018-01-12',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2018-01',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
          {
            date: {
              value: '2018-01-21',
            },
            amount: {
              value: 750,
            },
            month: {
              value: '2018-01',
            },
            employer: {
              value: 'Delta Marine Consultants PL',
            },
          },
        ],
        classification: 'C',
        source: '1',
        lastupdated: '2019-03-26',
      };

      this.page.tableFormattedData = cpfcontributions.history;
      this.page.tableFormattedData.length = 15;
      this.page.tableFormattedData.forEach((el) => {
        el['dateValue'] = new Date(el['date']['value']).toLocaleString(
          'en-us',
          { day: 'numeric', month: 'short', year: 'numeric' }
        );
        el['dateValue'] = el['dateValue'].replace(',', '');
        el['amountValue'] = formatter(el['amount']['value']);
        el['employerValue'] = el['employer']['value'];
        el['monthValue'] = new Date(el['date']['value']).toLocaleString(
          'en-us',
          { month: 'short', year: 'numeric' }
        );

        delete el['date'];
        delete el['amount'];
        delete el['employer'];
        delete el['month'];
      });

      this.page.truncatedTableFormattedData = [...this.page.tableFormattedData];

      this.page.truncatedTableFormattedData =
        this.page.truncatedTableFormattedData.slice(0, 3);

      this.page.tableDataLength = this.page.tableFormattedData.length || 0;
      setTimeout(() => {
        this.page.dataSource = new MatTableDataSource(
          this.page.truncatedTableFormattedData
        );

        this.page.dataSource.sort = this.MatSort;
        this.page.displayedColumns = [
          'monthValue',
          'dateValue',
          'amountValue',
          'employerValue',
        ];
      }, 1000);

      //appendnew_next_tableData
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_XnDsdIsiRPM96cDa');
    }
  }

  sd_iV4MvBbAde83ie5R(bh) {
    try {
      this.page.ViewContainerRef = this.__page_injector__.get(ViewContainerRef);
      //appendnew_next_sd_iV4MvBbAde83ie5R
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_iV4MvBbAde83ie5R');
    }
  }

  sd_0Hch1ojFzxDojwsC(bh) {
    try {
      //appendnew_next_sd_0Hch1ojFzxDojwsC
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_0Hch1ojFzxDojwsC');
    }
  }

  sd_poPHRSaBLwR5EUPt(bh) {
    try {
      //appendnew_next_sd_poPHRSaBLwR5EUPt
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_poPHRSaBLwR5EUPt');
    }
  }

  routerDep(bh) {
    try {
      this.page.router = this.__page_injector__.get(Router);
      this.page.activatedRoute = this.__page_injector__.get(ActivatedRoute);
      //appendnew_next_routerDep
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_2HzJClNp8PDOGAgF');
    }
  }

  navigateToReview(bh) {
    try {
      const page = this.page; // this.page.router.navigate(['home','review']);

      this.page.router.navigate(['review'], {
        relativeTo: this.page.activatedRoute.parent,
      });
      //appendnew_next_navigateToReview
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_bOkLDttzAm28Lm5V');
    }
  }

  sd_SWhtJiqQaud4imgj(bh) {
    try {
      bh.pageViews = Object.assign(bh.pageViews || {}, {
        MatSort: this.MatSort,
      });
      bh = this.sd_GSZ7V1Z6JiDmIB3b(bh);
      //appendnew_next_sd_SWhtJiqQaud4imgj
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_SWhtJiqQaud4imgj');
    }
  }

  sd_GSZ7V1Z6JiDmIB3b(bh) {
    try {
      const page = this.page;
      console.log(this.MatSort);

      console.log(bh.pageViews);

      //appendnew_next_sd_GSZ7V1Z6JiDmIB3b
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_GSZ7V1Z6JiDmIB3b');
    }
  }

  sd_BLheFvbkhnSWirqK(bh) {
    try {
      const page = this.page;
      this.page.truncatedTableFormattedData = [...this.page.tableFormattedData];
      setTimeout(() => {
        this.page.dataSource = new MatTableDataSource(
          this.page.truncatedTableFormattedData
        );
        this.page.seeAllClicked = true;
        this.page.dataSource.sort = this.MatSort;
        //this.page.displayedColumns = ['dateValue', 'amountValue', 'employerValue', 'monthValue']
      }, 500);
      //appendnew_next_sd_BLheFvbkhnSWirqK
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_BLheFvbkhnSWirqK');
    }
  }

  //appendnew_node

  ngOnDestroy() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    this.__page_injector__.get(SDPageCommonService).deletePageFromMap(this);
  }

  errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;
    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_MyInfoCheckComponent_Catch
}
