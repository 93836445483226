import { SDBaseService } from 'app/n-services/SDBaseService';
//CORE_REFERENCE_IMPORTS



export const sdProviders = [
SDBaseService,
 //CORE_REFERENCE_PUSH_TO_SD_ARRAY

];
