/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable } from '@angular/core';

@Injectable()
export class staticinfopersonalService {
public nationalities =[
    {
     
     "CodeDescription": "Singapore",
     "TPSystemValue": "SGP"
    },
    {
     
     "CodeDescription": "Malaysia",
     "TPSystemValue": "MYS"
    },
    {
     
     "CodeDescription": "India",
     "TPSystemValue": "IND"
    },
    {
     
     "CodeDescription": "United Arab Emirates",
     "TPSystemValue": "ARE"
    },
    {
     
     "CodeDescription": "United Kingdom",
     "TPSystemValue": "GBR"
    },
    {
     
     "CodeDescription": "United States of America",
     "TPSystemValue": "USA"
    },
    {
     
     "CodeDescription": "Canada",
     "TPSystemValue": "CAN"
    },
    {
     
     "CodeDescription": "Hong Kong",
     "TPSystemValue": "HKG"
    },
    {
     
     "CodeDescription": "Afghanistan",
     "TPSystemValue": "AFG"
    },
    {
     
     "CodeDescription": "Aland Islands",
     "TPSystemValue": "ALA"
    },
    {
     
     "CodeDescription": "Albania",
     "TPSystemValue": "ALB"
    },
    {
     
     "CodeDescription": "Algeria",
     "TPSystemValue": "DZA"
    },
    {
     
     "CodeDescription": "American Samoa",
     "TPSystemValue": "ASM"
    },
    {
     
     "CodeDescription": "Andorra",
     "TPSystemValue": "AND"
    },
    {
     
     "CodeDescription": "Angola",
     "TPSystemValue": "AGO"
    },
    {
     
     "CodeDescription": "Anguilla",
     "TPSystemValue": "AIA"
    },
    {
     
     "CodeDescription": "Antarctica",
     "TPSystemValue": "ATA"
    },
    {
     
     "CodeDescription": "Argentina",
     "TPSystemValue": "ARG"
    },
    {
     
     "CodeDescription": "Armenia",
     "TPSystemValue": "ARM"
    },
    {
     
     "CodeDescription": "Aruba",
     "TPSystemValue": "ABW"
    },
    {
     
     "CodeDescription": "Australia",
     "TPSystemValue": "AUS"
    },
    {
     
     "CodeDescription": "Austria",
     "TPSystemValue": "AUT"
    },
    {
     
     "CodeDescription": "Azerbaijan",
     "TPSystemValue": "AZE"
    },
    {
     
     "CodeDescription": "Bahamas",
     "TPSystemValue": "BHS"
    },
    {
     
     "CodeDescription": "Bahrain",
     "TPSystemValue": "BHR"
    },
    {
     
     "CodeDescription": "Bangladesh",
     "TPSystemValue": "BGD"
    },
    {
     
     "CodeDescription": "Barbados",
     "TPSystemValue": "BRB"
    },
    {
     
     "CodeDescription": "Belarus",
     "TPSystemValue": "BLR"
    },
    {
     
     "CodeDescription": "Belgium",
     "TPSystemValue": "BEL"
    },
    {
     
     "CodeDescription": "Belize",
     "TPSystemValue": "BLZ"
    },
    {
     
     "CodeDescription": "Benin",
     "TPSystemValue": "BEN"
    },
    {
     
     "CodeDescription": "Bermuda",
     "TPSystemValue": "BMU"
    },
    {
     
     "CodeDescription": "Bhutan",
     "TPSystemValue": "BTN"
    },
    {
     
     "CodeDescription": "Bolivia, Plurinational State of",
     "TPSystemValue": "BOL"
    },
    {
     
     "CodeDescription": "Bonaire, Sint Eustatius, Saba",
     "TPSystemValue": "BES"
    },
    {
     
     "CodeDescription": "Bosnia and Herzegovina",
     "TPSystemValue": "BIH"
    },
    {
     
     "CodeDescription": "Botswana",
     "TPSystemValue": "BWA"
    },
    {
     
     "CodeDescription": "Bouvet Island",
     "TPSystemValue": "BVT"
    },
    {
     
     "CodeDescription": "Brazil",
     "TPSystemValue": "BRA"
    },
    {
     
     "CodeDescription": "British Indian Ocean Territory",
     "TPSystemValue": "IOT"
    },
    {
     
     "CodeDescription": "Brunei Darussalam",
     "TPSystemValue": "BRN"
    },
    {
     
     "CodeDescription": "Bulgaria",
     "TPSystemValue": "BGR"
    },
    {
     
     "CodeDescription": "Burkina Faso",
     "TPSystemValue": "BFA"
    },
    {
     
     "CodeDescription": "Burundi",
     "TPSystemValue": "BDI"
    },
    {
     
     "CodeDescription": "Cabo Verde",
     "TPSystemValue": "CPV"
    },
    {
     
     "CodeDescription": "Cambodia",
     "TPSystemValue": "KHM"
    },
    {
     
     "CodeDescription": "Cameroon",
     "TPSystemValue": "CMR"
    },
    {
     
     "CodeDescription": "Cayman Islands",
     "TPSystemValue": "CYM"
    },
    {
     
     "CodeDescription": "Central African Republic",
     "TPSystemValue": "CAF"
    },
    {
     
     "CodeDescription": "Chad",
     "TPSystemValue": "TCD"
    },
    {
     
     "CodeDescription": "Chile",
     "TPSystemValue": "CHL"
    },
    {
     
     "CodeDescription": "China",
     "TPSystemValue": "CHN"
    },
    {
     
     "CodeDescription": "Christmas Island",
     "TPSystemValue": "CXR"
    },
    {
     
     "CodeDescription": "Cocos (Keeling) Islands",
     "TPSystemValue": "CCK"
    },
    {
     
     "CodeDescription": "Colombia",
     "TPSystemValue": "COL"
    },
    {
     
     "CodeDescription": "Comoros",
     "TPSystemValue": "COM"
    },
    {
     
     "CodeDescription": "Congo",
     "TPSystemValue": "COG"
    },
    {
     
     "CodeDescription": "Congo, Democratic Republic of",
     "TPSystemValue": "COD"
    },
    {
     
     "CodeDescription": "Cook Islands",
     "TPSystemValue": "COK"
    },
    {
     
     "CodeDescription": "Costa Rica",
     "TPSystemValue": "CRI"
    },
    {
     
     "CodeDescription": "Cote D'Ivoire",
     "TPSystemValue": "CIV"
    },
    {
     
     "CodeDescription": "Croatia",
     "TPSystemValue": "HRV"
    },
    {
     
     "CodeDescription": "Curacao",
     "TPSystemValue": "CUW"
    },
    {
     
     "CodeDescription": "Cyprus",
     "TPSystemValue": "CYP"
    },
    {
     
     "CodeDescription": "Czechia",
     "TPSystemValue": "CZE"
    },
    {
     
     "CodeDescription": "Denmark",
     "TPSystemValue": "DNK"
    },
    {
     
     "CodeDescription": "Djibouti",
     "TPSystemValue": "DJI"
    },
    {
     
     "CodeDescription": "Dominican Republic",
     "TPSystemValue": "DOM"
    },
    {
     
     "CodeDescription": "Ecuador",
     "TPSystemValue": "ECU"
    },
    {
     
     "CodeDescription": "Egypt",
     "TPSystemValue": "EGY"
    },
    {
     
     "CodeDescription": "El Salvador",
     "TPSystemValue": "SLV"
    },
    {
     
     "CodeDescription": "Equatorial Guinea",
     "TPSystemValue": "GNQ"
    },
    {
     
     "CodeDescription": "Eritrea",
     "TPSystemValue": "ERI"
    },
    {
     
     "CodeDescription": "Estonia",
     "TPSystemValue": "EST"
    },
    {
     
     "CodeDescription": "Eswatini",
     "TPSystemValue": "SWZ"
    },
    {
     
     "CodeDescription": "Ethiopia",
     "TPSystemValue": "ETH"
    },
    {
     
     "CodeDescription": "Falkland Islands FLK",
     "TPSystemValue": "FLK"
    },
    {
     
     "CodeDescription": "Faroe Islands",
     "TPSystemValue": "FRO"
    },
    {
     
     "CodeDescription": "Fiji",
     "TPSystemValue": "FJI"
    },
    {
     
     "CodeDescription": "Finland",
     "TPSystemValue": "FIN"
    },
    {
     
     "CodeDescription": "France",
     "TPSystemValue": "FRA"
    },
    {
     
     "CodeDescription": "French Guiana",
     "TPSystemValue": "GUF"
    },
    {
     
     "CodeDescription": "French Polynesia",
     "TPSystemValue": "PYF"
    },
    {
     
     "CodeDescription": "French Southern Territories",
     "TPSystemValue": "ATF"
    },
    {
     
     "CodeDescription": "Gabon",
     "TPSystemValue": "GAB"
    },
    {
     
     "CodeDescription": "Gambia",
     "TPSystemValue": "GMB"
    },
    {
     
     "CodeDescription": "Georgia",
     "TPSystemValue": "GEO"
    },
    {
     
     "CodeDescription": "Germany",
     "TPSystemValue": "DEU"
    },
    {
     
     "CodeDescription": "Ghana",
     "TPSystemValue": "GHA"
    },
    {
     
     "CodeDescription": "Gibraltar",
     "TPSystemValue": "GIB"
    },
    {
     
     "CodeDescription": "Greece",
     "TPSystemValue": "GRC"
    },
    {
     
     "CodeDescription": "Greenland",
     "TPSystemValue": "GRL"
    },
    {
     
     "CodeDescription": "Guadeloupe",
     "TPSystemValue": "GLP"
    },
    {
     
     "CodeDescription": "Guam",
     "TPSystemValue": "GUM"
    },
    {
     
     "CodeDescription": "Guatemala",
     "TPSystemValue": "GTM"
    },
    {
     
     "CodeDescription": "Guernsey",
     "TPSystemValue": "GGY"
    },
    {
     
     "CodeDescription": "Guinea",
     "TPSystemValue": "GIN"
    },
    {
     
     "CodeDescription": "Guinea-Bissau",
     "TPSystemValue": "GNB"
    },
    {
     
     "CodeDescription": "Guyana",
     "TPSystemValue": "GUY"
    },
    {
     
     "CodeDescription": "Haiti",
     "TPSystemValue": "HTI"
    },
    {
     
     "CodeDescription": "Heard Island, McDonald Islands",
     "TPSystemValue": "HMD"
    },
    {
     
     "CodeDescription": "Holy See",
     "TPSystemValue": "VAT"
    },
    {
     
     "CodeDescription": "Honduras",
     "TPSystemValue": "HND"
    },
    {
     
     "CodeDescription": "Hungary",
     "TPSystemValue": "HUN"
    },
    {
     
     "CodeDescription": "Iceland",
     "TPSystemValue": "ISL"
    },
    {
     
     "CodeDescription": "Indonesia",
     "TPSystemValue": "IDN"
    },
    {
     
     "CodeDescription": "Iraq",
     "TPSystemValue": "IRQ"
    },
    {
     
     "CodeDescription": "Ireland",
     "TPSystemValue": "IRL"
    },
    {
     
     "CodeDescription": "Isle of Man",
     "TPSystemValue": "IMN"
    },
    {
     
     "CodeDescription": "Israel",
     "TPSystemValue": "ISR"
    },
    {
     
     "CodeDescription": "Italy",
     "TPSystemValue": "ITA"
    },
    {
     
     "CodeDescription": "Jamaica",
     "TPSystemValue": "JAM"
    },
    {
     
     "CodeDescription": "Japan",
     "TPSystemValue": "JPN"
    },
    {
     
     "CodeDescription": "Jersey",
     "TPSystemValue": "JEY"
    },
    {
     
     "CodeDescription": "Jordan",
     "TPSystemValue": "JOR"
    },
    {
     
     "CodeDescription": "Kazakhstan",
     "TPSystemValue": "KAZ"
    },
    {
     
     "CodeDescription": "Kenya",
     "TPSystemValue": "KEN"
    },
    {
     
     "CodeDescription": "Kiribati",
     "TPSystemValue": "KIR"
    },
    {
     
     "CodeDescription": "Korea, Republic of",
     "TPSystemValue": "KOR"
    },
    {
     
     "CodeDescription": "Kosovo",
     "TPSystemValue": "XKD"
    },
    {
     
     "CodeDescription": "Kuwait",
     "TPSystemValue": "KWT"
    },
    {
     
     "CodeDescription": "Kyrgyzstan",
     "TPSystemValue": "KGZ"
    },
    {
     
     "CodeDescription": "Lao People's Democratic Republic",
     "TPSystemValue": "LAO"
    },
    {
     
     "CodeDescription": "Latvia",
     "TPSystemValue": "LVA"
    },
    {
     
     "CodeDescription": "Lebanon",
     "TPSystemValue": "LBN"
    },
    {
     
     "CodeDescription": "Lesotho",
     "TPSystemValue": "LSO"
    },
    {
     
     "CodeDescription": "Liberia",
     "TPSystemValue": "LBR"
    },
    {
     
     "CodeDescription": "Libya",
     "TPSystemValue": "LBY"
    },
    {
     
     "CodeDescription": "Liechtenstein",
     "TPSystemValue": "LIE"
    },
    {
     
     "CodeDescription": "Lithuania",
     "TPSystemValue": "LTU"
    },
    {
     
     "CodeDescription": "Luxembourg",
     "TPSystemValue": "LUX"
    },
    {
     
     "CodeDescription": "Macao",
     "TPSystemValue": "MAC"
    },
    {
     
     "CodeDescription": "Macedonia, Republic of",
     "TPSystemValue": "MKD"
    },
    {
     
     "CodeDescription": "Madagascar",
     "TPSystemValue": "MDG"
    },
    {
     
     "CodeDescription": "Malawi",
     "TPSystemValue": "MWI"
    },
    {
     
     "CodeDescription": "Maldives",
     "TPSystemValue": "MDV"
    },
    {
     
     "CodeDescription": "Mali",
     "TPSystemValue": "MLI"
    },
    {
     
     "CodeDescription": "Malta",
     "TPSystemValue": "MLT"
    },
    {
     
     "CodeDescription": "Marshall Islands",
     "TPSystemValue": "MHL"
    },
    {
     
     "CodeDescription": "Martinique",
     "TPSystemValue": "MTQ"
    },
    {
     
     "CodeDescription": "Mauritania",
     "TPSystemValue": "MRT"
    },
    {
     
     "CodeDescription": "Mauritius",
     "TPSystemValue": "MUS"
    },
    {
     
     "CodeDescription": "Mayotte",
     "TPSystemValue": "MYT"
    },
    {
     
     "CodeDescription": "Mexico",
     "TPSystemValue": "MEX"
    },
    {
     
     "CodeDescription": "Micronesia, Federated State of",
     "TPSystemValue": "FSM"
    },
    {
     
     "CodeDescription": "Moldova, Republic of",
     "TPSystemValue": "MDA"
    },
    {
     
     "CodeDescription": "Monaco",
     "TPSystemValue": "MCO"
    },
    {
     
     "CodeDescription": "Mongolia",
     "TPSystemValue": "MNG"
    },
    {
     
     "CodeDescription": "Montenegro",
     "TPSystemValue": "MNE"
    },
    {
     
     "CodeDescription": "Montserrat",
     "TPSystemValue": "MSR"
    },
    {
     
     "CodeDescription": "Morocco",
     "TPSystemValue": "MAR"
    },
    {
     
     "CodeDescription": "Mozambique",
     "TPSystemValue": "MOZ"
    },
    {
     
     "CodeDescription": "Myanmar",
     "TPSystemValue": "MMR"
    },
    {
     
     "CodeDescription": "Namibia",
     "TPSystemValue": "NAM"
    },
    {
     
     "CodeDescription": "Nauru",
     "TPSystemValue": "NRU"
    },
    {
     
     "CodeDescription": "Nepal",
     "TPSystemValue": "NPL"
    },
    {
     
     "CodeDescription": "Netherlands",
     "TPSystemValue": "NLD"
    },
    {
     
     "CodeDescription": "New Caledonia",
     "TPSystemValue": "NCL"
    },
    {
     
     "CodeDescription": "New Zealand",
     "TPSystemValue": "NZL"
    },
    {
     
     "CodeDescription": "Nicaragua",
     "TPSystemValue": "NIC"
    },
    {
     
     "CodeDescription": "Niger",
     "TPSystemValue": "NER"
    },
    {
     
     "CodeDescription": "Nigeria",
     "TPSystemValue": "NGA"
    },
    {
     
     "CodeDescription": "Niue",
     "TPSystemValue": "NIU"
    },
    {
     
     "CodeDescription": "Norfolk Island",
     "TPSystemValue": "NFK"
    },
    {
     
     "CodeDescription": "Northern Mariana Islands",
     "TPSystemValue": "MNP"
    },
    {
     
     "CodeDescription": "Norway",
     "TPSystemValue": "NOR"
    },
    {
     
     "CodeDescription": "Oman",
     "TPSystemValue": "OMN"
    },
    {
     
     "CodeDescription": "Pakistan",
     "TPSystemValue": "PAK"
    },
    {
     
     "CodeDescription": "Palau",
     "TPSystemValue": "PLW"
    },
    {
     
     "CodeDescription": "Palestine, State of",
     "TPSystemValue": "PSE"
    },
    {
     
     "CodeDescription": "Panama",
     "TPSystemValue": "PAN"
    },
    {
     
     "CodeDescription": "Papua New Guinea",
     "TPSystemValue": "PNG"
    },
    {
     
     "CodeDescription": "Paraguay",
     "TPSystemValue": "PRY"
    },
    {
     
     "CodeDescription": "Peru",
     "TPSystemValue": "PER"
    },
    {
     
     "CodeDescription": "Philippines",
     "TPSystemValue": "PHL"
    },
    {
     
     "CodeDescription": "Pitcairn",
     "TPSystemValue": "PCN"
    },
    {
     
     "CodeDescription": "Poland",
     "TPSystemValue": "POL"
    },
    {
     
     "CodeDescription": "Portugal",
     "TPSystemValue": "PRT"
    },
    {
     
     "CodeDescription": "Puerto Rico",
     "TPSystemValue": "PRI"
    },
    {
     
     "CodeDescription": "Qatar",
     "TPSystemValue": "QAT"
    },
    {
     
     "CodeDescription": "Reunion",
     "TPSystemValue": "REU"
    },
    {
     
     "CodeDescription": "Romania",
     "TPSystemValue": "ROU"
    },
    {
     
     "CodeDescription": "Russian Federation",
     "TPSystemValue": "RUS"
    },
    {
     
     "CodeDescription": "Rwanda",
     "TPSystemValue": "RWA"
    },
    {
     
     "CodeDescription": "Saint Barthelemy",
     "TPSystemValue": "BLM"
    },
    {
     
     "CodeDescription": "Saint Martin (French Part)",
     "TPSystemValue": "MAF"
    },
    {
     
     "CodeDescription": "Saint Pierre and Miquelon",
     "TPSystemValue": "SPM"
    },
    {
     
     "CodeDescription": "Saint Vincent, The Grenadines",
     "TPSystemValue": "VCT"
    },
    {
     
     "CodeDescription": "Samoa",
     "TPSystemValue": "WSM"
    },
    {
     
     "CodeDescription": "San Marino",
     "TPSystemValue": "SMR"
    },
    {
     
     "CodeDescription": "Sao Tome and Principe",
     "TPSystemValue": "STP"
    },
    {
     
     "CodeDescription": "Saudi Arabia",
     "TPSystemValue": "SAU"
    },
    {
     
     "CodeDescription": "Senegal",
     "TPSystemValue": "SEN"
    },
    {
     
     "CodeDescription": "Serbia",
     "TPSystemValue": "SRB"
    },
    {
     
     "CodeDescription": "Seychelles",
     "TPSystemValue": "SYC"
    },
    {
     
     "CodeDescription": "Sierra Leone",
     "TPSystemValue": "SLE"
    },
    {
     
     "CodeDescription": "Sint Maarten (Dutch Part)",
     "TPSystemValue": "SXM"
    },
    {
     
     "CodeDescription": "Slovakia",
     "TPSystemValue": "SVK"
    },
    {
     
     "CodeDescription": "Slovenia",
     "TPSystemValue": "SVN"
    },
    {
     
     "CodeDescription": "Solomon Islands",
     "TPSystemValue": "SLB"
    },
    {
     
     "CodeDescription": "Somalia",
     "TPSystemValue": "SOM"
    },
    {
     
     "CodeDescription": "South Africa",
     "TPSystemValue": "ZAF"
    },
    {
     
     "CodeDescription": "South Georgia, Sandwich Island",
     "TPSystemValue": "SGS"
    },
    {
     
     "CodeDescription": "South Sudan",
     "TPSystemValue": "SSD"
    },
    {
     
     "CodeDescription": "Spain",
     "TPSystemValue": "ESP"
    },
    {
     
     "CodeDescription": "Sri Lanka",
     "TPSystemValue": "LKA"
    },
    {
     
     "CodeDescription": "St Helena, Ascension, Tristan Cunha",
     "TPSystemValue": "SHN"
    },
    {
     
     "CodeDescription": "Suriname",
     "TPSystemValue": "SUR"
    },
    {
     
     "CodeDescription": "Svalbard and Jan Mayen",
     "TPSystemValue": "SJM"
    },
    {
     
     "CodeDescription": "Sweden",
     "TPSystemValue": "SWE"
    },
    {
     
     "CodeDescription": "Switzerland",
     "TPSystemValue": "CHE"
    },
    {
     
     "CodeDescription": "Taiwan",
     "TPSystemValue": "TWN"
    },
    {
     
     "CodeDescription": "Tajikistan",
     "TPSystemValue": "TJK"
    },
    {
     
     "CodeDescription": "Tanzania, United Republic of",
     "TPSystemValue": "TZA"
    },
    {
     
     "CodeDescription": "Thailand",
     "TPSystemValue": "THA"
    },
    {
     
     "CodeDescription": "Timor-Leste",
     "TPSystemValue": "TLS"
    },
    {
     
     "CodeDescription": "Togo",
     "TPSystemValue": "TGO"
    },
    {
     
     "CodeDescription": "Tokelau",
     "TPSystemValue": "TKL"
    },
    {
     
     "CodeDescription": "Tonga",
     "TPSystemValue": "TON"
    },
    {
     
     "CodeDescription": "Trinidad and Tobago",
     "TPSystemValue": "TTO"
    },
    {
     
     "CodeDescription": "Tunisia",
     "TPSystemValue": "TUN"
    },
    {
     
     "CodeDescription": "Turkey",
     "TPSystemValue": "TUR"
    },
    {
     
     "CodeDescription": "Turkmenistan",
     "TPSystemValue": "TKM"
    },
    {
     
     "CodeDescription": "Turks and Caicos Islands",
     "TPSystemValue": "TCA"
    },
    {
     
     "CodeDescription": "Tuvalu",
     "TPSystemValue": "TUV"
    },
    {
     
     "CodeDescription": "Uganda",
     "TPSystemValue": "UGA"
    },
    {
     
     "CodeDescription": "Ukraine",
     "TPSystemValue": "UKR"
    },
    {
     
     "CodeDescription": "United States Outlying Island",
     "TPSystemValue": "UMI"
    },
    {
     
     "CodeDescription": "Uruguay",
     "TPSystemValue": "URY"
    },
    {
     
     "CodeDescription": "Uzbekistan",
     "TPSystemValue": "UZB"
    },
    {
     
     "CodeDescription": "Venezuela, Bolicarian Republic of",
     "TPSystemValue": "VEN"
    },
    {
     
     "CodeDescription": "Vietnam",
     "TPSystemValue": "VNM"
    },
    {
     
     "CodeDescription": "Virgin Islands, British",
     "TPSystemValue": "VGB"
    },
    {
     
     "CodeDescription": "Virgin Islands, U.S.",
     "TPSystemValue": "VIR"
    },
    {
     
     "CodeDescription": "Wallis and Futuna",
     "TPSystemValue": "WLF"
    },
    {
     
     "CodeDescription": "Western Sahara",
     "TPSystemValue": "ESH"
    },
    {
     
     "CodeDescription": "Yemen",
     "TPSystemValue": "YEM"
    },
    {
     
     "CodeDescription": "Zambia",
     "TPSystemValue": "ZMB"
    },
    {
     
     "CodeDescription": "Zimbabwe",
     "TPSystemValue": "ZWE"
    },
    {
     
     "CodeDescription": "Unknown",
     "TPSystemValue": "ZZZ"
    },
    {
     
     "CodeDescription": "Sudan",
     "TPSystemValue": "SDN"
    },
    {
     
     "CodeDescription": "Antigua and Barbuda",
     "TPSystemValue": "ATG"
    },
    {
     
     "CodeDescription": "Dominica",
     "TPSystemValue": "DMA"
    },
    {
     
     "CodeDescription": "Saint Kitts and Nevis",
     "TPSystemValue": "KNA"
    },
    {
     
     "CodeDescription": "Grenada",
     "TPSystemValue": "GRD"
    },
    {
     
     "CodeDescription": "Saint Lucia",
     "TPSystemValue": "LCA"
    },
    {
     
     "CodeDescription": "Vanuatu",
     "TPSystemValue": "VUT"
    }
   ]
   public countryOfBirth = [
    {
      'CodeDescription': 'Singapore',
      'TPSystemValue': 'SG',
    },
    {
      'CodeDescription': 'Malaysia',
      'TPSystemValue': 'MY',
    },
    {
      'CodeDescription': 'India',
      'TPSystemValue': 'IN',
    },
    {
      'CodeDescription': 'United Arab Emirates',
      'TPSystemValue': 'AE',
    },
    {
      'CodeDescription': 'United Kingdom',
      'TPSystemValue': 'GB',
    },
    {
      'CodeDescription': 'United States of America',
      'TPSystemValue': 'US',
    },
    {
      'CodeDescription': 'Canada',
      'TPSystemValue': 'CA',
    },
    {
      'CodeDescription': 'Hong Kong',
      'TPSystemValue': 'HK',
    },
    {
      'CodeDescription': 'Afghanistan',
      'TPSystemValue': 'AF',
    },
    {
      'CodeDescription': 'Aland Islands',
      'TPSystemValue': 'AX',
    },
    {
      'CodeDescription': 'Albania',
      'TPSystemValue': 'AL',
    },
    {
      'CodeDescription': 'Algeria',
      'TPSystemValue': 'DZ',
    },
    {
      'CodeDescription': 'American Samoa',
      'TPSystemValue': 'AS',
    },
    {
      'CodeDescription': 'Andorra',
      'TPSystemValue': 'AD',
    },
    {
      'CodeDescription': 'Angola',
      'TPSystemValue': 'AO',
    },
    {
      'CodeDescription': 'Anguilla',
      'TPSystemValue': 'AI',
    },
    {
      'CodeDescription': 'Antarctica',
      'TPSystemValue': 'AQ',
    },
    {
      'CodeDescription': 'Antigua and Barbuda',
      'TPSystemValue': 'AG',
    },
    {
      'CodeDescription': 'Argentina',
      'TPSystemValue': 'AR',
    },
    {
      'CodeDescription': 'Armenia',
      'TPSystemValue': 'AM',
    },
    {
      'CodeDescription': 'Aruba',
      'TPSystemValue': 'AW',
    },
    {
      'CodeDescription': 'Australia',
      'TPSystemValue': 'AU',
    },
    {
      'CodeDescription': 'Austria',
      'TPSystemValue': 'AT',
    },
    {
      'CodeDescription': 'Azerbaijan',
      'TPSystemValue': 'AZ',
    },
    {
      'CodeDescription': 'Bahamas',
      'TPSystemValue': 'BS',
    },
    {
      'CodeDescription': 'Bahrain',
      'TPSystemValue': 'BH',
    },
    {
      'CodeDescription': 'Bangladesh',
      'TPSystemValue': 'BD',
    },
    {
      'CodeDescription': 'Barbados',
      'TPSystemValue': 'BB',
    },
    {
      'CodeDescription': 'Belarus',
      'TPSystemValue': 'BY',
    },
    {
      'CodeDescription': 'Belgium',
      'TPSystemValue': 'BE',
    },
    {
      'CodeDescription': 'Belize',
      'TPSystemValue': 'BZ',
    },
    {
      'CodeDescription': 'Benin',
      'TPSystemValue': 'BJ',
    },
    {
      'CodeDescription': 'Bermuda',
      'TPSystemValue': 'BM',
    },
    {
      'CodeDescription': 'Bhutan',
      'TPSystemValue': 'BT',
    },
    {
      'CodeDescription': 'Bolivia, Plurinational State of',
      'TPSystemValue': 'BO',
    },
    {
      'CodeDescription': 'Bonaire, Sint Eustatius, Saba',
      'TPSystemValue': 'BQ',
    },
    {
      'CodeDescription': 'Bosnia and Herzegovina',
      'TPSystemValue': 'BA',
    },
    {
      'CodeDescription': 'Botswana',
      'TPSystemValue': 'BW',
    },
    {
      'CodeDescription': 'Bouvet Island',
      'TPSystemValue': 'BV',
    },
    {
      'CodeDescription': 'Brazil',
      'TPSystemValue': 'BR',
    },
    {
      'CodeDescription': 'British Indian Ocean Territory',
      'TPSystemValue': 'IO',
    },
    {
      'CodeDescription': 'Brunei Darussalam',
      'TPSystemValue': 'BN',
    },
    {
      'CodeDescription': 'Bulgaria',
      'TPSystemValue': 'BG',
    },
    {
      'CodeDescription': 'Burkina Faso',
      'TPSystemValue': 'BF',
    },
    {
      'CodeDescription': 'Burundi',
      'TPSystemValue': 'BI',
    },
    {
      'CodeDescription': 'Cabo Verde',
      'TPSystemValue': 'CV',
    },
    {
      'CodeDescription': 'Cambodia',
      'TPSystemValue': 'KH',
    },
    {
      'CodeDescription': 'Cameroon',
      'TPSystemValue': 'CM',
    },
    {
      'CodeDescription': 'Cayman Islands',
      'TPSystemValue': 'KY',
    },
    {
      'CodeDescription': 'Central African Republic',
      'TPSystemValue': 'CF',
    },
    {
      'CodeDescription': 'Chad',
      'TPSystemValue': 'TD',
    },
    {
      'CodeDescription': 'Chile',
      'TPSystemValue': 'CL',
    },
    {
      'CodeDescription': 'China',
      'TPSystemValue': 'CN',
    },
    {
      'CodeDescription': 'Christmas Island',
      'TPSystemValue': 'CX',
    },
    {
      'CodeDescription': 'Cocos (Keeling) Islands',
      'TPSystemValue': 'CC',
    },
    {
      'CodeDescription': 'Colombia',
      'TPSystemValue': 'CO',
    },
    {
      'CodeDescription': 'Comoros',
      'TPSystemValue': 'KM',
    },
    {
      'CodeDescription': 'Congo',
      'TPSystemValue': 'CG',
    },
    {
      'CodeDescription': 'Congo, Democratic Republic of',
      'TPSystemValue': 'CD',
    },
    {
      'CodeDescription': 'Cook Islands',
      'TPSystemValue': 'CK',
    },
    {
      'CodeDescription': 'Costa Rica',
      'TPSystemValue': 'CR',
    },
    {
      'CodeDescription': "Cote D'Ivoire",
      'TPSystemValue': 'CI',
    },
    {
      'CodeDescription': 'Croatia',
      'TPSystemValue': 'HR',
    },
    {
      'CodeDescription': 'Cuba',
      'TPSystemValue': 'CU',
    },
    {
      'CodeDescription': 'Curacao',
      'TPSystemValue': 'CW',
    },
    {
      'CodeDescription': 'Cyprus',
      'TPSystemValue': 'CY',
    },
    {
      'CodeDescription': 'Czechia',
      'TPSystemValue': 'CZ',
    },
    {
      'CodeDescription': 'Denmark',
      'TPSystemValue': 'DK',
    },
    {
      'CodeDescription': 'Djibouti',
      'TPSystemValue': 'DJ',
    },
    {
      'CodeDescription': 'Dominica',
      'TPSystemValue': 'DM',
    },
    {
      'CodeDescription': 'Dominican Republic',
      'TPSystemValue': 'DO',
    },
    {
      'CodeDescription': 'Ecuador',
      'TPSystemValue': 'EC',
    },
    {
      'CodeDescription': 'Egypt',
      'TPSystemValue': 'EG',
    },
    {
      'CodeDescription': 'El Salvador',
      'TPSystemValue': 'SV',
    },
    {
      'CodeDescription': 'Equatorial Guinea',
      'TPSystemValue': 'GQ',
    },
    {
      'CodeDescription': 'Eritrea',
      'TPSystemValue': 'ER',
    },
    {
      'CodeDescription': 'Estonia',
      'TPSystemValue': 'EE',
    },
    {
      'CodeDescription': 'Eswatini',
      'TPSystemValue': 'SZ',
    },
    {
      'CodeDescription': 'Ethiopia',
      'TPSystemValue': 'ET',
    },
    {
      'CodeDescription': 'Falkland Islands FLK',
      'TPSystemValue': 'FK',
    },
    {
      'CodeDescription': 'Faroe Islands',
      'TPSystemValue': 'FO',
    },
    {
      'CodeDescription': 'Fiji',
      'TPSystemValue': 'FJ',
    },
    {
      'CodeDescription': 'Finland',
      'TPSystemValue': 'FI',
    },
    {
      'CodeDescription': 'France',
      'TPSystemValue': 'FR',
    },
    {
      'CodeDescription': 'French Guiana',
      'TPSystemValue': 'GF',
    },
    {
      'CodeDescription': 'French Polynesia',
      'TPSystemValue': 'PF',
    },
    {
      'CodeDescription': 'French Southern Territories',
      'TPSystemValue': 'TF',
    },
    {
      'CodeDescription': 'Gabon',
      'TPSystemValue': 'GA',
    },
    {
      'CodeDescription': 'Gambia',
      'TPSystemValue': 'GM',
    },
    {
      'CodeDescription': 'Georgia',
      'TPSystemValue': 'GE',
    },
    {
      'CodeDescription': 'Germany',
      'TPSystemValue': 'DE',
    },
    {
      'CodeDescription': 'Ghana',
      'TPSystemValue': 'GH',
    },
    {
      'CodeDescription': 'Gibraltar',
      'TPSystemValue': 'GI',
    },
    {
      'CodeDescription': 'Greece',
      'TPSystemValue': 'GR',
    },
    {
      'CodeDescription': 'Greenland',
      'TPSystemValue': 'GL',
    },
    {
      'CodeDescription': 'Grenada',
      'TPSystemValue': 'GD',
    },
    {
      'CodeDescription': 'Guadeloupe',
      'TPSystemValue': 'GP',
    },
    {
      'CodeDescription': 'Guam',
      'TPSystemValue': 'GU',
    },
    {
      'CodeDescription': 'Guatemala',
      'TPSystemValue': 'GT',
    },
    {
      'CodeDescription': 'Guernsey',
      'TPSystemValue': 'GG',
    },
    {
      'CodeDescription': 'Guinea',
      'TPSystemValue': 'GN',
    },
    {
      'CodeDescription': 'Guinea-Bissau',
      'TPSystemValue': 'GW',
    },
    {
      'CodeDescription': 'Guyana',
      'TPSystemValue': 'GY',
    },
    {
      'CodeDescription': 'Haiti',
      'TPSystemValue': 'HT',
    },
    {
      'CodeDescription': 'Heard Island, McDonald Islands',
      'TPSystemValue': 'HM',
    },
    {
      'CodeDescription': 'Holy See',
      'TPSystemValue': 'VA',
    },
    {
      'CodeDescription': 'Honduras',
      'TPSystemValue': 'HN',
    },
    {
      'CodeDescription': 'Hungary',
      'TPSystemValue': 'HU',
    },
    {
      'CodeDescription': 'Iceland',
      'TPSystemValue': 'IS',
    },
    {
      'CodeDescription': 'Indonesia',
      'TPSystemValue': 'ID',
    },
    {
      'CodeDescription': 'Iran, Islamic Republic of',
      'TPSystemValue': 'IR',
    },
    {
      'CodeDescription': 'Iraq',
      'TPSystemValue': 'IQ',
    },
    {
      'CodeDescription': 'Ireland',
      'TPSystemValue': 'IE',
    },
    {
      'CodeDescription': 'Isle of Man',
      'TPSystemValue': 'IM',
    },
    {
      'CodeDescription': 'Israel',
      'TPSystemValue': 'IL',
    },
    {
      'CodeDescription': 'Italy',
      'TPSystemValue': 'IT',
    },
    {
      'CodeDescription': 'Jamaica',
      'TPSystemValue': 'JM',
    },
    {
      'CodeDescription': 'Japan',
      'TPSystemValue': 'JP',
    },
    {
      'CodeDescription': 'Jersey',
      'TPSystemValue': 'JE',
    },
    {
      'CodeDescription': 'Jordan',
      'TPSystemValue': 'JO',
    },
    {
      'CodeDescription': 'Kazakhstan',
      'TPSystemValue': 'KZ',
    },
    {
      'CodeDescription': 'Kenya',
      'TPSystemValue': 'KE',
    },
    {
      'CodeDescription': 'Kiribati',
      'TPSystemValue': 'KI',
    },
    {
      'CodeDescription': "Korea Democratic People's Republic",
      'TPSystemValue': 'KP',
    },
    {
      'CodeDescription': 'Korea, Republic of',
      'TPSystemValue': 'KR',
    },
    {
      'CodeDescription': 'Kosovo',
      'TPSystemValue': 'XK',
    },
    {
      'CodeDescription': 'Kuwait',
      'TPSystemValue': 'KW',
    },
    {
      'CodeDescription': 'Kyrgyzstan',
      'TPSystemValue': 'KG',
    },
    {
      'CodeDescription': "Lao People's Democratic Republic",
      'TPSystemValue': 'LA',
    },
    {
      'CodeDescription': 'Latvia',
      'TPSystemValue': 'LV',
    },
    {
      'CodeDescription': 'Lebanon',
      'TPSystemValue': 'LB',
    },
    {
      'CodeDescription': 'Lesotho',
      'TPSystemValue': 'LS',
    },
    {
      'CodeDescription': 'Liberia',
      'TPSystemValue': 'LR',
    },
    {
      'CodeDescription': 'Libya',
      'TPSystemValue': 'LY',
    },
    {
      'CodeDescription': 'Liechtenstein',
      'TPSystemValue': 'LI',
    },
    {
      'CodeDescription': 'Lithuania',
      'TPSystemValue': 'LT',
    },
    {
      'CodeDescription': 'Luxembourg',
      'TPSystemValue': 'LU',
    },
    {
      'CodeDescription': 'Macao',
      'TPSystemValue': 'MO',
    },
    {
      'CodeDescription': 'Macedonia, Republic of',
      'TPSystemValue': 'MK',
    },
    {
      'CodeDescription': 'Madagascar',
      'TPSystemValue': 'MG',
    },
    {
      'CodeDescription': 'Malawi',
      'TPSystemValue': 'MW',
    },
    {
      'CodeDescription': 'Maldives',
      'TPSystemValue': 'MV',
    },
    {
      'CodeDescription': 'Mali',
      'TPSystemValue': 'ML',
    },
    {
      'CodeDescription': 'Malta',
      'TPSystemValue': 'MT',
    },
    {
      'CodeDescription': 'Marshall Islands',
      'TPSystemValue': 'MH',
    },
    {
      'CodeDescription': 'Martinique',
      'TPSystemValue': 'MQ',
    },
    {
      'CodeDescription': 'Mauritania',
      'TPSystemValue': 'MR',
    },
    {
      'CodeDescription': 'Mauritius',
      'TPSystemValue': 'MU',
    },
    {
      'CodeDescription': 'Mayotte',
      'TPSystemValue': 'YT',
    },
    {
      'CodeDescription': 'Mexico',
      'TPSystemValue': 'MX',
    },
    {
      'CodeDescription': 'Micronesia, Federated State of',
      'TPSystemValue': 'FM',
    },
    {
      'CodeDescription': 'Moldova, Republic of',
      'TPSystemValue': 'MD',
    },
    {
      'CodeDescription': 'Monaco',
      'TPSystemValue': 'MC',
    },
    {
      'CodeDescription': 'Mongolia',
      'TPSystemValue': 'MN',
    },
    {
      'CodeDescription': 'Montenegro',
      'TPSystemValue': 'ME',
    },
    {
      'CodeDescription': 'Montserrat',
      'TPSystemValue': 'MS',
    },
    {
      'CodeDescription': 'Morocco',
      'TPSystemValue': 'MA',
    },
    {
      'CodeDescription': 'Mozambique',
      'TPSystemValue': 'MZ',
    },
    {
      'CodeDescription': 'Myanmar',
      'TPSystemValue': 'MM',
    },
    {
      'CodeDescription': 'Namibia',
      'TPSystemValue': 'NA',
    },
    {
      'CodeDescription': 'Nauru',
      'TPSystemValue': 'NR',
    },
    {
      'CodeDescription': 'Nepal',
      'TPSystemValue': 'NP',
    },
    {
      'CodeDescription': 'Netherlands',
      'TPSystemValue': 'NL',
    },
    {
      'CodeDescription': 'New Caledonia',
      'TPSystemValue': 'NC',
    },
    {
      'CodeDescription': 'New Zealand',
      'TPSystemValue': 'NZ',
    },
    {
      'CodeDescription': 'Nicaragua',
      'TPSystemValue': 'NI',
    },
    {
      'CodeDescription': 'Niger',
      'TPSystemValue': 'NE',
    },
    {
      'CodeDescription': 'Nigeria',
      'TPSystemValue': 'NG',
    },
    {
      'CodeDescription': 'Niue',
      'TPSystemValue': 'NU',
    },
    {
      'CodeDescription': 'Norfolk Island',
      'TPSystemValue': 'NF',
    },
    {
      'CodeDescription': 'Northern Mariana Islands',
      'TPSystemValue': 'MP',
    },
    {
      'CodeDescription': 'Norway',
      'TPSystemValue': 'NO',
    },
    {
      'CodeDescription': 'Oman',
      'TPSystemValue': 'OM',
    },
    {
      'CodeDescription': 'Pakistan',
      'TPSystemValue': 'PK',
    },
    {
      'CodeDescription': 'Palau',
      'TPSystemValue': 'PW',
    },
    {
      'CodeDescription': 'Palestine, State of',
      'TPSystemValue': 'PS',
    },
    {
      'CodeDescription': 'Panama',
      'TPSystemValue': 'PA',
    },
    {
      'CodeDescription': 'Papua New Guinea',
      'TPSystemValue': 'PG',
    },
    {
      'CodeDescription': 'Paraguay',
      'TPSystemValue': 'PY',
    },
    {
      'CodeDescription': 'Peru',
      'TPSystemValue': 'PE',
    },
    {
      'CodeDescription': 'Philippines',
      'TPSystemValue': 'PH',
    },
    {
      'CodeDescription': 'Pitcairn',
      'TPSystemValue': 'PN',
    },
    {
      'CodeDescription': 'Poland',
      'TPSystemValue': 'PL',
    },
    {
      'CodeDescription': 'Portugal',
      'TPSystemValue': 'PT',
    },
    {
      'CodeDescription': 'Puerto Rico',
      'TPSystemValue': 'PR',
    },
    {
      'CodeDescription': 'Qatar',
      'TPSystemValue': 'QA',
    },
    {
      'CodeDescription': 'Reunion',
      'TPSystemValue': 'RE',
    },
    {
      'CodeDescription': 'Romania',
      'TPSystemValue': 'RO',
    },
    {
      'CodeDescription': 'Russian Federation',
      'TPSystemValue': 'RU',
    },
    {
      'CodeDescription': 'Rwanda',
      'TPSystemValue': 'RW',
    },
    {
      'CodeDescription': 'Saint Barthelemy',
      'TPSystemValue': 'BL',
    },
    {
      'CodeDescription': 'Saint Helena, Ascension, Tristan Cunha',
      'TPSystemValue': 'SH',
    },
    {
      'CodeDescription': 'Saint Kitts and Nevis',
      'TPSystemValue': 'KN',
    },
    {
      'CodeDescription': 'Saint Lucia',
      'TPSystemValue': 'LC',
    },
    {
      'CodeDescription': 'Saint Martin (French Part)',
      'TPSystemValue': 'MF',
    },
    {
      'CodeDescription': 'Saint Pierre and Miquelon',
      'TPSystemValue': 'PM',
    },
    {
      'CodeDescription': 'Saint Vincent, The Grenadines',
      'TPSystemValue': 'VC',
    },
    {
      'CodeDescription': 'Samoa',
      'TPSystemValue': 'WS',
    },
    {
      'CodeDescription': 'San Marino',
      'TPSystemValue': 'SM',
    },
    {
      'CodeDescription': 'Sao Tome and Principe',
      'TPSystemValue': 'ST',
    },
    {
      'CodeDescription': 'Saudi Arabia',
      'TPSystemValue': 'SA',
    },
    {
      'CodeDescription': 'Senegal',
      'TPSystemValue': 'SN',
    },
    {
      'CodeDescription': 'Serbia',
      'TPSystemValue': 'RS',
    },
    {
      'CodeDescription': 'Seychelles',
      'TPSystemValue': 'SC',
    },
    {
      'CodeDescription': 'Sierra Leone',
      'TPSystemValue': 'SL',
    },
    {
      'CodeDescription': 'Sint Maarten (Dutch Part)',
      'TPSystemValue': 'SX',
    },
    {
      'CodeDescription': 'Slovakia',
      'TPSystemValue': 'SK',
    },
    {
      'CodeDescription': 'Slovenia',
      'TPSystemValue': 'SI',
    },
    {
      'CodeDescription': 'Solomon Islands',
      'TPSystemValue': 'SB',
    },
    {
      'CodeDescription': 'Somalia',
      'TPSystemValue': 'SO',
    },
    {
      'CodeDescription': 'South Africa',
      'TPSystemValue': 'ZA',
    },
    {
      'CodeDescription': 'South Georgia, Sandwich Island',
      'TPSystemValue': 'GS',
    },
    {
      'CodeDescription': 'South Sudan',
      'TPSystemValue': 'SS',
    },
    {
      'CodeDescription': 'Spain',
      'TPSystemValue': 'ES',
    },
    {
      'CodeDescription': 'Sri Lanka',
      'TPSystemValue': 'LK',
    },
    {
      'CodeDescription': 'Sudan',
      'TPSystemValue': 'SD',
    },
    {
      'CodeDescription': 'Suriname',
      'TPSystemValue': 'SR',
    },
    {
      'CodeDescription': 'Svalbard and Jan Mayen',
      'TPSystemValue': 'SJ',
    },
    {
      'CodeDescription': 'Sweden',
      'TPSystemValue': 'SE',
    },
    {
      'CodeDescription': 'Switzerland',
      'TPSystemValue': 'CH',
    },
    {
      'CodeDescription': 'Syrian Arab Republic',
      'TPSystemValue': 'SY',
    },
    {
      'CodeDescription': 'Taiwan',
      'TPSystemValue': 'TW',
    },
    {
      'CodeDescription': 'Tajikistan',
      'TPSystemValue': 'TJ',
    },
    {
      'CodeDescription': 'Tanzania, United Republic of',
      'TPSystemValue': 'TZ',
    },
    {
      'CodeDescription': 'Thailand',
      'TPSystemValue': 'TH',
    },
    {
      'CodeDescription': 'Timor-Leste',
      'TPSystemValue': 'TL',
    },
    {
      'CodeDescription': 'Togo',
      'TPSystemValue': 'TG',
    },
    {
      'CodeDescription': 'Tokelau',
      'TPSystemValue': 'TK',
    },
    {
      'CodeDescription': 'Tonga',
      'TPSystemValue': 'TO',
    },
    {
      'CodeDescription': 'Trinidad and Tobago',
      'TPSystemValue': 'TT',
    },
    {
      'CodeDescription': 'Tunisia',
      'TPSystemValue': 'TN',
    },
    {
      'CodeDescription': 'Turkey',
      'TPSystemValue': 'TR',
    },
    {
      'CodeDescription': 'Turkmenistan',
      'TPSystemValue': 'TM',
    },
    {
      'CodeDescription': 'Turks and Caicos Islands',
      'TPSystemValue': 'TC',
    },
    {
      'CodeDescription': 'Tuvalu',
      'TPSystemValue': 'TV',
    },
    {
      'CodeDescription': 'Uganda',
      'TPSystemValue': 'UG',
    },
    {
      'CodeDescription': 'Ukraine',
      'TPSystemValue': 'UA',
    },
    {
      'CodeDescription': 'United States Outlying Island',
      'TPSystemValue': 'UM',
    },
    {
      'CodeDescription': 'Uruguay',
      'TPSystemValue': 'UY',
    },
    {
      'CodeDescription': 'Uzbekistan',
      'TPSystemValue': 'UZ',
    },
    {
      'CodeDescription': 'Vanuatu',
      'TPSystemValue': 'VU',
    },
    {
      'CodeDescription': 'Venezuela, Bolivarian Republic of',
      'TPSystemValue': 'VE',
    },
    {
      'CodeDescription': 'Vietnam',
      'TPSystemValue': 'VN',
    },
    {
      'CodeDescription': 'Virgin Islands, British',
      'TPSystemValue': 'VG',
    },
    {
      'CodeDescription': 'Virgin Islands, U.S.',
      'TPSystemValue': 'VI',
    },
    {
      'CodeDescription': 'Wallis and Futuna',
      'TPSystemValue': 'WF',
    },
    {
      'CodeDescription': 'Western Sahara',
      'TPSystemValue': 'EH',
    },
    {
      'CodeDescription': 'Yemen',
      'TPSystemValue': 'YE',
    },
    {
      'CodeDescription': 'Zambia',
      'TPSystemValue': 'ZM',
    },
    {
      'CodeDescription': 'Zimbabwe',
      'TPSystemValue': 'ZW',
    },
    {
      'CodeDescription': 'Unknown',
      'TPSystemValue': 'ZZ',
    },
  ]
    public gender = [
        {
          
          'CodeDescription': 'Male',
          'TPSystemValue': 'M',
        },
        {
          
          'CodeDescription': 'Female',
          'TPSystemValue': 'F',
        },
      ];

      public maritalStatus = [
        {
         
         "CodeDescription": "Single",
         "TPSystemValue": "S"
        },
        {
         
         "CodeDescription": "Married",
         "TPSystemValue": "M"
        },
        {
         
         "CodeDescription": "Others",
         "TPSystemValue": "O"
        }
       ]
       public educationLevel = [
        {
         "Field Name": "Education level",
         "CodeDescription": "Primary School",
         "TPSystemValue": "PSC"
        },
        {
         "Field Name": "Education level",
         "CodeDescription": "Secondary School \/ GCE O Level \/ GCE A Level",
         "TPSystemValue": "SEC"
        },
        {
         "Field Name": "Education level",
         "CodeDescription": "Diploma",
         "TPSystemValue": "DIP"
        },
        {
         "Field Name": "Education level",
         "CodeDescription": "Graduate",
         "TPSystemValue": "GRD"
        },
        {
         "Field Name": "Education level",
         "CodeDescription": "Post Graduate",
         "TPSystemValue": "PRF"
        },
        {
         "Field Name": "Education level",
         "CodeDescription": "Technical Certificate",
         "TPSystemValue": "TEC"
        },
        {
         "Field Name": "Education level",
         "CodeDescription": "Not Applicable",
         "TPSystemValue": "UNK"
        }
       ]


       public loanTenor =[
        {
         "Field Name": "Loan Tenor",
         "CodeDescription": 12,
         "TPSystemValue": 12
        },
        {
         "Field Name": "Loan Tenor",
         "CodeDescription": 24,
         "TPSystemValue": 24
        },
        {
         "Field Name": "Loan Tenor",
         "CodeDescription": 36,
         "TPSystemValue": 36
        },
        {
         "Field Name": "Loan Tenor",
         "CodeDescription": 48,
         "TPSystemValue": 48
        },
        {
         "Field Name": "Loan Tenor",
         "CodeDescription": 60,
         "TPSystemValue": 60
        }
       ]
}
