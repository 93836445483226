/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
//CORE_REFERENCE_IMPORTS
//append_imports_start

import {
  Component,
  Injector,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ElementRef,
  HostListener,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core'; //_splitter_
import { SDBaseService } from 'app/n-services/SDBaseService'; //_splitter_
import { SDPageCommonService } from 'app/n-services/sd-page-common.service'; //_splitter_
import { __NEU_ServiceInvokerService__ } from 'app/n-services/service-caller.service'; //_splitter_
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms'; //_splitter_
//append_imports_end

@Component({
  selector: 'bh-ResidentialAddresSingapore',
  templateUrl: './ResidentialAddresSingapore.template.html',
  providers: [
    //appendnew_element_providers
  ],
})
export class ResidentialAddresSingaporeComponent {
  page: any = { dep: {} };
  constructor(
    private __page_injector__: Injector,
    private sdService: SDBaseService,
    public __serviceInvoker__: __NEU_ServiceInvokerService__
  ) {
    this.__page_injector__.get(SDPageCommonService).addPageDefaults(this.page);
    this.registerListeners();
    this.page.dep.FormBuilder = this.__page_injector__.get(FormBuilder); //FormBuilder
    //appendnew_element_inject
  }

  ngOnInit() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    {
      this.sd_qpqV7iJ5bGe6cSUf(bh);
    }
  }

  private registerListeners() {
    let bh = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);

    //append_listeners
  }

  sd_qpqV7iJ5bGe6cSUf(bh) {
    try {
      bh = this.sd_SWuCDOK8eFaaICBs(bh);
      bh = this.sd_lGxnmzwC0gEMKNZH(bh);
      //appendnew_next_sd_qpqV7iJ5bGe6cSUf
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_qpqV7iJ5bGe6cSUf');
    }
  }

  //appendnew_flow_ResidentialAddresSingaporeComponent_start

  sd_SWuCDOK8eFaaICBs(bh) {
    try {
      //appendnew_next_sd_SWuCDOK8eFaaICBs
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_SWuCDOK8eFaaICBs');
    }
  }

  sd_lGxnmzwC0gEMKNZH(bh) {
    try {
      bh = this.sd_lMx4R4SXkGjJWppc(bh);
      //appendnew_next_sd_lGxnmzwC0gEMKNZH
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_lGxnmzwC0gEMKNZH');
    }
  }

  sd_lMx4R4SXkGjJWppc(bh) {
    try {
      const page = this.page;
      page.pform = new FormGroup({
        postal: new FormControl('169639', Validators.required),
        block: new FormControl('112'),
        street: new FormControl('East Coast Road', Validators.required),
        unit: new FormControl('#b1-13'),
        country: new FormControl('Singapore'),
        apartment: new FormControl('Louis Apartment'),
        yearsadd: new FormControl('3'),
        city: new FormControl('Singapore'),
        ownership: new FormControl('Owner'),
      });

      page.ownnershipstat = [
        'Owned (Fully Paid) ',
        ' Owned (Mortgaged) ',
        ' Rented ',
        ' Company / Employer Quarter ',
        ' Government Public House',
        ' Living with Relative / Parents ',
        ' Lodge ',
        ' Bachelor Accommodation ',
        ' Others',
      ];
      page.yearsinserv = ['<1', ' 1-2', ' 2-3', ' 3-4 ', '4-5 ', '5+'];

      //appendnew_next_sd_lMx4R4SXkGjJWppc
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_lMx4R4SXkGjJWppc');
    }
  }

  //appendnew_node

  ngOnDestroy() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    this.__page_injector__.get(SDPageCommonService).deletePageFromMap(this);
  }

  errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;
    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_ResidentialAddresSingaporeComponent_Catch
}
