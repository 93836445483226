import { Injectable } from '@angular/core';

@Injectable()
export class staticinfoemploymentService {
  public nameOfEmployer =[
    {
     
     "CodeDescription": "10,000 MILES",
     "TPSystemValue": "X088"
    },
    {
     
     "CodeDescription": "123 GO TRAVEL PTE. LTD.",
     "TPSystemValue": "X057"
    },
    {
     
     "CodeDescription": "1DERLUST PTE. LTD.",
     "TPSystemValue": "H854"
    },
    {
     
     "CodeDescription": "3IL CONSULTING PTE. LTD.",
     "TPSystemValue": "X206"
    },
    {
     
     "CodeDescription": "7 WONDERS TRAVELS & TOURS",
     "TPSystemValue": "X082"
    },
    {
     
     "CodeDescription": "88 LOTUS TRAVEL NOTE PTE. LTD.",
     "TPSystemValue": "X040"
    },
    {
     
     "CodeDescription": "A&P JOURNEYS LLP",
     "TPSystemValue": "X115"
    },
    {
     
     "CodeDescription": "A1 TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H975"
    },
    {
     
     "CodeDescription": "AASIA MANAGEMENT SERVICES PTE. LTD.",
     "TPSystemValue": "H955"
    },
    {
     
     "CodeDescription": "ABM TRAVEL & TOUR PTE. LTD.",
     "TPSystemValue": "X008"
    },
    {
     
     "CodeDescription": "ACCESS TRAVELS AND TRADING PRIVATE LIMITED",
     "TPSystemValue": "H949"
    },
    {
     
     "CodeDescription": "ACEVENTURES (OMT) TRAVELS CONSULTANCY PTE. LTD.",
     "TPSystemValue": "H857"
    },
    {
     
     "CodeDescription": "ACTIV ELEMENTS PTE. LTD.",
     "TPSystemValue": "X045"
    },
    {
     
     "CodeDescription": "ACTXPLORER PTE. LTD.",
     "TPSystemValue": "H858"
    },
    {
     
     "CodeDescription": "ADPEAK PTE. LTD.",
     "TPSystemValue": "X173"
    },
    {
     
     "CodeDescription": "ADVENTOURS TRAVEL PTE. LTD.",
     "TPSystemValue": "H979"
    },
    {
     
     "CodeDescription": "ADVENTURE TOUR PTE. LTD.",
     "TPSystemValue": "H897"
    },
    {
     
     "CodeDescription": "ADVENTURES UNLIMITED PTE. LTD.",
     "TPSystemValue": "H875"
    },
    {
     
     "CodeDescription": "AEGIR GLOBAL TRAVEL PTE. LTD.",
     "TPSystemValue": "X183"
    },
    {
     
     "CodeDescription": "AFDHAL SERVICES",
     "TPSystemValue": "X176"
    },
    {
     
     "CodeDescription": "AGAPE EXPERIENCE PTE. LTD.",
     "TPSystemValue": "X213"
    },
    {
     
     "CodeDescription": "AGI TECHNOSYS PTE. LTD.",
     "TPSystemValue": "X047"
    },
    {
     
     "CodeDescription": "AGODA TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "X158"
    },
    {
     
     "CodeDescription": "AIRKING WORLD TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "X137"
    },
    {
     
     "CodeDescription": "AL MUNAWWARAH TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H944"
    },
    {
     
     "CodeDescription": "ALCHEMY OF TRAVEL PTE. LTD.",
     "TPSystemValue": "H960"
    },
    {
     
     "CodeDescription": "ALHAFIZ TRAVELS AND SERVICES PRIVATE LIMITED",
     "TPSystemValue": "X049"
    },
    {
     
     "CodeDescription": "ALISIN TRAVEL PTE. LTD.",
     "TPSystemValue": "H870"
    },
    {
     
     "CodeDescription": "ALLINDIVE PTE. LTD.",
     "TPSystemValue": "X161"
    },
    {
     
     "CodeDescription": "AMAL TRAVEL & TOUR PTE. LTD.",
     "TPSystemValue": "X215"
    },
    {
     
     "CodeDescription": "AMALA TRAVEL",
     "TPSystemValue": "X086"
    },
    {
     
     "CodeDescription": "AMAZING BORNEO TRAVEL & EVENTS PTE. LTD.",
     "TPSystemValue": "H869"
    },
    {
     
     "CodeDescription": "AMAZON TRAVEL PTE. LTD.",
     "TPSystemValue": "H966"
    },
    {
     
     "CodeDescription": "AMBITION TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "H988"
    },
    {
     
     "CodeDescription": "AMERICAN EXPRESS INTERNATIONAL INC.",
     "TPSystemValue": "X108"
    },
    {
     
     "CodeDescription": "AMIRTHAMALA TRAVELS PTE. LTD.",
     "TPSystemValue": "H910"
    },
    {
     
     "CodeDescription": "ANJUNG TURIZM SERVICES PTE. LTD.",
     "TPSystemValue": "X061"
    },
    {
     
     "CodeDescription": "ANWAAR AL HIMAM UMRAH & HOLIDAYS PTE. LTD.",
     "TPSystemValue": "X041"
    },
    {
     
     "CodeDescription": "AR RAIYAN TOURS & TRAVEL PTE. LTD.",
     "TPSystemValue": "X015"
    },
    {
     
     "CodeDescription": "ARC TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "X192"
    },
    {
     
     "CodeDescription": "ASCENDA TRAVEL PTE. LTD.",
     "TPSystemValue": "X127"
    },
    {
     
     "CodeDescription": "ASCENT FLIGHTS GLOBAL PTE. LTD.",
     "TPSystemValue": "X182"
    },
    {
     
     "CodeDescription": "ASCENT TRAVEL PTE. LTD.",
     "TPSystemValue": "H887"
    },
    {
     
     "CodeDescription": "ASIA A LA CARTA PTE. LTD.",
     "TPSystemValue": "X067"
    },
    {
     
     "CodeDescription": "ASIA BLOCKCHAIN INDUSTRY INSTITUTE PTE. LTD.",
     "TPSystemValue": "X216"
    },
    {
     
     "CodeDescription": "ASIA PACIFIC INTERNATIONAL MICE & TRAVEL CENTRE PTE. LTD.",
     "TPSystemValue": "H855"
    },
    {
     
     "CodeDescription": "ASIA PLUS TRAVELING PTE. LTD.",
     "TPSystemValue": "H845"
    },
    {
     
     "CodeDescription": "ASTRO TOUR PTE. LTD.",
     "TPSystemValue": "X055"
    },
    {
     
     "CodeDescription": "ATA EVENT & VACATION HUB PTE. LTD.",
     "TPSystemValue": "H976"
    },
    {
     
     "CodeDescription": "ATELIER DENEB PTE. LTD.",
     "TPSystemValue": "X202"
    },
    {
     
     "CodeDescription": "ATHLETES' JOURNEY LLP",
     "TPSystemValue": "X113"
    },
    {
     
     "CodeDescription": "ATLAS TRAVELZ PTE. LTD.",
     "TPSystemValue": "H953"
    },
    {
     
     "CodeDescription": "AUGUST HOLIDAY PTE. LTD.",
     "TPSystemValue": "H913"
    },
    {
     
     "CodeDescription": "AURELIUS TRAVEL PTE. LTD.",
     "TPSystemValue": "H965"
    },
    {
     
     "CodeDescription": "AUTOMOBILE ASSOCIATION OF SINGAPORE",
     "TPSystemValue": "X105"
    },
    {
     
     "CodeDescription": "AXIAL AVIATION PTE. LTD.",
     "TPSystemValue": "X037"
    },
    {
     
     "CodeDescription": "AYLA TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "X178"
    },
    {
     
     "CodeDescription": "B2B TRAVEL PTE. LTD.",
     "TPSystemValue": "H891"
    },
    {
     
     "CodeDescription": "BECATION PTE. LTD.",
     "TPSystemValue": "X138"
    },
    {
     
     "CodeDescription": "BELOW THE WIND PTE. LTD.",
     "TPSystemValue": "H933"
    },
    {
     
     "CodeDescription": "BENETA DISCOVERY PTE. LTD.",
     "TPSystemValue": "H863"
    },
    {
     
     "CodeDescription": "BESPOKE TRAVEL INSIDER PTE. LTD.",
     "TPSystemValue": "X217"
    },
    {
     
     "CodeDescription": "BEYOND EXPEDITIONS PTE. LTD.",
     "TPSystemValue": "H986"
    },
    {
     
     "CodeDescription": "BEYOND X BOUNDARIES PRIVATE LIMITED",
     "TPSystemValue": "H951"
    },
    {
     
     "CodeDescription": "BI WORLDWIDE EVENTS (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H890"
    },
    {
     
     "CodeDescription": "BLACKTIP UNDERSEA ADVENTURES",
     "TPSystemValue": "X087"
    },
    {
     
     "CodeDescription": "BODHI TRAVEL PRIVATE LIMITED",
     "TPSystemValue": "X003"
    },
    {
     
     "CodeDescription": "BOUNDLESS BLUE PTE. LTD.",
     "TPSystemValue": "X195"
    },
    {
     
     "CodeDescription": "CARAVAN TOURS & TRAVEL PTE. LTD.",
     "TPSystemValue": "X007"
    },
    {
     
     "CodeDescription": "CARNIVAL PLC (SINGAPORE BRANCH)",
     "TPSystemValue": "X112"
    },
    {
     
     "CodeDescription": "CF DIVERS PTE. LTD.",
     "TPSystemValue": "X200"
    },
    {
     
     "CodeDescription": "CHAMP TRAVEL PRIVATE LIMITED",
     "TPSystemValue": "X058"
    },
    {
     
     "CodeDescription": "CHAN BROTHERS PRESTIGE PTE. LTD.",
     "TPSystemValue": "H943"
    },
    {
     
     "CodeDescription": "CHARACTER HOLIDAYS PTE. LTD.",
     "TPSystemValue": "X021"
    },
    {
     
     "CodeDescription": "CHINATOWN BUSINESS ASSOCIATION",
     "TPSystemValue": "X110"
    },
    {
     
     "CodeDescription": "CHOLA HOLIDAYS PTE. LTD.",
     "TPSystemValue": "X006"
    },
    {
     
     "CodeDescription": "CHOOSE YOUR FLIGHT TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "X171"
    },
    {
     
     "CodeDescription": "CITS TRAVELS PTE. LTD.",
     "TPSystemValue": "X133"
    },
    {
     
     "CodeDescription": "CITYLINE TRAVEL PTE. LTD.",
     "TPSystemValue": "H911"
    },
    {
     
     "CodeDescription": "COLOUR SEVEN TOURISM PTE. LTD.",
     "TPSystemValue": "X191"
    },
    {
     
     "CodeDescription": "COMET HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H868"
    },
    {
     
     "CodeDescription": "COMPASS INTERNATIONAL MICE & MEETINGS PTE. LTD.",
     "TPSystemValue": "X188"
    },
    {
     
     "CodeDescription": "CONCORD ASSOCIATES PTE LTD",
     "TPSystemValue": 1213
    },
    {
     
     "CodeDescription": "CONCORD ENGINEERING PTE LTD",
     "TPSystemValue": 1729
    },
    {
     
     "CodeDescription": "CONFIDENCE ENGINEERING PTE LTD",
     "TPSystemValue": 1730
    },
    {
     
     "CodeDescription": "CONFIDENCE ENGINEERING PTE. LTD.",
     "TPSystemValue": "14C"
    },
    {
     
     "CodeDescription": "CONFLUENCE PROJECT MANAGEMENT PTE LTD",
     "TPSystemValue": 1731
    },
    {
     
     "CodeDescription": "CONNECT 360 DEGREE PTE. LTD.",
     "TPSystemValue": "X222"
    },
    {
     
     "CodeDescription": "CONNECTING COMM. & SOLUTIONS PL",
     "TPSystemValue": 653
    },
    {
     
     "CodeDescription": "CONNELL BROS PTE LTD",
     "TPSystemValue": 1732
    },
    {
     
     "CodeDescription": "CONNER PERIPHERALS PTE LTD",
     "TPSystemValue": 1733
    },
    {
     
     "CodeDescription": "CONOCOPHILLIPS ASIA VENTURES PTE LTD",
     "TPSystemValue": 789
    },
    {
     
     "CodeDescription": "CONRAD CENTENNIAL SINGAPORE",
     "TPSystemValue": 1375
    },
    {
     
     "CodeDescription": "CONSOLIDATED PIPE CARRIERS PTE LTD",
     "TPSystemValue": 936
    },
    {
     
     "CodeDescription": "CONSULUS PTE LTD",
     "TPSystemValue": 928
    },
    {
     
     "CodeDescription": "CONTINENTAL AUTOMOTIVE SINGAPORE PL",
     "TPSystemValue": 634
    },
    {
     
     "CodeDescription": "CONVERGE ASIA PTE LTD",
     "TPSystemValue": 1734
    },
    {
     
     "CodeDescription": "CONVERGENT SYSTEMS (S) PTE LTD",
     "TPSystemValue": 1735
    },
    {
     
     "CodeDescription": "CONVERGYS SINGAPORE PTE LTD",
     "TPSystemValue": 1736
    },
    {
     
     "CodeDescription": "CONVERSANT SOLUTIONS PTE LTD",
     "TPSystemValue": 806
    },
    {
     
     "CodeDescription": "CONVERSE TRADING COMPANY BV SINGAPORE BRANCH",
     "TPSystemValue": 3626
    },
    {
     
     "CodeDescription": "COOPER CAMERON SINGAPORE PTE LTD",
     "TPSystemValue": 1737
    },
    {
     
     "CodeDescription": "COOPER CROUSE-HINDS PTE LTD",
     "TPSystemValue": 1738
    },
    {
     
     "CodeDescription": "CORDEOS PTE LTD",
     "TPSystemValue": 1248
    },
    {
     
     "CodeDescription": "CORDLIFE PL (CORDLIFE GROUP LIMITED)",
     "TPSystemValue": 778
    },
    {
     
     "CodeDescription": "CORDLIFE SERVICES (S) PTE LTD",
     "TPSystemValue": 991
    },
    {
     
     "CodeDescription": "CORE EDUCATION CONSULTING SOLUTIONS",
     "TPSystemValue": 1314
    },
    {
     
     "CodeDescription": "CORNERSTONE CAREER CONNECTIONS PTE LTD",
     "TPSystemValue": 1739
    },
    {
     
     "CodeDescription": "CORPORATE TRAVEL SERVICES PTE LTD",
     "TPSystemValue": 1740
    },
    {
     
     "CodeDescription": "CORRUPT PRACTICES INVESTIGATION BUREAU",
     "TPSystemValue": 1741
    },
    {
     
     "CodeDescription": "COSL DRILLING PAN-PACIFIC LTD",
     "TPSystemValue": 1212
    },
    {
     
     "CodeDescription": "COSTAR SHIPPING PTE LTD",
     "TPSystemValue": 1742
    },
    {
     
     "CodeDescription": "COTTON ON SINGAPORE PTE LTD",
     "TPSystemValue": 1743
    },
    {
     
     "CodeDescription": "COUNTRY FOODS PTE LTD",
     "TPSystemValue": 1744
    },
    {
     
     "CodeDescription": "COURTS SINGAPORE PTE LTD",
     "TPSystemValue": 90
    },
    {
     
     "CodeDescription": "COUTTS & CO LTD SINGAPORE",
     "TPSystemValue": 1745
    },
    {
     
     "CodeDescription": "COVANCE (ASIA) PTE LTD",
     "TPSystemValue": 1746
    },
    {
     
     "CodeDescription": "COVIDIEN PRIVATE LIMITED",
     "TPSystemValue": 1747
    },
    {
     
     "CodeDescription": "CPC INTERNATIONAL TRADING PTE LTD",
     "TPSystemValue": 1345
    },
    {
     
     "CodeDescription": "CPE INTEGRATED CENTRE",
     "TPSystemValue": 1426
    },
    {
     
     "CodeDescription": "CPF BOARD",
     "TPSystemValue": 1748
    },
    {
     
     "CodeDescription": "CPG CONSULTANTS PTE LTD",
     "TPSystemValue": 1749
    },
    {
     
     "CodeDescription": "CPG CORPORATION PTE LTD",
     "TPSystemValue": 1750
    },
    {
     
     "CodeDescription": "CPG FACILITIES MANAGEMENT PTE LTD",
     "TPSystemValue": 677
    },
    {
     
     "CodeDescription": "CPG LABORATORIES PTE LTD",
     "TPSystemValue": 1751
    },
    {
     
     "CodeDescription": "CPG LABORATORIES PTE LTD",
     "TPSystemValue": "15C"
    },
    {
     
     "CodeDescription": "CRABTREE & EVELYN (S) PTE LTD",
     "TPSystemValue": 1752
    },
    {
     
     "CodeDescription": "CREATIVE AVENUE PTE LTD",
     "TPSystemValue": 1753
    },
    {
     
     "CodeDescription": "CREATIVE BEVERAGE INGREDIENTS PTE LTD",
     "TPSystemValue": 902
    },
    {
     
     "CodeDescription": "CREATIVE EATERIES EXPO PTE LTD",
     "TPSystemValue": 873
    },
    {
     
     "CodeDescription": "CREATIVE TECHNOLOGY LTD",
     "TPSystemValue": 219
    },
    {
     
     "CodeDescription": "CREDENT TECHNOLOGY (ASIA) PTE LTD",
     "TPSystemValue": 195
    },
    {
     
     "CodeDescription": "CREDIT AGRICOLE",
     "TPSystemValue": 1754
    },
    {
     
     "CodeDescription": "CREDIT AGRICOLE (SUISSE) SA",
     "TPSystemValue": "C14"
    },
    {
     
     "CodeDescription": "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK",
     "TPSystemValue": "C15"
    },
    {
     
     "CodeDescription": "CREDIT HUB CAPITAL PTE LTD",
     "TPSystemValue": 1262
    },
    {
     
     "CodeDescription": "CREDIT INDUSTRIEL ET COMMERCIAL",
     "TPSystemValue": "C16"
    },
    {
     
     "CodeDescription": "CREDIT INDUTRIAL ET COMMERCIAL",
     "TPSystemValue": 1755
    },
    {
     
     "CodeDescription": "CREDIT SUISSE AG",
     "TPSystemValue": 129
    },
    {
     
     "CodeDescription": "CREDIT SUISSE AG",
     "TPSystemValue": 3627
    },
    {
     
     "CodeDescription": "CREDIT SUISSE AG",
     "TPSystemValue": "C17"
    },
    {
     
     "CodeDescription": "CREDO REAL ESTATE SINGAPORE PTE LTD",
     "TPSystemValue": 810
    },
    {
     
     "CodeDescription": "CREST TRAVEL & TOURS PTE LTD",
     "TPSystemValue": 107
    },
    {
     
     "CodeDescription": "CRESTAR EDUCATION GROUP PTE LTD",
     "TPSystemValue": 1756
    },
    {
     
     "CodeDescription": "CRIMSON LOGIC PTE LTD",
     "TPSystemValue": 1757
    },
    {
     
     "CodeDescription": "CRIMSONLOGIC PTE LTD",
     "TPSystemValue": 302
    },
    {
     
     "CodeDescription": "CRISTOFORI MUSIC PTE LTD",
     "TPSystemValue": 1758
    },
    {
     
     "CodeDescription": "CROCS SINGAPORE PTE LTD",
     "TPSystemValue": 1266
    },
    {
     
     "CodeDescription": "CRODA SINGAPORE PTE LTD",
     "TPSystemValue": 1759
    },
    {
     
     "CodeDescription": "CROWE HORWATH FIRST TRUST LLP",
     "TPSystemValue": 3104
    },
    {
     
     "CodeDescription": "CROWN BEVERAGE CANS SINGAPORE PTE LTD",
     "TPSystemValue": 417
    },
    {
     
     "CodeDescription": "CRU ASIA LIMITED",
     "TPSystemValue": 3778
    },
    {
     
     "CodeDescription": "CRUISE HOLIDAYS",
     "TPSystemValue": "X089"
    },
    {
     
     "CodeDescription": "CRYSTAL JADE SINGAPORE",
     "TPSystemValue": 1761
    },
    {
     
     "CodeDescription": "CS BORED PILE SYSTEM PTE LTD",
     "TPSystemValue": 530
    },
    {
     
     "CodeDescription": "CS CONSTRUCTION GEOTECHNIC PTE LTD",
     "TPSystemValue": 1762
    },
    {
     
     "CodeDescription": "CSA AUTOMATED PTE LTD",
     "TPSystemValue": 1763
    },
    {
     
     "CodeDescription": "CSA PTE LTD",
     "TPSystemValue": 1764
    },
    {
     
     "CodeDescription": "CSC ASIA HOLDINGS PTE LTD",
     "TPSystemValue": 369
    },
    {
     
     "CodeDescription": "CSC AUTOMATED PTE LTD",
     "TPSystemValue": 80
    },
    {
     
     "CodeDescription": "CSC HOLDINGS LIMITED",
     "TPSystemValue": 629
    },
    {
     
     "CodeDescription": "CSC INFORMATION TECHNOLOGY SERVICE PL",
     "TPSystemValue": 1765
    },
    {
     
     "CodeDescription": "CSC TECHNOLOGY SINGAPORE PTE LTD",
     "TPSystemValue": 235
    },
    {
     
     "CodeDescription": "CSIT PTE LTD",
     "TPSystemValue": 1766
    },
    {
     
     "CodeDescription": "CSL ASIA SHIPPING PTE LTD",
     "TPSystemValue": 725
    },
    {
     
     "CodeDescription": "CST CONTAINER SERVICES PTE LTD",
     "TPSystemValue": 288
    },
    {
     
     "CodeDescription": "CTS (S) PTE LTD",
     "TPSystemValue": 1767
    },
    {
     
     "CodeDescription": "CUBIX INTERNATIONAL PTE LTD",
     "TPSystemValue": 829
    },
    {
     
     "CodeDescription": "CUE ROOM",
     "TPSystemValue": 1418
    },
    {
     
     "CodeDescription": "CVH MANAGEMENT CONSULTANCY PTE LTD",
     "TPSystemValue": 1142
    },
    {
     
     "CodeDescription": "CWT COMMODITIES PTE LTD",
     "TPSystemValue": 841
    },
    {
     
     "CodeDescription": "CWT COMMODITIES PTE. LTD.",
     "TPSystemValue": "16C"
    },
    {
     
     "CodeDescription": "CWT GLOBELINK PTE LTD",
     "TPSystemValue": 234
    },
    {
     
     "CodeDescription": "CWT GLOBELINK PTE LTD",
     "TPSystemValue": "17C"
    },
    {
     
     "CodeDescription": "CWT LIMITED",
     "TPSystemValue": 63
    },
    {
     
     "CodeDescription": "CWT LIMITED",
     "TPSystemValue": "18C"
    },
    {
     
     "CodeDescription": "CWT LOGISTICS PTE LTD",
     "TPSystemValue": 883
    },
    {
     
     "CodeDescription": "DC TOURS PARADISE PTE. LTD.",
     "TPSystemValue": "X144"
    },
    {
     
     "CodeDescription": "DEFENGLIDA INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H936"
    },
    {
     
     "CodeDescription": "DESARU FRUIT FARM TOUR & TRAVEL",
     "TPSystemValue": "X077"
    },
    {
     
     "CodeDescription": "DHAKA TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H915"
    },
    {
     
     "CodeDescription": "DHP TRAVEL PTE. LTD.",
     "TPSystemValue": "X209"
    },
    {
     
     "CodeDescription": "DIAMOND COACH SINGAPORE PTE. LTD.",
     "TPSystemValue": "X046"
    },
    {
     
     "CodeDescription": "DIVE GAIA PTE. LTD.",
     "TPSystemValue": "H903"
    },
    {
     
     "CodeDescription": "DMC WORLD (PTE. LTD.)",
     "TPSystemValue": "X024"
    },
    {
     
     "CodeDescription": "DREAMCATION CRUISES AND TOURS PTE. LTD.",
     "TPSystemValue": "X019"
    },
    {
     
     "CodeDescription": "DREAMWEAVER CONSULTING PTE. LTD.",
     "TPSystemValue": "H945"
    },
    {
     
     "CodeDescription": "DRIST PTE. LTD.",
     "TPSystemValue": "X013"
    },
    {
     
     "CodeDescription": "E&S TRAVEL PTE. LTD.",
     "TPSystemValue": "X064"
    },
    {
     
     "CodeDescription": "EASTWOOD ALLIANCE TRAVEL & SERVICES",
     "TPSystemValue": "X075"
    },
    {
     
     "CodeDescription": "ECG PROPERTY PTE LTD",
     "TPSystemValue": 1851
    },
    {
     
     "CodeDescription": "ECICS LIMITED",
     "TPSystemValue": 320
    },
    {
     
     "CodeDescription": "ECO INTERIORS PTE LTD",
     "TPSystemValue": 1286
    },
    {
     
     "CodeDescription": "ECO SPECIAL WASTE MANAGEMENT PTE LTD",
     "TPSystemValue": 611
    },
    {
     
     "CodeDescription": "ECOARCH INTERNATIONAL PTE LTD",
     "TPSystemValue": 1323
    },
    {
     
     "CodeDescription": "ECOGREEN OLEOCHEMICAL SINGAPORE PTE LTD",
     "TPSystemValue": 586
    },
    {
     
     "CodeDescription": "ECOLAB PTE LTD",
     "TPSystemValue": 1852
    },
    {
     
     "CodeDescription": "ECONOMICS DEVELOPMMENT BOARD",
     "TPSystemValue": 3093
    },
    {
     
     "CodeDescription": "ECQUARIA TECHNOLOGIES PTE LTD",
     "TPSystemValue": 1853
    },
    {
     
     "CodeDescription": "ECS COMPUTERS ASIA PTE LTD",
     "TPSystemValue": 1854
    },
    {
     
     "CodeDescription": "ECS HOLDINGS LIMITED",
     "TPSystemValue": 3633
    },
    {
     
     "CodeDescription": "EDU TRAVEL PTE. LTD.",
     "TPSystemValue": "H901"
    },
    {
     
     "CodeDescription": "EDUCATIONAL BOOKINGS AND MANAGEMENT",
     "TPSystemValue": "X074"
    },
    {
     
     "CodeDescription": "EDVENTURE HONORS PTE. LTD.",
     "TPSystemValue": "X212"
    },
    {
     
     "CodeDescription": "EF SUPPORT SERVICES PTE. LTD.",
     "TPSystemValue": "H860"
    },
    {
     
     "CodeDescription": "ENCOUNTERS PTE. LTD.",
     "TPSystemValue": "X207"
    },
    {
     
     "CodeDescription": "EPIC OCEAN PTE. LTD.",
     "TPSystemValue": "X163"
    },
    {
     
     "CodeDescription": "EPIC TRAVEL TOURS",
     "TPSystemValue": "X091"
    },
    {
     
     "CodeDescription": "E-TIME TRAVEL PTE. LTD.",
     "TPSystemValue": "H970"
    },
    {
     
     "CodeDescription": "EUREKA EVENTS PTE. LTD.",
     "TPSystemValue": "H859"
    },
    {
     
     "CodeDescription": "EVERYDAY HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H874"
    },
    {
     
     "CodeDescription": "EXPERIENCE NEW ASIA",
     "TPSystemValue": "X073"
    },
    {
     
     "CodeDescription": "EZ TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H937"
    },
    {
     
     "CodeDescription": "FENMING TRAVEL PTE. LTD.",
     "TPSystemValue": "X054"
    },
    {
     
     "CodeDescription": "FIESTA SINGAPORE TRAVELS",
     "TPSystemValue": "X164"
    },
    {
     
     "CodeDescription": "FLAMINGO TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "H958"
    },
    {
     
     "CodeDescription": "FLYASIA TRAVEL PTE. LTD.",
     "TPSystemValue": "H982"
    },
    {
     
     "CodeDescription": "FLYNEW TOURS AND TRAVEL PTE. LTD.",
     "TPSystemValue": "X002"
    },
    {
     
     "CodeDescription": "FORAY TRAVELS PRIVATE LIMITED",
     "TPSystemValue": "X199"
    },
    {
     
     "CodeDescription": "FRONTIER TOURS PTE. LTD.",
     "TPSystemValue": "H898"
    },
    {
     
     "CodeDescription": "FROSTS FOOD & BEVERAGE PTE LTD",
     "TPSystemValue": 216
    },
    {
     
     "CodeDescription": "FUJI XEROX SINGAPORE PTE LTD",
     "TPSystemValue": 253
    },
    {
     
     "CodeDescription": "FUJITEC SINGAPORE CORPORATION LTD",
     "TPSystemValue": 79
    },
    {
     
     "CodeDescription": "FUJITSU ASIA PTE LTD",
     "TPSystemValue": 587
    },
    {
     
     "CodeDescription": "FUJITSU TEN SINGAPORE PTE LTD",
     "TPSystemValue": 336
    },
    {
     
     "CodeDescription": "FUKUYAMA ENGINEERING CONSTRUCTION PTE LTD",
     "TPSystemValue": 3801
    },
    {
     
     "CodeDescription": "FURAMA RIVERFRONT SINGAPORE",
     "TPSystemValue": 1388
    },
    {
     
     "CodeDescription": "FUSION COSMETICS PTE LTD",
     "TPSystemValue": 997
    },
    {
     
     "CodeDescription": "FUTURA SOLUTIONS PTE. LTD.",
     "TPSystemValue": 1042
    },
    {
     
     "CodeDescription": "FUTURE ELECTRONICS SINGAPORE PL",
     "TPSystemValue": 523
    },
    {
     
     "CodeDescription": "FUTURE ENTERPRISES PTE LTD",
     "TPSystemValue": 205
    },
    {
     
     "CodeDescription": "FUTURISTIC STORE FIXTURES PTE LTD",
     "TPSystemValue": 981
    },
    {
     
     "CodeDescription": "FUYU CORPORATION LIMITED",
     "TPSystemValue": 173
    },
    {
     
     "CodeDescription": "FXDS LEARNING GROUP PTE LTD",
     "TPSystemValue": 1188
    },
    {
     
     "CodeDescription": "G K TRAVEL PTE. LTD.",
     "TPSystemValue": "H930"
    },
    {
     
     "CodeDescription": "G T M ALUMINIUM PTE LTD",
     "TPSystemValue": "01G"
    },
    {
     
     "CodeDescription": "GA CONSTRUCTION PTE LTD",
     "TPSystemValue": 330
    },
    {
     
     "CodeDescription": "GA CONSTRUCTION PTE LTD",
     "TPSystemValue": "02G"
    },
    {
     
     "CodeDescription": "GAADIT PTE. LTD.",
     "TPSystemValue": "H879"
    },
    {
     
     "CodeDescription": "GAC SINGAPORE PTE LTD",
     "TPSystemValue": 177
    },
    {
     
     "CodeDescription": "GAMESA SINGAPORE PRIVATE LIMITED",
     "TPSystemValue": 1290
    },
    {
     
     "CodeDescription": "GAMESMADEME PTE LTD",
     "TPSystemValue": 3802
    },
    {
     
     "CodeDescription": "GAMMON PTE LTD",
     "TPSystemValue": 1896
    },
    {
     
     "CodeDescription": "GARDEN HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H918"
    },
    {
     
     "CodeDescription": "GARTNER ADVISORY SINGAPORE PTE LTD",
     "TPSystemValue": 297
    },
    {
     
     "CodeDescription": "GATES HUMAN RESOURCES PTE LTD",
     "TPSystemValue": 1041
    },
    {
     
     "CodeDescription": "GAYLIN INTERNATIONAL PTE LTD",
     "TPSystemValue": 135
    },
    {
     
     "CodeDescription": "GE AVIATION",
     "TPSystemValue": 435
    },
    {
     
     "CodeDescription": "GE PACIFIC PRIVATE LIMITED",
     "TPSystemValue": 58
    },
    {
     
     "CodeDescription": "GEMALTO PTE LTD",
     "TPSystemValue": 332
    },
    {
     
     "CodeDescription": "GENERAL ELECTRIC",
     "TPSystemValue": 1897
    },
    {
     
     "CodeDescription": "GENESIS NETWORKS PTE LTD",
     "TPSystemValue": 794
    },
    {
     
     "CodeDescription": "GENNEVA PTE LTD",
     "TPSystemValue": 1149
    },
    {
     
     "CodeDescription": "GENOME INSTITUTE OF SINGAPORE",
     "TPSystemValue": 1407
    },
    {
     
     "CodeDescription": "GEO CHEM FAR EAST PTE LTD",
     "TPSystemValue": 712
    },
    {
     
     "CodeDescription": "GEO-CHEM FAR EAST PTE LTD",
     "TPSystemValue": "03G"
    },
    {
     
     "CodeDescription": "GERARD BUSINESS SERVICES CONSULTANCY",
     "TPSystemValue": 1386
    },
    {
     
     "CodeDescription": "GERMAXCO SHIPPING AGENCIES PTE LTD",
     "TPSystemValue": 1898
    },
    {
     
     "CodeDescription": "GES INTERNATIONAL LIMITED",
     "TPSystemValue": 264
    },
    {
     
     "CodeDescription": "GES SINGAPORE PTE LTD",
     "TPSystemValue": 1899
    },
    {
     
     "CodeDescription": "GETRONICS SOLUTION S PTE LTD",
     "TPSystemValue": 35
    },
    {
     
     "CodeDescription": "GETTY IMAGES PTE LIMITED",
     "TPSystemValue": 3637
    },
    {
     
     "CodeDescription": "GEXPRO ASIA PTE LTD",
     "TPSystemValue": 308
    },
    {
     
     "CodeDescription": "GFI GROUP PTE LTD",
     "TPSystemValue": 1900
    },
    {
     
     "CodeDescription": "GFT GLOBAL MARKETS ASIA PTE LTD",
     "TPSystemValue": 1124
    },
    {
     
     "CodeDescription": "GH TRAVEL PTE. LTD.",
     "TPSystemValue": "X155"
    },
    {
     
     "CodeDescription": "GIANT HYPERMARKET",
     "TPSystemValue": 1389
    },
    {
     
     "CodeDescription": "GIC",
     "TPSystemValue": 1901
    },
    {
     
     "CodeDescription": "GIMMIL PTRIVATE LIMITED",
     "TPSystemValue": 1129
    },
    {
     
     "CodeDescription": "GINGA GLOBAL MARKETS PTE LTD",
     "TPSystemValue": 3638
    },
    {
     
     "CodeDescription": "GIVAUDAN SINGAPORE PTE LTD",
     "TPSystemValue": 415
    },
    {
     
     "CodeDescription": "GKE CORPORATION LIMITED",
     "TPSystemValue": 721
    },
    {
     
     "CodeDescription": "GKE EXPRESS LOGISTICS PTE LTD",
     "TPSystemValue": 376
    },
    {
     
     "CodeDescription": "GKE FREIGHT PTE LTD",
     "TPSystemValue": 940
    },
    {
     
     "CodeDescription": "GKE METAL LOGISTICS PTE LTD",
     "TPSystemValue": 236
    },
    {
     
     "CodeDescription": "GLAXO WELLCOME MANUFACTURING PTE LTD",
     "TPSystemValue": 381
    },
    {
     
     "CodeDescription": "GLAXOSMITHKLINE",
     "TPSystemValue": 1902
    },
    {
     
     "CodeDescription": "GLAXOSMITHKLINE BIOLOGICALS",
     "TPSystemValue": 1422
    },
    {
     
     "CodeDescription": "GLAXOSMITHKLINE BIOLOGICALS SINGAPORE PTE LTD",
     "TPSystemValue": 3639
    },
    {
     
     "CodeDescription": "GLAXOSMITHKLINE PTE LTD",
     "TPSystemValue": 185
    },
    {
     
     "CodeDescription": "GLENCORE SINGAPORE PTE LTD",
     "TPSystemValue": 184
    },
    {
     
     "CodeDescription": "GLENEAGLES HOSPITAL",
     "TPSystemValue": 1903
    },
    {
     
     "CodeDescription": "GLOBAL BRANDS GROUP PTE LTD",
     "TPSystemValue": 1016
    },
    {
     
     "CodeDescription": "GLOBAL CORPORATE ADVISORY PTE LTD",
     "TPSystemValue": 983
    },
    {
     
     "CodeDescription": "GLOBAL EVENTS MANAGEMENT AND TRAVEL PRIVATE LIMITED",
     "TPSystemValue": "H892"
    },
    {
     
     "CodeDescription": "GLOBAL FOUNDARIES SINGAPORE",
     "TPSystemValue": 558
    },
    {
     
     "CodeDescription": "GLOBAL FOUNDRIES SINGAPORE PTE LTD",
     "TPSystemValue": 292
    },
    {
     
     "CodeDescription": "GLOBAL MARINE TRANSPORTATION PTE LTD",
     "TPSystemValue": 638
    },
    {
     
     "CodeDescription": "GLOBAL MARKETING GROUP PTE LTD",
     "TPSystemValue": 1086
    },
    {
     
     "CodeDescription": "GLOBAL TRAVEL EXPERTS PTE. LTD.",
     "TPSystemValue": "H877"
    },
    {
     
     "CodeDescription": "GLOBAL TRAVEL RESERVATION SYSTEM PTE. LTD.",
     "TPSystemValue": "H931"
    },
    {
     
     "CodeDescription": "GLOBE TRAVEL PTE. LTD.",
     "TPSystemValue": "X143"
    },
    {
     
     "CodeDescription": "GLOWORLD TRAVEL PTE. LTD.",
     "TPSystemValue": "X029"
    },
    {
     
     "CodeDescription": "GLP SINGAPORE PTE LTD",
     "TPSystemValue": 3640
    },
    {
     
     "CodeDescription": "GMP RECRUITMENT SERVICES S PTE LTD",
     "TPSystemValue": 448
    },
    {
     
     "CodeDescription": "GMR ENERGY SINGAPORE PTE LTD",
     "TPSystemValue": 684
    },
    {
     
     "CodeDescription": "GOEASYTRIP PTE. LTD.",
     "TPSystemValue": "H935"
    },
    {
     
     "CodeDescription": "GOH KWANG HENG PTE LTD",
     "TPSystemValue": 1904
    },
    {
     
     "CodeDescription": "GOH WHERE TRANSPORTATION AND TOURS",
     "TPSystemValue": "X100"
    },
    {
     
     "CodeDescription": "GOLDBELL CORPORATION PTE LTD",
     "TPSystemValue": 1121
    },
    {
     
     "CodeDescription": "GOLDBELL LEASING PTE LTD",
     "TPSystemValue": 343
    },
    {
     
     "CodeDescription": "GOLDCREST INTERNATIONAL SINGAPORE PL",
     "TPSystemValue": 513
    },
    {
     
     "CodeDescription": "GOLDEN BRIDGE (SINGAPORE) TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "H864"
    },
    {
     
     "CodeDescription": "GOLDEN GLOBE TRAVEL & SERVICES PTE. LTD.",
     "TPSystemValue": "X043"
    },
    {
     
     "CodeDescription": "GOLDEN OCTOPUS TRADING PTE. LTD.",
     "TPSystemValue": 1091
    },
    {
     
     "CodeDescription": "GOLDEN TOP TRAVEL",
     "TPSystemValue": "X072"
    },
    {
     
     "CodeDescription": "GOLDIN ENTERPRISE PRIVATE LIMITED",
     "TPSystemValue": 239
    },
    {
     
     "CodeDescription": "GOLFTRIPZ PTE. LTD.",
     "TPSystemValue": "H991"
    },
    {
     
     "CodeDescription": "GREENLAND TOURS PTE. LTD.",
     "TPSystemValue": "H916"
    },
    {
     
     "CodeDescription": "HALALTRIP PTE. LTD.",
     "TPSystemValue": "H848"
    },
    {
     
     "CodeDescription": "HAPETOUR",
     "TPSystemValue": "X083"
    },
    {
     
     "CodeDescription": "HAPPY INTERNET & TRAVEL SERVICES",
     "TPSystemValue": "X078"
    },
    {
     
     "CodeDescription": "HASH EDUCATION & TECHNOLOGY PTE. LTD.",
     "TPSystemValue": "X160"
    },
    {
     
     "CodeDescription": "HBG HOLIDAYS SINGAPORE PTE. LTD.",
     "TPSystemValue": "H972"
    },
    {
     
     "CodeDescription": "HEALING HOLIDAYS (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "X203"
    },
    {
     
     "CodeDescription": "HOTELS AND ATTRACTIONS PTE. LTD.",
     "TPSystemValue": "X141"
    },
    {
     
     "CodeDescription": "HOTSENG BUSINESS & TRAVEL PTE. LTD.",
     "TPSystemValue": "H899"
    },
    {
     
     "CodeDescription": "HTPL TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "H985"
    },
    {
     
     "CodeDescription": "HUA CHENG SINGAPORE A TRAVEL PTE. LTD.",
     "TPSystemValue": "X060"
    },
    {
     
     "CodeDescription": "HUTCHISON TRAVEL (HK) LIMITED",
     "TPSystemValue": "X111"
    },
    {
     
     "CodeDescription": "ICONIC BUSINESS CORPORATION PTE. LTD.",
     "TPSystemValue": "H984"
    },
    {
     
     "CodeDescription": "IDEAL TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H886"
    },
    {
     
     "CodeDescription": "INCREDIBLE JOURNEYS PTE. LTD.",
     "TPSystemValue": "X159"
    },
    {
     
     "CodeDescription": "INCREDIBLE VOYAGES PTE. LTD.",
     "TPSystemValue": "H907"
    },
    {
     
     "CodeDescription": "INDIAN BANK",
     "TPSystemValue": "I02"
    },
    {
     
     "CodeDescription": "INDIAN OVERSEAS BANK",
     "TPSystemValue": 2808
    },
    {
     
     "CodeDescription": "INDIAN OVERSEAS BANK",
     "TPSystemValue": "I03"
    },
    {
     
     "CodeDescription": "INDIE SINGAPORE TOURS LLP",
     "TPSystemValue": "X125"
    },
    {
     
     "CodeDescription": "INDOCHINE WATERFRONT PTE LTD",
     "TPSystemValue": 799
    },
    {
     
     "CodeDescription": "INDOCHINE WINE BAR & RESTAURANT PTE LTD",
     "TPSystemValue": 536
    },
    {
     
     "CodeDescription": "INDON SHIPPING PTE LTD",
     "TPSystemValue": 228
    },
    {
     
     "CodeDescription": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED",
     "TPSystemValue": "I04"
    },
    {
     
     "CodeDescription": "INFIGRO PTE LTD",
     "TPSystemValue": 844
    },
    {
     
     "CodeDescription": "INFIGRO PTE. LTD.",
     "TPSystemValue": "04I"
    },
    {
     
     "CodeDescription": "INFINEON TECHNOLOGIES",
     "TPSystemValue": 1938
    },
    {
     
     "CodeDescription": "INFINEUM SINGAPORE PTE LTD",
     "TPSystemValue": 1939
    },
    {
     
     "CodeDescription": "INFINITE (GLOBAL) PTE LTD",
     "TPSystemValue": 182
    },
    {
     
     "CodeDescription": "INFINITE JOURNEYS",
     "TPSystemValue": "X090"
    },
    {
     
     "CodeDescription": "INFINITE PROSPERITY PTE LTD",
     "TPSystemValue": 1182
    },
    {
     
     "CodeDescription": "INFINITI SOLUTIONS PRIVATE LIMITED",
     "TPSystemValue": 3804
    },
    {
     
     "CodeDescription": "INFOCOMM ASIA HOLDINGS PTE. LTD",
     "TPSystemValue": 951
    },
    {
     
     "CodeDescription": "INFOCOMM DEVELOPMENT AUTHORITY OF SINGAPORE",
     "TPSystemValue": 3649
    },
    {
     
     "CodeDescription": "INFOSTACK SOLUTIONS PTE. LTD.",
     "TPSystemValue": 1179
    },
    {
     
     "CodeDescription": "INFOSYS TECHNOLOGIES LIMITED",
     "TPSystemValue": 3068
    },
    {
     
     "CodeDescription": "ING BANK N.V.",
     "TPSystemValue": "I05"
    },
    {
     
     "CodeDescription": "ING BANK NV",
     "TPSystemValue": 2820
    },
    {
     
     "CodeDescription": "ING BANK NV",
     "TPSystemValue": "05I"
    },
    {
     
     "CodeDescription": "INGERSOLLRAND SOUTH EAST ASIA P LTD",
     "TPSystemValue": 28
    },
    {
     
     "CodeDescription": "INGRAM MICRO ASIA LTD",
     "TPSystemValue": 218
    },
    {
     
     "CodeDescription": "INLAND REVENUE AUTHORITY OF SINGAPORE",
     "TPSystemValue": 1940
    },
    {
     
     "CodeDescription": "INNOFORM MEDIA PTE LTD",
     "TPSystemValue": 3
    },
    {
     
     "CodeDescription": "INNOTREK",
     "TPSystemValue": 1395
    },
    {
     
     "CodeDescription": "INNOTREK PTE. LTD.",
     "TPSystemValue": "06I"
    },
    {
     
     "CodeDescription": "INNOVARE MANAGEMENT SINGAPORE PTE. LTD",
     "TPSystemValue": 795
    },
    {
     
     "CodeDescription": "INNOX HIGA SINGAPORE PTE. LTD.",
     "TPSystemValue": 489
    },
    {
     
     "CodeDescription": "INS COMMUNICATIONS PRIVATE LIMITED",
     "TPSystemValue": 362
    },
    {
     
     "CodeDescription": "INSEAD",
     "TPSystemValue": 1941
    },
    {
     
     "CodeDescription": "INSPECTORATE SINGAPORE PTE LTD",
     "TPSystemValue": 1942
    },
    {
     
     "CodeDescription": "INSPIRE EDUCATION CENTRE PTE LTD",
     "TPSystemValue": 1146
    },
    {
     
     "CodeDescription": "INSTITUTE FOR INFOCOMM RESEARCH",
     "TPSystemValue": 649
    },
    {
     
     "CodeDescription": "INSTITUTE OF BIOENGINEERING NANOTECH",
     "TPSystemValue": 1406
    },
    {
     
     "CodeDescription": "INSTITUTE OF HIGH PERFORMANCE COMPUTING",
     "TPSystemValue": 660
    },
    {
     
     "CodeDescription": "INSTITUTE OF MATERIALS RESEARCH ENGIN",
     "TPSystemValue": 596
    },
    {
     
     "CodeDescription": "INSTITUTE OF MEDICAL BIOLOGY",
     "TPSystemValue": 1410
    },
    {
     
     "CodeDescription": "INSTITUTE OF MENTAL HEALTH",
     "TPSystemValue": 1392
    },
    {
     
     "CodeDescription": "INSTITUTE OF MICROELECTRONICS",
     "TPSystemValue": 594
    },
    {
     
     "CodeDescription": "INSTITUTE OF MOLECULAR AND CELL BIOLOGY",
     "TPSystemValue": 1943
    },
    {
     
     "CodeDescription": "INSTITUTE OF TECHNICAL EDUCATION",
     "TPSystemValue": 1944
    },
    {
     
     "CodeDescription": "INSYNCHQ PTE LTD",
     "TPSystemValue": 1303
    },
    {
     
     "CodeDescription": "INTEGRALIS SERVICES PRIVATE LIMITED",
     "TPSystemValue": 1144
    },
    {
     
     "CodeDescription": "INTEGRATED HEALTH INFOR. SYSTEMS PL",
     "TPSystemValue": 1172
    },
    {
     
     "CodeDescription": "INTELLECTUAL PROPERTY OFFICE OF (SIN)",
     "TPSystemValue": 3095
    },
    {
     
     "CodeDescription": "INTELLECTUAL VENTURES ASIA PTE LTD",
     "TPSystemValue": 1115
    },
    {
     
     "CodeDescription": "INTELLIGEN LEGAL LLC",
     "TPSystemValue": 887
    },
    {
     
     "CodeDescription": "INTERAKT ACTIVE COMMUNICATION INTER PL",
     "TPSystemValue": 1130
    },
    {
     
     "CodeDescription": "INTERCALL SINGAPORE PTE LTD",
     "TPSystemValue": 766
    },
    {
     
     "CodeDescription": "INTERFLY",
     "TPSystemValue": "X101"
    },
    {
     
     "CodeDescription": "INTERISLAND MANPOWER PTE LTD",
     "TPSystemValue": 1167
    },
    {
     
     "CodeDescription": "INTERNATIONAL APPLICATION SOLUTIONS PL",
     "TPSystemValue": 373
    },
    {
     
     "CodeDescription": "INTERNATIONAL BIBLE EXPERIENCES PTE. LTD.",
     "TPSystemValue": "H862"
    },
    {
     
     "CodeDescription": "INTERNATIONAL COMMUNITY SCHOOL S LTD",
     "TPSystemValue": 437
    },
    {
     
     "CodeDescription": "INTERNATIONAL DATA CORPORATION APC PL",
     "TPSystemValue": 553
    },
    {
     
     "CodeDescription": "INTERNATIONAL DESIGN STUDIO PTE LTD",
     "TPSystemValue": 1215
    },
    {
     
     "CodeDescription": "INTERNATIONAL ENGINE COMPONENT OVERHAUL",
     "TPSystemValue": 589
    },
    {
     
     "CodeDescription": "INTERNATIONAL ENTERPRISE SINGAPORE",
     "TPSystemValue": 3650
    },
    {
     
     "CodeDescription": "INTERNATIONAL PAINT SINGAPORE PTE LTD",
     "TPSystemValue": 3651
    },
    {
     
     "CodeDescription": "INTERNATIONAL SOS PTE LTD",
     "TPSystemValue": 187
    },
    {
     
     "CodeDescription": "INTERNATIONAL SQL STAR PTE LTD",
     "TPSystemValue": 539
    },
    {
     
     "CodeDescription": "INTERQUEST ASIA PTE LTD",
     "TPSystemValue": 1339
    },
    {
     
     "CodeDescription": "INTESA SANPAOLO S.P.A.",
     "TPSystemValue": "I06"
    },
    {
     
     "CodeDescription": "INTESA SANPAOLO SPA",
     "TPSystemValue": 3082
    },
    {
     
     "CodeDescription": "INTRACO LIMITED",
     "TPSystemValue": 46
    },
    {
     
     "CodeDescription": "INTRIQ JOURNEY PTE. LTD.",
     "TPSystemValue": "X205"
    },
    {
     
     "CodeDescription": "ISLAND BUS EXPRESS PTE. LTD.",
     "TPSystemValue": "H963"
    },
    {
     
     "CodeDescription": "IVC CONSULTING SERVICES PTE. LTD.",
     "TPSystemValue": "H968"
    },
    {
     
     "CodeDescription": "IWT LLP",
     "TPSystemValue": "X114"
    },
    {
     
     "CodeDescription": "JANE'S SINGAPORE TOURS PTE. LTD.",
     "TPSystemValue": "X219"
    },
    {
     
     "CodeDescription": "JARTA (S) PTE. LTD.",
     "TPSystemValue": "H908"
    },
    {
     
     "CodeDescription": "JE TRAVEL",
     "TPSystemValue": "X095"
    },
    {
     
     "CodeDescription": "JETASIA TRAVEL PTE. LTD.",
     "TPSystemValue": "H995"
    },
    {
     
     "CodeDescription": "JFS TRAVEL PTE. LTD.",
     "TPSystemValue": "H992"
    },
    {
     
     "CodeDescription": "JIA HE 88 TRADING",
     "TPSystemValue": "X080"
    },
    {
     
     "CodeDescription": "JJ TRAVEL & MICE PTE. LTD.",
     "TPSystemValue": "X179"
    },
    {
     
     "CodeDescription": "JOYFUL JOURNEY INTERNATIONAL TRAVEL (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H942"
    },
    {
     
     "CodeDescription": "JOYOJ PTE. LTD.",
     "TPSystemValue": "X050"
    },
    {
     
     "CodeDescription": "JOYTOUR HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H917"
    },
    {
     
     "CodeDescription": "KATYUSHA TOUR PTE. LTD.",
     "TPSystemValue": "H873"
    },
    {
     
     "CodeDescription": "KAYAKASIA SG",
     "TPSystemValue": "X093"
    },
    {
     
     "CodeDescription": "KEMBARAUSTAZ.SG PTE. LTD.",
     "TPSystemValue": "X169"
    },
    {
     
     "CodeDescription": "KHAN TOUR & TRAVEL PTE. LTD.",
     "TPSystemValue": "X189"
    },
    {
     
     "CodeDescription": "KING JIN CORPORATION PTE. LTD.",
     "TPSystemValue": "X208"
    },
    {
     
     "CodeDescription": "KIWI TOUR PTE. LTD.",
     "TPSystemValue": "H969"
    },
    {
     
     "CodeDescription": "KKDAY SINGAPORE PTE. LTD.",
     "TPSystemValue": "H902"
    },
    {
     
     "CodeDescription": "KLOOK TRAVEL TECHNOLOGY PTE. LTD.",
     "TPSystemValue": "H882"
    },
    {
     
     "CodeDescription": "KM TRAVELS & TOURS SERVICES PTE. LTD.",
     "TPSystemValue": "H904"
    },
    {
     
     "CodeDescription": "KOH BUS TRANSPORT SERVICE PTE. LTD.",
     "TPSystemValue": "X030"
    },
    {
     
     "CodeDescription": "KOREA NTC TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "X004"
    },
    {
     
     "CodeDescription": "KWANG CHOW PTE. LIMITED",
     "TPSystemValue": "H978"
    },
    {
     
     "CodeDescription": "KYOSEI TRAVEL CO-OPERATIVE LIMITED",
     "TPSystemValue": "X121"
    },
    {
     
     "CodeDescription": "LA HOLIDAYS TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H929"
    },
    {
     
     "CodeDescription": "LAGOON TRAVEL PTE. LTD.",
     "TPSystemValue": "X211"
    },
    {
     
     "CodeDescription": "LAON TRAVEL PTE. LTD.",
     "TPSystemValue": "H938"
    },
    {
     
     "CodeDescription": "LDA TRAVEL",
     "TPSystemValue": "X098"
    },
    {
     
     "CodeDescription": "LEISURE PASS ASIA PTE. LIMITED",
     "TPSystemValue": "X152"
    },
    {
     
     "CodeDescription": "LET'S GO TOUR SINGAPORE PTE. LTD.",
     "TPSystemValue": "H872"
    },
    {
     
     "CodeDescription": "LIGHTHOUSE EXPEDITIONS PTE. LTD.",
     "TPSystemValue": "X210"
    },
    {
     
     "CodeDescription": "LIMO Z PTE. LTD.",
     "TPSystemValue": "H912"
    },
    {
     
     "CodeDescription": "LIONESS ROAR GLOBAL PTE. LTD.",
     "TPSystemValue": "X039"
    },
    {
     
     "CodeDescription": "LIONHEARTLANDERS PTE. LTD.",
     "TPSystemValue": "X034"
    },
    {
     
     "CodeDescription": "LITTLE MONSTERS TRAVEL PTE. LTD.",
     "TPSystemValue": "X018"
    },
    {
     
     "CodeDescription": "LIVING TROVE PTE. LTD.",
     "TPSystemValue": "X070"
    },
    {
     
     "CodeDescription": "LONG VACATION (S) PTE. LTD.",
     "TPSystemValue": "H926"
    },
    {
     
     "CodeDescription": "LOVE4SCUBA PTE. LTD.",
     "TPSystemValue": "X147"
    },
    {
     
     "CodeDescription": "LUXE TOURZ PTE. LTD.",
     "TPSystemValue": "H867"
    },
    {
     
     "CodeDescription": "LUXURY COACH SERVICE",
     "TPSystemValue": "X071"
    },
    {
     
     "CodeDescription": "LUXURY DESTINATIONS PTE. LTD.",
     "TPSystemValue": "X154"
    },
    {
     
     "CodeDescription": "LUXURY ESCAPES (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H905"
    },
    {
     
     "CodeDescription": "LVMAMA INTERNATIONAL TRAVEL SERVICE (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "X048"
    },
    {
     
     "CodeDescription": "MABEY SKI PTE. LTD.",
     "TPSystemValue": "H999"
    },
    {
     
     "CodeDescription": "MAD DESTINATION ASIA PTE. LTD.",
     "TPSystemValue": "X009"
    },
    {
     
     "CodeDescription": "MAJOR INTERNATIONAL E&C PTE. LTD.",
     "TPSystemValue": "H996"
    },
    {
     
     "CodeDescription": "MALEE TRAVELS SINGAPORE PTE. LTD.",
     "TPSystemValue": "H987"
    },
    {
     
     "CodeDescription": "MAM HOLIDAYS SINGAPORE PTE. LTD.",
     "TPSystemValue": "X168"
    },
    {
     
     "CodeDescription": "MARINA BAY TRAVEL PTE. LTD.",
     "TPSystemValue": "H856"
    },
    {
     
     "CodeDescription": "MEGA TOURS PTE. LTD.",
     "TPSystemValue": "X135"
    },
    {
     
     "CodeDescription": "MEGA TRAVEL GALAXY PTE. LTD.",
     "TPSystemValue": "H923"
    },
    {
     
     "CodeDescription": "METROPOLITAN YMCA SINGAPORE",
     "TPSystemValue": "X107"
    },
    {
     
     "CodeDescription": "MFW TRAVEL SINGAPORE PTE. LTD.",
     "TPSystemValue": "X035"
    },
    {
     
     "CodeDescription": "MICE & CO PTE. LTD.",
     "TPSystemValue": "X156"
    },
    {
     
     "CodeDescription": "MIU TRAVEL PTE. LTD.",
     "TPSystemValue": "X126"
    },
    {
     
     "CodeDescription": "MS TRAVELS & TOURS PTE. LTD.",
     "TPSystemValue": "H954"
    },
    {
     
     "CodeDescription": "MULTIPLE WIN SOLUTIONS PTE. LTD.",
     "TPSystemValue": "X162"
    },
    {
     
     "CodeDescription": "MUMTAZ HOLIDAYS & TOURS PTE. LTD.",
     "TPSystemValue": "X148"
    },
    {
     
     "CodeDescription": "NAM HO DMC PTE. LTD.",
     "TPSystemValue": "H977"
    },
    {
     
     "CodeDescription": "NANAYAM TRAVELS PTE. LTD.",
     "TPSystemValue": "H849"
    },
    {
     
     "CodeDescription": "NATIVE INC. PTE. LTD.",
     "TPSystemValue": "X062"
    },
    {
     
     "CodeDescription": "NAVISTEPS PTE. LTD.",
     "TPSystemValue": "X157"
    },
    {
     
     "CodeDescription": "NEW HEART HOLIDAY PTE. LTD.",
     "TPSystemValue": "H990"
    },
    {
     
     "CodeDescription": "NEW STAR TRAVEL PTE. LTD.",
     "TPSystemValue": "H861"
    },
    {
     
     "CodeDescription": "NISSI TOURS AND TRAVELS PTE. LTD.",
     "TPSystemValue": "H957"
    },
    {
     
     "CodeDescription": "NOAH TRAVEL AND TOURS PTE. LTD.",
     "TPSystemValue": "X023"
    },
    {
     
     "CodeDescription": "NUGDS.COM PTE. LTD.",
     "TPSystemValue": "H934"
    },
    {
     
     "CodeDescription": "NUSATRIP SINGAPORE PTE. LTD.",
     "TPSystemValue": "H932"
    },
    {
     
     "CodeDescription": "OCEAN'S ODYSSEY PTE. LTD.",
     "TPSystemValue": "X201"
    },
    {
     
     "CodeDescription": "OOSS TRAVEL PTE. LTD.",
     "TPSystemValue": "X128"
    },
    {
     
     "CodeDescription": "ORCHID DMC (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H925"
    },
    {
     
     "CodeDescription": "ORICO CONSULTANT",
     "TPSystemValue": "X081"
    },
    {
     
     "CodeDescription": "ORIENTAL TRAVEL AND TOURS LLP",
     "TPSystemValue": "X119"
    },
    {
     
     "CodeDescription": "OTTER TOURS",
     "TPSystemValue": "X197"
    },
    {
     
     "CodeDescription": "OYSTERS & PEARLS PTE. LTD.",
     "TPSystemValue": "X010"
    },
    {
     
     "CodeDescription": "PALM MARINE TRAVEL PTE. LTD.",
     "TPSystemValue": "H948"
    },
    {
     
     "CodeDescription": "PESHWAIS PTE. LTD.",
     "TPSystemValue": "X190"
    },
    {
     
     "CodeDescription": "PING AN TRAVEL PTE. LTD.",
     "TPSystemValue": "H866"
    },
    {
     
     "CodeDescription": "PLAN B TRAVELS AND EVENTS LLP",
     "TPSystemValue": "X116"
    },
    {
     
     "CodeDescription": "PLOVER TRIP PTE. LTD.",
     "TPSystemValue": "H959"
    },
    {
     
     "CodeDescription": "PUDU PUDA LLP",
     "TPSystemValue": "X123"
    },
    {
     
     "CodeDescription": "QOO10 PTE. LTD.",
     "TPSystemValue": "X012"
    },
    {
     
     "CodeDescription": "RADIANT HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H998"
    },
    {
     
     "CodeDescription": "RAIINBOW TRAVELS PTE. LTD.",
     "TPSystemValue": "X180"
    },
    {
     
     "CodeDescription": "RAVISHING INDIA HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H847"
    },
    {
     
     "CodeDescription": "RAYNA TOURS PTE. LTD.",
     "TPSystemValue": "H884"
    },
    {
     
     "CodeDescription": "RD TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "X026"
    },
    {
     
     "CodeDescription": "REED & MACKAY TRAVEL SINGAPORE PTE. LTD.",
     "TPSystemValue": "H997"
    },
    {
     
     "CodeDescription": "REET TRAVEL CONSULTANCY",
     "TPSystemValue": "X094"
    },
    {
     
     "CodeDescription": "ROCKERFELLAS ADVENTURE PTE. LTD.",
     "TPSystemValue": "H878"
    },
    {
     
     "CodeDescription": "ROYAL HOLIDAY PTE. LTD.",
     "TPSystemValue": "X038"
    },
    {
     
     "CodeDescription": "RSVP SINGAPORE THE ORGANISATION OF SENIOR VOLUNTEERS",
     "TPSystemValue": "X109"
    },
    {
     
     "CodeDescription": "RTW EXPRESS SERVICES",
     "TPSystemValue": "X076"
    },
    {
     
     "CodeDescription": "RUNWAY 86 PTE. LTD.",
     "TPSystemValue": "X032"
    },
    {
     
     "CodeDescription": "RUSSIAN ASIAN BUSINESS TRAVEL PTE. LTD.",
     "TPSystemValue": "X068"
    },
    {
     
     "CodeDescription": "SABAI TOURS",
     "TPSystemValue": "X103"
    },
    {
     
     "CodeDescription": "SAHEL TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "X172"
    },
    {
     
     "CodeDescription": "SAI TOURS AND TRAVELS PTE. LTD.",
     "TPSystemValue": "H939"
    },
    {
     
     "CodeDescription": "SAIF TRAVEL AND TOURS LLP",
     "TPSystemValue": "X198"
    },
    {
     
     "CodeDescription": "SARA T & T",
     "TPSystemValue": "X104"
    },
    {
     
     "CodeDescription": "SCOTT DUNN SINGAPORE PTE. LTD.",
     "TPSystemValue": "H909"
    },
    {
     
     "CodeDescription": "SCUBA KNIGHTS PTE. LTD.",
     "TPSystemValue": "H928"
    },
    {
     
     "CodeDescription": "SCUBA VOYAGERS SINGAPORE PTE. LTD.",
     "TPSystemValue": "X022"
    },
    {
     
     "CodeDescription": "SEA DIVE TRAVEL PTE. LTD.",
     "TPSystemValue": "H871"
    },
    {
     
     "CodeDescription": "SGTREK PTE. LTD.",
     "TPSystemValue": "X031"
    },
    {
     
     "CodeDescription": "SHAN BROTHERS PTE. LTD.",
     "TPSystemValue": "X051"
    },
    {
     
     "CodeDescription": "SHEEN YEOW TRAVEL PTE. LTD.",
     "TPSystemValue": "X186"
    },
    {
     
     "CodeDescription": "SHOBUNSHA SINGAPORE PTE. LTD.",
     "TPSystemValue": "H974"
    },
    {
     
     "CodeDescription": "SIMPLY SCUBA PRIVATE LIMITED",
     "TPSystemValue": "H896"
    },
    {
     
     "CodeDescription": "SIN MA EXPRESS",
     "TPSystemValue": "X140"
    },
    {
     
     "CodeDescription": "SINCERE HOLIDAY TRAVELS PTE. LTD.",
     "TPSystemValue": "X005"
    },
    {
     
     "CodeDescription": "SINGAPORE CITY EXPLORERS PTE. LTD.",
     "TPSystemValue": "H846"
    },
    {
     
     "CodeDescription": "SINGAPORE LAND TOUR PTE. LTD.",
     "TPSystemValue": "H964"
    },
    {
     
     "CodeDescription": "SINGAPORE RISING PLEASURE INTERNATIONAL TRAVEL PTE. LTD.",
     "TPSystemValue": "H852"
    },
    {
     
     "CodeDescription": "SINGAPORE TREKKER",
     "TPSystemValue": "X085"
    },
    {
     
     "CodeDescription": "SINGTOUR INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H885"
    },
    {
     
     "CodeDescription": "SINYO HOLIDAYS (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H940"
    },
    {
     
     "CodeDescription": "SIVANA DMC PTE. LTD.",
     "TPSystemValue": "X194"
    },
    {
     
     "CodeDescription": "SK COMFORT TRAVELS",
     "TPSystemValue": "X099"
    },
    {
     
     "CodeDescription": "SKILLSEED PTE. LTD.",
     "TPSystemValue": "H850"
    },
    {
     
     "CodeDescription": "SKYSCANNER 2018 (SINGAPORE) PTE. LIMITED",
     "TPSystemValue": "X056"
    },
    {
     
     "CodeDescription": "SOE TUN TICKETING & SERVICES",
     "TPSystemValue": "X097"
    },
    {
     
     "CodeDescription": "SOL & LUNA (PTE. LTD.)",
     "TPSystemValue": "X214"
    },
    {
     
     "CodeDescription": "SONNENKIND PTE. LTD.",
     "TPSystemValue": "H993"
    },
    {
     
     "CodeDescription": "SOON TRAVEL PTE. LTD.",
     "TPSystemValue": "X149"
    },
    {
     
     "CodeDescription": "SPORTS TRAVEL 360 PTE. LTD.",
     "TPSystemValue": "X033"
    },
    {
     
     "CodeDescription": "SRC TRAVEL & TOURS (PTE.) LTD.",
     "TPSystemValue": "X014"
    },
    {
     
     "CodeDescription": "STAR TRAVELERS SG PTE. LTD.",
     "TPSystemValue": "X153"
    },
    {
     
     "CodeDescription": "STS WORLD DMC PTE. LTD.",
     "TPSystemValue": "X065"
    },
    {
     
     "CodeDescription": "STUDENT SERVICES CONSULTANCY PTE. LTD.",
     "TPSystemValue": "H865"
    },
    {
     
     "CodeDescription": "SUNRISE TRAVEL PTE. LTD.",
     "TPSystemValue": "X142"
    },
    {
     
     "CodeDescription": "SUPER TRAVELS GROUP PTE. LTD.",
     "TPSystemValue": "X184"
    },
    {
     
     "CodeDescription": "SWISH TRAVEL PTE. LTD.",
     "TPSystemValue": "X052"
    },
    {
     
     "CodeDescription": "SYUKRAN TRAVEL PTE. LTD.",
     "TPSystemValue": "X027"
    },
    {
     
     "CodeDescription": "SZIMSAI HOLIDAYS EVENTS PTE. LTD.",
     "TPSystemValue": "H962"
    },
    {
     
     "CodeDescription": "T&M TOUR GLOBAL PTE. LTD.",
     "TPSystemValue": "H950"
    },
    {
     
     "CodeDescription": "TAN LEONG HUAT PTE. LTD.",
     "TPSystemValue": "H956"
    },
    {
     
     "CodeDescription": "TANGY WORLDWIDE TRAVEL & TOURS",
     "TPSystemValue": "X084"
    },
    {
     
     "CodeDescription": "TAS GLOBAL HOLDINGS PTE. LTD.",
     "TPSystemValue": "H946"
    },
    {
     
     "CodeDescription": "TASTECH PTE. LTD.",
     "TPSystemValue": "X175"
    },
    {
     
     "CodeDescription": "TASTY CULTURAL TRAVEL SINGAPORE PTE. LTD.",
     "TPSystemValue": "H981"
    },
    {
     
     "CodeDescription": "TC WONDERS PTE. LTD.",
     "TPSystemValue": "X146"
    },
    {
     
     "CodeDescription": "TDMC PTE. LTD.",
     "TPSystemValue": "X221"
    },
    {
     
     "CodeDescription": "THE FOOD HEROES PTE. LTD.",
     "TPSystemValue": "X167"
    },
    {
     
     "CodeDescription": "THE GOLDEN GATE TRAVEL (S) PTE. LTD.",
     "TPSystemValue": "X218"
    },
    {
     
     "CodeDescription": "THE HELLO TOURISM COMPANY SINGAPORE PTE. LTD.",
     "TPSystemValue": "H941"
    },
    {
     
     "CodeDescription": "THE MORE I TRAVEL PTE. LTD.",
     "TPSystemValue": "X151"
    },
    {
     
     "CodeDescription": "THE RIDE SIDE PRIVATE LIMITED",
     "TPSystemValue": "H922"
    },
    {
     
     "CodeDescription": "THE SUMMIT LIMITED LIABILITY PARTNERSHIP",
     "TPSystemValue": "X122"
    },
    {
     
     "CodeDescription": "THE WANDERERS PTE. LTD.",
     "TPSystemValue": "X063"
    },
    {
     
     "CodeDescription": "THE WANDERING LENS PTE. LTD.",
     "TPSystemValue": "X066"
    },
    {
     
     "CodeDescription": "THEATLAS PTE. LTD.",
     "TPSystemValue": "X204"
    },
    {
     
     "CodeDescription": "THIN MARGIN PRIVATE LIMITED",
     "TPSystemValue": "H920"
    },
    {
     
     "CodeDescription": "THK TOUR AND TRAVEL PTE. LTD.",
     "TPSystemValue": "H895"
    },
    {
     
     "CodeDescription": "THREE IS TWO PTE. LTD.",
     "TPSystemValue": "H989"
    },
    {
     
     "CodeDescription": "TIAN TIAN TRAVEL",
     "TPSystemValue": "X096"
    },
    {
     
     "CodeDescription": "TIME DESIGN INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "X001"
    },
    {
     
     "CodeDescription": "TLC ADVENTURE TOURS PTE. LTD.",
     "TPSystemValue": "H900"
    },
    {
     
     "CodeDescription": "TLC TRAVEL (S) PTE. LTD.",
     "TPSystemValue": "X028"
    },
    {
     
     "CodeDescription": "TMC TRAVEL PTE. LTD.",
     "TPSystemValue": "X174"
    },
    {
     
     "CodeDescription": "TMT TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "X025"
    },
    {
     
     "CodeDescription": "TOPLINERS PTE. LTD.",
     "TPSystemValue": "H881"
    },
    {
     
     "CodeDescription": "TOUR IN SINGAPORE PTE. LTD.",
     "TPSystemValue": "H919"
    },
    {
     
     "CodeDescription": "TOUR MOUNT BROMO PTE. LTD.",
     "TPSystemValue": "H851"
    },
    {
     
     "CodeDescription": "TRAVEL 360 PTE. LTD.",
     "TPSystemValue": "H947"
    },
    {
     
     "CodeDescription": "TRAVEL CENTRAL HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H921"
    },
    {
     
     "CodeDescription": "TRAVEL CURATORS PTE. LTD.",
     "TPSystemValue": "H889"
    },
    {
     
     "CodeDescription": "TRAVEL KHAKIS PTE. LTD.",
     "TPSystemValue": "X059"
    },
    {
     
     "CodeDescription": "TRAVEL KING INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H952"
    },
    {
     
     "CodeDescription": "TRAVEL PLANNERS MART PTE. LTD.",
     "TPSystemValue": "X044"
    },
    {
     
     "CodeDescription": "TRAVEL RIGHT PTE. LTD.",
     "TPSystemValue": "X187"
    },
    {
     
     "CodeDescription": "TRAVEL TRADE MARKETPLACE PTE. LTD.",
     "TPSystemValue": "X130"
    },
    {
     
     "CodeDescription": "TRAVEL WANDER LLP",
     "TPSystemValue": "X117"
    },
    {
     
     "CodeDescription": "TRAVELATTE PTE. LTD.",
     "TPSystemValue": "H888"
    },
    {
     
     "CodeDescription": "TRAVELBULLZ PTE. LTD.",
     "TPSystemValue": "X220"
    },
    {
     
     "CodeDescription": "TRAVELBY LLP",
     "TPSystemValue": "X124"
    },
    {
     
     "CodeDescription": "TRAVELCONNECT.SG PTE. LTD.",
     "TPSystemValue": "H883"
    },
    {
     
     "CodeDescription": "TRAVELLERS.COM",
     "TPSystemValue": "X102"
    },
    {
     
     "CodeDescription": "TRAVELOGIX PTE. LTD.",
     "TPSystemValue": "H961"
    },
    {
     
     "CodeDescription": "TRAVELUXIS PTE. LTD.",
     "TPSystemValue": "X134"
    },
    {
     
     "CodeDescription": "TRAVIN TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "X145"
    },
    {
     
     "CodeDescription": "TREKKERS LEAGUE LLP",
     "TPSystemValue": "X118"
    },
    {
     
     "CodeDescription": "TREVELLAH PTE. LTD.",
     "TPSystemValue": "X177"
    },
    {
     
     "CodeDescription": "TRIP.COM TRAVEL SINGAPORE PTE. LTD.",
     "TPSystemValue": "H894"
    },
    {
     
     "CodeDescription": "TRIPURI AVIATION PTE. LTD.",
     "TPSystemValue": "H994"
    },
    {
     
     "CodeDescription": "TRIQUETRA SIDECARS PTE. LTD.",
     "TPSystemValue": "H973"
    },
    {
     
     "CodeDescription": "TRUTRIP PTE. LTD.",
     "TPSystemValue": "X196"
    },
    {
     
     "CodeDescription": "ULAVI TECHNOLOGIES PTE. LTD.",
     "TPSystemValue": "H927"
    },
    {
     
     "CodeDescription": "ULU SINGAPORE PTE. LTD.",
     "TPSystemValue": "H967"
    },
    {
     
     "CodeDescription": "UMIDOR AGENCY",
     "TPSystemValue": "X079"
    },
    {
     
     "CodeDescription": "USRAH TRAVEL PTE. LTD.",
     "TPSystemValue": "X020"
    },
    {
     
     "CodeDescription": "UTOUR TRAVEL PTE. LTD.",
     "TPSystemValue": "H880"
    },
    {
     
     "CodeDescription": "VACANZA GLOBAL TRAVELS PTE. LTD.",
     "TPSystemValue": "X170"
    },
    {
     
     "CodeDescription": "VAMOSLAH PTE. LTD.",
     "TPSystemValue": "X042"
    },
    {
     
     "CodeDescription": "VANILLA TRAVEL PTE. LTD.",
     "TPSystemValue": "X016"
    },
    {
     
     "CodeDescription": "VASCO WORLDWIDE PTE. LTD.",
     "TPSystemValue": "X150"
    },
    {
     
     "CodeDescription": "VELS TRAVEL AND TOURS PTE. LTD.",
     "TPSystemValue": "H924"
    },
    {
     
     "CodeDescription": "VIETNAM TRAVEL AND MARKETING TRANSPORTS PTE. LTD.",
     "TPSystemValue": "H914"
    },
    {
     
     "CodeDescription": "VIHAARAYATRA TOURS AND TRAVELS LIMITED PARTNERSHIP",
     "TPSystemValue": "X120"
    },
    {
     
     "CodeDescription": "WAN JI CORPORATE TRAVEL PTE. LTD.",
     "TPSystemValue": "X069"
    },
    {
     
     "CodeDescription": "WANTTOSEE.WORLD PTE. LTD.",
     "TPSystemValue": "X017"
    },
    {
     
     "CodeDescription": "WATERSPOT LLP",
     "TPSystemValue": "X136"
    },
    {
     
     "CodeDescription": "WAUG TRAVEL SINGAPORE PTE. LTD.",
     "TPSystemValue": "X011"
    },
    {
     
     "CodeDescription": "WECONEX HOLDINGS (SEA) PTE. LTD.",
     "TPSystemValue": "X185"
    },
    {
     
     "CodeDescription": "WHITE LABS PTE. LTD.",
     "TPSystemValue": "X131"
    },
    {
     
     "CodeDescription": "WILDFIRE EXPEDITIONS PTE. LTD.",
     "TPSystemValue": "H906"
    },
    {
     
     "CodeDescription": "WILFRED GO TRAVELLING & HIKING PTE. LTD.",
     "TPSystemValue": "H983"
    },
    {
     
     "CodeDescription": "WILLERS PTE. LTD.",
     "TPSystemValue": "X129"
    },
    {
     
     "CodeDescription": "WINS TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "X165"
    },
    {
     
     "CodeDescription": "WOMEN TRAVEL TOGETHER HOLIDAYS PTE. LTD.",
     "TPSystemValue": "X139"
    },
    {
     
     "CodeDescription": "WORLDROAMER PTE. LTD.",
     "TPSystemValue": "H980"
    },
    {
     
     "CodeDescription": "XPERIENCE DMC PRIVATE LIMITED",
     "TPSystemValue": "X036"
    },
    {
     
     "CodeDescription": "YALLA HOLIDAYS PTE. LTD.",
     "TPSystemValue": "X181"
    },
    {
     
     "CodeDescription": "YCP EDUCATION SINGAPORE PTE. LTD.",
     "TPSystemValue": "H971"
    },
    {
     
     "CodeDescription": "YELLOW KUDU JOURNEYS",
     "TPSystemValue": "X132"
    },
    {
     
     "CodeDescription": "YOUNG MEN'S CHRISTIAN ASSOCIATION OF SINGAPORE",
     "TPSystemValue": "X106"
    },
    {
     
     "CodeDescription": "YOUR VACATION PTE. LTD.",
     "TPSystemValue": "H853"
    },
    {
     
     "CodeDescription": "YSK TRAVEL SERVICE & TRANSPORT",
     "TPSystemValue": "X092"
    },
    {
     
     "CodeDescription": "YUGEN TRAVEL PTE. LTD.",
     "TPSystemValue": "X193"
    },
    {
     
     "CodeDescription": "YUMMI HOUSE PTE. LTD.",
     "TPSystemValue": "X166"
    },
    {
     
     "CodeDescription": "ZAYN TRAVELS PTE. LTD.",
     "TPSystemValue": "X053"
    },
    {
     
     "CodeDescription": "ZOOM TRAVEL INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H876"
    },
    {
     
     "CodeDescription": "ZUMA TRAVEL & SERVICES PTE. LTD.",
     "TPSystemValue": "H893"
    },
    {
     
     "CodeDescription": "ACCENT ENGINEERING PTE LTD",
     "TPSystemValue": 1459
    },
    {
     
     "CodeDescription": "ACCENT ENGINEERING PTE LTD",
     "TPSystemValue": "02A"
    },
    {
     
     "CodeDescription": "ADVENT LAW CORPORATION",
     "TPSystemValue": 866
    },
    {
     
     "CodeDescription": "ADVENT LAW CORPORATION",
     "TPSystemValue": "06A"
    },
    {
     
     "CodeDescription": "AGRICULTURAL BANK OF CHINA LIMITED",
     "TPSystemValue": 2829
    },
    {
     
     "CodeDescription": "AGRICULTURAL BANK OF CHINA LIMITED",
     "TPSystemValue": "A03"
    },
    {
     
     "CodeDescription": "AIK CHEONG METAL ENGINEERING PTE. LTD.",
     "TPSystemValue": 1101
    },
    {
     
     "CodeDescription": "AIK CHEONG METAL ENGINEERING PTE. LTD.",
     "TPSystemValue": "07A"
    },
    {
     
     "CodeDescription": "AIQON CAPITAL PTE LTD",
     "TPSystemValue": 614
    },
    {
     
     "CodeDescription": "AIQON CAPITAL PTE LTD",
     "TPSystemValue": "08A"
    },
    {
     
     "CodeDescription": "AMCOR FLEXIBLES SINGAPORE PTE LTD",
     "TPSystemValue": 31
    },
    {
     
     "CodeDescription": "AMCOR FLEXIBLES SINGAPORE PTE LTD",
     "TPSystemValue": "11A"
    },
    {
     
     "CodeDescription": "APOSTROPHE FILMS PTE LTD",
     "TPSystemValue": 786
    },
    {
     
     "CodeDescription": "APOSTROPHE FILMS PTE LTD",
     "TPSystemValue": "16A"
    },
    {
     
     "CodeDescription": "AUTOMOBILE ASSOCIATION OF SINGAPORE",
     "TPSystemValue": 1578
    },
    {
     
     "CodeDescription": "B S M ENGINEERING PTE LTD",
     "TPSystemValue": 1581
    },
    {
     
     "CodeDescription": "B S M ENGINEERING PTE LTD",
     "TPSystemValue": "01B"
    },
    {
     
     "CodeDescription": "BANK OF BARODA",
     "TPSystemValue": 3421
    },
    {
     
     "CodeDescription": "BANK OF BARODA",
     "TPSystemValue": "B06"
    },
    {
     
     "CodeDescription": "BANK OF SINGAPORE LIMITED",
     "TPSystemValue": 1588
    },
    {
     
     "CodeDescription": "BANK OF SINGAPORE LIMITED",
     "TPSystemValue": "B14"
    },
    {
     
     "CodeDescription": "BANK OF TAIWAN",
     "TPSystemValue": 2828
    },
    {
     
     "CodeDescription": "BANK OF TAIWAN",
     "TPSystemValue": "B15"
    },
    {
     
     "CodeDescription": "BENG KHIM CONSTRUCTION COMPANY PTE LTD",
     "TPSystemValue": 1604
    },
    {
     
     "CodeDescription": "BENG KHIM CONSTRUCTION COMPANY PTE LTD",
     "TPSystemValue": "03B"
    },
    {
     
     "CodeDescription": "BNP PARIBAS",
     "TPSystemValue": 1615
    },
    {
     
     "CodeDescription": "BNP PARIBAS",
     "TPSystemValue": "B18"
    },
    {
     
     "CodeDescription": "BUILDERS TRENDS PTE LTD",
     "TPSystemValue": 1629
    },
    {
     
     "CodeDescription": "BUILDERS TRENDS PTE LTD",
     "TPSystemValue": "06B"
    },
    {
     
     "CodeDescription": "CANADIAN IMPERIAL BANK OF COMMERCE",
     "TPSystemValue": 1640
    },
    {
     
     "CodeDescription": "CANADIAN IMPERIAL BANK OF COMMERCE",
     "TPSystemValue": "C01"
    },
    {
     
     "CodeDescription": "CATHAY UNITED BANK",
     "TPSystemValue": 3089
    },
    {
     
     "CodeDescription": "CATHAY UNITED BANK",
     "TPSystemValue": "C02"
    },
    {
     
     "CodeDescription": "CHAN LAM CONSTRUCTION PTE LTD",
     "TPSystemValue": 1672
    },
    {
     
     "CodeDescription": "CHAN LAM CONSTRUCTION PTE LTD",
     "TPSystemValue": "05C"
    },
    {
     
     "CodeDescription": "CHANG HWA COMMERCIAL BANK LTD",
     "TPSystemValue": 3417
    },
    {
     
     "CodeDescription": "CHANG HWA COMMERCIAL BANK LTD",
     "TPSystemValue": "C03"
    },
    {
     
     "CodeDescription": "CHEW INTERIOR DESIGN PTE LTD",
     "TPSystemValue": 804
    },
    {
     
     "CodeDescription": "CHEW INTERIOR DESIGN PTE LTD",
     "TPSystemValue": "07C"
    },
    {
     
     "CodeDescription": "CITIBANK SINGAPORE LIMITED",
     "TPSystemValue": 893
    },
    {
     
     "CodeDescription": "CITIBANK SINGAPORE LIMITED",
     "TPSystemValue": "C08"
    },
    {
     
     "CodeDescription": "CLEANING EXPRESS PTE LTD",
     "TPSystemValue": 1707
    },
    {
     
     "CodeDescription": "CLEANING EXPRESS PTE LTD",
     "TPSystemValue": "09C"
    },
    {
     
     "CodeDescription": "COMMERZBANK AKTIENGESELLSCHAFT",
     "TPSystemValue": 2823
    },
    {
     
     "CodeDescription": "COMMERZBANK AKTIENGESELLSCHAFT",
     "TPSystemValue": "C12"
    },
    {
     
     "CodeDescription": "DBS BANK LTD",
     "TPSystemValue": 44
    },
    {
     
     "CodeDescription": "DBS BANK LTD",
     "TPSystemValue": "D01"
    },
    {
     
     "CodeDescription": "DISCOVER THE WORLD MARKETING PTE LTD",
     "TPSystemValue": "H172"
    },
    {
     
     "CodeDescription": "DISCOVER THE WORLD MARKETING PTE LTD",
     "TPSystemValue": 6
    },
    {
     
     "CodeDescription": "FIRST COMMERCIAL BANK",
     "TPSystemValue": 2815
    },
    {
     
     "CodeDescription": "FIRST COMMERCIAL BANK",
     "TPSystemValue": "F02"
    },
    {
     
     "CodeDescription": "FOURWAY ENGINEERING PTE LTD",
     "TPSystemValue": 485
    },
    {
     
     "CodeDescription": "FOURWAY ENGINEERING PTE LTD",
     "TPSystemValue": "02F"
    },
    {
     
     "CodeDescription": "ICF INTERNATIONAL PTE LTD",
     "TPSystemValue": 1166
    },
    {
     
     "CodeDescription": "ICF INTERNATIONAL PTE LTD",
     "TPSystemValue": "01I"
    },
    {
     
     "CodeDescription": "IFAST FINANCIAL PTE LTD",
     "TPSystemValue": 715
    },
    {
     
     "CodeDescription": "IFAST FINANCIAL PTE LTD",
     "TPSystemValue": "02I"
    },
    {
     
     "CodeDescription": "INNOTREK PTE. LTD.",
     "TPSystemValue": "H345"
    },
    {
     
     "CodeDescription": "J STUDIO PTE LTD",
     "TPSystemValue": 598
    },
    {
     
     "CodeDescription": "J STUDIO PTE LTD",
     "TPSystemValue": "01J"
    },
    {
     
     "CodeDescription": "JOHN CONSTRUCTION PTE LTD",
     "TPSystemValue": 1953
    },
    {
     
     "CodeDescription": "JOHN CONSTRUCTION PTE LTD",
     "TPSystemValue": "03J"
    },
    {
     
     "CodeDescription": "JOIN AGES CONSTRUCTION PTE LTD",
     "TPSystemValue": 1955
    },
    {
     
     "CodeDescription": "JOIN AGES CONSTRUCTION PTE LTD",
     "TPSystemValue": "04J"
    },
    {
     
     "CodeDescription": "JRP & ASSOCIATES PTE LTD",
     "TPSystemValue": 1957
    },
    {
     
     "CodeDescription": "JRP & ASSOCIATES PTE LTD",
     "TPSystemValue": "05J"
    },
    {
     
     "CodeDescription": "KAIROS MARINE PTE LTD",
     "TPSystemValue": 1968
    },
    {
     
     "CodeDescription": "KAIROS MARINE PTE LTD",
     "TPSystemValue": "02K"
    },
    {
     
     "CodeDescription": "KELANTAN ELECTRIC CO",
     "TPSystemValue": 1976
    },
    {
     
     "CodeDescription": "KELANTAN ELECTRIC CO",
     "TPSystemValue": "04K"
    },
    {
     
     "CodeDescription": "KRUNG THAI BANK PUBLIC COMPANY LIMITED",
     "TPSystemValue": 2052
    },
    {
     
     "CodeDescription": "KRUNG THAI BANK PUBLIC COMPANY LIMITED",
     "TPSystemValue": "K04"
    },
    {
     
     "CodeDescription": "LENG AIK ENGINEERING PTE LTD",
     "TPSystemValue": 2103
    },
    {
     
     "CodeDescription": "LENG AIK ENGINEERING PTE LTD",
     "TPSystemValue": "05L"
    },
    {
     
     "CodeDescription": "LG DISPLAY SINGAPORE PTE LTD",
     "TPSystemValue": 2118
    },
    {
     
     "CodeDescription": "LG DISPLAY SINGAPORE PTE LTD",
     "TPSystemValue": "08L"
    },
    {
     
     "CodeDescription": "LIAN HOE LEONG & BROTHERS PTE LTD",
     "TPSystemValue": 2121
    },
    {
     
     "CodeDescription": "LIAN HOE LEONG & BROTHERS PTE LTD",
     "TPSystemValue": "09L"
    },
    {
     
     "CodeDescription": "LLOYD & ANDREW BUILDERS PTE LTD",
     "TPSystemValue": 2136
    },
    {
     
     "CodeDescription": "LLOYD & ANDREW BUILDERS PTE LTD",
     "TPSystemValue": "10L"
    },
    {
     
     "CodeDescription": "LOBB HENG PTE LTD",
     "TPSystemValue": 2138
    },
    {
     
     "CodeDescription": "LOBB HENG PTE LTD",
     "TPSystemValue": "11L"
    },
    {
     
     "CodeDescription": "MALAYAN BANKING BERHAD",
     "TPSystemValue": 2173
    },
    {
     
     "CodeDescription": "MALAYAN BANKING BERHAD",
     "TPSystemValue": "M01"
    },
    {
     
     "CodeDescription": "MANITOWOC CRANE GROUP ASIA PTE LTD",
     "TPSystemValue": 2180
    },
    {
     
     "CodeDescription": "MANITOWOC CRANE GROUP ASIA PTE LTD",
     "TPSystemValue": "01M"
    },
    {
     
     "CodeDescription": "MATOPO ENGINEERING PTE LTD",
     "TPSystemValue": 2211
    },
    {
     
     "CodeDescription": "MATOPO ENGINEERING PTE LTD",
     "TPSystemValue": "04M"
    },
    {
     
     "CodeDescription": "MAZDA PLASTIC FACTORY (PRIVATE) LIMITED",
     "TPSystemValue": 2222
    },
    {
     
     "CodeDescription": "MAZDA PLASTIC FACTORY (PRIVATE) LIMITED",
     "TPSystemValue": "06M"
    },
    {
     
     "CodeDescription": "METRO REMITTANCE SINGAPORE PTE LTD",
     "TPSystemValue": 2277
    },
    {
     
     "CodeDescription": "METRO REMITTANCE SINGAPORE PTE LTD",
     "TPSystemValue": "07M"
    },
    {
     
     "CodeDescription": "MIRADOR BUILDING CONTRACTOR PTE LTD",
     "TPSystemValue": 2333
    },
    {
     
     "CodeDescription": "MIRADOR BUILDING CONTRACTOR PTE LTD",
     "TPSystemValue": "11M"
    },
    {
     
     "CodeDescription": "MITSUBISHI UFJ TRUST AND BANKING CORPORATION",
     "TPSystemValue": 2341
    },
    {
     
     "CodeDescription": "MITSUBISHI UFJ TRUST AND BANKING CORPORATION",
     "TPSystemValue": "13M"
    },
    {
     
     "CodeDescription": "MMI BOILER MANAGEMENT PTE LTD",
     "TPSystemValue": "15M"
    },
    {
     
     "CodeDescription": "MMI BOILER MANAGEMENT PTE LTD",
     "TPSystemValue": 2351
    },
    {
     
     "CodeDescription": "MU-DIAN PTE LTD",
     "TPSystemValue": 2404
    },
    {
     
     "CodeDescription": "MU-DIAN PTE LTD",
     "TPSystemValue": "17M"
    },
    {
     
     "CodeDescription": "NAM SOON TIMBER PTE LTD",
     "TPSystemValue": 2421
    },
    {
     
     "CodeDescription": "NAM SOON TIMBER PTE LTD",
     "TPSystemValue": "01N"
    },
    {
     
     "CodeDescription": "NEWTECH ENGINEERING CONSTRUCTION PTE LTD",
     "TPSystemValue": 2491
    },
    {
     
     "CodeDescription": "NEWTECH ENGINEERING CONSTRUCTION PTE LTD",
     "TPSystemValue": "03N"
    },
    {
     
     "CodeDescription": "NORDDEUTSCHE LANDESBANK GIROZENTRALE",
     "TPSystemValue": 2527
    },
    {
     
     "CodeDescription": "NORDDEUTSCHE LANDESBANK GIROZENTRALE",
     "TPSystemValue": "N04"
    },
    {
     
     "CodeDescription": "OCWS LOGISTICS PTE LTD",
     "TPSystemValue": 2592
    },
    {
     
     "CodeDescription": "OCWS LOGISTICS PTE LTD",
     "TPSystemValue": "02O"
    },
    {
     
     "CodeDescription": "OMEGA PRO TECH (1996) PTE LTD",
     "TPSystemValue": 2609
    },
    {
     
     "CodeDescription": "OMEGA PRO TECH (1996) PTE LTD",
     "TPSystemValue": "03O"
    },
    {
     
     "CodeDescription": "PATRICK TAN LLC",
     "TPSystemValue": 1302
    },
    {
     
     "CodeDescription": "PATRICK TAN LLC",
     "TPSystemValue": "04P"
    },
    {
     
     "CodeDescription": "PEAK TOP ENGINEERING PTE LTD",
     "TPSystemValue": 2676
    },
    {
     
     "CodeDescription": "PEAK TOP ENGINEERING PTE LTD",
     "TPSystemValue": "05P"
    },
    {
     
     "CodeDescription": "POTENTIAL INSPECTION SERVICES PTE LTD",
     "TPSystemValue": 935
    },
    {
     
     "CodeDescription": "POTENTIAL INSPECTION SERVICES PTE LTD",
     "TPSystemValue": "08P"
    },
    {
     
     "CodeDescription": "PRICEWATERHOUSECOOPERS LLP",
     "TPSystemValue": 3108
    },
    {
     
     "CodeDescription": "PRICEWATERHOUSECOOPERS LLP",
     "TPSystemValue": "09P"
    },
    {
     
     "CodeDescription": "PROJECT MARINE SERVICES",
     "TPSystemValue": 1416
    },
    {
     
     "CodeDescription": "PROJECT MARINE SERVICES",
     "TPSystemValue": "11P"
    },
    {
     
     "CodeDescription": "PUTERA RESOURCES PTE LTD",
     "TPSystemValue": 781
    },
    {
     
     "CodeDescription": "PUTERA RESOURCES PTE LTD",
     "TPSystemValue": "13P"
    },
    {
     
     "CodeDescription": "RICHLAND LOGISTICS SERVICES PTE LTD",
     "TPSystemValue": 492
    },
    {
     
     "CodeDescription": "RICHLAND LOGISTICS SERVICES PTE LTD",
     "TPSystemValue": "01R"
    },
    {
     
     "CodeDescription": "RINGO CIVIL ENGINEERING PTE LTD",
     "TPSystemValue": 2778
    },
    {
     
     "CodeDescription": "RINGO CIVIL ENGINEERING PTE LTD",
     "TPSystemValue": "03R"
    },
    {
     
     "CodeDescription": "ROYAL BANK OF CANADA",
     "TPSystemValue": 2814
    },
    {
     
     "CodeDescription": "ROYAL BANK OF CANADA",
     "TPSystemValue": "R04"
    },
    {
     
     "CodeDescription": "SAN HUP SENG MARINE PRIVATE LIMITED",
     "TPSystemValue": 801
    },
    {
     
     "CodeDescription": "SAN HUP SENG MARINE PRIVATE LIMITED",
     "TPSystemValue": "02S"
    },
    {
     
     "CodeDescription": "SANKO ELECTRICAL ENGINEERING COMPANY",
     "TPSystemValue": 2853
    },
    {
     
     "CodeDescription": "SANKO ELECTRICAL ENGINEERING COMPANY",
     "TPSystemValue": "03S"
    },
    {
     
     "CodeDescription": "SEAWORLD ENGINEERING PRIVATE LIMITED",
     "TPSystemValue": 1088
    },
    {
     
     "CodeDescription": "SEAWORLD ENGINEERING PRIVATE LIMITED",
     "TPSystemValue": "08S"
    },
    {
     
     "CodeDescription": "SHELL EASTERN PETROLEUM PTE LTD",
     "TPSystemValue": 23
    },
    {
     
     "CodeDescription": "SHELL EASTERN PETROLEUM PTE LTD",
     "TPSystemValue": "13S"
    },
    {
     
     "CodeDescription": "SIMPLY BREAD PTE LTD",
     "TPSystemValue": 2930
    },
    {
     
     "CodeDescription": "SIMPLY BREAD PTE LTD",
     "TPSystemValue": "14S"
    },
    {
     
     "CodeDescription": "SINGAPORE CRICKET CLUB",
     "TPSystemValue": 2945
    },
    {
     
     "CodeDescription": "SINGAPORE CRICKET CLUB",
     "TPSystemValue": "15S"
    },
    {
     
     "CodeDescription": "SINGAPORE POST LIMITED",
     "TPSystemValue": "H209"
    },
    {
     
     "CodeDescription": "SINGAPORE POST LIMITED",
     "TPSystemValue": 408
    },
    {
     
     "CodeDescription": "SOCIETE GENERALE",
     "TPSystemValue": 2818
    },
    {
     
     "CodeDescription": "SOCIETE GENERALE",
     "TPSystemValue": "S07"
    },
    {
     
     "CodeDescription": "SOCIETE GENERALE BANK & TRUST",
     "TPSystemValue": 3004
    },
    {
     
     "CodeDescription": "SOCIETE GENERALE BANK & TRUST",
     "TPSystemValue": "S08"
    },
    {
     
     "CodeDescription": "STANDARD CHARTERED BANK",
     "TPSystemValue": 2801
    },
    {
     
     "CodeDescription": "STANDARD CHARTERED BANK",
     "TPSystemValue": "S10"
    },
    {
     
     "CodeDescription": "STEREO ELECTRONICS PTE LTD",
     "TPSystemValue": 397
    },
    {
     
     "CodeDescription": "STEREO ELECTRONICS PTE LTD",
     "TPSystemValue": "23S"
    },
    {
     
     "CodeDescription": "STRONTIUM ASSEMBLIES PTE LTD",
     "TPSystemValue": 618
    },
    {
     
     "CodeDescription": "STRONTIUM ASSEMBLIES PTE LTD",
     "TPSystemValue": "24S"
    },
    {
     
     "CodeDescription": "SUMITOMO MITSUI BANKING CORPORATION",
     "TPSystemValue": 3032
    },
    {
     
     "CodeDescription": "SUMITOMO MITSUI BANKING CORPORATION",
     "TPSystemValue": "S13"
    },
    {
     
     "CodeDescription": "TECHCOM CONSTRUCTION & TRADING PTE LTD",
     "TPSystemValue": 3150
    },
    {
     
     "CodeDescription": "TECHCOM CONSTRUCTION & TRADING PTE LTD",
     "TPSystemValue": "02T"
    },
    {
     
     "CodeDescription": "TELFORD MANPOWER PTE LTD",
     "TPSystemValue": 3157
    },
    {
     
     "CodeDescription": "TELFORD MANPOWER PTE LTD",
     "TPSystemValue": "03T"
    },
    {
     
     "CodeDescription": "TERRAPINN PTE LTD",
     "TPSystemValue": 3171
    },
    {
     
     "CodeDescription": "TERRAPINN PTE LTD",
     "TPSystemValue": "04T"
    },
    {
     
     "CodeDescription": "THOMSON REUTERS CORPORATION PTE LTD",
     "TPSystemValue": "09T"
    },
    {
     
     "CodeDescription": "THOMSON REUTERS CORPORATION PTE LTD",
     "TPSystemValue": 3746
    },
    {
     
     "CodeDescription": "THONG SHENG ENGINEERING CO PTE LTD",
     "TPSystemValue": 3222
    },
    {
     
     "CodeDescription": "THONG SHENG ENGINEERING CO PTE LTD",
     "TPSystemValue": "11T"
    },
    {
     
     "CodeDescription": "TONG LEE ENGINEERING WORKS PTE LTD",
     "TPSystemValue": 3240
    },
    {
     
     "CodeDescription": "TONG LEE ENGINEERING WORKS PTE LTD",
     "TPSystemValue": "12T"
    },
    {
     
     "CodeDescription": "TSA TRAINING SERVICES PTE LTD",
     "TPSystemValue": 3268
    },
    {
     
     "CodeDescription": "TSA TRAINING SERVICES PTE LTD",
     "TPSystemValue": "14T"
    },
    {
     
     "CodeDescription": "TSMP LAW CORPORATION",
     "TPSystemValue": 3269
    },
    {
     
     "CodeDescription": "TSMP LAW CORPORATION",
     "TPSystemValue": "15T"
    },
    {
     
     "CodeDescription": "UNICREDIT BANK AG",
     "TPSystemValue": 2830
    },
    {
     
     "CodeDescription": "UNICREDIT BANK AG",
     "TPSystemValue": "U03"
    },
    {
     
     "CodeDescription": "WATSONS PERSONAL CARE STORES PTE LTD",
     "TPSystemValue": 281
    },
    {
     
     "CodeDescription": "WATSONS PERSONAL CARE STORES PTE LTD",
     "TPSystemValue": "01W"
    },
    {
     
     "CodeDescription": "WELLS FARGO BANK, NATIONAL ASSOCIATION",
     "TPSystemValue": 3112
    },
    {
     
     "CodeDescription": "WELLS FARGO BANK, NATIONAL ASSOCIATION",
     "TPSystemValue": "W01"
    },
    {
     
     "CodeDescription": "WESTPAC BANKING CORPORATION",
     "TPSystemValue": 2819
    },
    {
     
     "CodeDescription": "WESTPAC BANKING CORPORATION",
     "TPSystemValue": "W03"
    },
    {
     
     "CodeDescription": "YONG FAN KIONG & COMPANY",
     "TPSystemValue": 3392
    },
    {
     
     "CodeDescription": "YONG FAN KIONG & COMPANY",
     "TPSystemValue": "04Y"
    },
    {
     
     "CodeDescription": "ZOUK MANAGEMENT PTE LTD",
     "TPSystemValue": "01Z"
    },
    {
     
     "CodeDescription": "ZOUK MANAGEMENT PTE LTD",
     "TPSystemValue": 340
    },
    {
     
     "CodeDescription": "138 GP MANPOWER MANAGEMENT PTE LTD",
     "TPSystemValue": 5
    },
    {
     
     "CodeDescription": "1G ASIA",
     "TPSystemValue": 713
    },
    {
     
     "CodeDescription": "2XPLORE TOURS & TRAVEL PTE. LTD.",
     "TPSystemValue": "H707"
    },
    {
     
     "CodeDescription": "3 DEGREES ASSET MANAGEMENT PTE LTD",
     "TPSystemValue": 839
    },
    {
     
     "CodeDescription": "3D LINGERIE INTERNATIONAL PTE LTD",
     "TPSystemValue": 1268
    },
    {
     
     "CodeDescription": "3I INFOTECH ASIA PACIFIC PTE LTD",
     "TPSystemValue": 757
    },
    {
     
     "CodeDescription": "3M ASIA PACIFIC PTE LTD",
     "TPSystemValue": 317
    },
    {
     
     "CodeDescription": "3M INNOVATION SINGAPORE PTE LTD",
     "TPSystemValue": 1152
    },
    {
     
     "CodeDescription": "3M SINGAPORE PTE LTD",
     "TPSystemValue": 34
    },
    {
     
     "CodeDescription": "3M TECHNOLOGIES S PTE LTD",
     "TPSystemValue": 813
    },
    {
     
     "CodeDescription": "3S CONSTRUCTION SERVICES PTE LTD",
     "TPSystemValue": 805
    },
    {
     
     "CodeDescription": "6 DRUNK MEN PTE LTD",
     "TPSystemValue": 823
    },
    {
     
     "CodeDescription": "707-INC GREAT EXCURSION TRAVEL PTE. LTD.",
     "TPSystemValue": "H325"
    },
    {
     
     "CodeDescription": "7ELEVEN",
     "TPSystemValue": 1357
    },
    {
     
     "CodeDescription": "88DB SINGAPORE PTE LTD",
     "TPSystemValue": 1010
    },
    {
     
     "CodeDescription": "8PM EVENTS PTE. LTD.",
     "TPSystemValue": "H487"
    },
    {
     
     "CodeDescription": "8TH WAVE PTE. LTD.",
     "TPSystemValue": "H495"
    },
    {
     
     "CodeDescription": "96 TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H708"
    },
    {
     
     "CodeDescription": "A ANG SEAH & HOE",
     "TPSystemValue": 1435
    },
    {
     
     "CodeDescription": "A IT SOFTWARE SERVICES PTE LTD",
     "TPSystemValue": 1436
    },
    {
     
     "CodeDescription": "A P MOLLER SINGAPORE PTE LTD",
     "TPSystemValue": 1437
    },
    {
     
     "CodeDescription": "A PLUS GLASS PTE LTD",
     "TPSystemValue": 1438
    },
    {
     
     "CodeDescription": "A STAR",
     "TPSystemValue": 1439
    },
    {
     
     "CodeDescription": "A STAR AGENCY",
     "TPSystemValue": 1440
    },
    {
     
     "CodeDescription": "A.B. MOHAMED TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H206"
    },
    {
     
     "CodeDescription": "A.P MOLLER SINGAPORE PTE LTD",
     "TPSystemValue": 1441
    },
    {
     
     "CodeDescription": "A+B EDU TOURS AND TRAVEL PTE. LTD.",
     "TPSystemValue": "H406"
    },
    {
     
     "CodeDescription": "A2A CAPITAL MANAGEMENT PTE LTD",
     "TPSystemValue": 1226
    },
    {
     
     "CodeDescription": "AA ALLIANCE CONSULTING PTE LTD",
     "TPSystemValue": 800
    },
    {
     
     "CodeDescription": "AALST CHOCOLATE PTE LTD",
     "TPSystemValue": 867
    },
    {
     
     "CodeDescription": "AAM ADVISORY PTE LTD",
     "TPSystemValue": 3771
    },
    {
     
     "CodeDescription": "AARDWOLF PESTKARE (S) PTE LTD",
     "TPSystemValue": 1442
    },
    {
     
     "CodeDescription": "AAVANTI INDUSTRIES PTE LTD",
     "TPSystemValue": 439
    },
    {
     
     "CodeDescription": "AB SCIEX MANUFACTURING",
     "TPSystemValue": 1427
    },
    {
     
     "CodeDescription": "AB VISTA ASIA PTE LTD",
     "TPSystemValue": 3788
    },
    {
     
     "CodeDescription": "ABACUS INTERNATIONAL PTE LTD",
     "TPSystemValue": 1443
    },
    {
     
     "CodeDescription": "ABAN SINGAPORE PTE LTD",
     "TPSystemValue": 1021
    },
    {
     
     "CodeDescription": "ABB PTE LTD",
     "TPSystemValue": 61
    },
    {
     
     "CodeDescription": "ABBOT MANUFACTURING SINGAPORE PTE LTD",
     "TPSystemValue": 1444
    },
    {
     
     "CodeDescription": "ABBOTT LABORATORIES S PRIVATE LIMITED",
     "TPSystemValue": 56
    },
    {
     
     "CodeDescription": "ABBOTT MANUFACTURING S PTE LIMITED",
     "TPSystemValue": 1034
    },
    {
     
     "CodeDescription": "ABBOTT MANUFACTURING SINGAPORE PRIVATE LIMITED",
     "TPSystemValue": "01A"
    },
    {
     
     "CodeDescription": "ABDULLAH TRANSPORT SERVICES PTE LTD",
     "TPSystemValue": "H219"
    },
    {
     
     "CodeDescription": "ABEAM CONSULTING (SINGAPORE) PTE LTD",
     "TPSystemValue": 970
    },
    {
     
     "CodeDescription": "ABERDEEN ASSET MANAGEMENT ASIA LIMITED",
     "TPSystemValue": 1445
    },
    {
     
     "CodeDescription": "ABI SERVICES AND RESOURCES PTE LTD",
     "TPSystemValue": 1446
    },
    {
     
     "CodeDescription": "A-BIO PHARMA PTE LTD",
     "TPSystemValue": 1447
    },
    {
     
     "CodeDescription": "ABITEX DESIONS (S) PTE LTD",
     "TPSystemValue": 1448
    },
    {
     
     "CodeDescription": "ABN AMERO BANK",
     "TPSystemValue": 1449
    },
    {
     
     "CodeDescription": "ABN AMRO BANK N.V.",
     "TPSystemValue": "A01"
    },
    {
     
     "CodeDescription": "ABN AMRO CLEARING BANK N.V.",
     "TPSystemValue": "A02"
    },
    {
     
     "CodeDescription": "ABODE CAPITAL VENTURES PTE LTD",
     "TPSystemValue": 1450
    },
    {
     
     "CodeDescription": "ABOGADO PTE LTD",
     "TPSystemValue": 1451
    },
    {
     
     "CodeDescription": "ABOTT MANUFACTURING SINGAPORE PTE LTD",
     "TPSystemValue": 1452
    },
    {
     
     "CodeDescription": "ABR HOLDING PTE LTD",
     "TPSystemValue": 1453
    },
    {
     
     "CodeDescription": "ABS CONSULING SINGAPORE PL",
     "TPSystemValue": 1454
    },
    {
     
     "CodeDescription": "ABSG CONSULTING INC",
     "TPSystemValue": 2811
    },
    {
     
     "CodeDescription": "ABSOLUTE ASIA ASSET MANAGEMENT LTD",
     "TPSystemValue": 1455
    },
    {
     
     "CodeDescription": "ABSOLUTE THAI PTE LTD",
     "TPSystemValue": 1456
    },
    {
     
     "CodeDescription": "ABU BAKAR TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "H326"
    },
    {
     
     "CodeDescription": "ACACIA",
     "TPSystemValue": 1408
    },
    {
     
     "CodeDescription": "ACADEMY OF MEDICINE SINGAPORE",
     "TPSystemValue": 1457
    },
    {
     
     "CodeDescription": "ACC LINK MANAGEMENT CONSULTANTS PTE LTD",
     "TPSystemValue": 1458
    },
    {
     
     "CodeDescription": "ACCENTURE PTE LTD",
     "TPSystemValue": 110
    },
    {
     
     "CodeDescription": "ACCENTURE TECHNOLOGY SOLUTIONS PTE LTD",
     "TPSystemValue": 821
    },
    {
     
     "CodeDescription": "ACCESS EXPRESS COURIERS PTE LTD",
     "TPSystemValue": 1460
    },
    {
     
     "CodeDescription": "ACCESSTECH ENGINEERING PTE LTD",
     "TPSystemValue": 1461
    },
    {
     
     "CodeDescription": "ACC-LINK MANAGEMENT CONSULTANTS PTE LTD",
     "TPSystemValue": 1111
    },
    {
     
     "CodeDescription": "ACC-LINK MANAGEMENT CONSULTANTS PTE. LTD.",
     "TPSystemValue": "03A"
    },
    {
     
     "CodeDescription": "ACCLIVIS TECHNOLOGIES AND SOLUTIONS",
     "TPSystemValue": 1462
    },
    {
     
     "CodeDescription": "ACCON ENGINEERING PTE LTD",
     "TPSystemValue": 1463
    },
    {
     
     "CodeDescription": "ACCORD HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H790"
    },
    {
     
     "CodeDescription": "ACCTIMUS PTE LTD",
     "TPSystemValue": 884
    },
    {
     
     "CodeDescription": "ACE ADVENTURE EXPEDITIONS PTE. LTD.",
     "TPSystemValue": "H686"
    },
    {
     
     "CodeDescription": "ACE ASIA PACIFIC SERVICES PTE LTD",
     "TPSystemValue": 100
    },
    {
     
     "CodeDescription": "ACE DAYTONS ADVERTISING INTL PL",
     "TPSystemValue": 1464
    },
    {
     
     "CodeDescription": "ACE EQUIPMENT",
     "TPSystemValue": 1465
    },
    {
     
     "CodeDescription": "ACE INSURANCE LIMITED",
     "TPSystemValue": 597
    },
    {
     
     "CodeDescription": "ACE PRESSUREWELD INTERNATIONAL PTE LTD",
     "TPSystemValue": 1466
    },
    {
     
     "CodeDescription": "ACE TOURS & TRAVEL PTE LTD",
     "TPSystemValue": "H350"
    },
    {
     
     "CodeDescription": "ACEPAC INTERNATIONAL PTE LTD",
     "TPSystemValue": 1467
    },
    {
     
     "CodeDescription": "ACER COMPUTER SINGAPORE PTE LTD",
     "TPSystemValue": 1468
    },
    {
     
     "CodeDescription": "ACESTAR CLEANING SERVICES",
     "TPSystemValue": 1469
    },
    {
     
     "CodeDescription": "ACETECH MANPOWER CONSULTANCY PTE LTD",
     "TPSystemValue": 609
    },
    {
     
     "CodeDescription": "ACHIEVE CARRER CONSULTANTS PTE LTD",
     "TPSystemValue": 650
    },
    {
     
     "CodeDescription": "ACI WORLDWIDE ASIA PTE LTD",
     "TPSystemValue": 1470
    },
    {
     
     "CodeDescription": "ACORN MARKETING RESEARCH CONSULTANTS PL",
     "TPSystemValue": 1471
    },
    {
     
     "CodeDescription": "ACP COMPUTER TRAINING SCHOOL PTE. LTD.",
     "TPSystemValue": 1118
    },
    {
     
     "CodeDescription": "ACP MAGAZINES PTE LTD",
     "TPSystemValue": 1472
    },
    {
     
     "CodeDescription": "ACR CAPITAL HOLDINGS PTE LTD",
     "TPSystemValue": 1473
    },
    {
     
     "CodeDescription": "ACRA",
     "TPSystemValue": 1474
    },
    {
     
     "CodeDescription": "ACS",
     "TPSystemValue": 1475
    },
    {
     
     "CodeDescription": "ACU (1955) CONTRACT PTE LTD",
     "TPSystemValue": 706
    },
    {
     
     "CodeDescription": "ADAM KHOO EMPOWERING YOUTH PTE. LTD.",
     "TPSystemValue": "H677"
    },
    {
     
     "CodeDescription": "ADAM KHOO LEARNING TECHNOLOGIES PTE LTD",
     "TPSystemValue": 828
    },
    {
     
     "CodeDescription": "ADAMPAK LIMITED",
     "TPSystemValue": 3793
    },
    {
     
     "CodeDescription": "ADAPTEC MFG(S) PTE LTD",
     "TPSystemValue": 1476
    },
    {
     
     "CodeDescription": "ADC TECHNOLOGIES INTERNATIONAL PTE LTD",
     "TPSystemValue": 266
    },
    {
     
     "CodeDescription": "ADDVALUE COMMUNICATIONS PTE LTD",
     "TPSystemValue": 454
    },
    {
     
     "CodeDescription": "ADECCO PERSONNEL PTE LTD",
     "TPSystemValue": 250
    },
    {
     
     "CodeDescription": "ADHARASOFT (PTE) LTD",
     "TPSystemValue": 1131
    },
    {
     
     "CodeDescription": "ADIDAS SINGAPORE",
     "TPSystemValue": 455
    },
    {
     
     "CodeDescription": "ADIWARNA INDUSTRIES PTE LTD",
     "TPSystemValue": 258
    },
    {
     
     "CodeDescription": "ADM COCOA PTE LTD",
     "TPSystemValue": 1477
    },
    {
     
     "CodeDescription": "ADNOVA PTE LTD",
     "TPSystemValue": 1061
    },
    {
     
     "CodeDescription": "ADORE TECHNOLOGIES PTE LTD",
     "TPSystemValue": 3794
    },
    {
     
     "CodeDescription": "ADSOLUTION",
     "TPSystemValue": 1414
    },
    {
     
     "CodeDescription": "ADVANCE SCT LIMITED",
     "TPSystemValue": 921
    },
    {
     
     "CodeDescription": "ADVANCED MANUFACTURING CORPORATION PL",
     "TPSystemValue": 1478
    },
    {
     
     "CodeDescription": "ADVANCED MANUFACTURING CORPORATION PTE. LTD.",
     "TPSystemValue": "04A"
    },
    {
     
     "CodeDescription": "ADVANCED MARINE PTE LTD",
     "TPSystemValue": 1479
    },
    {
     
     "CodeDescription": "ADVANCED MATERIAL ENGINEERING PTE LTD",
     "TPSystemValue": 1480
    },
    {
     
     "CodeDescription": "ADVANCED MATERIALS TECHNOLOGIES",
     "TPSystemValue": 1481
    },
    {
     
     "CodeDescription": "ADVANCED MICRO DEVICES (S) PTE LTD",
     "TPSystemValue": 1482
    },
    {
     
     "CodeDescription": "ADVANCED SOLUTIONS ENGINEERING PTE LTD",
     "TPSystemValue": 3770
    },
    {
     
     "CodeDescription": "ADVANCED SYNERGIC PTE LTD",
     "TPSystemValue": 615
    },
    {
     
     "CodeDescription": "ADVANTEST (SINGAPORE) PTE. LTD.",
     "TPSystemValue": 542
    },
    {
     
     "CodeDescription": "ADVENT CREDIT MANAGEMENT PTE LTD",
     "TPSystemValue": 880
    },
    {
     
     "CodeDescription": "ADVENT CREDIT MANAGEMENT PTE. LTD.",
     "TPSystemValue": "05A"
    },
    {
     
     "CodeDescription": "AEDAS PRIVATE LIMITED",
     "TPSystemValue": 1483
    },
    {
     
     "CodeDescription": "AEGIS MEDIA PTE LTD",
     "TPSystemValue": 1325
    },
    {
     
     "CodeDescription": "AERO SYSTEMS PTE LTD",
     "TPSystemValue": 1484
    },
    {
     
     "CodeDescription": "AERO TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H281"
    },
    {
     
     "CodeDescription": "AEROHUB LOGISTICS PL",
     "TPSystemValue": 1485
    },
    {
     
     "CodeDescription": "AEROSPACE COMPONENTS SERVICES LTD",
     "TPSystemValue": 1486
    },
    {
     
     "CodeDescription": "AEROSPEC SUPPLIES PTE LTD",
     "TPSystemValue": 404
    },
    {
     
     "CodeDescription": "AES TRANSPOWER PRIVATE LTD",
     "TPSystemValue": 457
    },
    {
     
     "CodeDescription": "AET SHIPMANAGEMENT SINGAPORE PTE LTD",
     "TPSystemValue": 843
    },
    {
     
     "CodeDescription": "AET TANKERS PTE LTD",
     "TPSystemValue": 252
    },
    {
     
     "CodeDescription": "AETOS SECURITY MANAGEMENT PTE LTD",
     "TPSystemValue": 834
    },
    {
     
     "CodeDescription": "AFANDI TRAVEL & SERVICES PTE. LTD.",
     "TPSystemValue": "H600"
    },
    {
     
     "CodeDescription": "AFH SINGAPORE PTE LTD",
     "TPSystemValue": 3427
    },
    {
     
     "CodeDescription": "AFPD PTE LTD",
     "TPSystemValue": 772
    },
    {
     
     "CodeDescription": "AGENCY FOR INTREGRATED CARE PTE LTD",
     "TPSystemValue": 1234
    },
    {
     
     "CodeDescription": "AGENCY FOR SCIENCE TECHNOLOGYRESEARCH",
     "TPSystemValue": 3091
    },
    {
     
     "CodeDescription": "AGERE SYSTEMS SINGAPORE PTE LTD",
     "TPSystemValue": 1487
    },
    {
     
     "CodeDescription": "AGIE CHARMILLES",
     "TPSystemValue": 1488
    },
    {
     
     "CodeDescription": "AGILENT TECHNOLOGIES SG INT PTE LTD",
     "TPSystemValue": 1250
    },
    {
     
     "CodeDescription": "AGILITY INTERNATIONAL LOGISTICS PTE LTD",
     "TPSystemValue": 1489
    },
    {
     
     "CodeDescription": "AGRI- FOOD & VENTORING AUTHORITY",
     "TPSystemValue": 1490
    },
    {
     
     "CodeDescription": "AGRIFOOD & VETERINARY AUTHORITY OF SPORE",
     "TPSystemValue": 1491
    },
    {
     
     "CodeDescription": "AGROCORP INTERNATIONAL PTE LTD",
     "TPSystemValue": 370
    },
    {
     
     "CodeDescription": "AIA SINGAPORE PRIVATE LIMITED",
     "TPSystemValue": 1317
    },
    {
     
     "CodeDescription": "AIBEL PTE LTD",
     "TPSystemValue": 1492
    },
    {
     
     "CodeDescription": "AIG",
     "TPSystemValue": 1493
    },
    {
     
     "CodeDescription": "AIK MOH PAINTS & CHEMICALS PL",
     "TPSystemValue": 1494
    },
    {
     
     "CodeDescription": "AIMS RELOCATION SPECIALIST (PTE. LTD.)",
     "TPSystemValue": "H777"
    },
    {
     
     "CodeDescription": "AIR ENERGI GROUP SINGAPORE PTE LTD",
     "TPSystemValue": 1495
    },
    {
     
     "CodeDescription": "AIR FORCE",
     "TPSystemValue": 1496
    },
    {
     
     "CodeDescription": "AIR FRANCE",
     "TPSystemValue": "H010"
    },
    {
     
     "CodeDescription": "AIR ONE AVIATION PTE. LTD.",
     "TPSystemValue": "H224"
    },
    {
     
     "CodeDescription": "AIR POWER RESOURCES PTE LTD",
     "TPSystemValue": 316
    },
    {
     
     "CodeDescription": "AIR PRODUCTS SINGAPORE PTE LTD",
     "TPSystemValue": 1497
    },
    {
     
     "CodeDescription": "AIR SINO-EURO ASSOCIATES TRAVEL PTE. LTD.",
     "TPSystemValue": "H097"
    },
    {
     
     "CodeDescription": "AIRASIA PTE LTD",
     "TPSystemValue": 891
    },
    {
     
     "CodeDescription": "AIRCOM INTERNATIONAL PTE LTD",
     "TPSystemValue": 1498
    },
    {
     
     "CodeDescription": "AIRELATED TRAVEL PTE LTD",
     "TPSystemValue": "H141"
    },
    {
     
     "CodeDescription": "AIRFOIL TECHNOLOGIES SINGAPORE PTE LTD",
     "TPSystemValue": 1499
    },
    {
     
     "CodeDescription": "AIRSERVE MARINE TRAVEL PTE LTD",
     "TPSystemValue": 508
    },
    {
     
     "CodeDescription": "AIRSERVE MARINE TRAVEL PTE. LTD.",
     "TPSystemValue": "H248"
    },
    {
     
     "CodeDescription": "AIRTICIAN PTE LTD",
     "TPSystemValue": 1001
    },
    {
     
     "CodeDescription": "AIT SOFTWARE SERVICES PTE LTD",
     "TPSystemValue": 394
    },
    {
     
     "CodeDescription": "AJ CONSTRUCTION & TRANSP PTE LTD",
     "TPSystemValue": 1500
    },
    {
     
     "CodeDescription": "AJ NETSOLUTIONS PTE LTD",
     "TPSystemValue": 923
    },
    {
     
     "CodeDescription": "AK TRAVELS PTE. LTD.",
     "TPSystemValue": "H767"
    },
    {
     
     "CodeDescription": "AKER SOLUTIONS",
     "TPSystemValue": 1501
    },
    {
     
     "CodeDescription": "AKZO NOBEL",
     "TPSystemValue": 1502
    },
    {
     
     "CodeDescription": "AL ENG & CONSTRUCTION SERVICES",
     "TPSystemValue": 1503
    },
    {
     
     "CodeDescription": "AL FAHEEM TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "H679"
    },
    {
     
     "CodeDescription": "AL FIRDAUS TRAVELS PTE LTD",
     "TPSystemValue": "H291"
    },
    {
     
     "CodeDescription": "AL JABER HEAVYLIFT & TRANSPORT PTE LTD",
     "TPSystemValue": 1504
    },
    {
     
     "CodeDescription": "ALASKA AIR GROUP",
     "TPSystemValue": "H028"
    },
    {
     
     "CodeDescription": "ALBANY MOLECULAR RESEARCH S RESEARCH CENTRE PL",
     "TPSystemValue": 1505
    },
    {
     
     "CodeDescription": "ALBATROSS WORLD TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H316"
    },
    {
     
     "CodeDescription": "ALCATELLUCENT SINGAPORE PTE LTD",
     "TPSystemValue": 468
    },
    {
     
     "CodeDescription": "ALDION CONSULTING PTE LTD",
     "TPSystemValue": 792
    },
    {
     
     "CodeDescription": "ALEXANDER MANN BPO SOLUTIONS",
     "TPSystemValue": 1066
    },
    {
     
     "CodeDescription": "ALEXANDRA HEALTH PTE LTD",
     "TPSystemValue": 1123
    },
    {
     
     "CodeDescription": "ALEXANDRA HOSPITAL",
     "TPSystemValue": 1391
    },
    {
     
     "CodeDescription": "ALFASI CONSTRUCTIONS (S) PTE LTD",
     "TPSystemValue": 1002
    },
    {
     
     "CodeDescription": "AL-FATTAH TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H658"
    },
    {
     
     "CodeDescription": "AL-HAQ TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H289"
    },
    {
     
     "CodeDescription": "ALKANAAH INTERNATIONAL TOURS & TRAVEL (PTE) LTD",
     "TPSystemValue": "H072"
    },
    {
     
     "CodeDescription": "ALL ABOUT MASSAGE PTE LTD",
     "TPSystemValue": 1506
    },
    {
     
     "CodeDescription": "ALL EIGHTS (SINGAPORE) PRIVATE LIMITED",
     "TPSystemValue": 214
    },
    {
     
     "CodeDescription": "ALL EIGHTS (SINGAPORE) PTE LTD",
     "TPSystemValue": "09A"
    },
    {
     
     "CodeDescription": "ALL PROPERTY MEDIA",
     "TPSystemValue": 1507
    },
    {
     
     "CodeDescription": "ALLALLOY DYNAWELO PTE LTD",
     "TPSystemValue": 1508
    },
    {
     
     "CodeDescription": "ALLEGIS GROUP SINGAPORE PRIVATE LIMITED",
     "TPSystemValue": 1218
    },
    {
     
     "CodeDescription": "ALLEN GLEDHILL LLP",
     "TPSystemValue": 3088
    },
    {
     
     "CodeDescription": "ALLERGAN SINGAPORE PTE LTD",
     "TPSystemValue": 907
    },
    {
     
     "CodeDescription": "ALLGREEN PROPERTIES LIMITED",
     "TPSystemValue": 261
    },
    {
     
     "CodeDescription": "ALLIANCE CONCRETE SPORE PL",
     "TPSystemValue": 1509
    },
    {
     
     "CodeDescription": "ALLIANCE ENTERTAIMENT SINGAPORE PTE LTD",
     "TPSystemValue": 700
    },
    {
     
     "CodeDescription": "ALLIANCE GRAPHICS PTE LTD",
     "TPSystemValue": 1320
    },
    {
     
     "CodeDescription": "ALLIANCE ONE INTERNATIONAL SINGAPORE P L",
     "TPSystemValue": 1510
    },
    {
     
     "CodeDescription": "ALLIANCE PEST MANAGEMENT PTE LTD",
     "TPSystemValue": 1511
    },
    {
     
     "CodeDescription": "ALLIANZ GLOBAL CORPORATE SPECIALTY AG",
     "TPSystemValue": 3116
    },
    {
     
     "CodeDescription": "ALLIANZ GLOBAL INVESTORS SINGAPORE LTD",
     "TPSystemValue": 703
    },
    {
     
     "CodeDescription": "ALLIANZ INVESTMENT MANAGEMENT S PL",
     "TPSystemValue": 1201
    },
    {
     
     "CodeDescription": "ALLIANZ SE INSURANCE MANAGEMENT APAC",
     "TPSystemValue": 1430
    },
    {
     
     "CodeDescription": "ALLIED MOVING SERVICES PTE LTD",
     "TPSystemValue": 1512
    },
    {
     
     "CodeDescription": "ALLIED SOLUTIONS GROUP PTE LTD",
     "TPSystemValue": 1513
    },
    {
     
     "CodeDescription": "ALLIED TELESIS INTL ASIA PTE LTD",
     "TPSystemValue": 1514
    },
    {
     
     "CodeDescription": "ALLPROPERTY MEDIA (PTE. LTD.)",
     "TPSystemValue": "10A"
    },
    {
     
     "CodeDescription": "ALNOOR TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H399"
    },
    {
     
     "CodeDescription": "ALPAQ SEAFOOD PROCESSING PTE LTD",
     "TPSystemValue": 924
    },
    {
     
     "CodeDescription": "ALPHA ACOUSTICS ENGINEERING PTE LTD",
     "TPSystemValue": 920
    },
    {
     
     "CodeDescription": "ALPHA CONCEPTS PTE LTD",
     "TPSystemValue": 1192
    },
    {
     
     "CodeDescription": "ALPHA- HEALTH (ASIA) PTE LTD",
     "TPSystemValue": 1515
    },
    {
     
     "CodeDescription": "ALPHA INVESTMENT PARTNERS LIMITED",
     "TPSystemValue": 779
    },
    {
     
     "CodeDescription": "ALPHA PIONEER ENTERPRISES PTE LTD",
     "TPSystemValue": 484
    },
    {
     
     "CodeDescription": "ALPHAHEALTH ASIA PTE LTD",
     "TPSystemValue": 744
    },
    {
     
     "CodeDescription": "ALPINE BAU GMBH SINGAPORE BRANCH",
     "TPSystemValue": 1516
    },
    {
     
     "CodeDescription": "ALPINE ENGINEERING SERVICES PTE LTD",
     "TPSystemValue": 1517
    },
    {
     
     "CodeDescription": "AL-QASWA TRAVEL & TOURS (S) PTE. LTD.",
     "TPSystemValue": "H633"
    },
    {
     
     "CodeDescription": "ALS TECHNICHEM SINGAPORE PTE LTD",
     "TPSystemValue": 243
    },
    {
     
     "CodeDescription": "ALSTOM ASIA PTE LTD",
     "TPSystemValue": 3600
    },
    {
     
     "CodeDescription": "ALSTOM GRID PTE LTD",
     "TPSystemValue": 1518
    },
    {
     
     "CodeDescription": "ALSTOM TRANSPORT PTE LTD",
     "TPSystemValue": 652
    },
    {
     
     "CodeDescription": "ALSTON PUBLISHING HOUSE PTE LTD",
     "TPSystemValue": 1253
    },
    {
     
     "CodeDescription": "ALTERNATIVE TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H319"
    },
    {
     
     "CodeDescription": "ALUMINIUM OFFSHORE PTE LTD",
     "TPSystemValue": 1519
    },
    {
     
     "CodeDescription": "AMARA HLDGS",
     "TPSystemValue": "H020"
    },
    {
     
     "CodeDescription": "AMARA HOTEL PROPERTIES PTE LTD",
     "TPSystemValue": 155
    },
    {
     
     "CodeDescription": "AMAZ TOURS (S) PTE LTD",
     "TPSystemValue": "H366"
    },
    {
     
     "CodeDescription": "AMAZING EXPLORER PTE. LTD.",
     "TPSystemValue": "H794"
    },
    {
     
     "CodeDescription": "AMAZING TOURS & TRAVELS SINGAPORE PTE. LTD.",
     "TPSystemValue": "H589"
    },
    {
     
     "CodeDescription": "AMAZING TRAVEL & TOUR PTE. LTD.",
     "TPSystemValue": "H787"
    },
    {
     
     "CodeDescription": "AMCOR SINGAPORE PRIVATE LTD",
     "TPSystemValue": 147
    },
    {
     
     "CodeDescription": "AMCOR TOBACCO PACKAGING SINGAPORE PL",
     "TPSystemValue": 422
    },
    {
     
     "CodeDescription": "AMDOCS SINGAPORE PTE LTD",
     "TPSystemValue": 3602
    },
    {
     
     "CodeDescription": "AME INTERNATIONAL PTE LTD",
     "TPSystemValue": 1047
    },
    {
     
     "CodeDescription": "AME INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "12A"
    },
    {
     
     "CodeDescription": "AMERICAN AIRLINES GROUP",
     "TPSystemValue": "H024"
    },
    {
     
     "CodeDescription": "AMERICAN BUREAU OF SHIPPING",
     "TPSystemValue": 1520
    },
    {
     
     "CodeDescription": "AMERICAN EXPRESS INTL INC",
     "TPSystemValue": 2810
    },
    {
     
     "CodeDescription": "AMERICAN HOME ASSURANCE COMPANY",
     "TPSystemValue": 2803
    },
    {
     
     "CodeDescription": "AMERICAN INSURANCE ASSURANCE COMPANY",
     "TPSystemValue": 3412
    },
    {
     
     "CodeDescription": "AMERICAN INT L ASSURANCE CO LTD",
     "TPSystemValue": 1522
    },
    {
     
     "CodeDescription": "AMERICAN INTERNATIONAL ANSURANCE COMPANY",
     "TPSystemValue": 1521
    },
    {
     
     "CodeDescription": "AMERICAN LLOYD TRAVEL SERVICES PTE LTD",
     "TPSystemValue": "H037"
    },
    {
     
     "CodeDescription": "AMERICAN TOURIST SERVICE (S) PTE LTD",
     "TPSystemValue": "H040"
    },
    {
     
     "CodeDescription": "AMEROPA ASIA PTE LTD",
     "TPSystemValue": 1137
    },
    {
     
     "CodeDescription": "AMEX",
     "TPSystemValue": 1523
    },
    {
     
     "CodeDescription": "AMFRASER SECURITIES PTE LTD",
     "TPSystemValue": 18
    },
    {
     
     "CodeDescription": "AMI MARITIME PTE LTD",
     "TPSystemValue": 1110
    },
    {
     
     "CodeDescription": "AMICI RESTUARANT PTE LTD",
     "TPSystemValue": 1524
    },
    {
     
     "CodeDescription": "AMIGOZ TRAVELS & TOURS PTE. LTD.",
     "TPSystemValue": "H690"
    },
    {
     
     "CodeDescription": "AMKOR TECHNOLOGY SINGAPORE PTE LTD",
     "TPSystemValue": 914
    },
    {
     
     "CodeDescription": "AMMERAAL BELTECH PTE LTD",
     "TPSystemValue": 1525
    },
    {
     
     "CodeDescription": "AMNET TECHNOLOGY PTE LTD",
     "TPSystemValue": 426
    },
    {
     
     "CodeDescription": "AMO SINGAPORE PTE LTD",
     "TPSystemValue": 1526
    },
    {
     
     "CodeDescription": "AMPWAY INDUSTRIES PTE LTD",
     "TPSystemValue": 1527
    },
    {
     
     "CodeDescription": "AMTEK PRECISION TECHNOLOGY PTE LTD",
     "TPSystemValue": 1140
    },
    {
     
     "CodeDescription": "AMWAY SINGAPORE PTE LTD",
     "TPSystemValue": 1528
    },
    {
     
     "CodeDescription": "ANCHORAGE CONSTRUCTION CO PTE LTD",
     "TPSystemValue": "13A"
    },
    {
     
     "CodeDescription": "ANCHORAGE CONSTRUCTION COMPANY PTE LTD",
     "TPSystemValue": 1529
    },
    {
     
     "CodeDescription": "ANDERCO PTE LTD",
     "TPSystemValue": 453
    },
    {
     
     "CodeDescription": "ANDERCO PTE. LTD.",
     "TPSystemValue": "14A"
    },
    {
     
     "CodeDescription": "ANDERSON SHIPPING COMPANY PTE LTD",
     "TPSystemValue": 696
    },
    {
     
     "CodeDescription": "ANG BROTHERS (M & E) P L",
     "TPSystemValue": "15A"
    },
    {
     
     "CodeDescription": "ANG BROTHERS (M&E) PTE LTD",
     "TPSystemValue": 827
    },
    {
     
     "CodeDescription": "ANGLO EASTERN SHIPMANGEMENT (S) PL",
     "TPSystemValue": 1530
    },
    {
     
     "CodeDescription": "ANIMAL RECOVERY VETERINARY CENTRE PL",
     "TPSystemValue": 1531
    },
    {
     
     "CodeDescription": "ANIXTER SINGAPORE PTE LTD",
     "TPSystemValue": 379
    },
    {
     
     "CodeDescription": "ANL SINGAPORE PTE LTD",
     "TPSystemValue": 869
    },
    {
     
     "CodeDescription": "AN-NUR TRAVEL PTE LTD",
     "TPSystemValue": "H294"
    },
    {
     
     "CodeDescription": "ANOTHER MARINE & ENGINEERING WORKS",
     "TPSystemValue": 1532
    },
    {
     
     "CodeDescription": "ANTHONY LAW CORPORATION",
     "TPSystemValue": 3603
    },
    {
     
     "CodeDescription": "ANZ BANK",
     "TPSystemValue": 1533
    },
    {
     
     "CodeDescription": "AON BENFIELD ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1534
    },
    {
     
     "CodeDescription": "AON CONSULTING SINGAPORE PTE LTD",
     "TPSystemValue": 1535
    },
    {
     
     "CodeDescription": "AON HEWITT SINGAPORE",
     "TPSystemValue": 319
    },
    {
     
     "CodeDescription": "AON RISK SERVICES SINGAPORE PL",
     "TPSystemValue": 1536
    },
    {
     
     "CodeDescription": "AON SINGAPORE PTE LTD",
     "TPSystemValue": 1537
    },
    {
     
     "CodeDescription": "AP COMMUNICATIONS PTE LTD",
     "TPSystemValue": 1538
    },
    {
     
     "CodeDescription": "APAR TECHNOLOGIES PTE LTD",
     "TPSystemValue": 1036
    },
    {
     
     "CodeDescription": "APEXLYNX LEISURESPORT PRIVATE LIMITED",
     "TPSystemValue": "H683"
    },
    {
     
     "CodeDescription": "APG PTE LTD",
     "TPSystemValue": 1539
    },
    {
     
     "CodeDescription": "APL CO PTE LTD",
     "TPSystemValue": 1540
    },
    {
     
     "CodeDescription": "APM (HOLDINGS) PTE LTD",
     "TPSystemValue": 1242
    },
    {
     
     "CodeDescription": "APM PROPERTY MANAGEMENT PTE LTD",
     "TPSystemValue": 473
    },
    {
     
     "CodeDescription": "APOLLO ASIA HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H659"
    },
    {
     
     "CodeDescription": "APP ENGINEERING PTE LTD",
     "TPSystemValue": 1541
    },
    {
     
     "CodeDescription": "APP SYSTEMS SERVICES PTE LTD",
     "TPSystemValue": 1542
    },
    {
     
     "CodeDescription": "APPCO GROUP HOLDINGS PTE LTD",
     "TPSystemValue": 1252
    },
    {
     
     "CodeDescription": "APPLE HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H371"
    },
    {
     
     "CodeDescription": "APPLE INC PTE LTD",
     "TPSystemValue": 1543
    },
    {
     
     "CodeDescription": "APPLE SOUTH ASIA PTE LTD",
     "TPSystemValue": 328
    },
    {
     
     "CodeDescription": "APPLE VACATIONS SINGAPORE PTE. LTD.",
     "TPSystemValue": "H575"
    },
    {
     
     "CodeDescription": "APPLE WORLD TRAVEL PTE. LTD.",
     "TPSystemValue": "H693"
    },
    {
     
     "CodeDescription": "APPLIED BIOSYSTEMS(S) PL",
     "TPSystemValue": 1544
    },
    {
     
     "CodeDescription": "APPLIED MATERIALS (S) TECHNOLOGY PL",
     "TPSystemValue": 1326
    },
    {
     
     "CodeDescription": "APPLIED MATRIALS SOUTH EAST ASIA PTE LTD",
     "TPSystemValue": 1545
    },
    {
     
     "CodeDescription": "APRO TECHNOLOGY PTE LTD",
     "TPSystemValue": 461
    },
    {
     
     "CodeDescription": "APS ASSET MANAGEMENT PTE LTD",
     "TPSystemValue": 1546
    },
    {
     
     "CodeDescription": "APT TRAVEL PTE. LTD.",
     "TPSystemValue": "H549"
    },
    {
     
     "CodeDescription": "AQ TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H737"
    },
    {
     
     "CodeDescription": "ARA MANAGEMENT PTE LTD",
     "TPSystemValue": 3604
    },
    {
     
     "CodeDescription": "ARAB BANK PLC",
     "TPSystemValue": "A04"
    },
    {
     
     "CodeDescription": "ARCADIA ENERGY PTE LTD",
     "TPSystemValue": 1055
    },
    {
     
     "CodeDescription": "ARCHITECT 61 PTE LTD",
     "TPSystemValue": 1547
    },
    {
     
     "CodeDescription": "ARCTURUS ENTERPRISE",
     "TPSystemValue": 1370
    },
    {
     
     "CodeDescription": "ARENA TRAVEL PTE. LTD.",
     "TPSystemValue": "H505"
    },
    {
     
     "CodeDescription": "AREVA T&D SA",
     "TPSystemValue": 2837
    },
    {
     
     "CodeDescription": "ARGENTA UNDERWRITING ASIA PTE LIMITED",
     "TPSystemValue": 1210
    },
    {
     
     "CodeDescription": "ARGON CRITICAL CARE SYSTEMS S PL",
     "TPSystemValue": 215
    },
    {
     
     "CodeDescription": "ARGON MEDICAL DEVICES SINGAPORE PTE LTD",
     "TPSystemValue": 3605
    },
    {
     
     "CodeDescription": "ARMAJARO SINGAPORE PTE LTD",
     "TPSystemValue": 1105
    },
    {
     
     "CodeDescription": "ARMSTRONG INDUSTRIAL CORPORATION LTD",
     "TPSystemValue": 169
    },
    {
     
     "CodeDescription": "ARMSTRONG RUBBER MAN PTE LTD",
     "TPSystemValue": 245
    },
    {
     
     "CodeDescription": "ARRAY ENGRG & CONSTRUCTION PTE LTD",
     "TPSystemValue": 1548
    },
    {
     
     "CodeDescription": "ARROW ELECTRONICS (S) PTE LTD",
     "TPSystemValue": 298
    },
    {
     
     "CodeDescription": "ARROW ELECTRONICS ASIA S PTE LTD",
     "TPSystemValue": 514
    },
    {
     
     "CodeDescription": "ARUP SINGAPORE PTE LTD",
     "TPSystemValue": 1549
    },
    {
     
     "CodeDescription": "ARVATO DIGITAL SERVICE PTE LTD",
     "TPSystemValue": 1550
    },
    {
     
     "CodeDescription": "ARVATO SYSTEMS SINGAPORE PTE LTD",
     "TPSystemValue": 1551
    },
    {
     
     "CodeDescription": "ASAHI KASEI PLASTICS SPORE PTE LTD",
     "TPSystemValue": 1552
    },
    {
     
     "CodeDescription": "ASAHI TRAVEL PTE. LTD.",
     "TPSystemValue": "H557"
    },
    {
     
     "CodeDescription": "ASCADE ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1553
    },
    {
     
     "CodeDescription": "ASCEND COM PTE LTD",
     "TPSystemValue": 656
    },
    {
     
     "CodeDescription": "ASCEND RADIANCE TRAVEL PTE. LTD.",
     "TPSystemValue": "H411"
    },
    {
     
     "CodeDescription": "ASCENDAS ASIA FUNDS MANAGEMENT PTE LTD",
     "TPSystemValue": 1089
    },
    {
     
     "CodeDescription": "ASCENDAS HOLDINGS PTE LTD",
     "TPSystemValue": 1103
    },
    {
     
     "CodeDescription": "ASCENDAS LAND SINGAPORE PTE LTD",
     "TPSystemValue": 183
    },
    {
     
     "CodeDescription": "ASCENDAS PTE LTD",
     "TPSystemValue": 3607
    },
    {
     
     "CodeDescription": "ASCENDAS SERVICES PTE LTD",
     "TPSystemValue": 1554
    },
    {
     
     "CodeDescription": "ASCO ASIA",
     "TPSystemValue": 1555
    },
    {
     
     "CodeDescription": "ASCOTT INTERNATIONAL MANAGEMENT PL",
     "TPSystemValue": 450
    },
    {
     
     "CodeDescription": "ASCOTT RESIDENCE TRUST MANAGEMENT LTD",
     "TPSystemValue": 1022
    },
    {
     
     "CodeDescription": "ASE SINGAPORE PTE LTD",
     "TPSystemValue": 1556
    },
    {
     
     "CodeDescription": "ASEAN+3 MACROECONOMIC RESEARCH",
     "TPSystemValue": 1557
    },
    {
     
     "CodeDescription": "ASIA ACCESS TELECOM PTE LTD",
     "TPSystemValue": 719
    },
    {
     
     "CodeDescription": "ASIA AIR TRAVEL & TOUR PRIVATE LIMITED",
     "TPSystemValue": "H826"
    },
    {
     
     "CodeDescription": "ASIA CONSOLIDATED DMC PTE. LTD.",
     "TPSystemValue": "H626"
    },
    {
     
     "CodeDescription": "ASIA DISCOVERY CONSULTANCY PTE. LTD.",
     "TPSystemValue": "H634"
    },
    {
     
     "CodeDescription": "ASIA ECO TRAVEL PTE. LTD.",
     "TPSystemValue": "H329"
    },
    {
     
     "CodeDescription": "ASIA GLOBAL VACATION PTE. LTD.",
     "TPSystemValue": "H432"
    },
    {
     
     "CodeDescription": "ASIA MATSUSHITA ELECTRIC (S) PTE LTD",
     "TPSystemValue": 1558
    },
    {
     
     "CodeDescription": "ASIA MYANMAR TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H531"
    },
    {
     
     "CodeDescription": "ASIA MYRIAD PTE. LTD.",
     "TPSystemValue": 812
    },
    {
     
     "CodeDescription": "ASIA PACIFIC BREWERIES LIMITED",
     "TPSystemValue": 9
    },
    {
     
     "CodeDescription": "ASIA PACIFIC BREWERIES SINGAPORE PTE LTD",
     "TPSystemValue": 1559
    },
    {
     
     "CodeDescription": "ASIA PACIFIC METALS REFINER PTE LTD",
     "TPSystemValue": 1313
    },
    {
     
     "CodeDescription": "ASIA PACIFIC STAR PTE LTD",
     "TPSystemValue": 1560
    },
    {
     
     "CodeDescription": "ASIA PACIFIC TOURISM & CULTURAL COUNCIL PTE. LTD.",
     "TPSystemValue": "H638"
    },
    {
     
     "CodeDescription": "ASIA PROJECT ENGINEERING PTE LTD",
     "TPSystemValue": 1561
    },
    {
     
     "CodeDescription": "ASIA TO AFRICA SAFARIS HOLDINGS PTE. LTD.",
     "TPSystemValue": "H662"
    },
    {
     
     "CodeDescription": "ASIA TRANS TRAVEL PTE. LTD.",
     "TPSystemValue": "H457"
    },
    {
     
     "CodeDescription": "ASIA TRAVEL GROUP PTE. LTD.",
     "TPSystemValue": "H456"
    },
    {
     
     "CodeDescription": "ASIA TREASURE HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H644"
    },
    {
     
     "CodeDescription": "ASIABIZ SERVICES PTE. LTD.",
     "TPSystemValue": 836
    },
    {
     
     "CodeDescription": "ASIAGOODLIFE PTE. LTD.",
     "TPSystemValue": "H385"
    },
    {
     
     "CodeDescription": "ASIAN CLASSIC TRAVELS PTE. LTD.",
     "TPSystemValue": "H825"
    },
    {
     
     "CodeDescription": "ASIAN COMPASS TRAVEL PTE. LTD.",
     "TPSystemValue": "H630"
    },
    {
     
     "CodeDescription": "ASIAN CONTINENT TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H489"
    },
    {
     
     "CodeDescription": "ASIAN ESCAPES TOURISM PTE. LTD.",
     "TPSystemValue": "H431"
    },
    {
     
     "CodeDescription": "ASIAN FAMOUS TOURS & TRAVEL PTE. LTD.",
     "TPSystemValue": "H618"
    },
    {
     
     "CodeDescription": "ASIAN GLOBAL GROUND HANDLING SERVICES PTE. LTD.",
     "TPSystemValue": "H553"
    },
    {
     
     "CodeDescription": "ASIAN OVERLAND TRAVEL PTE. LTD.",
     "TPSystemValue": "H670"
    },
    {
     
     "CodeDescription": "ASIANA HOLIDAYS SINGAPORE PTE. LTD.",
     "TPSystemValue": "H655"
    },
    {
     
     "CodeDescription": "ASIA-PACIFIC STAR PRIVATE LIMITED",
     "TPSystemValue": 1562
    },
    {
     
     "CodeDescription": "ASIASOFT ONLINE PL",
     "TPSystemValue": 3608
    },
    {
     
     "CodeDescription": "ASIATEC ENGINEERING PTE LTD",
     "TPSystemValue": 1563
    },
    {
     
     "CodeDescription": "ASIATEC MARINE SERVICES PTE LTD",
     "TPSystemValue": 1564
    },
    {
     
     "CodeDescription": "ASIATRAVEL.COM HLDGS",
     "TPSystemValue": "H022"
    },
    {
     
     "CodeDescription": "ASIAWIDE PRINT HOLDINGS PTE LTD",
     "TPSystemValue": 1107
    },
    {
     
     "CodeDescription": "ASL AVIATION GROUP PTE. LTD.",
     "TPSystemValue": "H106"
    },
    {
     
     "CodeDescription": "ASLER TRAVEL SERVICES PTE LTD",
     "TPSystemValue": "H308"
    },
    {
     
     "CodeDescription": "ASM FRONTEND MANUFACTURING S PL",
     "TPSystemValue": 897
    },
    {
     
     "CodeDescription": "ASM TECHNOLOGY SINGAPORE PTE LTD",
     "TPSystemValue": 335
    },
    {
     
     "CodeDescription": "ASML SINGAPORE PTE LTD",
     "TPSystemValue": 1565
    },
    {
     
     "CodeDescription": "ASPEN PROPERTY CONSULTANTS PTE LTD",
     "TPSystemValue": 1566
    },
    {
     
     "CodeDescription": "ASPEN3 PTE. LTD.",
     "TPSystemValue": "H435"
    },
    {
     
     "CodeDescription": "ASPENTECH PTE LTD",
     "TPSystemValue": 824
    },
    {
     
     "CodeDescription": "ASPIAL CORPORATION LIMITED",
     "TPSystemValue": 1567
    },
    {
     
     "CodeDescription": "ASPIAL-LEE HWA JEWELLERY SINGAPORE PL",
     "TPSystemValue": 1568
    },
    {
     
     "CodeDescription": "ASPIRE HUB COACHING INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H485"
    },
    {
     
     "CodeDescription": "ASSISTANCE TRAVEL PTE. LTD.",
     "TPSystemValue": "H162"
    },
    {
     
     "CodeDescription": "ASTI HOLDINGS LIMITED",
     "TPSystemValue": 686
    },
    {
     
     "CodeDescription": "ASTON FOOD & BEVERAGE SPECIALITIES PL",
     "TPSystemValue": 1569
    },
    {
     
     "CodeDescription": "ASTON FOOD & BEVERAGE SPECIALITIES PTE. LTD.",
     "TPSystemValue": "17A"
    },
    {
     
     "CodeDescription": "ASTRAZENELA SINGAPORE PTE LTD",
     "TPSystemValue": 1570
    },
    {
     
     "CodeDescription": "ASTRONIC SERVICES & TRADING PTE LTD",
     "TPSystemValue": 1571
    },
    {
     
     "CodeDescription": "ASUS TECHNOLOGY PTE LIMITED",
     "TPSystemValue": 980
    },
    {
     
     "CodeDescription": "ASVIDA ASIA PTE LTD",
     "TPSystemValue": 1245
    },
    {
     
     "CodeDescription": "AT & T SINGAPORE PTE. LTD.",
     "TPSystemValue": 532
    },
    {
     
     "CodeDescription": "ATEA ENVIRONMENTAL TECHNOLOGY PTE LTD",
     "TPSystemValue": 767
    },
    {
     
     "CodeDescription": "ATG TOURS PTE. LTD.",
     "TPSystemValue": "H723"
    },
    {
     
     "CodeDescription": "ATIS GLOBAL PTE. LTD.",
     "TPSystemValue": "H405"
    },
    {
     
     "CodeDescription": "ATLAS COPCO SEA PL",
     "TPSystemValue": 1572
    },
    {
     
     "CodeDescription": "ATLAS LOGISTICS (FAR EAST) PTE LTD",
     "TPSystemValue": 1013
    },
    {
     
     "CodeDescription": "ATLAS TRAVEL & LIMOUSINE PTE. LTD.",
     "TPSystemValue": "H467"
    },
    {
     
     "CodeDescription": "ATMD BIRD & BIRD LLP",
     "TPSystemValue": 3099
    },
    {
     
     "CodeDescription": "ATOS INFORMATION TECHNOLOGY (S) P L",
     "TPSystemValue": 1573
    },
    {
     
     "CodeDescription": "ATOS ORIGIN",
     "TPSystemValue": 1574
    },
    {
     
     "CodeDescription": "ATPI (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H275"
    },
    {
     
     "CodeDescription": "ATTORNEY GENERAL CHAMBERS",
     "TPSystemValue": 1575
    },
    {
     
     "CodeDescription": "AUDITOR GENERAL S OFFICE",
     "TPSystemValue": 1576
    },
    {
     
     "CodeDescription": "AUPE GENERAL SERVICES CO-OPERATIVE LTD",
     "TPSystemValue": 2835
    },
    {
     
     "CodeDescription": "AUPE MULTI-PURPOSE CO-OPERATIVE LTD",
     "TPSystemValue": 3413
    },
    {
     
     "CodeDescription": "AUPE SPORTS & RECREATIONAL CLUB",
     "TPSystemValue": 3415
    },
    {
     
     "CodeDescription": "AURIC PACIFIC MARKETING PTE LTD",
     "TPSystemValue": 495
    },
    {
     
     "CodeDescription": "AUSGROUP SINGAPORE PTE LTD",
     "TPSystemValue": 367
    },
    {
     
     "CodeDescription": "AUSPICIOUS TRAVEL PTE. LTD.",
     "TPSystemValue": "H642"
    },
    {
     
     "CodeDescription": "AUSSINO HOME FASHIONS PTE LTD",
     "TPSystemValue": 520
    },
    {
     
     "CodeDescription": "AUSTEN MARITIME SERVICES PTE LTD",
     "TPSystemValue": 272
    },
    {
     
     "CodeDescription": "AUSTRAL ASIA LINE PTE LTD",
     "TPSystemValue": 1190
    },
    {
     
     "CodeDescription": "AUSTRALIA & NEW ZEALAND BANKING GROUP LIMITED",
     "TPSystemValue": "A05"
    },
    {
     
     "CodeDescription": "AUSTRALIA AND NEW ZEALAND BANKING GROUP",
     "TPSystemValue": 1577
    },
    {
     
     "CodeDescription": "AUSTRALIAN INTERNATIONAL SCHOOL PTE LTD",
     "TPSystemValue": 418
    },
    {
     
     "CodeDescription": "AUTO EUROKARS PTE LTD",
     "TPSystemValue": 782
    },
    {
     
     "CodeDescription": "AUTODESK ASIA PTE LTD",
     "TPSystemValue": 420
    },
    {
     
     "CodeDescription": "AVAGO TECHNOLOGIES ECBU IP SINGAPORE PTE LTD",
     "TPSystemValue": 3609
    },
    {
     
     "CodeDescription": "AVAGO TECHNOLOGIES MANUFACTURING SPL",
     "TPSystemValue": 1005
    },
    {
     
     "CodeDescription": "AVAGO TECHNOLOGIES PTE LTD",
     "TPSystemValue": 1579
    },
    {
     
     "CodeDescription": "AVANADE ASIA PTE LTD",
     "TPSystemValue": 741
    },
    {
     
     "CodeDescription": "AVANSTRATE ASIA PRIVATE LIMITED",
     "TPSystemValue": 746
    },
    {
     
     "CodeDescription": "AVA'S TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H383"
    },
    {
     
     "CodeDescription": "AVAYA SINGAPORE PTE LTD",
     "TPSystemValue": 733
    },
    {
     
     "CodeDescription": "AVENSYS CONSULTING PTE LTD",
     "TPSystemValue": 1104
    },
    {
     
     "CodeDescription": "AVENTIS PHARMA MANUFACTURING PL",
     "TPSystemValue": 1580
    },
    {
     
     "CodeDescription": "AVESON TRAVEL PTE. LTD.",
     "TPSystemValue": "H389"
    },
    {
     
     "CodeDescription": "AVIATION SERVICES (GSA) PTE LTD",
     "TPSystemValue": "H108"
    },
    {
     
     "CodeDescription": "AVIATION SERVICES PTE LTD",
     "TPSystemValue": "H193"
    },
    {
     
     "CodeDescription": "AVIVA ASIA PTE LTD",
     "TPSystemValue": 683
    },
    {
     
     "CodeDescription": "AVIVA LTD",
     "TPSystemValue": 55
    },
    {
     
     "CodeDescription": "AVNET ASIA PTE LTD",
     "TPSystemValue": 679
    },
    {
     
     "CodeDescription": "AVNET TECHNOLOGY SOLUTIONS S PL",
     "TPSystemValue": 579
    },
    {
     
     "CodeDescription": "AVON SERVICES PTE LTD",
     "TPSystemValue": 1189
    },
    {
     
     "CodeDescription": "AXA ASIA REGIONAL CENTRE PTE LTD",
     "TPSystemValue": 662
    },
    {
     
     "CodeDescription": "AXA INSURANCE SINGAPORE PTE LTD",
     "TPSystemValue": 54
    },
    {
     
     "CodeDescription": "AXA LIFE INSURANCE COMPANY LIMITED",
     "TPSystemValue": 2804
    },
    {
     
     "CodeDescription": "AXA LIFE INSURANCE SINGAPORE PL",
     "TPSystemValue": 691
    },
    {
     
     "CodeDescription": "AXA LIFE S AXA FINANCIAL SERVICES",
     "TPSystemValue": 853
    },
    {
     
     "CodeDescription": "AXIOMSL PTE LTD",
     "TPSystemValue": 1272
    },
    {
     
     "CodeDescription": "AZ BUS PTE. LTD.",
     "TPSystemValue": "H348"
    },
    {
     
     "CodeDescription": "AZ HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H507"
    },
    {
     
     "CodeDescription": "AZIL ENGINEERS PTE LTD",
     "TPSystemValue": 985
    },
    {
     
     "CodeDescription": "AZIMUTH WATCH COMPANY PTE LTD",
     "TPSystemValue": 974
    },
    {
     
     "CodeDescription": "AZURE HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H799"
    },
    {
     
     "CodeDescription": "AZZA TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H213"
    },
    {
     
     "CodeDescription": "AZZURA RESORT PL -AZZURA BEACHCLUB PL",
     "TPSystemValue": 927
    },
    {
     
     "CodeDescription": "AZZURA RESORTS PTE LTD",
     "TPSystemValue": 942
    },
    {
     
     "CodeDescription": "B & A TRAVEL PTE. LTD.",
     "TPSystemValue": "H426"
    },
    {
     
     "CodeDescription": "B & L TRAVEL PTE LTD",
     "TPSystemValue": "H185"
    },
    {
     
     "CodeDescription": "BABUS SALAM HOLIDAYS PTE LTD",
     "TPSystemValue": "H317"
    },
    {
     
     "CodeDescription": "BABY HOLIDAY EVENTS PTE. LTD.",
     "TPSystemValue": "H619"
    },
    {
     
     "CodeDescription": "BAIN & COMPANY SE ASIA INC",
     "TPSystemValue": 2827
    },
    {
     
     "CodeDescription": "BAKER HUGHES",
     "TPSystemValue": 1582
    },
    {
     
     "CodeDescription": "BAKER MCKENZIE WONG LEOW",
     "TPSystemValue": 759
    },
    {
     
     "CodeDescription": "BAKER TILLY TFW LLP",
     "TPSystemValue": 1583
    },
    {
     
     "CodeDescription": "BALLET & MUSIC",
     "TPSystemValue": 1584
    },
    {
     
     "CodeDescription": "BAN CHUAN TRADING ENGINEERING PTE LTD",
     "TPSystemValue": 1585
    },
    {
     
     "CodeDescription": "BANCO BILBAO VIZCAYA ARGENTARIA, S.A.",
     "TPSystemValue": "B01"
    },
    {
     
     "CodeDescription": "BANGKOK BANK PUBLIC COMPANY LIMITED",
     "TPSystemValue": "B02"
    },
    {
     
     "CodeDescription": "BANK HAPOALIM (SWITZERLAND) LTD",
     "TPSystemValue": "B03"
    },
    {
     
     "CodeDescription": "BANK JULIUS BAER & CO LTD",
     "TPSystemValue": 3083
    },
    {
     
     "CodeDescription": "BANK JULIUS BAER & CO. LTD.",
     "TPSystemValue": "B04"
    },
    {
     
     "CodeDescription": "BANK OF AMERICA",
     "TPSystemValue": 1586
    },
    {
     
     "CodeDescription": "BANK OF AMERICA, NATIONAL ASSOCIATION",
     "TPSystemValue": "B05"
    },
    {
     
     "CodeDescription": "BANK OF CHINA",
     "TPSystemValue": 1587
    },
    {
     
     "CodeDescription": "BANK OF CHINA LIMITED",
     "TPSystemValue": "B07"
    },
    {
     
     "CodeDescription": "BANK OF COMMUNICATIONS CO LTD",
     "TPSystemValue": "B08"
    },
    {
     
     "CodeDescription": "BANK OF EAST ASIA LTD, THE",
     "TPSystemValue": "B09"
    },
    {
     
     "CodeDescription": "BANK OF INDIA",
     "TPSystemValue": "B10"
    },
    {
     
     "CodeDescription": "BANK OF NEW YORK MELLON, THE",
     "TPSystemValue": "B11"
    },
    {
     
     "CodeDescription": "BANK OF NEW ZEALAND",
     "TPSystemValue": "B12"
    },
    {
     
     "CodeDescription": "BANK OF NOVA SCOTIA, THE",
     "TPSystemValue": "B13"
    },
    {
     
     "CodeDescription": "BANK OF TOKYO MITSUBISHI",
     "TPSystemValue": 1589
    },
    {
     
     "CodeDescription": "BANK OF TOKYO-MITSUBISHI UFJ, LTD, THE",
     "TPSystemValue": "B16"
    },
    {
     
     "CodeDescription": "BANKING COMPUTER SERVICES PRIVATE LTD",
     "TPSystemValue": 1590
    },
    {
     
     "CodeDescription": "BANSHING INDUSTRIAL COMPANY PTE LTD",
     "TPSystemValue": 101
    },
    {
     
     "CodeDescription": "BANYAN TREE ARCHITRAVE DESIGN SER PL",
     "TPSystemValue": 1340
    },
    {
     
     "CodeDescription": "BANYAN TREE CAPITAL PTE LTD",
     "TPSystemValue": 3611
    },
    {
     
     "CodeDescription": "BANYAN TREE HOLDINGS LIMITED",
     "TPSystemValue": 732
    },
    {
     
     "CodeDescription": "BANYAN TREE HOTELS RESORTS PTE LTD",
     "TPSystemValue": 395
    },
    {
     
     "CodeDescription": "BAOLAU PTE. LTD.",
     "TPSystemValue": "H808"
    },
    {
     
     "CodeDescription": "BARCLAYS BANK PLC",
     "TPSystemValue": "B17"
    },
    {
     
     "CodeDescription": "BARCLAYS BANK PTE LTD",
     "TPSystemValue": 1591
    },
    {
     
     "CodeDescription": "BARCLAYS CAP SERS LTD SPORE BRANCH",
     "TPSystemValue": 1592
    },
    {
     
     "CodeDescription": "BARCLAYS CAPITAL HOLDINGS SINGAPORE PRIVATE LIMI",
     "TPSystemValue": 3612
    },
    {
     
     "CodeDescription": "BARCLAYS CAPITAL SERVICES LMITED SINGAPORE BRANCH",
     "TPSystemValue": 1593
    },
    {
     
     "CodeDescription": "BARCLAYS GRB TECHNOLOGY",
     "TPSystemValue": 3423
    },
    {
     
     "CodeDescription": "BARCLAYS TECHNOLOGY CENTRE LIMITED",
     "TPSystemValue": 1594
    },
    {
     
     "CodeDescription": "BASF SOUTH EAST ASIA PTE LTD",
     "TPSystemValue": 1595
    },
    {
     
     "CodeDescription": "BATA SHOES (SINGAPORE) PL",
     "TPSystemValue": 1596
    },
    {
     
     "CodeDescription": "BATAM FAST FERRY PTE. LTD.",
     "TPSystemValue": "H390"
    },
    {
     
     "CodeDescription": "BAUER TECHNOLOGIES FAR EAST PTE LTD",
     "TPSystemValue": 393
    },
    {
     
     "CodeDescription": "BAUSCH LOMB SINGAPORE PRIVATE LIMITED",
     "TPSystemValue": 192
    },
    {
     
     "CodeDescription": "BAX GLOBAL",
     "TPSystemValue": 1597
    },
    {
     
     "CodeDescription": "BAXTER HEALTHCARE (ASIA) PTE LTD",
     "TPSystemValue": 131
    },
    {
     
     "CodeDescription": "BAYER",
     "TPSystemValue": 1598
    },
    {
     
     "CodeDescription": "BBH",
     "TPSystemValue": 1393
    },
    {
     
     "CodeDescription": "BCA",
     "TPSystemValue": 1599
    },
    {
     
     "CodeDescription": "BCD TRAVEL",
     "TPSystemValue": 1428
    },
    {
     
     "CodeDescription": "BCD TRAVEL ASIA PACIFIC PTE LTD",
     "TPSystemValue": 938
    },
    {
     
     "CodeDescription": "BCD TRAVEL SINGAPORE PTE LTD",
     "TPSystemValue": 203
    },
    {
     
     "CodeDescription": "BCS INFORMATION SYSTEMS PTE LTD",
     "TPSystemValue": 1600
    },
    {
     
     "CodeDescription": "BDO LLP",
     "TPSystemValue": 3113
    },
    {
     
     "CodeDescription": "BDO TAX ADVISORY PTE LTD",
     "TPSystemValue": 1186
    },
    {
     
     "CodeDescription": "BDP ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1601
    },
    {
     
     "CodeDescription": "BE HAO GENERAL CONSTRUCTION",
     "TPSystemValue": 1413
    },
    {
     
     "CodeDescription": "BEAUTY HOPE PTE LTD",
     "TPSystemValue": 1184
    },
    {
     
     "CodeDescription": "BEAUTY HOPE PTE. LTD.",
     "TPSystemValue": "02B"
    },
    {
     
     "CodeDescription": "BECA CARTER HOLLINGS&FERNER PTE.LTD",
     "TPSystemValue": 1602
    },
    {
     
     "CodeDescription": "BECKMAN COULTER SINGAPORE PTE LTD",
     "TPSystemValue": 153
    },
    {
     
     "CodeDescription": "BECTON DICKINSON MEDICAL SG PTE LTD",
     "TPSystemValue": 1603
    },
    {
     
     "CodeDescription": "BEECONOMIC SINGAPORE PTE. LTD.",
     "TPSystemValue": "H547"
    },
    {
     
     "CodeDescription": "BEIJING101 HAIR CARE PTE LTD",
     "TPSystemValue": 3795
    },
    {
     
     "CodeDescription": "BELGARATH INVESTMENTS PTE LTD",
     "TPSystemValue": 538
    },
    {
     
     "CodeDescription": "BEMYGUEST PTE. LTD.",
     "TPSystemValue": "H637"
    },
    {
     
     "CodeDescription": "BENCHMARK ELECTRONICS MANUFACTURING PL",
     "TPSystemValue": 666
    },
    {
     
     "CodeDescription": "BENCHMARK ELECTRONICS PTE LTD",
     "TPSystemValue": 294
    },
    {
     
     "CodeDescription": "BENGAL TIGER LINE PTE LTD",
     "TPSystemValue": 1185
    },
    {
     
     "CodeDescription": "BERCA INTERNATIONAL PTE LTD",
     "TPSystemValue": 1605
    },
    {
     
     "CodeDescription": "BERG PROPULSION PRODUCTION PTE LTD",
     "TPSystemValue": 1606
    },
    {
     
     "CodeDescription": "BERHERD SEHULTE SHIPMANAGEMENT P L",
     "TPSystemValue": 1607
    },
    {
     
     "CodeDescription": "BERJAYA HOTELS & RESORTS (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H087"
    },
    {
     
     "CodeDescription": "BERKELEY BOOKS PTE LTD",
     "TPSystemValue": 416
    },
    {
     
     "CodeDescription": "BERNARD & RADA LAW CORPORATION",
     "TPSystemValue": 901
    },
    {
     
     "CodeDescription": "BERNHARD SCHULTE SHIPMANAGEMENT S PL",
     "TPSystemValue": 742
    },
    {
     
     "CodeDescription": "BES TECHNOLOGY PTE LTD",
     "TPSystemValue": 728
    },
    {
     
     "CodeDescription": "BEST DENKI (SINGAPORE) PTE LTD",
     "TPSystemValue": 199
    },
    {
     
     "CodeDescription": "BEST TOURS PTE. LTD.",
     "TPSystemValue": "H804"
    },
    {
     
     "CodeDescription": "BEST WORLD INTERNATIONAL LIMITED",
     "TPSystemValue": 371
    },
    {
     
     "CodeDescription": "BESTLINK TRAVEL PTE LTD",
     "TPSystemValue": "H135"
    },
    {
     
     "CodeDescription": "BETEL BOX ASIA PTE. LTD.",
     "TPSystemValue": "H359"
    },
    {
     
     "CodeDescription": "BETIME MARKETING PTE LTD",
     "TPSystemValue": 1608
    },
    {
     
     "CodeDescription": "BEX TRAVEL ASIA PTE. LTD.",
     "TPSystemValue": "H602"
    },
    {
     
     "CodeDescription": "BEYONICS INTERNATIONAL PTE LTD",
     "TPSystemValue": 143
    },
    {
     
     "CodeDescription": "BEYONICS PRECISION ENGINEERING PTE LTD",
     "TPSystemValue": 220
    },
    {
     
     "CodeDescription": "BEYONICS TECHNOLOGY LIMITED",
     "TPSystemValue": 481
    },
    {
     
     "CodeDescription": "BF GLASS MOULD OVERSEAS PTE LTD",
     "TPSystemValue": 888
    },
    {
     
     "CodeDescription": "BG ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1609
    },
    {
     
     "CodeDescription": "BGC GROUP PTE LTD",
     "TPSystemValue": 971
    },
    {
     
     "CodeDescription": "BGC PARTNERS (SINGAPORE) LMITED",
     "TPSystemValue": 1187
    },
    {
     
     "CodeDescription": "BHP BILLITON MARKETING ASIA PTE LTD",
     "TPSystemValue": 780
    },
    {
     
     "CodeDescription": "BIBBY OFFSHORE SINGAPORE PTE LTD",
     "TPSystemValue": 1265
    },
    {
     
     "CodeDescription": "BIDEAS INTERNATIONAL PTE LTD",
     "TPSystemValue": 948
    },
    {
     
     "CodeDescription": "BIDEAS INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "04B"
    },
    {
     
     "CodeDescription": "BIG BUS SINGAPORE CITY SIGHTSEEING PTE. LTD.",
     "TPSystemValue": "H609"
    },
    {
     
     "CodeDescription": "BIG RALLYE PTE. LTD.",
     "TPSystemValue": "H538"
    },
    {
     
     "CodeDescription": "BIG S'HOLIDAY PTE. LTD.",
     "TPSystemValue": "H152"
    },
    {
     
     "CodeDescription": "BIGCITY FASHION PTE LTD",
     "TPSystemValue": 3613
    },
    {
     
     "CodeDescription": "BIL SHIP MANAGEMENT PTE LTD",
     "TPSystemValue": 1244
    },
    {
     
     "CodeDescription": "BILCARE TECHNOLOGIES SINGAPORE PTE LTD",
     "TPSystemValue": 960
    },
    {
     
     "CodeDescription": "BINA MARINE PTE LTD",
     "TPSystemValue": 605
    },
    {
     
     "CodeDescription": "BINTAN RESORT FERRIES PRIVATE LIMITED",
     "TPSystemValue": "H231"
    },
    {
     
     "CodeDescription": "BINTAN RESORTS INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H472"
    },
    {
     
     "CodeDescription": "BIO PROCESSING TECHNOLOGY INSTITUTE",
     "TPSystemValue": 1403
    },
    {
     
     "CodeDescription": "BIO RAD LABORATORIES (S) P L",
     "TPSystemValue": 1610
    },
    {
     
     "CodeDescription": "BIOINFORMATICS INSTITUTE",
     "TPSystemValue": 1405
    },
    {
     
     "CodeDescription": "BIOMEDICAL SCIENCES INSTITUTE",
     "TPSystemValue": 593
    },
    {
     
     "CodeDescription": "BIOPROCESSING TECHNOLOGY INSTITUTE",
     "TPSystemValue": 1404
    },
    {
     
     "CodeDescription": "BIORAD LABORATORIES SINGAPORE PL",
     "TPSystemValue": 460
    },
    {
     
     "CodeDescription": "BIOSENSORS INTERVENTIONAL TECHN PL",
     "TPSystemValue": 1037
    },
    {
     
     "CodeDescription": "BISLEY ASIA PTE LTD",
     "TPSystemValue": 202
    },
    {
     
     "CodeDescription": "BITMAZK PTE. LTD.",
     "TPSystemValue": 1341
    },
    {
     
     "CodeDescription": "BKK TRAVEL PTE. LTD.",
     "TPSystemValue": "H800"
    },
    {
     
     "CodeDescription": "BLACKROCK ADVISORS SINGAPORE PTE. LTD.",
     "TPSystemValue": 1611
    },
    {
     
     "CodeDescription": "BLOOMBERG L.P.",
     "TPSystemValue": 2824
    },
    {
     
     "CodeDescription": "BLS INTERNATIONAL SERVICES SINGAPORE PTE. LTD.",
     "TPSystemValue": "H632"
    },
    {
     
     "CodeDescription": "BLUESCOPE LYSAGHT SINGAPORE PTE LTD",
     "TPSystemValue": 1612
    },
    {
     
     "CodeDescription": "BLUESTONE OFFSHORE PTE LTD",
     "TPSystemValue": 1069
    },
    {
     
     "CodeDescription": "BLUGRAPES PTE LTD",
     "TPSystemValue": 1043
    },
    {
     
     "CodeDescription": "BM EXECUTIVES LLP",
     "TPSystemValue": 3425
    },
    {
     
     "CodeDescription": "BMSI",
     "TPSystemValue": 1613
    },
    {
     
     "CodeDescription": "BMT ASIA PACIFIC PTE LTD",
     "TPSystemValue": 755
    },
    {
     
     "CodeDescription": "BMW ASIA PACIFIC CAPITAL PTE LTD",
     "TPSystemValue": 1614
    },
    {
     
     "CodeDescription": "BNP PARIBAS SECURITIES SERVICES",
     "TPSystemValue": "B19"
    },
    {
     
     "CodeDescription": "BNP PARIBAS WEALTH MANAGEMENT",
     "TPSystemValue": "B20"
    },
    {
     
     "CodeDescription": "BOC AVIATION PTE LTD",
     "TPSystemValue": 1616
    },
    {
     
     "CodeDescription": "BODY CONTOURS PTE LTD",
     "TPSystemValue": 1617
    },
    {
     
     "CodeDescription": "BOERGER PUMPS ASIA PTE LTD",
     "TPSystemValue": 637
    },
    {
     
     "CodeDescription": "BOND BUILDING PRODUCTS PTE LTD",
     "TPSystemValue": 1618
    },
    {
     
     "CodeDescription": "BOOKING.COM",
     "TPSystemValue": "H011"
    },
    {
     
     "CodeDescription": "BOON KENG TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H576"
    },
    {
     
     "CodeDescription": "BORNEO MOTORS SINGAPORE PTE LTD",
     "TPSystemValue": 37
    },
    {
     
     "CodeDescription": "BOSCH REXROTH PTE LTD",
     "TPSystemValue": 124
    },
    {
     
     "CodeDescription": "BOSSARD PTE LTD",
     "TPSystemValue": 522
    },
    {
     
     "CodeDescription": "BOSTON SCIENTIFIC ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1619
    },
    {
     
     "CodeDescription": "BOUSTEAD PROJECTS PTE LTD",
     "TPSystemValue": 555
    },
    {
     
     "CodeDescription": "BOUSTEAD SINGAPORE LTD",
     "TPSystemValue": 1620
    },
    {
     
     "CodeDescription": "BOVIS LEND LEASE PHARMACEUTICAL PL",
     "TPSystemValue": 1621
    },
    {
     
     "CodeDescription": "BOWRING MARSH ASIA PTE LTD",
     "TPSystemValue": 114
    },
    {
     
     "CodeDescription": "BP ASIA PACIFIC PTE LTD",
     "TPSystemValue": 351
    },
    {
     
     "CodeDescription": "BRANSON ULTRASONICS",
     "TPSystemValue": 1364
    },
    {
     
     "CodeDescription": "BREADTALK GROUP LIMITED",
     "TPSystemValue": 3614
    },
    {
     
     "CodeDescription": "BREADTALK PTE LTD",
     "TPSystemValue": 735
    },
    {
     
     "CodeDescription": "BRIGHT STAR ENGINEERING",
     "TPSystemValue": 1622
    },
    {
     
     "CodeDescription": "BRIGHTOIL PETROLEUM SINGAPORE PTE LTD",
     "TPSystemValue": 1168
    },
    {
     
     "CodeDescription": "BRISTOLMYERS SQUIBB SINGAPORE PTE LTD",
     "TPSystemValue": 36
    },
    {
     
     "CodeDescription": "BRITISH AIRWAYS",
     "TPSystemValue": 1623
    },
    {
     
     "CodeDescription": "BRITISH AMERICAN TOBACCO",
     "TPSystemValue": 175
    },
    {
     
     "CodeDescription": "BRITISH HIGH COMMISSION SINGAPORE",
     "TPSystemValue": 1624
    },
    {
     
     "CodeDescription": "BRITISH TELECOMUNICATION",
     "TPSystemValue": 1625
    },
    {
     
     "CodeDescription": "BRIZ ASIA PTE LTD",
     "TPSystemValue": 798
    },
    {
     
     "CodeDescription": "BROADCOM ASIA DISTRIBUTION PTE LTD",
     "TPSystemValue": 822
    },
    {
     
     "CodeDescription": "BROADCOM SINGAPORE PTE LTD",
     "TPSystemValue": 681
    },
    {
     
     "CodeDescription": "BROADWAY TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H081"
    },
    {
     
     "CodeDescription": "BROTHERS INTERNATIONAL TOURS & TRADING PTE. LTD.",
     "TPSystemValue": "H471"
    },
    {
     
     "CodeDescription": "BRUNEL ENERGY",
     "TPSystemValue": 3615
    },
    {
     
     "CodeDescription": "BRUNEL INTERNATIONAL SEA PL",
     "TPSystemValue": 548
    },
    {
     
     "CodeDescription": "BRYAN BOILER ENGINEERING PTE LTD",
     "TPSystemValue": "05B"
    },
    {
     
     "CodeDescription": "BS TECH PTE LTD",
     "TPSystemValue": 998
    },
    {
     
     "CodeDescription": "BSH HOME APPLIANCES PTE LTD",
     "TPSystemValue": 574
    },
    {
     
     "CodeDescription": "BSI BANK",
     "TPSystemValue": 1626
    },
    {
     
     "CodeDescription": "BSM ENGINEEERING PTE LTD",
     "TPSystemValue": 1627
    },
    {
     
     "CodeDescription": "BSTONE TRAVEL PTE. LTD.",
     "TPSystemValue": "H438"
    },
    {
     
     "CodeDescription": "BT SINGAPORE PTE LTD",
     "TPSystemValue": 16
    },
    {
     
     "CodeDescription": "BUCK CONSULTANTS LLC (SINGAPORE BRANCH)",
     "TPSystemValue": 2836
    },
    {
     
     "CodeDescription": "BUDGETPLUS SERVICES PTE. LTD.",
     "TPSystemValue": "H605"
    },
    {
     
     "CodeDescription": "BUFFALO TOURS (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H783"
    },
    {
     
     "CodeDescription": "BUILDERS HUB PTE LTD",
     "TPSystemValue": 1628
    },
    {
     
     "CodeDescription": "BUILDING AND CONTRUCTION AUTHORITY",
     "TPSystemValue": 3092
    },
    {
     
     "CodeDescription": "BUKIT BATOK VILLAGE EATING HOUSE",
     "TPSystemValue": 1412
    },
    {
     
     "CodeDescription": "BUMI ARMADA (SINGAPORE) PTE LTD",
     "TPSystemValue": 958
    },
    {
     
     "CodeDescription": "BUNGE AGRIBUSINESS SINGAPORE PL",
     "TPSystemValue": "07B"
    },
    {
     
     "CodeDescription": "BUNGE AGRIBUSINESS SINGAPORE PTE LTD",
     "TPSystemValue": 635
    },
    {
     
     "CodeDescription": "BUNKA LANGUAGE SCHOOL PTE LTD",
     "TPSystemValue": 1630
    },
    {
     
     "CodeDescription": "BUREAU VERITAS",
     "TPSystemValue": 1631
    },
    {
     
     "CodeDescription": "BURNABY SOLUTIONS PTE. LTD.",
     "TPSystemValue": "H572"
    },
    {
     
     "CodeDescription": "BUSINESS REWARDS TRAVEL PTE LTD",
     "TPSystemValue": "H099"
    },
    {
     
     "CodeDescription": "BUSINESSIT PTE LTD",
     "TPSystemValue": 3796
    },
    {
     
     "CodeDescription": "BUSONLINETICKET PTE. LTD.",
     "TPSystemValue": "H607"
    },
    {
     
     "CodeDescription": "BUZZCITY PTE LTD",
     "TPSystemValue": 672
    },
    {
     
     "CodeDescription": "BW OFFSHORE SINGAPORE PTE LTD",
     "TPSystemValue": 1632
    },
    {
     
     "CodeDescription": "CA TECHNOLOGIES SINGAPORE PL",
     "TPSystemValue": 274
    },
    {
     
     "CodeDescription": "CAAS",
     "TPSystemValue": 1633
    },
    {
     
     "CodeDescription": "CABLE WIRELESS WORLDWIDE",
     "TPSystemValue": 1202
    },
    {
     
     "CodeDescription": "CABOT MICROELECTRONICS (S) PTE LTD",
     "TPSystemValue": 1634
    },
    {
     
     "CodeDescription": "CADBURY ENTERPRISES PTE LTD",
     "TPSystemValue": 3616
    },
    {
     
     "CodeDescription": "CAFE CALLE REAL GROUP PTE LTD",
     "TPSystemValue": 1635
    },
    {
     
     "CodeDescription": "CAFE CALLE REAL GROUP PTE. LTD.",
     "TPSystemValue": "01C"
    },
    {
     
     "CodeDescription": "CALIBER HOTELS PTE LTD",
     "TPSystemValue": 1636
    },
    {
     
     "CodeDescription": "CALIFORNIA FITNESS CENTRE",
     "TPSystemValue": 1379
    },
    {
     
     "CodeDescription": "CALLATAY & WOUTERS",
     "TPSystemValue": 1637
    },
    {
     
     "CodeDescription": "CALLE REAL CULINARY CONCEPTS PTE LTD",
     "TPSystemValue": 1260
    },
    {
     
     "CodeDescription": "CALTEX (S) PTE LTD",
     "TPSystemValue": 1638
    },
    {
     
     "CodeDescription": "CALVIN CHAN AESTHETIC &LASER CLINIC PL",
     "TPSystemValue": 1048
    },
    {
     
     "CodeDescription": "CAMBIASO & RISSO (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H587"
    },
    {
     
     "CodeDescription": "CAMBRIDGE ASSOCIATES ASIA PTE LTD",
     "TPSystemValue": 768
    },
    {
     
     "CodeDescription": "CAMBRIDGE INDUSTRIAL TRUST",
     "TPSystemValue": 1008
    },
    {
     
     "CodeDescription": "CAMBRIDGE SOLUTIONS XCHANGING PTE LTD",
     "TPSystemValue": 452
    },
    {
     
     "CodeDescription": "CAMELOT PTE. LTD.",
     "TPSystemValue": "H332"
    },
    {
     
     "CodeDescription": "CAMERON SINGAPORE PTE LTD",
     "TPSystemValue": 93
    },
    {
     
     "CodeDescription": "CAMP HIGH ACHIEVERS PTE. LTD.",
     "TPSystemValue": "H610"
    },
    {
     
     "CodeDescription": "CAMPAIGN COMPLETE SOLUTION PTE LTD",
     "TPSystemValue": 1639
    },
    {
     
     "CodeDescription": "CAMPUS CRUSADE ASIA LIMITED",
     "TPSystemValue": 76
    },
    {
     
     "CodeDescription": "CANON SINGAPORE PTE LTD",
     "TPSystemValue": 156
    },
    {
     
     "CodeDescription": "CANYON",
     "TPSystemValue": 1641
    },
    {
     
     "CodeDescription": "CAPE ENGINEERING CONSTRUCTION PTE LTD",
     "TPSystemValue": 682
    },
    {
     
     "CodeDescription": "CAPELLA HOTEL, SINGAPORE",
     "TPSystemValue": 1421
    },
    {
     
     "CodeDescription": "CAPGEMINI SINGAPORE PTD LTD",
     "TPSystemValue": 399
    },
    {
     
     "CodeDescription": "CAPITAL BROKERS HOLDINGS PTE LTD",
     "TPSystemValue": 1276
    },
    {
     
     "CodeDescription": "CAPITAL INTERNATIONAL INC",
     "TPSystemValue": 2821
    },
    {
     
     "CodeDescription": "CAPITAL TRAVEL SERVICES PRIVATE LIMITED",
     "TPSystemValue": "H525"
    },
    {
     
     "CodeDescription": "CAPITALAND COMMERCIAL LIMITED",
     "TPSystemValue": 139
    },
    {
     
     "CodeDescription": "CAPITALAND FINANCIAL LIMITED",
     "TPSystemValue": 889
    },
    {
     
     "CodeDescription": "CAPITALAND LIMITED",
     "TPSystemValue": 314
    },
    {
     
     "CodeDescription": "CAPITALMALLS ASIA LIMITED",
     "TPSystemValue": 945
    },
    {
     
     "CodeDescription": "CAPITALVALUE HOMES CHINA PTE LTD",
     "TPSystemValue": 3617
    },
    {
     
     "CodeDescription": "CAPSOLON INVESTMENTS PTE LTD",
     "TPSystemValue": 1307
    },
    {
     
     "CodeDescription": "CARAT MEDIA SERVICES SINGAPORE PTE LTD",
     "TPSystemValue": 708
    },
    {
     
     "CodeDescription": "CARDS N SUCH PTE LTD",
     "TPSystemValue": 443
    },
    {
     
     "CodeDescription": "CARGILL ASIA PACIFIC HOLDINGS PTE LTD",
     "TPSystemValue": 912
    },
    {
     
     "CodeDescription": "CARGILL ASIA PACIFIC TREASURY LTD",
     "TPSystemValue": 487
    },
    {
     
     "CodeDescription": "CARGILL INTERNATIONAL TRADING PTE LTD",
     "TPSystemValue": 39
    },
    {
     
     "CodeDescription": "CARGO COMMUNITY NETWORK PTE LTD",
     "TPSystemValue": 1642
    },
    {
     
     "CodeDescription": "CARGOTEC CHS ASIA PTE LTD",
     "TPSystemValue": 464
    },
    {
     
     "CodeDescription": "CARL ZEISS PTE LTD",
     "TPSystemValue": 1643
    },
    {
     
     "CodeDescription": "CARLSBERG SINGAPORE PTE LTD",
     "TPSystemValue": 1644
    },
    {
     
     "CodeDescription": "CARLSON HOTELS ASIA PACIFIC PTY LTD",
     "TPSystemValue": 3070
    },
    {
     
     "CodeDescription": "CARLSON WAGONLIT SINGAPORE PTE LTD",
     "TPSystemValue": 531
    },
    {
     
     "CodeDescription": "CARLSON WAGONLIT SINGAPORE PTE. LTD.",
     "TPSystemValue": "H253"
    },
    {
     
     "CodeDescription": "CARLSON WAGONLIT TRAVEL PTE LTD",
     "TPSystemValue": "02C"
    },
    {
     
     "CodeDescription": "CARLTON HOTEL (SINGAPORE) PTE LTD",
     "TPSystemValue": 83
    },
    {
     
     "CodeDescription": "CARREFOUR",
     "TPSystemValue": 1367
    },
    {
     
     "CodeDescription": "CARREFOUR SINGAPORE PTE LTD",
     "TPSystemValue": 556
    },
    {
     
     "CodeDescription": "CARRIER SINGAPORE PTE LTD",
     "TPSystemValue": 30
    },
    {
     
     "CodeDescription": "CARRIER TRANSICOLD PTE LTD",
     "TPSystemValue": 59
    },
    {
     
     "CodeDescription": "CARTUS CORPORATION PTE LTD",
     "TPSystemValue": 727
    },
    {
     
     "CodeDescription": "CASCO ADHESIVE (ASIA) PTE LTD",
     "TPSystemValue": 1645
    },
    {
     
     "CodeDescription": "CASINO REGULATORY AUTHORITY",
     "TPSystemValue": 1646
    },
    {
     
     "CodeDescription": "CASIO SINGAPORE PTE LTD",
     "TPSystemValue": 1647
    },
    {
     
     "CodeDescription": "CAST LABORATORIES PTE LTD",
     "TPSystemValue": 179
    },
    {
     
     "CodeDescription": "CASTROL SINGAPORE",
     "TPSystemValue": 1648
    },
    {
     
     "CodeDescription": "CATERPILLAR ASIA PTE LTD",
     "TPSystemValue": 1649
    },
    {
     
     "CodeDescription": "CATERPILLAR LOGISTICS SERVICES, INC",
     "TPSystemValue": 1650
    },
    {
     
     "CodeDescription": "CATHAY ORGANISATION HOLDINGS LTD",
     "TPSystemValue": 1651
    },
    {
     
     "CodeDescription": "CATHAY PACIFIC AIRWAYS LIMITED",
     "TPSystemValue": 2805
    },
    {
     
     "CodeDescription": "CATLIN SINGAPORE PTE LTD",
     "TPSystemValue": 694
    },
    {
     
     "CodeDescription": "CAUSEWAY LINK HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H279"
    },
    {
     
     "CodeDescription": "CAVIUM NETWORKS SINGAPORE PTE LTD",
     "TPSystemValue": 1119
    },
    {
     
     "CodeDescription": "CAVU CORPORATION PTE LTD",
     "TPSystemValue": 1652
    },
    {
     
     "CodeDescription": "CB & I LUMMUS PTE LTD",
     "TPSystemValue": 233
    },
    {
     
     "CodeDescription": "CB RICHARD ELLIS PTE LTD",
     "TPSystemValue": 1653
    },
    {
     
     "CodeDescription": "CBRE PTE. LTD.",
     "TPSystemValue": 1654
    },
    {
     
     "CodeDescription": "CBS INTERACTIVE PTE LTD",
     "TPSystemValue": 641
    },
    {
     
     "CodeDescription": "CCM INDUSTRIAL PTE LTD",
     "TPSystemValue": 1655
    },
    {
     
     "CodeDescription": "CEBU AIR TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H568"
    },
    {
     
     "CodeDescription": "CEBU GENESIS TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H567"
    },
    {
     
     "CodeDescription": "CEDERVALL (S) PTE LTD",
     "TPSystemValue": 1656
    },
    {
     
     "CodeDescription": "CEH GROUP",
     "TPSystemValue": 345
    },
    {
     
     "CodeDescription": "CEI CONTRACT MANUFACTURING LIMITED",
     "TPSystemValue": 1657
    },
    {
     
     "CodeDescription": "CEL DEVELOPMENT PTE LTD",
     "TPSystemValue": 224
    },
    {
     
     "CodeDescription": "CELANESE SINGAPORE PTE LTD",
     "TPSystemValue": 1658
    },
    {
     
     "CodeDescription": "CELESTICA ASIA PTE LIMITED",
     "TPSystemValue": 667
    },
    {
     
     "CodeDescription": "CELESTICA ELECTRONICS S PTE LTD",
     "TPSystemValue": 275
    },
    {
     
     "CodeDescription": "CEMENTONE (S) PTE LTD",
     "TPSystemValue": 310
    },
    {
     
     "CodeDescription": "CENGAGE LEARNING ASIA PTE LTD",
     "TPSystemValue": 425
    },
    {
     
     "CodeDescription": "CENGAGE LEARNING ASIA PTE. LTD.",
     "TPSystemValue": "03C"
    },
    {
     
     "CodeDescription": "CENTILLION ENVIRONMENT RECYCLING SINGAPORE PTE",
     "TPSystemValue": 3618
    },
    {
     
     "CodeDescription": "CENTOUR TRAVEL & TRADING (S) PTE LTD",
     "TPSystemValue": "H123"
    },
    {
     
     "CodeDescription": "CENTRAL ASIA DEVELOPMENT GROUP (S) PL",
     "TPSystemValue": 765
    },
    {
     
     "CodeDescription": "CENTRAL NARCOTICS BUREAU",
     "TPSystemValue": 1659
    },
    {
     
     "CodeDescription": "CENTRAL PROVIDENT FUND BOARD",
     "TPSystemValue": 1660
    },
    {
     
     "CodeDescription": "CENTRAL SINGAPORE CDC",
     "TPSystemValue": 3619
    },
    {
     
     "CodeDescription": "CENTRE FOR STRATEGIC INFOCOMM TECHNOLOGY",
     "TPSystemValue": 1661
    },
    {
     
     "CodeDescription": "CENTRE LINK TRAVEL PTE. LTD.",
     "TPSystemValue": "H588"
    },
    {
     
     "CodeDescription": "CENTURY CONSTRUCTION & ENGINEERING PL",
     "TPSystemValue": 819
    },
    {
     
     "CodeDescription": "CENTURY EVERGREEN PTE LTD",
     "TPSystemValue": 1662
    },
    {
     
     "CodeDescription": "CENTURY HOLIDAY INTERNATIONAL TRAVEL SERVICE SINGAPORE PTE. LTD.",
     "TPSystemValue": "H510"
    },
    {
     
     "CodeDescription": "CENTURY MARINE SUPPLY PTE LTD",
     "TPSystemValue": 1147
    },
    {
     
     "CodeDescription": "CENTURY MARINE SUPPLY PTE. LTD.",
     "TPSystemValue": "04C"
    },
    {
     
     "CodeDescription": "CEREBOS PACIFIC LIMITED",
     "TPSystemValue": 1663
    },
    {
     
     "CodeDescription": "CERTACT ENGINEERING PTE LTD",
     "TPSystemValue": 1664
    },
    {
     
     "CodeDescription": "CERTIS CISCO AUXILLARY POLICE FORCE PL",
     "TPSystemValue": 1200
    },
    {
     
     "CodeDescription": "CERTIS CISCO AVIATION SECURITY P L",
     "TPSystemValue": 1665
    },
    {
     
     "CodeDescription": "CERTIS CISCO PROTECTION SERVICES PL",
     "TPSystemValue": 771
    },
    {
     
     "CodeDescription": "CERTIS CISCO SECURITY PTE LTD",
     "TPSystemValue": 933
    },
    {
     
     "CodeDescription": "CES BUILDING AND CONSTRUCTION PTE LTD",
     "TPSystemValue": 3620
    },
    {
     
     "CodeDescription": "CESPRECAST PTE LTD",
     "TPSystemValue": 1073
    },
    {
     
     "CodeDescription": "CESSTECH (S) PTE LTD",
     "TPSystemValue": 1666
    },
    {
     
     "CodeDescription": "CET TECHNOLOGIES PTE LTD",
     "TPSystemValue": 1667
    },
    {
     
     "CodeDescription": "CEVA ASIA PACIFIC HOLDINGS COMPANY PL",
     "TPSystemValue": 661
    },
    {
     
     "CodeDescription": "CEVA FREIGHT (SINGAPORE) PTE LTD",
     "TPSystemValue": 1668
    },
    {
     
     "CodeDescription": "CEVA LOGISTICS ASIA PTE LTD",
     "TPSystemValue": 490
    },
    {
     
     "CodeDescription": "CEVA LOGISTICS SINGAPORE PTE LTD",
     "TPSystemValue": 88
    },
    {
     
     "CodeDescription": "CGG VERITAS SERVICES SINGAPORE PTE LTD",
     "TPSystemValue": 1669
    },
    {
     
     "CodeDescription": "CH2M HILL SINGAPORE PTE LTD",
     "TPSystemValue": 1670
    },
    {
     
     "CodeDescription": "CHAB PTE. LTD.",
     "TPSystemValue": "H727"
    },
    {
     
     "CodeDescription": "CHAN BROTHERS EXPRESS PTE LTD",
     "TPSystemValue": "H320"
    },
    {
     
     "CodeDescription": "CHAN BROTHERS MICE & TRAVEL PTE. LTD.",
     "TPSystemValue": "H103"
    },
    {
     
     "CodeDescription": "CHAN BROTHERS TRAVEL PTE LTD",
     "TPSystemValue": 1671
    },
    {
     
     "CodeDescription": "CHAN BROTHERS WORLDWIDE CRUISE CENTRE PTE. LTD.",
     "TPSystemValue": "H169"
    },
    {
     
     "CodeDescription": "CHAN WENG WAH ENGINEERING PTE LTD",
     "TPSystemValue": 304
    },
    {
     
     "CodeDescription": "CHANBROTHERS ASIA PACIFIC PTE. LTD.",
     "TPSystemValue": "H251"
    },
    {
     
     "CodeDescription": "CHANEL PTE LTD",
     "TPSystemValue": 1673
    },
    {
     
     "CodeDescription": "CHANG HUA PTE LTD",
     "TPSystemValue": 1674
    },
    {
     
     "CodeDescription": "CHANG SENG SERVICES PTE LTD",
     "TPSystemValue": 3785
    },
    {
     
     "CodeDescription": "CHANGI AIRPORT GROUP SINGAPORE PTE LTD",
     "TPSystemValue": 1225
    },
    {
     
     "CodeDescription": "CHANGI TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "H744"
    },
    {
     
     "CodeDescription": "CHANGI TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "H648"
    },
    {
     
     "CodeDescription": "CHAN'S WORLD HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H601"
    },
    {
     
     "CodeDescription": "CHARIOT TRAVELS PTE LTD",
     "TPSystemValue": "H284"
    },
    {
     
     "CodeDescription": "CHARLES & KEITH INTERNATIONAL PTE LTD",
     "TPSystemValue": 963
    },
    {
     
     "CodeDescription": "CHARLES & KEITH SINGAPORE PTE LTD",
     "TPSystemValue": 1153
    },
    {
     
     "CodeDescription": "CHARLES RIVER SYSTEMS INC",
     "TPSystemValue": 1675
    },
    {
     
     "CodeDescription": "CHARMING TRAVELS PTE. LTD.",
     "TPSystemValue": "H582"
    },
    {
     
     "CodeDescription": "CHARTERED SEMICONDUCTOR MNF PTE LTD",
     "TPSystemValue": 1676
    },
    {
     
     "CodeDescription": "CHARTERED SILICON PARTNERS PTE LTD",
     "TPSystemValue": 1677
    },
    {
     
     "CodeDescription": "CHARTIS ASIA PACIFIC PTE LTD",
     "TPSystemValue": 72
    },
    {
     
     "CodeDescription": "CHARTIS SINGAPORE INSURANCE PTE LTD",
     "TPSystemValue": 1273
    },
    {
     
     "CodeDescription": "CHASETON SECURITY PTE LTD",
     "TPSystemValue": 1051
    },
    {
     
     "CodeDescription": "CHATSWORTH INTERNATIONAL SCHOOL PTE LTD",
     "TPSystemValue": 469
    },
    {
     
     "CodeDescription": "CHATSWORTH KINDERGARTEN",
     "TPSystemValue": 1409
    },
    {
     
     "CodeDescription": "CHC BUILDING & CONSTRUCTION PTE LTD",
     "TPSystemValue": 1678
    },
    {
     
     "CodeDescription": "CHC BUILDING & CONSTRUCTION PTE. LTD.",
     "TPSystemValue": "06C"
    },
    {
     
     "CodeDescription": "CHEIN PTE LTD",
     "TPSystemValue": 1211
    },
    {
     
     "CodeDescription": "CHEMOIL INTERNATIONAL PTE LTD",
     "TPSystemValue": 1679
    },
    {
     
     "CodeDescription": "CHENG KIA ELECTRICAL CONTRACTOR PTE LTD",
     "TPSystemValue": 874
    },
    {
     
     "CodeDescription": "CHENG XIN SHIPMANAGEMENT PTE LTD",
     "TPSystemValue": 1337
    },
    {
     
     "CodeDescription": "CHERIE HEART",
     "TPSystemValue": 1680
    },
    {
     
     "CodeDescription": "CHEVALIER SINGAPORE HOLDINGS LTD",
     "TPSystemValue": 1681
    },
    {
     
     "CodeDescription": "CHEVRON INTERNATONAL PTE LTD",
     "TPSystemValue": 1682
    },
    {
     
     "CodeDescription": "CHEVRON ORONITE PTE LTD",
     "TPSystemValue": 1683
    },
    {
     
     "CodeDescription": "CHEVRON PHILLIPS SINGAPORE CHEMICALS PL",
     "TPSystemValue": 1684
    },
    {
     
     "CodeDescription": "CHEVRON SINGAPORE PTE LTD",
     "TPSystemValue": 1685
    },
    {
     
     "CodeDescription": "CHEW DESIGN & RESERVATION PTE LTD",
     "TPSystemValue": 1686
    },
    {
     
     "CodeDescription": "CHEW DESIGN PTE LTD",
     "TPSystemValue": 689
    },
    {
     
     "CodeDescription": "CHILTERN HOUSE",
     "TPSystemValue": 1369
    },
    {
     
     "CodeDescription": "CHINA CONSTRUCTION BANK",
     "TPSystemValue": 3078
    },
    {
     
     "CodeDescription": "CHINA CONSTRUCTION BANK CORPORATION",
     "TPSystemValue": "C04"
    },
    {
     
     "CodeDescription": "CHINA EXPRESS TRAVEL (S) PTE LTD",
     "TPSystemValue": "H239"
    },
    {
     
     "CodeDescription": "CHINA JINGYE CONSTRUCTION ENG PL",
     "TPSystemValue": 631
    },
    {
     
     "CodeDescription": "CHINA JINGYE ENGINEE CORPORATION LTD",
     "TPSystemValue": 3079
    },
    {
     
     "CodeDescription": "CHINA NAVIGATION PTE LTD",
     "TPSystemValue": 1687
    },
    {
     
     "CodeDescription": "CHINA SHIPPING SINGAPORE AGENCY PTE LTD",
     "TPSystemValue": 1688
    },
    {
     
     "CodeDescription": "CHINA SONANGOL INTERNATIONAL S PL",
     "TPSystemValue": 1197
    },
    {
     
     "CodeDescription": "CHINA SU YANG CONSTRUCTION ENG PL",
     "TPSystemValue": 647
    },
    {
     
     "CodeDescription": "CHINATRUST COMMERCIAL BANK CO., LTD",
     "TPSystemValue": "C05"
    },
    {
     
     "CodeDescription": "CHINESE INTERNATIONAL SCHOOL PTE LTD",
     "TPSystemValue": 1054
    },
    {
     
     "CodeDescription": "CHIO LIM STONE FOREST",
     "TPSystemValue": 1374
    },
    {
     
     "CodeDescription": "CHIP AIK ALUMINIUM (PRIVATE) LTD",
     "TPSystemValue": 96
    },
    {
     
     "CodeDescription": "CHIP ENG SENG CORPORATION LTD",
     "TPSystemValue": 669
    },
    {
     
     "CodeDescription": "CHIRON FLIGHT SERVICES PTE. LTD.",
     "TPSystemValue": "H409"
    },
    {
     
     "CodeDescription": "CHIROPRACTIC FIRST (BT) (S0 PTE LTD",
     "TPSystemValue": 1178
    },
    {
     
     "CodeDescription": "CHIROPRACTIC FIRST (IMM) (S) PTE LTD",
     "TPSystemValue": 1269
    },
    {
     
     "CodeDescription": "CHITSON TRANSPORT SERVICE CO",
     "TPSystemValue": 1689
    },
    {
     
     "CodeDescription": "CHIYODA SINGAPORE PTE LTD",
     "TPSystemValue": 1690
    },
    {
     
     "CodeDescription": "CHL BUILDING & CONSTRUCTION PTE LTD",
     "TPSystemValue": 1691
    },
    {
     
     "CodeDescription": "CHL TRAVEL PTE LTD",
     "TPSystemValue": "H303"
    },
    {
     
     "CodeDescription": "CHONG BROTHERS GENERAL CONTRACTOR PL",
     "TPSystemValue": 1692
    },
    {
     
     "CodeDescription": "CHONG LEE LEONG SENG COMPANY LIMITED",
     "TPSystemValue": 21
    },
    {
     
     "CodeDescription": "CHOON CONSTRUCTION PTE LTD",
     "TPSystemValue": 1693
    },
    {
     
     "CodeDescription": "CHOON SENG TRAVEL TOURS PTE. LTD.",
     "TPSystemValue": "H821"
    },
    {
     
     "CodeDescription": "CHOONG HENG PLASTICS S PTE LTD",
     "TPSystemValue": 350
    },
    {
     
     "CodeDescription": "CHUAN LIM CONSTRUCTION PTE LTD",
     "TPSystemValue": 535
    },
    {
     
     "CodeDescription": "CHUBB SINGAPORE PRIVATE LIMITED",
     "TPSystemValue": 81
    },
    {
     
     "CodeDescription": "CIBA VISION (S) PTE LTD",
     "TPSystemValue": 1694
    },
    {
     
     "CodeDescription": "CIMB BANK BARHAD",
     "TPSystemValue": 1695
    },
    {
     
     "CodeDescription": "CIMB BANK BERHAD",
     "TPSystemValue": "C06"
    },
    {
     
     "CodeDescription": "CIMB SECURITIES (S) PTE LTD",
     "TPSystemValue": 1696
    },
    {
     
     "CodeDescription": "CIRCUITWORKS ASIA PTE LTD",
     "TPSystemValue": 711
    },
    {
     
     "CodeDescription": "CISCO RECALL TOTAL INFO MANAGEMENT PL",
     "TPSystemValue": 547
    },
    {
     
     "CodeDescription": "CISCO SECURITY PTE LTD",
     "TPSystemValue": 1697
    },
    {
     
     "CodeDescription": "CISCO SYSTEM INTERNATIONAL",
     "TPSystemValue": 3621
    },
    {
     
     "CodeDescription": "CISCO SYSTEMS USA PTE LTD",
     "TPSystemValue": 526
    },
    {
     
     "CodeDescription": "CITADEL CONSULTING PTE LTD",
     "TPSystemValue": 953
    },
    {
     
     "CodeDescription": "CITCO FUND SERVICES SINGAPORE PTE LTD",
     "TPSystemValue": 1081
    },
    {
     
     "CodeDescription": "CITI SUPPLY TOURS AND HOLIDAYS PRIVATE LIMITED",
     "TPSystemValue": "H402"
    },
    {
     
     "CodeDescription": "CITIAIR & HOLIDAYS PTE LTD",
     "TPSystemValue": "H334"
    },
    {
     
     "CodeDescription": "CITIBANK N.A.",
     "TPSystemValue": "C07"
    },
    {
     
     "CodeDescription": "CITIBANK NA",
     "TPSystemValue": 2802
    },
    {
     
     "CodeDescription": "CITIC BANK INTERNATIONAL LIMITED",
     "TPSystemValue": "C09"
    },
    {
     
     "CodeDescription": "CITICENTER TRAVELS & COACH TOUR (PTE) LTD",
     "TPSystemValue": "H044"
    },
    {
     
     "CodeDescription": "CITISPA",
     "TPSystemValue": 1382
    },
    {
     
     "CodeDescription": "CITRIX SYSTEMS SINGAPORE PTE LTD",
     "TPSystemValue": 1698
    },
    {
     
     "CodeDescription": "CITY 99 ASIA TRAVEL (S) PTE LTD",
     "TPSystemValue": "H290"
    },
    {
     
     "CodeDescription": "CITY DEVELOPMENTS LIMITED",
     "TPSystemValue": 26
    },
    {
     
     "CodeDescription": "CITY DMC PTE. LTD.",
     "TPSystemValue": "H429"
    },
    {
     
     "CodeDescription": "CITY GAS PTE LTD",
     "TPSystemValue": 1699
    },
    {
     
     "CodeDescription": "CITY HARVEST CHURCH",
     "TPSystemValue": 1700
    },
    {
     
     "CodeDescription": "CITY INDEX ASIA PTE LTD",
     "TPSystemValue": 3797
    },
    {
     
     "CodeDescription": "CITY STATE TRAVEL PTE LTD",
     "TPSystemValue": 1701
    },
    {
     
     "CodeDescription": "CITY TOURS & CAR RENTALS PTE. LTD.",
     "TPSystemValue": "H048"
    },
    {
     
     "CodeDescription": "CITYCAB PTE LTD",
     "TPSystemValue": "H247"
    },
    {
     
     "CodeDescription": "CITYNEON CONCEPTS PTE LTD",
     "TPSystemValue": 442
    },
    {
     
     "CodeDescription": "CITYNEON EVENTS PTE LTD",
     "TPSystemValue": 1114
    },
    {
     
     "CodeDescription": "CITYNEON HOLDINGS LIMITED",
     "TPSystemValue": 693
    },
    {
     
     "CodeDescription": "CITYNEON MANGEMENT SERVICES PTE LTD",
     "TPSystemValue": 1294
    },
    {
     
     "CodeDescription": "CITYSTATE ARDENT TRAVEL PTE LTD",
     "TPSystemValue": 3622
    },
    {
     
     "CodeDescription": "CITYSTATE CAPITAL ASIA PTE LTD",
     "TPSystemValue": 3623
    },
    {
     
     "CodeDescription": "CITYSTATE GROUP PTE LTD",
     "TPSystemValue": 289
    },
    {
     
     "CodeDescription": "CITYSTATE TRAVEL PTE LTD",
     "TPSystemValue": 322
    },
    {
     
     "CodeDescription": "CITYSTATE TRAVEL PTE. LTD.",
     "TPSystemValue": "H170"
    },
    {
     
     "CodeDescription": "CIVIL AVIATION AUTHORITY OF SINGAPORE",
     "TPSystemValue": 1702
    },
    {
     
     "CodeDescription": "CIVIL DEFENCE",
     "TPSystemValue": 1703
    },
    {
     
     "CodeDescription": "CIVIL SERVICE",
     "TPSystemValue": 1704
    },
    {
     
     "CodeDescription": "CJ INTERNATIONAL ASIA PTE LTD",
     "TPSystemValue": 1062
    },
    {
     
     "CodeDescription": "CK TRANSPORT & TOUR PTE. LTD.",
     "TPSystemValue": "H481"
    },
    {
     
     "CodeDescription": "CKH STRATA MANAGEMENT PTE LTD",
     "TPSystemValue": 1705
    },
    {
     
     "CodeDescription": "CLARIANT SINGAPORE PTE LTD",
     "TPSystemValue": 1706
    },
    {
     
     "CodeDescription": "CLARIDEN LEU LTD.",
     "TPSystemValue": "C10"
    },
    {
     
     "CodeDescription": "CLASSIC FINE FOOD S PTE LTD",
     "TPSystemValue": 756
    },
    {
     
     "CodeDescription": "CLASSIC FINE FOODS (S) PTE LTD",
     "TPSystemValue": "08C"
    },
    {
     
     "CodeDescription": "CLASSIC TRAVEL PTE LTD",
     "TPSystemValue": "H223"
    },
    {
     
     "CodeDescription": "CLEAN SOLUTIONS PTE LTD",
     "TPSystemValue": 305
    },
    {
     
     "CodeDescription": "CLEAN SYSTEMS TECHNOLOGY (S) PTE LTD",
     "TPSystemValue": 697
    },
    {
     
     "CodeDescription": "CLEANSING & MAINTENANCE SERVICES PTE LTD",
     "TPSystemValue": 1708
    },
    {
     
     "CodeDescription": "CLEARLAB SG PTE LTD",
     "TPSystemValue": 1099
    },
    {
     
     "CodeDescription": "CLEARSTREAM BANKING S.A.",
     "TPSystemValue": "C11"
    },
    {
     
     "CodeDescription": "CLEARWATER CAPITAL PARTNERS",
     "TPSystemValue": 1709
    },
    {
     
     "CodeDescription": "CLIFFORD CHANCE PTE LTD",
     "TPSystemValue": 1710
    },
    {
     
     "CodeDescription": "CLSA CAPITAL PARTNER SINGAPORE PTE LTD",
     "TPSystemValue": 1052
    },
    {
     
     "CodeDescription": "CLSA SINGAPORE PTE LTD",
     "TPSystemValue": 1711
    },
    {
     
     "CodeDescription": "CLSA SINGAPORE PTE LTD - TRUST AC",
     "TPSystemValue": "10C"
    },
    {
     
     "CodeDescription": "CLUB 21 PTE LTD",
     "TPSystemValue": 115
    },
    {
     
     "CodeDescription": "CLUB MED PTE LTD",
     "TPSystemValue": 1712
    },
    {
     
     "CodeDescription": "CLUB MED SERVICES (SINGAPORE) PTE LTD",
     "TPSystemValue": "H137"
    },
    {
     
     "CodeDescription": "CLYDE CO LLP",
     "TPSystemValue": 3085
    },
    {
     
     "CodeDescription": "CMA INTERNATIONAL CONSULTANTS PTE. LTD.",
     "TPSystemValue": "H250"
    },
    {
     
     "CodeDescription": "CMC MARKETS PTE LTD",
     "TPSystemValue": 1046
    },
    {
     
     "CodeDescription": "CMC RAVENNA SINGAPORE BRANCH",
     "TPSystemValue": 3105
    },
    {
     
     "CodeDescription": "CMM MARKETING MANAGEMENT PTE LTD",
     "TPSystemValue": 1713
    },
    {
     
     "CodeDescription": "CMORETON ENGINEERING PTE LTD",
     "TPSystemValue": 1032
    },
    {
     
     "CodeDescription": "CMORETON ENGINEERING PTE. LTD.",
     "TPSystemValue": "11C"
    },
    {
     
     "CodeDescription": "CMORETON MARINE PTE LTD",
     "TPSystemValue": 1714
    },
    {
     
     "CodeDescription": "CMORETON MARINE PTE. LTD.",
     "TPSystemValue": "12C"
    },
    {
     
     "CodeDescription": "CMR FAR EAST PTE LTD",
     "TPSystemValue": 1715
    },
    {
     
     "CodeDescription": "CNA GROUP LTD",
     "TPSystemValue": 339
    },
    {
     
     "CodeDescription": "CNBC ASIA PACIFIC",
     "TPSystemValue": 1380
    },
    {
     
     "CodeDescription": "CNET NETWORKS ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1716
    },
    {
     
     "CodeDescription": "CNS CONNECTIONS PTE LTD",
     "TPSystemValue": 1717
    },
    {
     
     "CodeDescription": "COBB & COACH SERVICES PTE. LTD.",
     "TPSystemValue": "H450"
    },
    {
     
     "CodeDescription": "COCA - COLA SINGAPORE BEVERAGES PTE LTD",
     "TPSystemValue": 620
    },
    {
     
     "CodeDescription": "COECLERICI ASIA PTE LTD",
     "TPSystemValue": 3624
    },
    {
     
     "CodeDescription": "COGNIZANT TECHNOLOGY SOLUTIONS APAC PL",
     "TPSystemValue": 816
    },
    {
     
     "CodeDescription": "COILCRAFT SINGAPORE PTE LTD",
     "TPSystemValue": 1718
    },
    {
     
     "CodeDescription": "COLD STORAGE SINGAPORE (1983) PL",
     "TPSystemValue": "13C"
    },
    {
     
     "CodeDescription": "COLD STORAGE SINGAPORE 1983 PTE LTD",
     "TPSystemValue": 13
    },
    {
     
     "CodeDescription": "COLD STORAGE SINGAPORE LTD",
     "TPSystemValue": 20
    },
    {
     
     "CodeDescription": "COLDWORLD AIRCONDITIONING SERVICES",
     "TPSystemValue": 1719
    },
    {
     
     "CodeDescription": "COLLIERS INTERNATIONAL SINGAPORE PL",
     "TPSystemValue": 321
    },
    {
     
     "CodeDescription": "COLUMBIA SHIPMANAGEMENT (S) PL",
     "TPSystemValue": 303
    },
    {
     
     "CodeDescription": "COLWELL CONSTRUCTION & ENGINEERING PL",
     "TPSystemValue": 354
    },
    {
     
     "CodeDescription": "COMBUSTOR AIRMOTIVE SERVICE PTE LTD",
     "TPSystemValue": 1720
    },
    {
     
     "CodeDescription": "COMET TRAVEL PTE LTD",
     "TPSystemValue": "H243"
    },
    {
     
     "CodeDescription": "COMFORT DELGRO CORPORATION LIMITED",
     "TPSystemValue": 846
    },
    {
     
     "CodeDescription": "COMFORT DELGRO DRIVING CENTRE PTE LTD",
     "TPSystemValue": 1721
    },
    {
     
     "CodeDescription": "COMFORT TRANSPORTATION PTE LTD",
     "TPSystemValue": 436
    },
    {
     
     "CodeDescription": "COMFORTDELGRO BUS PTE LTD",
     "TPSystemValue": "H265"
    },
    {
     
     "CodeDescription": "COMFORTDELGRO CORPORATION LIMITED",
     "TPSystemValue": 1722
    },
    {
     
     "CodeDescription": "COMFORTDELGRO ENGINEERING PTE LTD",
     "TPSystemValue": 516
    },
    {
     
     "CodeDescription": "COMFORTDELGRO RENT A CAR PTE LTD",
     "TPSystemValue": 191
    },
    {
     
     "CodeDescription": "COMLAW LLC",
     "TPSystemValue": 791
    },
    {
     
     "CodeDescription": "COMMERZBANK",
     "TPSystemValue": 1723
    },
    {
     
     "CodeDescription": "COMMONWEALTH BANK OF AUSTRALIA",
     "TPSystemValue": "C13"
    },
    {
     
     "CodeDescription": "COMMONWEALTH TRAVEL SERVICE CORPORATION PTE LTD",
     "TPSystemValue": "H182"
    },
    {
     
     "CodeDescription": "COMMSCOPE SOLUTIONS SINGAPORE PTE LTD",
     "TPSystemValue": 900
    },
    {
     
     "CodeDescription": "COMPAQ COMPUTER ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1724
    },
    {
     
     "CodeDescription": "COMPASS ENERGY PTE LTD",
     "TPSystemValue": 858
    },
    {
     
     "CodeDescription": "COMPASS HOLDINGS PTE LTD",
     "TPSystemValue": 1725
    },
    {
     
     "CodeDescription": "COMPASS TRAVEL & TOUR PTE. LTD.",
     "TPSystemValue": "H581"
    },
    {
     
     "CodeDescription": "COMPETITION COMMISSION OF SINGAPORE",
     "TPSystemValue": 1726
    },
    {
     
     "CodeDescription": "COMPUTER ASSOCIATES PTE LTD",
     "TPSystemValue": 1727
    },
    {
     
     "CodeDescription": "COMPUTER SCIENCES CORPORATION PTE LTD",
     "TPSystemValue": 1728
    },
    {
     
     "CodeDescription": "COMTEL SOLUTIONS PTE LTD",
     "TPSystemValue": 648
    },
    {
     
     "CodeDescription": "COMTRACK PTE LTD",
     "TPSystemValue": 1125
    },
    {
     
     "CodeDescription": "CONCEPTS GOLF MANAGEMENT PTE LTD",
     "TPSystemValue": "H307"
    },
    {
     
     "CodeDescription": "CONCERTO GLOBAL LTD SINGAPORE BRANCH",
     "TPSystemValue": 3081
    },
    {
     
     "CodeDescription": "CONNECTION PLUS PTE. LTD.",
     "TPSystemValue": "H760"
    },
    {
     
     "CodeDescription": "CONTACT TRAVEL PTE LTD",
     "TPSystemValue": "H201"
    },
    {
     
     "CodeDescription": "CONTINENT FIVE TRAVELS PTE LTD",
     "TPSystemValue": "H066"
    },
    {
     
     "CodeDescription": "CONTINENTAL TRAVEL SINGAPORE PTE. LTD.",
     "TPSystemValue": "H591"
    },
    {
     
     "CodeDescription": "CORAL HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H665"
    },
    {
     
     "CodeDescription": "CORPORATE TRAVEL MANAGEMENT (S) PTE. LTD.",
     "TPSystemValue": "H061"
    },
    {
     
     "CodeDescription": "COSMO HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H482"
    },
    {
     
     "CodeDescription": "COSTA CROCIERE PTE. LTD.",
     "TPSystemValue": "H635"
    },
    {
     
     "CodeDescription": "COSTSAVERS TRAVEL PTE. LTD.",
     "TPSystemValue": "H324"
    },
    {
     
     "CodeDescription": "CPI TRAVEL PTE. LTD.",
     "TPSystemValue": "H483"
    },
    {
     
     "CodeDescription": "CRESCENDO TRAVELS PTE. LTD.",
     "TPSystemValue": "H517"
    },
    {
     
     "CodeDescription": "CROSS ROAD TOURS & TRAVEL PTE LTD",
     "TPSystemValue": "H236"
    },
    {
     
     "CodeDescription": "CROWN WORLDWIDE PTE LTD",
     "TPSystemValue": 1760
    },
    {
     
     "CodeDescription": "CRUISE CONNEXIONS PTE. LTD.",
     "TPSystemValue": "H810"
    },
    {
     
     "CodeDescription": "CS TRAVEL PTE. LTD.",
     "TPSystemValue": "H534"
    },
    {
     
     "CodeDescription": "CTRIP CORPORATE TRAVEL (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H844"
    },
    {
     
     "CodeDescription": "CTRIP SINGAPORE PTE. LTD.",
     "TPSystemValue": "H807"
    },
    {
     
     "CodeDescription": "CULTURAL & ENTERTAINMENT HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H110"
    },
    {
     
     "CodeDescription": "CURATED TRAVEL PTE. LTD.",
     "TPSystemValue": "H802"
    },
    {
     
     "CodeDescription": "CXRUS SOLUTIONS PTE LTD",
     "TPSystemValue": 870
    },
    {
     
     "CodeDescription": "CYCLE CARRIAGE INDUSTRIES PTE LTD",
     "TPSystemValue": 32
    },
    {
     
     "CodeDescription": "CYCLE CARRIAGEFULCO MOTOR DEALER PL",
     "TPSystemValue": 624
    },
    {
     
     "CodeDescription": "CYCLONE HOLDINGS PTE LTD",
     "TPSystemValue": 1329
    },
    {
     
     "CodeDescription": "D L RESOURCES PTE LTD",
     "TPSystemValue": 1768
    },
    {
     
     "CodeDescription": "D M CREDIT PTE LTD",
     "TPSystemValue": 692
    },
    {
     
     "CodeDescription": "DA HUAN TRAVEL PTE LTD",
     "TPSystemValue": "H340"
    },
    {
     
     "CodeDescription": "DAEWOO INTERNATIONAL SINGAPORE PTE LTD",
     "TPSystemValue": 3798
    },
    {
     
     "CodeDescription": "DAIFUKU MECHATRONICS (SINGAPORE) PL",
     "TPSystemValue": 1769
    },
    {
     
     "CodeDescription": "DAIKIN (S) PTE LTD",
     "TPSystemValue": 1770
    },
    {
     
     "CodeDescription": "DAIKIN AIRCONDITIONING S PTE LTD",
     "TPSystemValue": 47
    },
    {
     
     "CodeDescription": "DAIMLER SOUTH EAST ASIA PTE LTD",
     "TPSystemValue": 337
    },
    {
     
     "CodeDescription": "DAIWA CAPITAL MKT INVESTMENT SERV PL",
     "TPSystemValue": 414
    },
    {
     
     "CodeDescription": "DALLAS MARINE & ENGINEERING PTE LTD",
     "TPSystemValue": 1771
    },
    {
     
     "CodeDescription": "DAMEAN PTE LTD",
     "TPSystemValue": 1162
    },
    {
     
     "CodeDescription": "DANISCO SINGAPORE PTE LTD",
     "TPSystemValue": 3628
    },
    {
     
     "CodeDescription": "DANONE ASIA PACIFIC PL",
     "TPSystemValue": 1772
    },
    {
     
     "CodeDescription": "DANONE DUMEX MALAYSIA SDN BHD",
     "TPSystemValue": 3419
    },
    {
     
     "CodeDescription": "DATA STORAGE INSTITUTE",
     "TPSystemValue": 595
    },
    {
     
     "CodeDescription": "DATA TERMINATOR PTE LTD",
     "TPSystemValue": 1093
    },
    {
     
     "CodeDescription": "DATACRAFT SINGAPORE PTE LTD",
     "TPSystemValue": 1773
    },
    {
     
     "CodeDescription": "DAVID LIM & PARTNERS",
     "TPSystemValue": 1774
    },
    {
     
     "CodeDescription": "DAVIS LANGDON & SEAH SINGAPORE PTE LTD",
     "TPSystemValue": 1775
    },
    {
     
     "CodeDescription": "DAVOE CREATIVE PTE. LTD",
     "TPSystemValue": 959
    },
    {
     
     "CodeDescription": "DB SCHENKER SINGAPORE PTE LTD",
     "TPSystemValue": 1776
    },
    {
     
     "CodeDescription": "DBS VICKERS SECURITIES (S) PTE LTD",
     "TPSystemValue": 1777
    },
    {
     
     "CodeDescription": "DDB WORLDWIDE PTE LTD",
     "TPSystemValue": 1778
    },
    {
     
     "CodeDescription": "DDIASIAPACIFIC INTERNATIONAL LTD",
     "TPSystemValue": 3416
    },
    {
     
     "CodeDescription": "DDWPAXOCEAN SHIPYARD PTE LTD",
     "TPSystemValue": 3629
    },
    {
     
     "CodeDescription": "DE BEAUTE BEAUTY & SLIMMING PTE LTD",
     "TPSystemValue": 818
    },
    {
     
     "CodeDescription": "DE HAYAT TRAVEL & SERVICES PTE. LTD.",
     "TPSystemValue": "H498"
    },
    {
     
     "CodeDescription": "DEBAO PROPERTY DEVELOPMENT LTD",
     "TPSystemValue": 1116
    },
    {
     
     "CodeDescription": "DEBENHO PTE LTD",
     "TPSystemValue": 525
    },
    {
     
     "CodeDescription": "DECAL SINGAPORE PTE LTD",
     "TPSystemValue": 1779
    },
    {
     
     "CodeDescription": "DECILLIONS SOLUTIONS PTE LTD",
     "TPSystemValue": 1780
    },
    {
     
     "CodeDescription": "DECLOUT LIMITED",
     "TPSystemValue": 1292
    },
    {
     
     "CodeDescription": "DECOLINES CONSTRUCTION PTE LTD",
     "TPSystemValue": 429
    },
    {
     
     "CodeDescription": "DEFENSE SCIENCE AND TECHONOLOGY AGENCY",
     "TPSystemValue": 1781
    },
    {
     
     "CodeDescription": "DEG BUILDERS PTE LTD",
     "TPSystemValue": 3775
    },
    {
     
     "CodeDescription": "DEKS AIR (SINGAPORE) PTE LTD",
     "TPSystemValue": "H074"
    },
    {
     
     "CodeDescription": "DEKS AIR TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H477"
    },
    {
     
     "CodeDescription": "DELL COMPUTER ASIA PL DELL ASIA PL",
     "TPSystemValue": 333
    },
    {
     
     "CodeDescription": "DELL GLOBAL BV",
     "TPSystemValue": 3074
    },
    {
     
     "CodeDescription": "DELOITTE & TOUCHE (E) RISK SERV PL",
     "TPSystemValue": 136
    },
    {
     
     "CodeDescription": "DELOITTE & TOUCHE LLP",
     "TPSystemValue": "01D"
    },
    {
     
     "CodeDescription": "DELOITTE TOUCHE FINAN ADVISORY SERV",
     "TPSystemValue": 826
    },
    {
     
     "CodeDescription": "DELOITTE TOUCHE LLP",
     "TPSystemValue": 3101
    },
    {
     
     "CodeDescription": "DELPHI AUTOMOTIVE SYSTEMS PTE LTD",
     "TPSystemValue": 1782
    },
    {
     
     "CodeDescription": "DELTA AIR LINES",
     "TPSystemValue": "H025"
    },
    {
     
     "CodeDescription": "DELUGE FIRE PROTECTION (S.E.A) PTE LTD",
     "TPSystemValue": 1783
    },
    {
     
     "CodeDescription": "DELUGE FIRE PROTECTION (SEA) PTE LTD",
     "TPSystemValue": "02D"
    },
    {
     
     "CodeDescription": "DEMATIC PTE LTD",
     "TPSystemValue": 3630
    },
    {
     
     "CodeDescription": "DENEED SERVICES",
     "TPSystemValue": 1360
    },
    {
     
     "CodeDescription": "DENKA SINGAPORE PTE LTD",
     "TPSystemValue": 1784
    },
    {
     
     "CodeDescription": "DEN-NI GS CO.",
     "TPSystemValue": "03D"
    },
    {
     
     "CodeDescription": "DENNI INTERNATIONAL PTE LTD",
     "TPSystemValue": 462
    },
    {
     
     "CodeDescription": "DENNIS WEE PROPERTIES PTE LTD",
     "TPSystemValue": 1785
    },
    {
     
     "CodeDescription": "DENNIS WEE REALTY PTE LTD",
     "TPSystemValue": 423
    },
    {
     
     "CodeDescription": "DENSO INTERNATIONAL ASIA PTE LTD",
     "TPSystemValue": 1786
    },
    {
     
     "CodeDescription": "DENSO SWITCHGEAR PTE LTD",
     "TPSystemValue": 1787
    },
    {
     
     "CodeDescription": "DENSO SWITCHGEAR PTE. LTD.",
     "TPSystemValue": "04D"
    },
    {
     
     "CodeDescription": "DENTSU, YOUNG & RUBICAM PTE LTD",
     "TPSystemValue": 151
    },
    {
     
     "CodeDescription": "DESSUS OUTSOURCING GROUP PTE LTD",
     "TPSystemValue": 1084
    },
    {
     
     "CodeDescription": "DESTINATION ASIA (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H561"
    },
    {
     
     "CodeDescription": "DESTINATION SERVICES SINGAPORE PTE. LTD.",
     "TPSystemValue": "H746"
    },
    {
     
     "CodeDescription": "DET NORSKE VERITAS PTE LTD",
     "TPSystemValue": 1788
    },
    {
     
     "CodeDescription": "DEUTSCHE BANK AG",
     "TPSystemValue": "D02"
    },
    {
     
     "CodeDescription": "DEUTSCHE BANK AG SINGAPORE",
     "TPSystemValue": 1789
    },
    {
     
     "CodeDescription": "DEXIA BANQUE INTERNATIONALE A LUXEMBOURG SA",
     "TPSystemValue": "D03"
    },
    {
     
     "CodeDescription": "DFS VENTURE SINGAPORE PTE LIMITED",
     "TPSystemValue": 87
    },
    {
     
     "CodeDescription": "DHI WATER AND ENVIRONMENT PTE LTD",
     "TPSystemValue": 1790
    },
    {
     
     "CodeDescription": "DHL EXPRESS (SINGAPORE) PTE LTD",
     "TPSystemValue": "05D"
    },
    {
     
     "CodeDescription": "DHL EXPRESS SINGAPORE PTE LTD",
     "TPSystemValue": 122
    },
    {
     
     "CodeDescription": "DHL GLOBAL FORWARDING S PL",
     "TPSystemValue": 64
    },
    {
     
     "CodeDescription": "DHL GLOBAL MAIL SINGAPORE PTE LTD",
     "TPSystemValue": 482
    },
    {
     
     "CodeDescription": "DHL INTERNATIONAL (S) PTE LTD",
     "TPSystemValue": 1791
    },
    {
     
     "CodeDescription": "DHL SUPPLY CHAIN SINGAPORE PTE LTD",
     "TPSystemValue": 673
    },
    {
     
     "CodeDescription": "DIAGEO SINGAPORE PTE LTD",
     "TPSystemValue": 40
    },
    {
     
     "CodeDescription": "DIAGEO SINGAPORE PTE. LTD.",
     "TPSystemValue": "06D"
    },
    {
     
     "CodeDescription": "DIAGEO SINGAPORE SUPPLY PTELTD",
     "TPSystemValue": 3631
    },
    {
     
     "CodeDescription": "DIAMOND TANKER PTE LTD",
     "TPSystemValue": 978
    },
    {
     
     "CodeDescription": "DIAMOND TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "H742"
    },
    {
     
     "CodeDescription": "DICETEK SING PTE LTD",
     "TPSystemValue": 1792
    },
    {
     
     "CodeDescription": "DICK LEE CHUO SENKO (S) PL",
     "TPSystemValue": 832
    },
    {
     
     "CodeDescription": "DIETHELM TRAVEL (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H519"
    },
    {
     
     "CodeDescription": "DIGITAL EQUIPMENT SPORE PTE LTD",
     "TPSystemValue": 1793
    },
    {
     
     "CodeDescription": "DIGO CORPORATION PTE LTD",
     "TPSystemValue": 1794
    },
    {
     
     "CodeDescription": "DILY S CREATION PTE LTD",
     "TPSystemValue": 1795
    },
    {
     
     "CodeDescription": "DIMENSION DATA (SINGAPORE) PTE LTD",
     "TPSystemValue": 223
    },
    {
     
     "CodeDescription": "DIMENSIONS TRAVEL & STUDY TOUR PTE. LTD.",
     "TPSystemValue": "H550"
    },
    {
     
     "CodeDescription": "DINERS CLUB SINGAPORE PTE LTD",
     "TPSystemValue": 1796
    },
    {
     
     "CodeDescription": "DINERS WORLD TRAVEL PTE LTD",
     "TPSystemValue": "H082"
    },
    {
     
     "CodeDescription": "DISCOVER HOLIDAYS (ASIA) PTE LTD",
     "TPSystemValue": "H315"
    },
    {
     
     "CodeDescription": "DISCOVERY ASIA INCORPORATION",
     "TPSystemValue": 1797
    },
    {
     
     "CodeDescription": "DISCOVERY NETWORKS ASIA PACIFIC",
     "TPSystemValue": 1798
    },
    {
     
     "CodeDescription": "DISCOVERY TOURS & TRAVEL PTE LTD",
     "TPSystemValue": "H151"
    },
    {
     
     "CodeDescription": "DIVE ASIA NOW PTE. LTD.",
     "TPSystemValue": "H537"
    },
    {
     
     "CodeDescription": "DIVINE INTERNATIONAL EXPLORE & TREKS (S) PTE. LTD.",
     "TPSystemValue": "H488"
    },
    {
     
     "CodeDescription": "DKSH MARKETING (S) PTE LTD",
     "TPSystemValue": 99
    },
    {
     
     "CodeDescription": "DKSH SINGAPORE PTE LTD",
     "TPSystemValue": 1799
    },
    {
     
     "CodeDescription": "DL RESOURCES PTE LTD",
     "TPSystemValue": 1800
    },
    {
     
     "CodeDescription": "DM ROPE ACCESS -UNIDIVE MARINE SERV PL",
     "TPSystemValue": 568
    },
    {
     
     "CodeDescription": "DMG & PARTNERS RESEARCH PTE LTD",
     "TPSystemValue": 1801
    },
    {
     
     "CodeDescription": "DNB NOR BANK ASA",
     "TPSystemValue": "D04"
    },
    {
     
     "CodeDescription": "DNV PETROLEUM SERVICES PTE LTD",
     "TPSystemValue": 1802
    },
    {
     
     "CodeDescription": "DOCOMO INTERTOUCH PTE LTD",
     "TPSystemValue": 699
    },
    {
     
     "CodeDescription": "DOLPHIN COACH SERVICE PTE LTD",
     "TPSystemValue": "H160"
    },
    {
     
     "CodeDescription": "DOMINO S PIZZA SINGAPORE PTE LTD",
     "TPSystemValue": 1803
    },
    {
     
     "CodeDescription": "DON THAKER BUGS & SHRUBS PRIVATE LTD",
     "TPSystemValue": 890
    },
    {
     
     "CodeDescription": "DONALDSON FILTRATION ASIA PACIFIC",
     "TPSystemValue": 1804
    },
    {
     
     "CodeDescription": "DORSETT SINGAPORE",
     "TPSystemValue": 1433
    },
    {
     
     "CodeDescription": "DOUBLE NEGATIVE SINGAPORE PTE LTD",
     "TPSystemValue": 1805
    },
    {
     
     "CodeDescription": "DOVER SUPPLY PTE LTD",
     "TPSystemValue": 1806
    },
    {
     
     "CodeDescription": "DOW CHEMICAL PACIFIC (SINGAPORE) PTE LTD",
     "TPSystemValue": 1807
    },
    {
     
     "CodeDescription": "DOW JONES",
     "TPSystemValue": 1808
    },
    {
     
     "CodeDescription": "DOZO RESTAURANT PTE LTD",
     "TPSystemValue": 1809
    },
    {
     
     "CodeDescription": "DOZO RESTAURANT PTE. LTD.",
     "TPSystemValue": "07D"
    },
    {
     
     "CodeDescription": "DP ARCHITECTS PTE LTD",
     "TPSystemValue": 109
    },
    {
     
     "CodeDescription": "DP CREDIT BEREAU PTE LTD",
     "TPSystemValue": 1000
    },
    {
     
     "CodeDescription": "DP INFORMATION NETWORK PTE LTD",
     "TPSystemValue": 217
    },
    {
     
     "CodeDescription": "DPS ENGINEERING (SINGAPORE) PTE LTD",
     "TPSystemValue": 1012
    },
    {
     
     "CodeDescription": "DPS PROCESS SYSTEM INTEGRATOR PTE.LTD.",
     "TPSystemValue": 1194
    },
    {
     
     "CodeDescription": "DRAEGER MEDICAL",
     "TPSystemValue": 1810
    },
    {
     
     "CodeDescription": "DRAEGER SAFETY ASIA PTE LTD",
     "TPSystemValue": 1811
    },
    {
     
     "CodeDescription": "DRAGAGES SINGAPORE PTE LTD",
     "TPSystemValue": 1812
    },
    {
     
     "CodeDescription": "DREAM HOLIDAYS PLANNER PTE. LTD.",
     "TPSystemValue": "H781"
    },
    {
     
     "CodeDescription": "DREAM VACATIONS TRAVEL & TOURS (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H419"
    },
    {
     
     "CodeDescription": "DREAM WORLD TRAVEL PTE. LTD.",
     "TPSystemValue": "H672"
    },
    {
     
     "CodeDescription": "DRESSER SINGAPORE PTE LTD",
     "TPSystemValue": 1813
    },
    {
     
     "CodeDescription": "DREW & NAPIER LLC",
     "TPSystemValue": 1814
    },
    {
     
     "CodeDescription": "DREW AMEROID SINGAPORE PTE LTD",
     "TPSystemValue": 1815
    },
    {
     
     "CodeDescription": "DREW AND NAPIER LLC",
     "TPSystemValue": 1816
    },
    {
     
     "CodeDescription": "DRIL QUIP ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1817
    },
    {
     
     "CodeDescription": "DRILL EQUIP SUPPLY PTE LTD",
     "TPSystemValue": 1221
    },
    {
     
     "CodeDescription": "DRILLING INTERNATIONAL PTE. LTD.",
     "TPSystemValue": 1310
    },
    {
     
     "CodeDescription": "DRILMACO PTE LTD",
     "TPSystemValue": 1818
    },
    {
     
     "CodeDescription": "DRUK ASIA PRIVATE LIMITED",
     "TPSystemValue": "H530"
    },
    {
     
     "CodeDescription": "DRYDOCKS WORLD",
     "TPSystemValue": 1819
    },
    {
     
     "CodeDescription": "DSCO GROUP PTE LTD",
     "TPSystemValue": 389
    },
    {
     
     "CodeDescription": "DSM",
     "TPSystemValue": 1820
    },
    {
     
     "CodeDescription": "DSO NATIONAL LABORATORIES",
     "TPSystemValue": 590
    },
    {
     
     "CodeDescription": "DSTA DEFENCE SCIENCE TECHNOLOGY AGENCY",
     "TPSystemValue": 1821
    },
    {
     
     "CodeDescription": "DTZ DEBENHAM TIE LEUNG SEA PTE LTD",
     "TPSystemValue": 498
    },
    {
     
     "CodeDescription": "DTZ PROPERTY NETWORK PTE LTD",
     "TPSystemValue": 1822
    },
    {
     
     "CodeDescription": "DU LEXBUILD INTERNATIONAL PTE LTD",
     "TPSystemValue": 737
    },
    {
     
     "CodeDescription": "DU PONT COMPANY(SINGAPORE) PTE LTD",
     "TPSystemValue": 1823
    },
    {
     
     "CodeDescription": "DUKE NUS",
     "TPSystemValue": 1824
    },
    {
     
     "CodeDescription": "DULEX BUILD INTERNATIONAL",
     "TPSystemValue": 1825
    },
    {
     
     "CodeDescription": "DULWICH COLLEGE SINGAPORE PTE LTD",
     "TPSystemValue": 3632
    },
    {
     
     "CodeDescription": "DUN & BRADSTREET (S) PTE LTD",
     "TPSystemValue": 306
    },
    {
     
     "CodeDescription": "DUPONT COMPANY SINGAPORE PTE LTD",
     "TPSystemValue": 1826
    },
    {
     
     "CodeDescription": "DUPONT SINGAPORE PTE LTD",
     "TPSystemValue": 1827
    },
    {
     
     "CodeDescription": "DURASLIDE PTE LTD",
     "TPSystemValue": 1011
    },
    {
     
     "CodeDescription": "DUXTON ASSET MANAGEMENT",
     "TPSystemValue": 1204
    },
    {
     
     "CodeDescription": "DVB GROUP MERCHANT BANK (ASIA) LTD",
     "TPSystemValue": 546
    },
    {
     
     "CodeDescription": "DWG INK CREATIVE",
     "TPSystemValue": 1401
    },
    {
     
     "CodeDescription": "DYAMED BIOTECH PTE LTD",
     "TPSystemValue": 1828
    },
    {
     
     "CodeDescription": "DYNAMAC ENGINEERING SERVICES PTE LTD",
     "TPSystemValue": 359
    },
    {
     
     "CodeDescription": "DYNAMAC HOLDINGS PTE LTD",
     "TPSystemValue": 876
    },
    {
     
     "CodeDescription": "DYNAMIC SOURCE (S) PTE LTD",
     "TPSystemValue": 1829
    },
    {
     
     "CodeDescription": "DYNAMIC WORLD CONTRACTING SERVICES PL",
     "TPSystemValue": 1830
    },
    {
     
     "CodeDescription": "DYNAMICS SPEECH PTE LTD",
     "TPSystemValue": 1017
    },
    {
     
     "CodeDescription": "DYNAMIX COSMO PTE LTD",
     "TPSystemValue": 1831
    },
    {
     
     "CodeDescription": "DYNASTY CLASSIC KTV PTE LTD",
     "TPSystemValue": 1832
    },
    {
     
     "CodeDescription": "DYNASTY TRAVEL INTERNATIONAL PTE LTD",
     "TPSystemValue": "H086"
    },
    {
     
     "CodeDescription": "DYNAVEST PTE LTD",
     "TPSystemValue": 1833
    },
    {
     
     "CodeDescription": "DYNEA (S) PTE LTD",
     "TPSystemValue": 1834
    },
    {
     
     "CodeDescription": "DYSLEXIA ASSOCIATION OF SINGAPORE",
     "TPSystemValue": 1835
    },
    {
     
     "CodeDescription": "DYSON OPERATIONS PTE LTD",
     "TPSystemValue": 1836
    },
    {
     
     "CodeDescription": "DYSTAR SINGAPORE PTE LTD",
     "TPSystemValue": 509
    },
    {
     
     "CodeDescription": "DZ BANK",
     "TPSystemValue": 1837
    },
    {
     
     "CodeDescription": "DZ BANK AG DEUTSCHE ZENTRAL",
     "TPSystemValue": "D05"
    },
    {
     
     "CodeDescription": "E FUSION SOLUTIONS PTE LTD",
     "TPSystemValue": 1838
    },
    {
     
     "CodeDescription": "E HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H358"
    },
    {
     
     "CodeDescription": "E LINK BUSINESS SINGAPORE PTE. LTD.",
     "TPSystemValue": "H650"
    },
    {
     
     "CodeDescription": "E M SERVICES PTE LTD",
     "TPSystemValue": 1839
    },
    {
     
     "CodeDescription": "E.N.S ELECTRICAL ENGINEERING PL",
     "TPSystemValue": 1840
    },
    {
     
     "CodeDescription": "E.N.S. ELECTRICAL ENGINEERING PTE LTD",
     "TPSystemValue": "01E"
    },
    {
     
     "CodeDescription": "EAGLE SERVICES ASIA",
     "TPSystemValue": 1841
    },
    {
     
     "CodeDescription": "EAGLE SHIP MANAGEMENT PTE LTD",
     "TPSystemValue": 1842
    },
    {
     
     "CodeDescription": "EARTH ARTS PTE LTD",
     "TPSystemValue": 552
    },
    {
     
     "CodeDescription": "EARTH CONSTRUCTION & TRADING",
     "TPSystemValue": 1383
    },
    {
     
     "CodeDescription": "EAST MARINE PTE LTD",
     "TPSystemValue": 1843
    },
    {
     
     "CodeDescription": "EAST WEST PLANNERS PTE. LTD.",
     "TPSystemValue": "H663"
    },
    {
     
     "CodeDescription": "EASTBUILT PTE LTD",
     "TPSystemValue": 1844
    },
    {
     
     "CodeDescription": "EASTERN HOLDINGS LTD",
     "TPSystemValue": 189
    },
    {
     
     "CodeDescription": "EASTERN HOLIDAY SINGAPORE PTE LTD",
     "TPSystemValue": "H272"
    },
    {
     
     "CodeDescription": "EASTMAN CHEMICAL LTD",
     "TPSystemValue": 2816
    },
    {
     
     "CodeDescription": "EASTPORT MARITIME PTE LTD",
     "TPSystemValue": 1845
    },
    {
     
     "CodeDescription": "EASTSPRING INVESTMENTS",
     "TPSystemValue": 1846
    },
    {
     
     "CodeDescription": "EASYBOOK.COM PTE. LTD.",
     "TPSystemValue": "H522"
    },
    {
     
     "CodeDescription": "EASY-FLY TRAVEL PTE. LTD.",
     "TPSystemValue": "H604"
    },
    {
     
     "CodeDescription": "EASYGO PTE. LTD.",
     "TPSystemValue": "H598"
    },
    {
     
     "CodeDescription": "EATON INDUSTRIES PTE LTD",
     "TPSystemValue": 1847
    },
    {
     
     "CodeDescription": "EBARA ENGINEERING SINGAPORE PTE LTD",
     "TPSystemValue": 1848
    },
    {
     
     "CodeDescription": "EBIX SINGAPORE PTE LTD",
     "TPSystemValue": 655
    },
    {
     
     "CodeDescription": "EBUSINESSWARE SINGAPORE PTE LTD",
     "TPSystemValue": 856
    },
    {
     
     "CodeDescription": "EBWORX INTERNATIONAL PTE LTD",
     "TPSystemValue": 1849
    },
    {
     
     "CodeDescription": "ECENTA ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1850
    },
    {
     
     "CodeDescription": "ECHO TRAVELS & TOURS PTE. LTD.",
     "TPSystemValue": "H676"
    },
    {
     
     "CodeDescription": "ECOFIELDTRIPS PTE. LTD.",
     "TPSystemValue": "H347"
    },
    {
     
     "CodeDescription": "ECON HOLIDAYS PTE LTD",
     "TPSystemValue": "H124"
    },
    {
     
     "CodeDescription": "EDB",
     "TPSystemValue": 1855
    },
    {
     
     "CodeDescription": "EDB INVESTMENTS PTE LTD",
     "TPSystemValue": 385
    },
    {
     
     "CodeDescription": "EDEN INFORMATION SERVICES (S) PTE LTD",
     "TPSystemValue": 1074
    },
    {
     
     "CodeDescription": "EDIPRESSE SINGAPORE PTE LTD",
     "TPSystemValue": 1856
    },
    {
     
     "CodeDescription": "EDMI LIMITED",
     "TPSystemValue": 1857
    },
    {
     
     "CodeDescription": "EDMUND OPTICS",
     "TPSystemValue": 760
    },
    {
     
     "CodeDescription": "EDN MEDIA PTE. LTD.",
     "TPSystemValue": "H327"
    },
    {
     
     "CodeDescription": "EDS INTERNATIONAL (S) PTE LTD",
     "TPSystemValue": 1858
    },
    {
     
     "CodeDescription": "EDUHUB INVESTMENT PTE. LTD.",
     "TPSystemValue": "H379"
    },
    {
     
     "CodeDescription": "EDUPRO TOURISM PTE LTD",
     "TPSystemValue": 1151
    },
    {
     
     "CodeDescription": "EDUTRAVEL PTE. LTD.",
     "TPSystemValue": "H728"
    },
    {
     
     "CodeDescription": "EDWARDS LIFESCIENCES SINGAPORE PTE LTD",
     "TPSystemValue": 1859
    },
    {
     
     "CodeDescription": "EDWARDS TECHNOLOGIES SINGAPORE PTE LTD",
     "TPSystemValue": 377
    },
    {
     
     "CodeDescription": "EFG BANK",
     "TPSystemValue": 1860
    },
    {
     
     "CodeDescription": "E-FORCE TECHNOLOGIES PTE LTD",
     "TPSystemValue": 962
    },
    {
     
     "CodeDescription": "EFUSION SOLUTION+S PTE LTD",
     "TPSystemValue": "02E"
    },
    {
     
     "CodeDescription": "EFUSION SOLUTIONS PTE LTD",
     "TPSystemValue": 802
    },
    {
     
     "CodeDescription": "EGENCIA SINGAPORE PTE. LTD.",
     "TPSystemValue": "H841"
    },
    {
     
     "CodeDescription": "EGON ZEHNDER INTERNATIONAL",
     "TPSystemValue": 1861
    },
    {
     
     "CodeDescription": "EIING HAI TRADING (SINGAPORE) PTE LTD",
     "TPSystemValue": 363
    },
    {
     
     "CodeDescription": "EL VIZION TRAVEL PTE. LTD.",
     "TPSystemValue": "H792"
    },
    {
     
     "CodeDescription": "ELAN SCHOOL PTE LTD",
     "TPSystemValue": 1258
    },
    {
     
     "CodeDescription": "ELDAN LAW LLP",
     "TPSystemValue": 3111
    },
    {
     
     "CodeDescription": "ELECTROLUX SEA PTE LTD",
     "TPSystemValue": 62
    },
    {
     
     "CodeDescription": "ELECTRONIC REALTY ASSOCIATES PTE LTD",
     "TPSystemValue": 1862
    },
    {
     
     "CodeDescription": "ELEMENT 14 PTE LTD",
     "TPSystemValue": 1863
    },
    {
     
     "CodeDescription": "ELE-TRUNK MARINE ENGR",
     "TPSystemValue": 1864
    },
    {
     
     "CodeDescription": "EL-INCOR DESIGN",
     "TPSystemValue": 1371
    },
    {
     
     "CodeDescription": "ELIPVA LTD",
     "TPSystemValue": 710
    },
    {
     
     "CodeDescription": "ELITE",
     "TPSystemValue": 1865
    },
    {
     
     "CodeDescription": "ELITE INTERNATIONAL LOGISTICS (S) PL",
     "TPSystemValue": 554
    },
    {
     
     "CodeDescription": "ELIXIR TECHONOLOGY PTE LTD",
     "TPSystemValue": 432
    },
    {
     
     "CodeDescription": "ELIZABETH ARDEN SINGAPORE PTE LTD",
     "TPSystemValue": 1866
    },
    {
     
     "CodeDescription": "ELLDAMS CONSTRUCTION AND TRANSPORTATION PTE. LTD.",
     "TPSystemValue": "03E"
    },
    {
     
     "CodeDescription": "ELLDAMS CONSTRUCTION PTE LTD",
     "TPSystemValue": 1867
    },
    {
     
     "CodeDescription": "ELSEVIER (SINGAPORE) PTE LTD",
     "TPSystemValue": 197
    },
    {
     
     "CodeDescription": "EM SERVICES PTE LTD",
     "TPSystemValue": 1868
    },
    {
     
     "CodeDescription": "EMBASSY ALLIANCE TRAVEL PTE. LTD.",
     "TPSystemValue": "H496"
    },
    {
     
     "CodeDescription": "EMBRAER ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1080
    },
    {
     
     "CodeDescription": "EMC COMPUTER SYSTEMS PTE LTD",
     "TPSystemValue": 1869
    },
    {
     
     "CodeDescription": "EMC INTERNATIONAL SARL SINGAPORE",
     "TPSystemValue": 1870
    },
    {
     
     "CodeDescription": "EMERALD TOURS AND TRAVELS PRIVATE LIMITED",
     "TPSystemValue": "H745"
    },
    {
     
     "CodeDescription": "EMERIO CORPORATION PTE LTD",
     "TPSystemValue": 776
    },
    {
     
     "CodeDescription": "EMERIO GLOBESOFT PTE LTD",
     "TPSystemValue": 619
    },
    {
     
     "CodeDescription": "EMERIO INFOTECH PTE LTD",
     "TPSystemValue": 445
    },
    {
     
     "CodeDescription": "EMERSON PROCESS MANAGEMENT",
     "TPSystemValue": 1871
    },
    {
     
     "CodeDescription": "EMERSON PROCESS MANAGEMENT ASIA PACIFIC",
     "TPSystemValue": 1872
    },
    {
     
     "CodeDescription": "EMIRATES NBD PJSC",
     "TPSystemValue": "E01"
    },
    {
     
     "CodeDescription": "EMPEROR INTERNATIONAL TRAVEL PTE. LTD.",
     "TPSystemValue": "H820"
    },
    {
     
     "CodeDescription": "EMPLOYER NAME",
     "TPSystemValue": "04E"
    },
    {
     
     "CodeDescription": "ENCORA TECHNOLOGIES PTE LTD",
     "TPSystemValue": 751
    },
    {
     
     "CodeDescription": "ENERGIZER SINGAPORE PTE LTD",
     "TPSystemValue": 1873
    },
    {
     
     "CodeDescription": "ENERGY EQUIPMENT SERVICE PTE LTD",
     "TPSystemValue": 1019
    },
    {
     
     "CodeDescription": "ENERGY MARKET AUTHORITY",
     "TPSystemValue": 3094
    },
    {
     
     "CodeDescription": "ENFATICO PTE LTD",
     "TPSystemValue": 1150
    },
    {
     
     "CodeDescription": "ENG HUA FURNITURE MANUFACTURING PTE LTD",
     "TPSystemValue": 95
    },
    {
     
     "CodeDescription": "ENG HUAT INDUSTRIES PTE LTD",
     "TPSystemValue": 671
    },
    {
     
     "CodeDescription": "ENG LEE ENGINEERING PTE LTD",
     "TPSystemValue": 1874
    },
    {
     
     "CodeDescription": "ENG SENG TRAVEL SERVICE PTE LTD",
     "TPSystemValue": "H120"
    },
    {
     
     "CodeDescription": "ENG SOON INVESTMENT PTE LTD",
     "TPSystemValue": 400
    },
    {
     
     "CodeDescription": "ENGGSOL PTE LTD",
     "TPSystemValue": 1009
    },
    {
     
     "CodeDescription": "ENJOY YOUR HOLIDAY TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H551"
    },
    {
     
     "CodeDescription": "ENS ELECTRICAL ENGINEERING PTE LTD",
     "TPSystemValue": 1875
    },
    {
     
     "CodeDescription": "ENTERPRISE SOFTWARE SOLUTIONS PL",
     "TPSystemValue": 1106
    },
    {
     
     "CodeDescription": "EPCOS PTE LTD",
     "TPSystemValue": 581
    },
    {
     
     "CodeDescription": "EPE PACKAGING PTE LTD",
     "TPSystemValue": 355
    },
    {
     
     "CodeDescription": "EPRO ENGRG PTE LTD",
     "TPSystemValue": 1876
    },
    {
     
     "CodeDescription": "EPS COMPUTER SYSTEMS PTE LTD",
     "TPSystemValue": 428
    },
    {
     
     "CodeDescription": "EPS CONSULTANTS PTE LTD",
     "TPSystemValue": 1877
    },
    {
     
     "CodeDescription": "EPSILON INTERNATIONAL LLC",
     "TPSystemValue": 1878
    },
    {
     
     "CodeDescription": "EPSILON TELECOMMUNICATIONS",
     "TPSystemValue": 1113
    },
    {
     
     "CodeDescription": "EPSON SINGAPORE PTE LTD",
     "TPSystemValue": 208
    },
    {
     
     "CodeDescription": "EQS ASIA PTE LTD",
     "TPSystemValue": 601
    },
    {
     
     "CodeDescription": "EQUANT PTE LTD",
     "TPSystemValue": 1879
    },
    {
     
     "CodeDescription": "EQUATORIAL WOOD CO (PTE) LTD",
     "TPSystemValue": 98
    },
    {
     
     "CodeDescription": "EQUINIX ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1880
    },
    {
     
     "CodeDescription": "ERA",
     "TPSystemValue": 1881
    },
    {
     
     "CodeDescription": "ERA TOURS PTE. LTD.",
     "TPSystemValue": "H400"
    },
    {
     
     "CodeDescription": "ERGO: TRAVEL PTE. LTD.",
     "TPSystemValue": "H518"
    },
    {
     
     "CodeDescription": "ERICSON TRAVEL PTE LTD",
     "TPSystemValue": "H269"
    },
    {
     
     "CodeDescription": "ERICSSON TELECOMMUNICATIONS PTE LTD",
     "TPSystemValue": 260
    },
    {
     
     "CodeDescription": "ERNST YOUNG ADVISORY PTE LTD",
     "TPSystemValue": 334
    },
    {
     
     "CodeDescription": "ERNST YOUNG LLP",
     "TPSystemValue": 3103
    },
    {
     
     "CodeDescription": "ERNST YOUNG SOLUTIONS LLP",
     "TPSystemValue": 3102
    },
    {
     
     "CodeDescription": "ESKA GRAPHIC BOARD ASIA PACIFIC",
     "TPSystemValue": 3072
    },
    {
     
     "CodeDescription": "ESPN STAR SPORTS",
     "TPSystemValue": 2833
    },
    {
     
     "CodeDescription": "ESTEE LAUDER COSMETICS PTE LTD",
     "TPSystemValue": 247
    },
    {
     
     "CodeDescription": "ETEQ COMPONENTS PTE LTD",
     "TPSystemValue": 463
    },
    {
     
     "CodeDescription": "EU ASIA HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H721"
    },
    {
     
     "CodeDescription": "EU HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H569"
    },
    {
     
     "CodeDescription": "EU MICE PTE. LTD.",
     "TPSystemValue": "H720"
    },
    {
     
     "CodeDescription": "EU YAN SANG MARKETING PTE LTD",
     "TPSystemValue": 382
    },
    {
     
     "CodeDescription": "EULER HERMES CREDIT INSU. AGENCY PL",
     "TPSystemValue": 955
    },
    {
     
     "CodeDescription": "EURO GROUP PTE LTD",
     "TPSystemValue": 913
    },
    {
     
     "CodeDescription": "EURO-ASIA HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H153"
    },
    {
     
     "CodeDescription": "EUROCOPTER SOUTH EAST ASIA PL",
     "TPSystemValue": 130
    },
    {
     
     "CodeDescription": "EUROFIN ASIA GROUP",
     "TPSystemValue": 3799
    },
    {
     
     "CodeDescription": "EUROKARS HABITAT PTE LTD",
     "TPSystemValue": 675
    },
    {
     
     "CodeDescription": "EUROKARS PTE LTD",
     "TPSystemValue": 212
    },
    {
     
     "CodeDescription": "EUROMONITOR INTERNATIONAL ASIA PL",
     "TPSystemValue": 658
    },
    {
     
     "CodeDescription": "EUROSTOP SINGAPORE PRIVATE LIMITED",
     "TPSystemValue": 1095
    },
    {
     
     "CodeDescription": "EUROTOURS AND TRAVEL PTE LTD",
     "TPSystemValue": "H078"
    },
    {
     
     "CodeDescription": "EURYDICE TRAVEL PTE. LTD.",
     "TPSystemValue": "H775"
    },
    {
     
     "CodeDescription": "EUSTENZA CONTRACT SERVICES PTE LTD",
     "TPSystemValue": 1094
    },
    {
     
     "CodeDescription": "EVER GLORY LOGISTICS PTE LTD",
     "TPSystemValue": 734
    },
    {
     
     "CodeDescription": "EVER HOLIDAYS PTE LTD",
     "TPSystemValue": "H157"
    },
    {
     
     "CodeDescription": "EVEREST REINSURANCE COMPANY",
     "TPSystemValue": 882
    },
    {
     
     "CodeDescription": "EVERGREEN ENGINEERING & CONSTR PL",
     "TPSystemValue": 1882
    },
    {
     
     "CodeDescription": "EVERSAFE CONSULTANTS PTE LTD",
     "TPSystemValue": 1171
    },
    {
     
     "CodeDescription": "EVERSHINE TRAVEL & SERVICES PTE. LTD.",
     "TPSystemValue": "H689"
    },
    {
     
     "CodeDescription": "EVOLUTION WERKS PTE LTD",
     "TPSystemValue": 1883
    },
    {
     
     "CodeDescription": "EVONIK (SEA) PTE LTD",
     "TPSystemValue": 608
    },
    {
     
     "CodeDescription": "EXCEL CYCLE CENTRE PTE LTD",
     "TPSystemValue": 541
    },
    {
     
     "CodeDescription": "EXCELITAS TECHNOLOGIES SINGAPORE PL",
     "TPSystemValue": 1293
    },
    {
     
     "CodeDescription": "EXCELLENCE TRAVEL PTE. LTD.",
     "TPSystemValue": "H502"
    },
    {
     
     "CodeDescription": "EXCELPOINT SYSTEMS PTE LTD",
     "TPSystemValue": 1884
    },
    {
     
     "CodeDescription": "EXCELSIOR INTERNATIONAL SCHOOL",
     "TPSystemValue": 1415
    },
    {
     
     "CodeDescription": "EXCEPTIONAL PTE LTD",
     "TPSystemValue": 1155
    },
    {
     
     "CodeDescription": "EXECUTIVE TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "H436"
    },
    {
     
     "CodeDescription": "EXO TRAVEL (SINGAPORE) CO. PTE. LTD.",
     "TPSystemValue": "H437"
    },
    {
     
     "CodeDescription": "EXPEDIA GROUP",
     "TPSystemValue": "H027"
    },
    {
     
     "CodeDescription": "EXPEDIA SINGAPORE PTE. LTD.",
     "TPSystemValue": "H465"
    },
    {
     
     "CodeDescription": "EXPERIAN ASIA PACIFIC",
     "TPSystemValue": 133
    },
    {
     
     "CodeDescription": "EXPERIAN SINGAPORE PTE.LTD.",
     "TPSystemValue": 137
    },
    {
     
     "CodeDescription": "EXPERIENCE TOURS SERVICES PTE LTD",
     "TPSystemValue": "H232"
    },
    {
     
     "CodeDescription": "EXPERTISE TECHNOLOGIES PTE LTD",
     "TPSystemValue": 1885
    },
    {
     
     "CodeDescription": "EXPLOIT TECHNOLOGIES PTE LTD",
     "TPSystemValue": 505
    },
    {
     
     "CodeDescription": "EXPLORA PTE. LTD.",
     "TPSystemValue": "H539"
    },
    {
     
     "CodeDescription": "EXPLORER BEAR PRIVATE LIMITED",
     "TPSystemValue": "H714"
    },
    {
     
     "CodeDescription": "EXPOFIN PTE LTD",
     "TPSystemValue": 329
    },
    {
     
     "CodeDescription": "EXPORT TRADING COMMODITIES PTE LTD",
     "TPSystemValue": 1274
    },
    {
     
     "CodeDescription": "EXPRESS CONSULTANCY PTE LTD",
     "TPSystemValue": 1165
    },
    {
     
     "CodeDescription": "EXPRESS OFFSHORE SOLUTIONS PTE LTD",
     "TPSystemValue": 427
    },
    {
     
     "CodeDescription": "EXXONMOBIL ASIA PACIFIC PTE LTD",
     "TPSystemValue": 45
    },
    {
     
     "CodeDescription": "F G BUILDERS PTE LTD",
     "TPSystemValue": 1886
    },
    {
     
     "CodeDescription": "F G BUILDERS PTE. LTD.",
     "TPSystemValue": "01F"
    },
    {
     
     "CodeDescription": "F.H. BERTLING SHIPPING HOLDING PTE LTD",
     "TPSystemValue": 1220
    },
    {
     
     "CodeDescription": "FABRISTEEL PRIVATE LIMITED",
     "TPSystemValue": 157
    },
    {
     
     "CodeDescription": "FABULOUS GROUP PL (FABULOUS TAN PL)",
     "TPSystemValue": 1057
    },
    {
     
     "CodeDescription": "FAIR SKY TRAVEL (PRIVATE) LIMITED",
     "TPSystemValue": "H226"
    },
    {
     
     "CodeDescription": "FAIR TRAVEL & TRADING PTE. LTD.",
     "TPSystemValue": "H536"
    },
    {
     
     "CodeDescription": "FAIRPRICE TRAVEL PTE. LTD.",
     "TPSystemValue": "H407"
    },
    {
     
     "CodeDescription": "FAITH TOURS & TRAVEL PTE LTD",
     "TPSystemValue": "H158"
    },
    {
     
     "CodeDescription": "FAITHFUL + GOULD PTE LIMITED",
     "TPSystemValue": 299
    },
    {
     
     "CodeDescription": "FALCON CAPITAL PARTNERS PTE LTD",
     "TPSystemValue": 1180
    },
    {
     
     "CodeDescription": "FALCON PRIVATE BANK PL",
     "TPSystemValue": 1029
    },
    {
     
     "CodeDescription": "FALCON TOURS PTE. LTD.",
     "TPSystemValue": "H717"
    },
    {
     
     "CodeDescription": "FAR EAST ALLIANCE PTE. LTD.",
     "TPSystemValue": "H599"
    },
    {
     
     "CodeDescription": "FAR EAST ORGANIZATION CENTRE PTE LTD",
     "TPSystemValue": 67
    },
    {
     
     "CodeDescription": "FAR EAST TECHNOLOGY PTE LTD",
     "TPSystemValue": 1887
    },
    {
     
     "CodeDescription": "FAR EASTERN BANK LTD",
     "TPSystemValue": "F01"
    },
    {
     
     "CodeDescription": "FAR EASTERN MARINE & ENGINEERING",
     "TPSystemValue": 1888
    },
    {
     
     "CodeDescription": "FARMOSA HOLIDAY TOUR (S) PTE LTD",
     "TPSystemValue": "H114"
    },
    {
     
     "CodeDescription": "FASCINATING HOLIDAYS PTE LTD",
     "TPSystemValue": "H220"
    },
    {
     
     "CodeDescription": "FAYYAZ TRAVELS PTE. LTD.",
     "TPSystemValue": "H559"
    },
    {
     
     "CodeDescription": "FCI CONNECTORS SINGAPORE PTE LTD",
     "TPSystemValue": 1026
    },
    {
     
     "CodeDescription": "FCL MANAGEMENT SERVICES PTE LTD",
     "TPSystemValue": 449
    },
    {
     
     "CodeDescription": "FCM SINGAPORE PTE. LTD.",
     "TPSystemValue": "H058"
    },
    {
     
     "CodeDescription": "FEDERAL EXPRESS",
     "TPSystemValue": 1889
    },
    {
     
     "CodeDescription": "FEDERAL EXPRESS PACIFIC INC",
     "TPSystemValue": 2825
    },
    {
     
     "CodeDescription": "FEDERAL EXPRESS SINGAPORE PTE LTD",
     "TPSystemValue": 240
    },
    {
     
     "CodeDescription": "FEDERAL HARDWARE ENGINEERING CO PTE LTD",
     "TPSystemValue": 166
    },
    {
     
     "CodeDescription": "FEDERAL INTERNATIONAL 2000 PTE LTD",
     "TPSystemValue": 701
    },
    {
     
     "CodeDescription": "FENG XIANG RUBBER PARTS TRADING",
     "TPSystemValue": 1390
    },
    {
     
     "CodeDescription": "FERRERO ASIA LIMITED SINGAPORE BRANCH",
     "TPSystemValue": 3080
    },
    {
     
     "CodeDescription": "FG (77)",
     "TPSystemValue": 1890
    },
    {
     
     "CodeDescription": "FG BUILDERS PTE LTD",
     "TPSystemValue": 1891
    },
    {
     
     "CodeDescription": "FH BERTLING CATERING SHIP MANAGEMENT",
     "TPSystemValue": 1222
    },
    {
     
     "CodeDescription": "FH BERTLING PTE LTD",
     "TPSystemValue": 784
    },
    {
     
     "CodeDescription": "FIL AIR TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H349"
    },
    {
     
     "CodeDescription": "FINANCIAL ALLIANCE PTE LTD",
     "TPSystemValue": 447
    },
    {
     
     "CodeDescription": "FINANTIX ASIA PACIFIC PTE LTD",
     "TPSystemValue": 837
    },
    {
     
     "CodeDescription": "FINEXIS ADVISORY PTE LTD",
     "TPSystemValue": 930
    },
    {
     
     "CodeDescription": "FIRMENICH ASIA PTE LTD",
     "TPSystemValue": 352
    },
    {
     
     "CodeDescription": "FIRST DATA SINGAPORE PTE LTD",
     "TPSystemValue": 984
    },
    {
     
     "CodeDescription": "FIRST GULF BANK",
     "TPSystemValue": "F03"
    },
    {
     
     "CodeDescription": "FIRST GULF BANK PJSC",
     "TPSystemValue": 3106
    },
    {
     
     "CodeDescription": "FIRST RESOURCES LIMITED",
     "TPSystemValue": 956
    },
    {
     
     "CodeDescription": "FIRST RESOURCES TRADING PTE LTD",
     "TPSystemValue": 1332
    },
    {
     
     "CodeDescription": "FIRST STATE INVESTMENTS",
     "TPSystemValue": 3800
    },
    {
     
     "CodeDescription": "FIRSTLINK INVESTMENTS CORPORATION LTD",
     "TPSystemValue": 141
    },
    {
     
     "CodeDescription": "FISCHER MEDTECH PTE LTD",
     "TPSystemValue": 1305
    },
    {
     
     "CodeDescription": "FISCHER TECH PTE LTD",
     "TPSystemValue": 467
    },
    {
     
     "CodeDescription": "FISH2GO PTE. LTD.",
     "TPSystemValue": "H805"
    },
    {
     
     "CodeDescription": "FIT RUUMS PTE. LTD.",
     "TPSystemValue": "H338"
    },
    {
     
     "CodeDescription": "FIT TRANSPORT SYSTEMS PTE LTD",
     "TPSystemValue": 1038
    },
    {
     
     "CodeDescription": "FITNESS FIRST SINGAPORE PTE LTD",
     "TPSystemValue": 507
    },
    {
     
     "CodeDescription": "FJ BENJAMIN",
     "TPSystemValue": 3634
    },
    {
     
     "CodeDescription": "FLAIR ILLUME PTE. LTD.",
     "TPSystemValue": 835
    },
    {
     
     "CodeDescription": "FLEXOR LITHO PTE LTD",
     "TPSystemValue": 761
    },
    {
     
     "CodeDescription": "FLEXSPEED TECHNOLOGY PTE LTD",
     "TPSystemValue": 848
    },
    {
     
     "CodeDescription": "FLEXTRONICS",
     "TPSystemValue": 1892
    },
    {
     
     "CodeDescription": "FLEXTRONICS MANUFACTURING S PL",
     "TPSystemValue": 180
    },
    {
     
     "CodeDescription": "FLIGHT FOCUS PTE LTD",
     "TPSystemValue": 1893
    },
    {
     
     "CodeDescription": "FLIGHT RAJA TRAVELS SINGAPORE PTE. LTD.",
     "TPSystemValue": "H698"
    },
    {
     
     "CodeDescription": "FLOWSERVE PTE LTD",
     "TPSystemValue": 134
    },
    {
     
     "CodeDescription": "FLUKE SOUTH EAST ASIA PTE LTD",
     "TPSystemValue": 433
    },
    {
     
     "CodeDescription": "FLY LINK'S HOLIDAYS PRIVATE LIMITED",
     "TPSystemValue": "H653"
    },
    {
     
     "CodeDescription": "FLYING EAGLE TRAVEL (S) PTE LTD",
     "TPSystemValue": "H237"
    },
    {
     
     "CodeDescription": "FMC TECHNOLOGIES SINGAPORE PTE LTD",
     "TPSystemValue": 103
    },
    {
     
     "CodeDescription": "FMG CORPORATE SERVICES PTE LTD",
     "TPSystemValue": 430
    },
    {
     
     "CodeDescription": "FN FOODS PTE LTD",
     "TPSystemValue": 38
    },
    {
     
     "CodeDescription": "FOCAL TRAVEL PTE LTD",
     "TPSystemValue": "H222"
    },
    {
     
     "CodeDescription": "FOCUS ADVENTURE PTE. LTD.",
     "TPSystemValue": "H363"
    },
    {
     
     "CodeDescription": "FOCUS NETWORK AGENCIES (SINGAPORE) PL",
     "TPSystemValue": 392
    },
    {
     
     "CodeDescription": "FOLLOW ME JAPAN PTE. LTD.",
     "TPSystemValue": "H396"
    },
    {
     
     "CodeDescription": "FONGSOON ENGINEERING (S) PTE LTD",
     "TPSystemValue": 112
    },
    {
     
     "CodeDescription": "FOO KON TAN GRANT THORNTON LLP",
     "TPSystemValue": 3114
    },
    {
     
     "CodeDescription": "FOOD EMPIRE HOLDINGS LIMITED",
     "TPSystemValue": 718
    },
    {
     
     "CodeDescription": "FORCE 21 EQUIPMENT PTE LTD",
     "TPSystemValue": 796
    },
    {
     
     "CodeDescription": "FOREIGN RECRUITMENT CENTRE PTE LTD",
     "TPSystemValue": 478
    },
    {
     
     "CodeDescription": "FORTIS BANK",
     "TPSystemValue": 1894
    },
    {
     
     "CodeDescription": "FORTIS BANK S.A.N.V.",
     "TPSystemValue": "F04"
    },
    {
     
     "CodeDescription": "FORTIS HEALTHCARE INTERNATIONAL PTE LTD",
     "TPSystemValue": 1283
    },
    {
     
     "CodeDescription": "FORTIS HEALTHCARE SINGAPORE PTE LIMITED",
     "TPSystemValue": 1312
    },
    {
     
     "CodeDescription": "FORTRESS INVESTMENT GROUP S PTE LTD",
     "TPSystemValue": 1284
    },
    {
     
     "CodeDescription": "FORTUNE TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "H840"
    },
    {
     
     "CodeDescription": "FORTUNE TRAVEL PTE LTD",
     "TPSystemValue": "H056"
    },
    {
     
     "CodeDescription": "FOSTER WHEELER ASIA PACIFIC",
     "TPSystemValue": 1895
    },
    {
     
     "CodeDescription": "FPT ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1085
    },
    {
     
     "CodeDescription": "FRANKLIN OFFSHORE HOLDINGS PTE LTD",
     "TPSystemValue": 922
    },
    {
     
     "CodeDescription": "FRANKLIN OFFSHORE INTERNATIONAL PTE LTD",
     "TPSystemValue": 3635
    },
    {
     
     "CodeDescription": "FRASER NEAVE SINGAPORE PTE LIMITED",
     "TPSystemValue": 25
    },
    {
     
     "CodeDescription": "FRASERS CENTREPOINT ASSET MANAG C LTD",
     "TPSystemValue": 969
    },
    {
     
     "CodeDescription": "FROST SULLIVAN SINGAPORE PTE LTD",
     "TPSystemValue": 724
    },
    {
     
     "CodeDescription": "FUJI GOLF TOURS & TRAVEL PTE LTD",
     "TPSystemValue": "H305"
    },
    {
     
     "CodeDescription": "FULLINK TRAVEL PTE. LTD.",
     "TPSystemValue": "H542"
    },
    {
     
     "CodeDescription": "G TRAVEL SINGAPORE PTE. LTD.",
     "TPSystemValue": "H621"
    },
    {
     
     "CodeDescription": "GALAXY HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H504"
    },
    {
     
     "CodeDescription": "GALAXY ONE PTE. LTD.",
     "TPSystemValue": "H828"
    },
    {
     
     "CodeDescription": "GANESH TRAVEL SERVICE PTE LTD",
     "TPSystemValue": "H271"
    },
    {
     
     "CodeDescription": "GARNET TRAVEL PRIVATE LIMITED",
     "TPSystemValue": "H771"
    },
    {
     
     "CodeDescription": "GATEWAY GROUP HOLDINGS PTE. LTD.",
     "TPSystemValue": "H780"
    },
    {
     
     "CodeDescription": "GE TRAVEL PTE. LTD.",
     "TPSystemValue": "H594"
    },
    {
     
     "CodeDescription": "GEM VACATION & TRAVEL PTE. LTD.",
     "TPSystemValue": "H554"
    },
    {
     
     "CodeDescription": "GEMINI TRAVEL N TOURS PTE. LTD.",
     "TPSystemValue": "H475"
    },
    {
     
     "CodeDescription": "GET MANAGEMENT PTE. LTD.",
     "TPSystemValue": "H529"
    },
    {
     
     "CodeDescription": "GETAWAY TOURS PTE. LTD.",
     "TPSystemValue": "H393"
    },
    {
     
     "CodeDescription": "GIAMSO INTERNATIONAL TOURS PTE LTD",
     "TPSystemValue": "H095"
    },
    {
     
     "CodeDescription": "GLOBAL ADVENTURE CONSULTANTS PTE. LTD.",
     "TPSystemValue": "H377"
    },
    {
     
     "CodeDescription": "GLOBAL BUSINESS TRAVEL (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H214"
    },
    {
     
     "CodeDescription": "GLOBAL HOLIDAYS PTE LTD",
     "TPSystemValue": "H364"
    },
    {
     
     "CodeDescription": "GLOBAL INTERNATIONAL TRAVEL PTE. LTD.",
     "TPSystemValue": "H788"
    },
    {
     
     "CodeDescription": "GLOBAL PREMIUM HOTELS",
     "TPSystemValue": "H023"
    },
    {
     
     "CodeDescription": "GLOBAL SINGAPORE ASSOCIATES PTE. LTD.",
     "TPSystemValue": "H664"
    },
    {
     
     "CodeDescription": "GLOBAL TRAVEL PTE LTD",
     "TPSystemValue": "H069"
    },
    {
     
     "CodeDescription": "GLOREE TOURS AND TRAVELS PTE. LTD.",
     "TPSystemValue": "H520"
    },
    {
     
     "CodeDescription": "GNS LEARNING HUB PTE. LTD.",
     "TPSystemValue": "H321"
    },
    {
     
     "CodeDescription": "GOBUDGETAIR.COM PTE. LTD.",
     "TPSystemValue": "H815"
    },
    {
     
     "CodeDescription": "GOING PLACES TRAVEL PTE LTD",
     "TPSystemValue": "H306"
    },
    {
     
     "CodeDescription": "GOLDEN DOVE TRAVEL PTE. LTD.",
     "TPSystemValue": "H503"
    },
    {
     
     "CodeDescription": "GOLDEN LINE EXPRESS PTE LTD",
     "TPSystemValue": "H186"
    },
    {
     
     "CodeDescription": "GOLDEN LINK TRAVEL PTE LTD",
     "TPSystemValue": "H768"
    },
    {
     
     "CodeDescription": "GOLDEN M PREMIUM HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H842"
    },
    {
     
     "CodeDescription": "GOLDEN SITE PTE. LTD.",
     "TPSystemValue": "H578"
    },
    {
     
     "CodeDescription": "GOLDEN STATE TRAVEL & SERVICES PTE. LTD.",
     "TPSystemValue": "H597"
    },
    {
     
     "CodeDescription": "GOLDEN TRAVEL SERVICES PTE LTD",
     "TPSystemValue": "H076"
    },
    {
     
     "CodeDescription": "GOLDENBELL TRAVEL PTE LTD",
     "TPSystemValue": "H302"
    },
    {
     
     "CodeDescription": "GOLDMAN SACH INTERNATIONAL",
     "TPSystemValue": 3641
    },
    {
     
     "CodeDescription": "GOLDMAN SACHS (SINGAPORE) PTE",
     "TPSystemValue": "04G"
    },
    {
     
     "CodeDescription": "GOLDMAN SACHS FUTURES SINGAPORE PTE LTD",
     "TPSystemValue": 364
    },
    {
     
     "CodeDescription": "GOLDMAN SACHS SINGAPORE PTE LTD",
     "TPSystemValue": 268
    },
    {
     
     "CodeDescription": "GOLDVEIN PTE LTD",
     "TPSystemValue": 105
    },
    {
     
     "CodeDescription": "GOLF HOLIDAYS AND TRAVEL PTE. LTD.",
     "TPSystemValue": "H577"
    },
    {
     
     "CodeDescription": "GOLFHUB INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H463"
    },
    {
     
     "CodeDescription": "GOODWOOD PARK HOTEL",
     "TPSystemValue": 1905
    },
    {
     
     "CodeDescription": "GOODWOOD PARK HOTEL LTD",
     "TPSystemValue": "05G"
    },
    {
     
     "CodeDescription": "GOVERNMENT OF SINGAPORE INVESTMENT COR",
     "TPSystemValue": 365
    },
    {
     
     "CodeDescription": "GRACE MANAGEMENT & CONSULTANCY SER PL",
     "TPSystemValue": 1227
    },
    {
     
     "CodeDescription": "GRAND HYATT SINGAPORE",
     "TPSystemValue": 1355
    },
    {
     
     "CodeDescription": "GRASSLAND EXPRESS & TOURS PTE LTD",
     "TPSystemValue": "H167"
    },
    {
     
     "CodeDescription": "GRASSLAND EXPRESS PTE LTD",
     "TPSystemValue": "H200"
    },
    {
     
     "CodeDescription": "GREAT EASTERN FINANCIAL ADVISERS PL",
     "TPSystemValue": 898
    },
    {
     
     "CodeDescription": "GREAT EASTERN LIFE",
     "TPSystemValue": 1906
    },
    {
     
     "CodeDescription": "GREAT WALL TRAVEL SERVICE PTE LTD",
     "TPSystemValue": "H093"
    },
    {
     
     "CodeDescription": "GREAT WORLD TRAVEL PTE. LTD.",
     "TPSystemValue": "H718"
    },
    {
     
     "CodeDescription": "GREEN ISLAND TOURS (S) PTE LTD",
     "TPSystemValue": "H080"
    },
    {
     
     "CodeDescription": "GREEN OCEAN MARINE & ENGINEERING PTE LTD",
     "TPSystemValue": 1907
    },
    {
     
     "CodeDescription": "GREEN OCEAN PTE LTD",
     "TPSystemValue": 1908
    },
    {
     
     "CodeDescription": "GREEN STAR PRODUCTS SINGAPORE PTE LTD",
     "TPSystemValue": 817
    },
    {
     
     "CodeDescription": "GREENFOSSIL PTE LTD",
     "TPSystemValue": 1028
    },
    {
     
     "CodeDescription": "GREENLAM ASIA PACIFIC PTE LTD",
     "TPSystemValue": 972
    },
    {
     
     "CodeDescription": "GREENLAM ASIA PACIFIC PTE LTD.",
     "TPSystemValue": "06G"
    },
    {
     
     "CodeDescription": "GREY GROUP PTE LTD",
     "TPSystemValue": 714
    },
    {
     
     "CodeDescription": "GROHE PACIFIC PTE LTD",
     "TPSystemValue": 466
    },
    {
     
     "CodeDescription": "GROUND INSTRUMENTATION & ENGINEERING PTE",
     "TPSystemValue": 1909
    },
    {
     
     "CodeDescription": "GROUND INSTRUMENTATION & ENGINEERING PTE. LTD.",
     "TPSystemValue": "07G"
    },
    {
     
     "CodeDescription": "GROUP M ASIA PACIFIC HOLDINGS PTE LTD",
     "TPSystemValue": 534
    },
    {
     
     "CodeDescription": "GROUPAMA TRANSPORT SINGAPORE BRANCH",
     "TPSystemValue": 3420
    },
    {
     
     "CodeDescription": "GROUPM SINGAPORE PTE LTD",
     "TPSystemValue": 533
    },
    {
     
     "CodeDescription": "GSA TRAVEL PTE LTD",
     "TPSystemValue": "H218"
    },
    {
     
     "CodeDescription": "GS-DIVING PTE. LTD.",
     "TPSystemValue": "H608"
    },
    {
     
     "CodeDescription": "GT DOLLAR PTE. LTD.",
     "TPSystemValue": "H797"
    },
    {
     
     "CodeDescription": "GTAR TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "H474"
    },
    {
     
     "CodeDescription": "GTL SINGAPORE PTE LTD",
     "TPSystemValue": 511
    },
    {
     
     "CodeDescription": "GTM ALUMINIUM PTE LTD",
     "TPSystemValue": 232
    },
    {
     
     "CodeDescription": "GTMC TRAVEL PTE LTD",
     "TPSystemValue": "H288"
    },
    {
     
     "CodeDescription": "GTS TRAVEL PTE. LTD.",
     "TPSystemValue": "H713"
    },
    {
     
     "CodeDescription": "GUARDIAN PHARMACY",
     "TPSystemValue": 1352
    },
    {
     
     "CodeDescription": "GUMPOLD FLUID CONTROLS PTE LTD",
     "TPSystemValue": 1079
    },
    {
     
     "CodeDescription": "GUNNEBO SINGAPORE PTE LTD",
     "TPSystemValue": 881
    },
    {
     
     "CodeDescription": "GURU TRAVELS PTE LTD",
     "TPSystemValue": "H252"
    },
    {
     
     "CodeDescription": "GUTHRIE ENGINEERING SINGAPORE PTE LTD",
     "TPSystemValue": 15
    },
    {
     
     "CodeDescription": "GUY CARPENTER & COMPANY PRIVATE LIMITED",
     "TPSystemValue": 475
    },
    {
     
     "CodeDescription": "GVS HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H501"
    },
    {
     
     "CodeDescription": "H.I.S. GLOBAL TRAVEL PTE. LTD.",
     "TPSystemValue": "H314"
    },
    {
     
     "CodeDescription": "H.I.S. INTERNATIONAL TRAVEL PTE LTD",
     "TPSystemValue": "H217"
    },
    {
     
     "CodeDescription": "H.K HARDWARE & ENGINEERING PTE LTD",
     "TPSystemValue": 517
    },
    {
     
     "CodeDescription": "H.W HOLDINGS PTE LTD",
     "TPSystemValue": 1910
    },
    {
     
     "CodeDescription": "HABIB BANK LIMITED",
     "TPSystemValue": 2809
    },
    {
     
     "CodeDescription": "HABIB BANK LTD",
     "TPSystemValue": "H01"
    },
    {
     
     "CodeDescription": "HAGEL TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H299"
    },
    {
     
     "CodeDescription": "HAHNEMANN TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H191"
    },
    {
     
     "CodeDescription": "HAI LECK ENGINEERING PTE LTD",
     "TPSystemValue": 1911
    },
    {
     
     "CodeDescription": "HAICON ENGINEERING",
     "TPSystemValue": 1912
    },
    {
     
     "CodeDescription": "HAILECK ENGINEERING PTE LTD",
     "TPSystemValue": 1913
    },
    {
     
     "CodeDescription": "HALIJAH TRAVELS PTE LTD",
     "TPSystemValue": "H197"
    },
    {
     
     "CodeDescription": "HALLIBURTON FAR EAST PTE LTD",
     "TPSystemValue": 346
    },
    {
     
     "CodeDescription": "HALLIBURTON SINGAPORE PTE LTD",
     "TPSystemValue": 300
    },
    {
     
     "CodeDescription": "HAMIDAH TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H365"
    },
    {
     
     "CodeDescription": "HAMILTON SUNDSTRAND",
     "TPSystemValue": 1914
    },
    {
     
     "CodeDescription": "HAMILTON SUNDSTRAND PACIFIC",
     "TPSystemValue": 1915
    },
    {
     
     "CodeDescription": "HAN LANGUAGE CENTRE",
     "TPSystemValue": 1298
    },
    {
     
     "CodeDescription": "HAN LANGUAGE CENTRE (WOODLANDS) PTE. LTD.",
     "TPSystemValue": "01H"
    },
    {
     
     "CodeDescription": "HANA BANK",
     "TPSystemValue": "H02"
    },
    {
     
     "CodeDescription": "HANATOUR PTE. LTD.",
     "TPSystemValue": "H395"
    },
    {
     
     "CodeDescription": "HANG SENG BANK LIMITED",
     "TPSystemValue": "H03"
    },
    {
     
     "CodeDescription": "HANJIN OVERSEAS TANKER PTE LTD",
     "TPSystemValue": 1098
    },
    {
     
     "CodeDescription": "HANJIN SHIPPING SINGAPORE PTE LTD",
     "TPSystemValue": 431
    },
    {
     
     "CodeDescription": "HANKOOKCHON PTE. LTD.",
     "TPSystemValue": "H484"
    },
    {
     
     "CodeDescription": "HANKYU TRAVEL INTERNATIONAL SINGAPORE PTE. LTD.",
     "TPSystemValue": "H761"
    },
    {
     
     "CodeDescription": "HANS MARINE PTE. LTD.",
     "TPSystemValue": "02H"
    },
    {
     
     "CodeDescription": "HAPPY LABS PTE LTD",
     "TPSystemValue": 3803
    },
    {
     
     "CodeDescription": "HAPPY PEOPLE CO",
     "TPSystemValue": 1916
    },
    {
     
     "CodeDescription": "HAPPY YOU TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "H749"
    },
    {
     
     "CodeDescription": "HARD ROCK CAFPTE LTD",
     "TPSystemValue": 325
    },
    {
     
     "CodeDescription": "HARDI TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H394"
    },
    {
     
     "CodeDescription": "HARDWICKE RACING PTE LTD",
     "TPSystemValue": 1917
    },
    {
     
     "CodeDescription": "HARRYS INTERNATIONAL",
     "TPSystemValue": 388
    },
    {
     
     "CodeDescription": "HART TECHNOGIES PTE LTD",
     "TPSystemValue": 256
    },
    {
     
     "CodeDescription": "HARVEST MARINE SERVICE",
     "TPSystemValue": 1918
    },
    {
     
     "CodeDescription": "HARVEST TRAVEL PTE. LTD.",
     "TPSystemValue": "H479"
    },
    {
     
     "CodeDescription": "HAWORTH SINGAPORE PTE LTD",
     "TPSystemValue": 198
    },
    {
     
     "CodeDescription": "HAYS SPECIALIST RECRUITMENT PTE LTD",
     "TPSystemValue": 1059
    },
    {
     
     "CodeDescription": "HCL INSYS PTE LTD",
     "TPSystemValue": 1251
    },
    {
     
     "CodeDescription": "HCL SINGAPORE PTE LTD",
     "TPSystemValue": 163
    },
    {
     
     "CodeDescription": "HCM INTERNATIONAL PTE. LTD.",
     "TPSystemValue": 1259
    },
    {
     
     "CodeDescription": "HE TRAVEL PTE. LTD.",
     "TPSystemValue": "H716"
    },
    {
     
     "CodeDescription": "HEALTH SCIENCES AUTHORITY",
     "TPSystemValue": 1919
    },
    {
     
     "CodeDescription": "HEALTHWAY MEDICAL GROUP PTE LTD",
     "TPSystemValue": 456
    },
    {
     
     "CodeDescription": "HEARTLINK HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H580"
    },
    {
     
     "CodeDescription": "HEAVENLY TOURS PTE. LTD.",
     "TPSystemValue": "H562"
    },
    {
     
     "CodeDescription": "HELIUS TECHNOLOGIES PTE LTD",
     "TPSystemValue": 1050
    },
    {
     
     "CodeDescription": "HELVETIC INVESTMENTS PTE LTD",
     "TPSystemValue": 1229
    },
    {
     
     "CodeDescription": "HENA GLOBAL HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H458"
    },
    {
     
     "CodeDescription": "HENG LONG LEATHER CO PTE LTD",
     "TPSystemValue": 160
    },
    {
     
     "CodeDescription": "HERITAGE MANAGEMENT & CONSULTANCY SERVICES PTE. LTD.",
     "TPSystemValue": "H355"
    },
    {
     
     "CodeDescription": "HERMESEPITEK CORPORATION PTE LTD",
     "TPSystemValue": 405
    },
    {
     
     "CodeDescription": "HEWLETT PACKARD",
     "TPSystemValue": 1920
    },
    {
     
     "CodeDescription": "HEWLETT PACKARD REGIONAL SALES PTE LTD",
     "TPSystemValue": 3642
    },
    {
     
     "CodeDescription": "HEWLETT PACKARD SINGAPORE SALES PL",
     "TPSystemValue": 207
    },
    {
     
     "CodeDescription": "HEWLETTPACKARD ASIA PACIFIC PL",
     "TPSystemValue": 290
    },
    {
     
     "CodeDescription": "HEWLETTPACKARD SINGAPORE P LTD",
     "TPSystemValue": 57
    },
    {
     
     "CodeDescription": "HEXAMATICS SINGAPORE PTE LTD",
     "TPSystemValue": 1223
    },
    {
     
     "CodeDescription": "HIAP SENG ENGINEERING LTD",
     "TPSystemValue": 71
    },
    {
     
     "CodeDescription": "HILL & KNOWLTON (SEA) PTE LTD",
     "TPSystemValue": 504
    },
    {
     
     "CodeDescription": "HILTON SINGAPORE",
     "TPSystemValue": 4
    },
    {
     
     "CodeDescription": "HIN HIN MANAGEMENT HOLDINGS PTE LTD",
     "TPSystemValue": 441
    },
    {
     
     "CodeDescription": "HIN SOON MARINE & ENGINEERING PTE LTD",
     "TPSystemValue": 1921
    },
    {
     
     "CodeDescription": "HI-P ELECTRONICS PTE. LTD.",
     "TPSystemValue": 750
    },
    {
     
     "CodeDescription": "HISOFT SINGAPORE PTE LTD",
     "TPSystemValue": 342
    },
    {
     
     "CodeDescription": "HITACHI ASIA LTD",
     "TPSystemValue": 315
    },
    {
     
     "CodeDescription": "HITACHI CABLE ASIA PACIFIC HCAP PL",
     "TPSystemValue": 1161
    },
    {
     
     "CodeDescription": "HITACHI CHEMICAL",
     "TPSystemValue": 1922
    },
    {
     
     "CodeDescription": "HITACHI CHEMICAL ASIAPACIFIC PTE LTD",
     "TPSystemValue": 919
    },
    {
     
     "CodeDescription": "HITACHI GLOBAL STORAGE TECHN (S) PL",
     "TPSystemValue": 830
    },
    {
     
     "CodeDescription": "HITACHI INTERNATIONAL TREASURY LTD",
     "TPSystemValue": 854
    },
    {
     
     "CodeDescription": "HITACHI PLANT TECHNOLOGIES LTD",
     "TPSystemValue": 2817
    },
    {
     
     "CodeDescription": "HKT GLOBAL SINGAPORE PTE LTD",
     "TPSystemValue": 1169
    },
    {
     
     "CodeDescription": "HL BANK",
     "TPSystemValue": "H04"
    },
    {
     
     "CodeDescription": "HL SHIPBUILDING & ENGINEERING PL",
     "TPSystemValue": 1923
    },
    {
     
     "CodeDescription": "HL TRAVEL AND TOUR PTE. LTD.",
     "TPSystemValue": "H545"
    },
    {
     
     "CodeDescription": "HLT INTERNATIONAL PTE LTD",
     "TPSystemValue": 119
    },
    {
     
     "CodeDescription": "HNK TOURS PTE LTD",
     "TPSystemValue": "H203"
    },
    {
     
     "CodeDescription": "HO BEE INVESTMENT LTD",
     "TPSystemValue": 283
    },
    {
     
     "CodeDescription": "HOCK TAT POLYTHENE (PRIVATE) LIMITED",
     "TPSystemValue": 27
    },
    {
     
     "CodeDescription": "HOCK TRAVEL PTE LTD",
     "TPSystemValue": "H117"
    },
    {
     
     "CodeDescription": "HOERBIGER KT ASIA SERVICES PTE LTD",
     "TPSystemValue": 254
    },
    {
     
     "CodeDescription": "HOGAN LOVELLS INTERNATIONAL LLP",
     "TPSystemValue": 3643
    },
    {
     
     "CodeDescription": "HOGAN LOVELLS LEE LEE",
     "TPSystemValue": 3644
    },
    {
     
     "CodeDescription": "HOGARTH WORLDWIDE PTE LIMITED",
     "TPSystemValue": 1324
    },
    {
     
     "CodeDescription": "HOGG ROBINSON SINGAPORE PTE LTD",
     "TPSystemValue": "H312"
    },
    {
     
     "CodeDescription": "HOLCIM SINGAPORE PTE LTD",
     "TPSystemValue": 196
    },
    {
     
     "CodeDescription": "HOLIDAY TOURS & TRAVEL (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H126"
    },
    {
     
     "CodeDescription": "HOLIDAY TOURS & TRAVEL PTE. LTD.",
     "TPSystemValue": "H051"
    },
    {
     
     "CodeDescription": "HOLIDAYBAGUS TRAVEL SERVICES PTE LTD",
     "TPSystemValue": "H295"
    },
    {
     
     "CodeDescription": "HOLMAN FENWICK WILLIAN SINGAPORE LLP",
     "TPSystemValue": 3107
    },
    {
     
     "CodeDescription": "HOME BOX OFFICE (SINGAPORE) PTE LTD",
     "TPSystemValue": 402
    },
    {
     
     "CodeDescription": "HOMELAND CURRY FOOD PTE. LTD.",
     "TPSystemValue": 1233
    },
    {
     
     "CodeDescription": "HOMELAND FOOD SUPPLIES TRADING PL",
     "TPSystemValue": 1206
    },
    {
     
     "CodeDescription": "HOMETEAMNS",
     "TPSystemValue": 3645
    },
    {
     
     "CodeDescription": "HONEYWELL (SINGAPORE) PTE LTD",
     "TPSystemValue": 121
    },
    {
     
     "CodeDescription": "HONEYWELL PTE LTD",
     "TPSystemValue": 145
    },
    {
     
     "CodeDescription": "HONEYWORLD HOLIDAYS PTE LTD",
     "TPSystemValue": "H154"
    },
    {
     
     "CodeDescription": "HONG AN ENGINEERING PTE LTD",
     "TPSystemValue": 1924
    },
    {
     
     "CodeDescription": "HONG LEONG FINANCE",
     "TPSystemValue": 1925
    },
    {
     
     "CodeDescription": "HONG LEONG FINANCE LIMITED",
     "TPSystemValue": "H05"
    },
    {
     
     "CodeDescription": "HONG TECH CONSULTANT PTE LTD",
     "TPSystemValue": 633
    },
    {
     
     "CodeDescription": "HONG THAI TRAVEL SERVICES (S) PTE LTD",
     "TPSystemValue": "H129"
    },
    {
     
     "CodeDescription": "HONGDA HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H643"
    },
    {
     
     "CodeDescription": "HONGKONG LAND SINGAPORE PTE LTD",
     "TPSystemValue": 159
    },
    {
     
     "CodeDescription": "HORIZON FAST FERRY PTE. LTD.",
     "TPSystemValue": "H666"
    },
    {
     
     "CodeDescription": "HORIZON SHIPPING AGENCIES (S) PL",
     "TPSystemValue": 602
    },
    {
     
     "CodeDescription": "HOST HOTELS & RESORTS",
     "TPSystemValue": "H014"
    },
    {
     
     "CodeDescription": "HOT TOMATO @ SERANGOON PTE LTD",
     "TPSystemValue": 1216
    },
    {
     
     "CodeDescription": "HOTEL AIRLINES SERVICES PTE LTD",
     "TPSystemValue": "H255"
    },
    {
     
     "CodeDescription": "HOTEL GRAND CENTRAL",
     "TPSystemValue": "H021"
    },
    {
     
     "CodeDescription": "HOTEL PROPERTIES",
     "TPSystemValue": "H019"
    },
    {
     
     "CodeDescription": "HOTEL ROYAL",
     "TPSystemValue": "H018"
    },
    {
     
     "CodeDescription": "HOTELBEDS PTE. LTD.",
     "TPSystemValue": "H161"
    },
    {
     
     "CodeDescription": "HOUSING DEVELOPMENT BOARD",
     "TPSystemValue": 1926
    },
    {
     
     "CodeDescription": "HP ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1927
    },
    {
     
     "CodeDescription": "HP SINGAPORE",
     "TPSystemValue": 1928
    },
    {
     
     "CodeDescription": "HQSM ENGINEERING PTE LTD",
     "TPSystemValue": 1092
    },
    {
     
     "CodeDescription": "HR SOLUTIONS GROUP PTE LTD",
     "TPSystemValue": 1238
    },
    {
     
     "CodeDescription": "HSBC",
     "TPSystemValue": 1929
    },
    {
     
     "CodeDescription": "HSBC INSURANCE",
     "TPSystemValue": 1930
    },
    {
     
     "CodeDescription": "HSBC LIMITED, THE",
     "TPSystemValue": "H06"
    },
    {
     
     "CodeDescription": "HSBC PRIVATE BANK (SUISSE) SA",
     "TPSystemValue": "H07"
    },
    {
     
     "CodeDescription": "HSH NORDBANK AG",
     "TPSystemValue": "H08"
    },
    {
     
     "CodeDescription": "HSR",
     "TPSystemValue": 1931
    },
    {
     
     "CodeDescription": "HTC TRAVEL PTE. LTD.",
     "TPSystemValue": "H155"
    },
    {
     
     "CodeDescription": "HUA CHENG EDUCATION CENTRE PTE LTD",
     "TPSystemValue": 739
    },
    {
     
     "CodeDescription": "HUA NAN COMMERCIAL BANK LTD",
     "TPSystemValue": "H09"
    },
    {
     
     "CodeDescription": "HUA WEI TECHNOLOGY PTE LTD",
     "TPSystemValue": 3646
    },
    {
     
     "CodeDescription": "HUAMEI HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H813"
    },
    {
     
     "CodeDescription": "HUAWEI INTERNATIONAL PTE LTD",
     "TPSystemValue": 1122
    },
    {
     
     "CodeDescription": "HUB DISTRIBUTORS SERVICES PTE LTD",
     "TPSystemValue": 1932
    },
    {
     
     "CodeDescription": "HUB DISTRIBUTORS SERVICES PTE. LTD.",
     "TPSystemValue": "03H"
    },
    {
     
     "CodeDescription": "HUP ANN TRAVEL PTE LTD",
     "TPSystemValue": "H138"
    },
    {
     
     "CodeDescription": "HUP FATT BROTHERS ENGINEERING PTE LTD",
     "TPSystemValue": 312
    },
    {
     
     "CodeDescription": "HUP SIONG INDUSTRY",
     "TPSystemValue": 1353
    },
    {
     
     "CodeDescription": "HUP SOON LEE GLASS CONSTRUCTION",
     "TPSystemValue": 1381
    },
    {
     
     "CodeDescription": "HUTTONS ASIA PTE LTD",
     "TPSystemValue": 1933
    },
    {
     
     "CodeDescription": "HWA CHONG INSTITUTION",
     "TPSystemValue": 1934
    },
    {
     
     "CodeDescription": "HYATT HOTELS",
     "TPSystemValue": "H015"
    },
    {
     
     "CodeDescription": "HYBRID GLOCAL LLP (CARATO LLP)",
     "TPSystemValue": 3422
    },
    {
     
     "CodeDescription": "HYDROCHEM S PTE LTD",
     "TPSystemValue": 326
    },
    {
     
     "CodeDescription": "HYPEX ENGINEERING & SERVICES PTE LTD",
     "TPSystemValue": 850
    },
    {
     
     "CodeDescription": "HY-TECHLINK INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H753"
    },
    {
     
     "CodeDescription": "HYUNDAI ROTEM COMPANY",
     "TPSystemValue": 3075
    },
    {
     
     "CodeDescription": "I HOLIDAYS TRAVEL PRIVATE LIMITED",
     "TPSystemValue": "H786"
    },
    {
     
     "CodeDescription": "I TRAVELING PTE. LTD.",
     "TPSystemValue": "H267"
    },
    {
     
     "CodeDescription": "IAHGAMES PTE. LTD",
     "TPSystemValue": 1246
    },
    {
     
     "CodeDescription": "I-ASIA TRAVEL MANAGEMENT PTE. LTD.",
     "TPSystemValue": "H680"
    },
    {
     
     "CodeDescription": "IBIBO GROUP PTE. LIMITED",
     "TPSystemValue": "H824"
    },
    {
     
     "CodeDescription": "IBM SINGAPORE PTE LTD",
     "TPSystemValue": 108
    },
    {
     
     "CodeDescription": "IBS CONSULTING SERVICES",
     "TPSystemValue": 1419
    },
    {
     
     "CodeDescription": "ICAP AP SINGAPORE PTE LTD",
     "TPSystemValue": 78
    },
    {
     
     "CodeDescription": "ICHIRO FILM LLP",
     "TPSystemValue": 3115
    },
    {
     
     "CodeDescription": "ICICI BANK LIMITED",
     "TPSystemValue": "I01"
    },
    {
     
     "CodeDescription": "I-C-I-D PTE. LTD.",
     "TPSystemValue": "H466"
    },
    {
     
     "CodeDescription": "ICONNEXION ASIA PTE LTD",
     "TPSystemValue": 1127
    },
    {
     
     "CodeDescription": "IDEAL MARINE SERVICES (S) PTE LTD",
     "TPSystemValue": 277
    },
    {
     
     "CodeDescription": "IDIRECT ASIA PTE LTD",
     "TPSystemValue": 1315
    },
    {
     
     "CodeDescription": "IDS GROUP",
     "TPSystemValue": 3647
    },
    {
     
     "CodeDescription": "IDS MEDICAL SYSTEMS",
     "TPSystemValue": 1377
    },
    {
     
     "CodeDescription": "IFAST CAPITAL LTD",
     "TPSystemValue": 3648
    },
    {
     
     "CodeDescription": "IFAST CORPORATION PTE LTD",
     "TPSystemValue": 749
    },
    {
     
     "CodeDescription": "IFS CAPITAL LIMITED",
     "TPSystemValue": 278
    },
    {
     
     "CodeDescription": "IG ASIA PTE LTD",
     "TPSystemValue": 994
    },
    {
     
     "CodeDescription": "IHG LIMITED",
     "TPSystemValue": 273
    },
    {
     
     "CodeDescription": "IHIS PTE LTD",
     "TPSystemValue": 1935
    },
    {
     
     "CodeDescription": "IK CHIN TRAVEL SERVICE (S) PTE LTD",
     "TPSystemValue": "H073"
    },
    {
     
     "CodeDescription": "IKANO PTE LTD",
     "TPSystemValue": 170
    },
    {
     
     "CodeDescription": "IKAS INTERNATIONAL ASIA PTE LTD",
     "TPSystemValue": 1232
    },
    {
     
     "CodeDescription": "ILLINOIS AT SINGAPORE PTE LTD",
     "TPSystemValue": 1196
    },
    {
     
     "CodeDescription": "ILLUMINA SINGAPORE PTE LTD",
     "TPSystemValue": 896
    },
    {
     
     "CodeDescription": "IM FLASH SINGAPORE LLP",
     "TPSystemValue": 1936
    },
    {
     
     "CodeDescription": "IMATION SINGAPORE PTE LTD",
     "TPSystemValue": 551
    },
    {
     
     "CodeDescription": "IMH",
     "TPSystemValue": 1937
    },
    {
     
     "CodeDescription": "IMMIGRATION CHECKPOINTS AUTHORITY",
     "TPSystemValue": 3090
    },
    {
     
     "CodeDescription": "IMRB MILLWARD BROWN INTERNATIONAL PL",
     "TPSystemValue": 577
    },
    {
     
     "CodeDescription": "INCLUSION RESOURCES, PRIVATE LIMITED",
     "TPSystemValue": 1350
    },
    {
     
     "CodeDescription": "INCREDIBLE ODYSSEY PTE. LTD.",
     "TPSystemValue": "H735"
    },
    {
     
     "CodeDescription": "INDECO ENGINEERS (PTE) LTD",
     "TPSystemValue": "03I"
    },
    {
     
     "CodeDescription": "INDECO ENGINEERS PTE LTD",
     "TPSystemValue": 222
    },
    {
     
     "CodeDescription": "INTERNATIONAL EVENT PLANNERS PTE. LTD.",
     "TPSystemValue": "H736"
    },
    {
     
     "CodeDescription": "INTERNATIONAL INDENTING HOUSE",
     "TPSystemValue": "H034"
    },
    {
     
     "CodeDescription": "INTERNATIONAL PARADISE CONNEXIONS TOURS & TRAVELS PTE LTD",
     "TPSystemValue": "H270"
    },
    {
     
     "CodeDescription": "INTERSYS PTE LTD",
     "TPSystemValue": "H273"
    },
    {
     
     "CodeDescription": "INTERVAL INTERNATIONAL SINGAPORE (PTE) LTD",
     "TPSystemValue": "H205"
    },
    {
     
     "CodeDescription": "INTRACO TECHNOLOGY PTE LTD",
     "TPSystemValue": 3652
    },
    {
     
     "CodeDescription": "INTROTECH TRADING PTE LTD",
     "TPSystemValue": 1039
    },
    {
     
     "CodeDescription": "INVENIO HOLDINGS PTE LTD",
     "TPSystemValue": 1261
    },
    {
     
     "CodeDescription": "INVENSYS PROCESS SYSTEMS S PTE LTD",
     "TPSystemValue": 123
    },
    {
     
     "CodeDescription": "INVENTA TECHNOLOGIES (S) PTE LTD",
     "TPSystemValue": 911
    },
    {
     
     "CodeDescription": "IPP FINANCIAL ADVISERS PTE LTD",
     "TPSystemValue": 225
    },
    {
     
     "CodeDescription": "IPS GROUP PTE LTD",
     "TPSystemValue": 263
    },
    {
     
     "CodeDescription": "IPSOS SINGAPORE PTE LTD",
     "TPSystemValue": 3653
    },
    {
     
     "CodeDescription": "IRAS",
     "TPSystemValue": 1945
    },
    {
     
     "CodeDescription": "IRDETO PTE LTD",
     "TPSystemValue": 1183
    },
    {
     
     "CodeDescription": "IS SERVISYSTEM PTE LTD",
     "TPSystemValue": 943
    },
    {
     
     "CodeDescription": "ISE TRAVEL PTE. LTD.",
     "TPSystemValue": "H361"
    },
    {
     
     "CodeDescription": "ISETAN (SINGAPORE) LTD",
     "TPSystemValue": "07I"
    },
    {
     
     "CodeDescription": "ISETAN SINGAPORE LIMITED",
     "TPSystemValue": 68
    },
    {
     
     "CodeDescription": "ISHIMA PTE LTD",
     "TPSystemValue": 992
    },
    {
     
     "CodeDescription": "ISLAMIC BANK OF ASIA LIMITED, THE",
     "TPSystemValue": "I07"
    },
    {
     
     "CodeDescription": "ISS CONSULTING S PTE LTD",
     "TPSystemValue": 670
    },
    {
     
     "CodeDescription": "ISS FACILITY SERVICES PTRIVATE LIMITED",
     "TPSystemValue": 77
    },
    {
     
     "CodeDescription": "ISSATIS TOURS & TRAVEL PTE. LTD.",
     "TPSystemValue": "H817"
    },
    {
     
     "CodeDescription": "IT TECHNOLOGIES PTE LTD",
     "TPSystemValue": 564
    },
    {
     
     "CodeDescription": "ITCAN PTE LIMITED",
     "TPSystemValue": 947
    },
    {
     
     "CodeDescription": "ITE",
     "TPSystemValue": 1946
    },
    {
     
     "CodeDescription": "ITECH VISION PTE LTD",
     "TPSystemValue": 731
    },
    {
     
     "CodeDescription": "ITFORCE SINGAPORE PTE LTD NCS",
     "TPSystemValue": 1228
    },
    {
     
     "CodeDescription": "ITL HARDWARE & ENGINEERING SUPPLIES PL",
     "TPSystemValue": "08I"
    },
    {
     
     "CodeDescription": "ITL HARDWARE ENGINEERING SUPPLIES PL",
     "TPSystemValue": 391
    },
    {
     
     "CodeDescription": "ITRON PTE LTD",
     "TPSystemValue": 753
    },
    {
     
     "CodeDescription": "ITS SCIENCE & MEDICAL PTE LTD",
     "TPSystemValue": 1947
    },
    {
     
     "CodeDescription": "ITT GLOBAL LEARNING PTE. LTD.",
     "TPSystemValue": "H297"
    },
    {
     
     "CodeDescription": "ITW GSE GROUP APAC S PTE LTD",
     "TPSystemValue": 1327
    },
    {
     
     "CodeDescription": "IZZY HOLIDAYS (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H732"
    },
    {
     
     "CodeDescription": "J & L VACATION PTE. LTD.",
     "TPSystemValue": "H734"
    },
    {
     
     "CodeDescription": "J AND J TRAVEL PTE. LTD.",
     "TPSystemValue": "H410"
    },
    {
     
     "CodeDescription": "J V FITNESS PTE LTD",
     "TPSystemValue": 1948
    },
    {
     
     "CodeDescription": "J. WALTER THOMPSON (SINGAPORE) PTE LTD",
     "TPSystemValue": 142
    },
    {
     
     "CodeDescription": "J.B. RUPA & CO (PTE) LTD",
     "TPSystemValue": 154
    },
    {
     
     "CodeDescription": "JA TRAVEL PTE LTD",
     "TPSystemValue": "H245"
    },
    {
     
     "CodeDescription": "JAC RECRUITMENT PTE LTD",
     "TPSystemValue": 276
    },
    {
     
     "CodeDescription": "JAC TRAVEL PTE LTD",
     "TPSystemValue": "H059"
    },
    {
     
     "CodeDescription": "JACCO TOURS (S) PRIVATE LIMITED",
     "TPSystemValue": "H832"
    },
    {
     
     "CodeDescription": "JACOBS ENGINEERING SINGAPORE PTE LTD",
     "TPSystemValue": 657
    },
    {
     
     "CodeDescription": "JACQTOURS PTE. LTD.",
     "TPSystemValue": "H685"
    },
    {
     
     "CodeDescription": "JALALUDDIN TRAVEL & SERVICES PTE. LTD.",
     "TPSystemValue": "H490"
    },
    {
     
     "CodeDescription": "JAP AIR TOURS PTE. LTD.",
     "TPSystemValue": "H374"
    },
    {
     
     "CodeDescription": "JAPAN AE POWER SYSTEMS ASIA PTE LTD",
     "TPSystemValue": 939
    },
    {
     
     "CodeDescription": "JAPAN PORK SUPPLIER PTE LTD",
     "TPSystemValue": 1267
    },
    {
     
     "CodeDescription": "JAPAN TOURS (PRIVATE) LIMITED",
     "TPSystemValue": "H046"
    },
    {
     
     "CodeDescription": "JARDINE CYCLE AND CARRIAGE LIMITED",
     "TPSystemValue": 50
    },
    {
     
     "CodeDescription": "JARDINE LLYOD THOMPSON ASIA PTE LTD",
     "TPSystemValue": 659
    },
    {
     
     "CodeDescription": "JARDINE LLYOD THOMPSON PRIVATE LIMITED",
     "TPSystemValue": 51
    },
    {
     
     "CodeDescription": "JARDINE ONESOLUTION 2001 PTE LTD",
     "TPSystemValue": 229
    },
    {
     
     "CodeDescription": "JARDINE SHIPPING AGENCIES",
     "TPSystemValue": 1354
    },
    {
     
     "CodeDescription": "JAS CONSULTANTS PTE LTD",
     "TPSystemValue": 990
    },
    {
     
     "CodeDescription": "JASONS TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H091"
    },
    {
     
     "CodeDescription": "JAVA MARINE LINES PTE LTD",
     "TPSystemValue": 186
    },
    {
     
     "CodeDescription": "JAYA SHIPBUILDING AND ENGINEERING PL",
     "TPSystemValue": 73
    },
    {
     
     "CodeDescription": "JAYANTI ISPAT (SINGAPORE) PTE LTD",
     "TPSystemValue": 1044
    },
    {
     
     "CodeDescription": "JDSU T&M SINGAPORE PTE. LTD.",
     "TPSystemValue": 544
    },
    {
     
     "CodeDescription": "JE ANN INTERNATIONAL PTE LTD",
     "TPSystemValue": "H339"
    },
    {
     
     "CodeDescription": "JEBSEN JESSEN COMMUNICATION S PL",
     "TPSystemValue": 375
    },
    {
     
     "CodeDescription": "JEFF S MARINE & ENGINEERING PTE LTD",
     "TPSystemValue": 1949
    },
    {
     
     "CodeDescription": "JESAL BROTHERS TOURS & TRAVEL PTE LTD",
     "TPSystemValue": "H089"
    },
    {
     
     "CodeDescription": "JESSICA HOLIDAY PTE. LTD.",
     "TPSystemValue": "H773"
    },
    {
     
     "CodeDescription": "JET POWER MARINE SERVICES",
     "TPSystemValue": 1950
    },
    {
     
     "CodeDescription": "JETBLUE AIRWAYS",
     "TPSystemValue": "H029"
    },
    {
     
     "CodeDescription": "JETCHOICE PTE. LTD.",
     "TPSystemValue": "H816"
    },
    {
     
     "CodeDescription": "JETSPEED TRAVEL PTE LTD",
     "TPSystemValue": "H083"
    },
    {
     
     "CodeDescription": "JETSTAR ASIA AIRWAYS PTE LTD",
     "TPSystemValue": 918
    },
    {
     
     "CodeDescription": "JETSTAR REGIONAL SERVICES PTE. LTD.",
     "TPSystemValue": "H681"
    },
    {
     
     "CodeDescription": "JETWINGS INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H373"
    },
    {
     
     "CodeDescription": "JGC SINGAPORE PTE LTD",
     "TPSystemValue": 1951
    },
    {
     
     "CodeDescription": "JHJ INTERNATIONAL LOGISTICS (S) PTE LTD",
     "TPSystemValue": 910
    },
    {
     
     "CodeDescription": "JIANGTONG INTERNATIONAL SINGAPORE PTELTD",
     "TPSystemValue": 3654
    },
    {
     
     "CodeDescription": "JIE YUN TONG TRAVEL PTE. LTD.",
     "TPSystemValue": "H351"
    },
    {
     
     "CodeDescription": "JIN CHOON CIVIL ENGINEERING PTE LTD",
     "TPSystemValue": "02J"
    },
    {
     
     "CodeDescription": "JIN CHOON ENGINEERING",
     "TPSystemValue": 1952
    },
    {
     
     "CodeDescription": "JLT INTERACTIVE PTE LTD",
     "TPSystemValue": 977
    },
    {
     
     "CodeDescription": "JLT SPECIALTY PTE LTD",
     "TPSystemValue": 3655
    },
    {
     
     "CodeDescription": "JM FINANCIAL SINGAPORE PTE LTD",
     "TPSystemValue": 1321
    },
    {
     
     "CodeDescription": "JML DESIGN & CONTRACT",
     "TPSystemValue": 1372
    },
    {
     
     "CodeDescription": "JNF TRAVELS (PTE.) LTD.",
     "TPSystemValue": "H674"
    },
    {
     
     "CodeDescription": "JNP TRAVEL PTE. LTD.",
     "TPSystemValue": "H415"
    },
    {
     
     "CodeDescription": "JOAQUIM TRAVELAND PTE. LTD.",
     "TPSystemValue": "H370"
    },
    {
     
     "CodeDescription": "JOBLINE RESOURCES PTE LTD",
     "TPSystemValue": 886
    },
    {
     
     "CodeDescription": "JOBSOURCE INTERNATIONALE PTE. LTD.",
     "TPSystemValue": 1318
    },
    {
     
     "CodeDescription": "JOE S TAILORING AND FASHION DESIGN",
     "TPSystemValue": 1358
    },
    {
     
     "CodeDescription": "JOHN LEW HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H439"
    },
    {
     
     "CodeDescription": "JOHNASIA SHIPPING (S) PTE. LTD.",
     "TPSystemValue": "H335"
    },
    {
     
     "CodeDescription": "JOHNSON & JOHNSON",
     "TPSystemValue": 1954
    },
    {
     
     "CodeDescription": "JOHNSON CONTROLS S PTE LTD",
     "TPSystemValue": 210
    },
    {
     
     "CodeDescription": "JONES LANG LASALLE PROPERTY CONSULTANT",
     "TPSystemValue": 174
    },
    {
     
     "CodeDescription": "JOSCO GSA TRAVEL PTE. LTD.",
     "TPSystemValue": "H558"
    },
    {
     
     "CodeDescription": "JOURNEYS PTE LTD",
     "TPSystemValue": "H111"
    },
    {
     
     "CodeDescription": "JOY TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H548"
    },
    {
     
     "CodeDescription": "JOYALUKKAS JEWELLERY INTERNATIONAL PL",
     "TPSystemValue": 851
    },
    {
     
     "CodeDescription": "JP INTERNATIONAL TRAVEL PTE. LTD.",
     "TPSystemValue": "H427"
    },
    {
     
     "CodeDescription": "JP MORGAN",
     "TPSystemValue": 1956
    },
    {
     
     "CodeDescription": "JP SUPPLIER PTE LTD",
     "TPSystemValue": 995
    },
    {
     
     "CodeDescription": "JPMORGAN CHASE BANK, N.A.",
     "TPSystemValue": "J01"
    },
    {
     
     "CodeDescription": "JQ TRAVEL & TRANSPORT PTE. LTD.",
     "TPSystemValue": "H702"
    },
    {
     
     "CodeDescription": "JTB PTE LTD",
     "TPSystemValue": "H145"
    },
    {
     
     "CodeDescription": "JTC CORPORATION",
     "TPSystemValue": 1958
    },
    {
     
     "CodeDescription": "JTC SINGAPORE PTE LTD",
     "TPSystemValue": 3656
    },
    {
     
     "CodeDescription": "JU JU JAPANESE HOTPOT",
     "TPSystemValue": "06J"
    },
    {
     
     "CodeDescription": "JUN YI ENTERPRISE PTE LTD",
     "TPSystemValue": 664
    },
    {
     
     "CodeDescription": "JUN-AIR TRAVEL PTE LTD",
     "TPSystemValue": "H168"
    },
    {
     
     "CodeDescription": "JUPITER TRAVELS AND TOURS PTE. LTD.",
     "TPSystemValue": "H739"
    },
    {
     
     "CodeDescription": "JURONG CONSULTANTS PTE LTD",
     "TPSystemValue": 585
    },
    {
     
     "CodeDescription": "JURONG DISTRICENTRE PTE LTD",
     "TPSystemValue": 380
    },
    {
     
     "CodeDescription": "JURONG ENGINEERING LIMITED",
     "TPSystemValue": 964
    },
    {
     
     "CodeDescription": "JURONG HEALTH SERVICES PTE LTD",
     "TPSystemValue": 1224
    },
    {
     
     "CodeDescription": "JURONG ICEWORKS PTE LTD",
     "TPSystemValue": 1959
    },
    {
     
     "CodeDescription": "JURONG INTERNATIONAL HOLDINGS PTE LTD",
     "TPSystemValue": 764
    },
    {
     
     "CodeDescription": "JURONG POINT REALTY LIMITED",
     "TPSystemValue": 440
    },
    {
     
     "CodeDescription": "JURONG PORT PTE LTD",
     "TPSystemValue": 1960
    },
    {
     
     "CodeDescription": "JURONG SHIPYARD PTE LTD",
     "TPSystemValue": 1961
    },
    {
     
     "CodeDescription": "JUST TRAVEL PTE. LTD.",
     "TPSystemValue": "H434"
    },
    {
     
     "CodeDescription": "JV FITNESS PTE LTD",
     "TPSystemValue": 617
    },
    {
     
     "CodeDescription": "JVC ELECTRONICS SINGAPORE PTE LTD",
     "TPSystemValue": 138
    },
    {
     
     "CodeDescription": "K & C HAN SING TRAVEL PTE LTD",
     "TPSystemValue": "H178"
    },
    {
     
     "CodeDescription": "K H TEH CONSULTING PTE LTD",
     "TPSystemValue": 1962
    },
    {
     
     "CodeDescription": "K K WOMEN & CHILDEREN HOSPITAL",
     "TPSystemValue": 1963
    },
    {
     
     "CodeDescription": "K LINE PTE LTD",
     "TPSystemValue": 1964
    },
    {
     
     "CodeDescription": "K.G.M. BROTHERS CONTRACTORS PTE LTD",
     "TPSystemValue": "01K"
    },
    {
     
     "CodeDescription": "K2 SPECIALIST SERVICE PTE LTD",
     "TPSystemValue": 1966
    },
    {
     
     "CodeDescription": "KABAYAN TRAVEL PTE LTD",
     "TPSystemValue": "H280"
    },
    {
     
     "CodeDescription": "KAH MOTOR COMPANY SDN BHD",
     "TPSystemValue": 1967
    },
    {
     
     "CodeDescription": "KAJIMA OVERSEA ASIA PTE LTD",
     "TPSystemValue": 1969
    },
    {
     
     "CodeDescription": "KALEIDO MARKETING COMMUNICATION PTE LTD",
     "TPSystemValue": 1970
    },
    {
     
     "CodeDescription": "KAN ENGINEERING PTE LTD",
     "TPSystemValue": 1971
    },
    {
     
     "CodeDescription": "KAN ENGINEERING PTE. LTD.",
     "TPSystemValue": "03K"
    },
    {
     
     "CodeDescription": "KARANA TRAVEL SERVICES PTE LTD",
     "TPSystemValue": "H277"
    },
    {
     
     "CodeDescription": "KARMEL INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H754"
    },
    {
     
     "CodeDescription": "KAV SERVICES",
     "TPSystemValue": 1972
    },
    {
     
     "CodeDescription": "KBC BANK N.V.",
     "TPSystemValue": "K01"
    },
    {
     
     "CodeDescription": "KBC BANK NV",
     "TPSystemValue": 1973
    },
    {
     
     "CodeDescription": "KBR",
     "TPSystemValue": 1974
    },
    {
     
     "CodeDescription": "KDS MARINE",
     "TPSystemValue": 1975
    },
    {
     
     "CodeDescription": "KEIO-SCA INTERNATIONAL PTE LTD",
     "TPSystemValue": "H041"
    },
    {
     
     "CodeDescription": "KELLOGG BROWN & ROOT ASIA PACIFIC PL",
     "TPSystemValue": 1977
    },
    {
     
     "CodeDescription": "KELLY SERVICES SINGAPORE PTE LTD",
     "TPSystemValue": 1978
    },
    {
     
     "CodeDescription": "KELVIN CHIA PARTNERSHIP",
     "TPSystemValue": 1979
    },
    {
     
     "CodeDescription": "KENT HOLIDAYS (S) PTE LTD",
     "TPSystemValue": "H104"
    },
    {
     
     "CodeDescription": "KENWOOD ELECTRONICS TECHNOLOGY S PTE LTD",
     "TPSystemValue": 1980
    },
    {
     
     "CodeDescription": "KEPPEL BAY PTE LTD",
     "TPSystemValue": 3657
    },
    {
     
     "CodeDescription": "KEPPEL CORPORATION LIMITED",
     "TPSystemValue": 1981
    },
    {
     
     "CodeDescription": "KEPPEL ENGINEERING PTE LTD",
     "TPSystemValue": 1982
    },
    {
     
     "CodeDescription": "KEPPEL FELS",
     "TPSystemValue": 1983
    },
    {
     
     "CodeDescription": "KEPPEL LAND INTERNATIONAL LIMITED",
     "TPSystemValue": 1984
    },
    {
     
     "CodeDescription": "KEPPEL LAND LIMITED",
     "TPSystemValue": 1985
    },
    {
     
     "CodeDescription": "KEPPEL LOGISTICS PTE LTD",
     "TPSystemValue": 1986
    },
    {
     
     "CodeDescription": "KEPPEL OFFSHORE & MARINE TECHNOLOGY CENTRE PTE LTD",
     "TPSystemValue": 1988
    },
    {
     
     "CodeDescription": "KEPPEL OFFSHORE MARINE PTE LTD",
     "TPSystemValue": 1987
    },
    {
     
     "CodeDescription": "KEPPEL SHIPYARD LIMITED",
     "TPSystemValue": 1989
    },
    {
     
     "CodeDescription": "KEPPEL SINGAPROE PTE LTD",
     "TPSystemValue": 1990
    },
    {
     
     "CodeDescription": "KEPPEL SINGMARINE PTE LTD",
     "TPSystemValue": 1991
    },
    {
     
     "CodeDescription": "KEPPEL T&T LTD",
     "TPSystemValue": 1992
    },
    {
     
     "CodeDescription": "KEPPEL TATLEE BANK LTD",
     "TPSystemValue": 1993
    },
    {
     
     "CodeDescription": "KEPPEL TELECOMMUNICATIONS TRANSPORTATION LTD",
     "TPSystemValue": 1994
    },
    {
     
     "CodeDescription": "KEPPEL TUAS PTE LTD",
     "TPSystemValue": 3658
    },
    {
     
     "CodeDescription": "KEPPELS FELS LIMITED",
     "TPSystemValue": 1995
    },
    {
     
     "CodeDescription": "KERNEL OIL PTE LTD",
     "TPSystemValue": 1996
    },
    {
     
     "CodeDescription": "KERRY ITS TERMINAL PTE LTD",
     "TPSystemValue": 1997
    },
    {
     
     "CodeDescription": "KF PROPERTY NETWORK PTE LTD",
     "TPSystemValue": 1998
    },
    {
     
     "CodeDescription": "KFL INTERNATIONAL LOGISTICS PTE LTD",
     "TPSystemValue": 1999
    },
    {
     
     "CodeDescription": "KFL INTERNATIONAL LOGISTICS PTE. LTD.",
     "TPSystemValue": "05K"
    },
    {
     
     "CodeDescription": "KGM BROTHERS CONTRACTORS PTE LTD",
     "TPSystemValue": 1965
    },
    {
     
     "CodeDescription": "KHATTARWONG",
     "TPSystemValue": 2000
    },
    {
     
     "CodeDescription": "KHENG KENG AUTO PTE LTD",
     "TPSystemValue": 2001
    },
    {
     
     "CodeDescription": "KHENG KENG AUTO PTE. LTD.",
     "TPSystemValue": "06K"
    },
    {
     
     "CodeDescription": "KHL MARKETING ASIA-PACIFIC LTD",
     "TPSystemValue": 2002
    },
    {
     
     "CodeDescription": "KHL MARKETING ASIA-PACIFIC PTE LTD",
     "TPSystemValue": "07K"
    },
    {
     
     "CodeDescription": "KHOO TEAK PUAT HOSPITAL",
     "TPSystemValue": 2003
    },
    {
     
     "CodeDescription": "KIAN ANN ENGINEERING LTD",
     "TPSystemValue": 2004
    },
    {
     
     "CodeDescription": "KIAN HO BEARINGS LTD",
     "TPSystemValue": 2005
    },
    {
     
     "CodeDescription": "KIM CHOO HOLDINGS PTE. LTD.",
     "TPSystemValue": "H356"
    },
    {
     
     "CodeDescription": "KIM ENG",
     "TPSystemValue": 2006
    },
    {
     
     "CodeDescription": "KIM ENG SECURITIESMBB KIM ENG SECURITIES NOMINEES",
     "TPSystemValue": 2007
    },
    {
     
     "CodeDescription": "KIM THAI MARINE PTE LTD",
     "TPSystemValue": 2008
    },
    {
     
     "CodeDescription": "KIM THAI MARINE PTE. LTD.",
     "TPSystemValue": "08K"
    },
    {
     
     "CodeDescription": "KIM TRAVEL SERVICE PTE LTD",
     "TPSystemValue": "H084"
    },
    {
     
     "CodeDescription": "KIM YEW ELECTRICAL & SANITARY PTE LTD",
     "TPSystemValue": 2009
    },
    {
     
     "CodeDescription": "KIMBERLY CLAIR PTE LTD",
     "TPSystemValue": 2010
    },
    {
     
     "CodeDescription": "KIMBERLYCLARK ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2011
    },
    {
     
     "CodeDescription": "KIN XIN ENGINEERING PTE LTD",
     "TPSystemValue": 2012
    },
    {
     
     "CodeDescription": "KINDLY TRAVEL EXPRESS PTE. LTD.",
     "TPSystemValue": "H687"
    },
    {
     
     "CodeDescription": "KINDNESS EDUCATION GROUP SINGAPORE PTE. LTD.",
     "TPSystemValue": "H627"
    },
    {
     
     "CodeDescription": "KINERGY LTD",
     "TPSystemValue": 2013
    },
    {
     
     "CodeDescription": "KINGEVER PTE. LTD.",
     "TPSystemValue": "H823"
    },
    {
     
     "CodeDescription": "KINGSLAND DEVELOPMENT PTE LTD",
     "TPSystemValue": 2014
    },
    {
     
     "CodeDescription": "KINGSMEN EXHIBITS PTE LTD",
     "TPSystemValue": 2015
    },
    {
     
     "CodeDescription": "KINOKUNIYA BOOKSTORE",
     "TPSystemValue": 2016
    },
    {
     
     "CodeDescription": "KK WOMENS AND CHILDRENS HOSPITAL",
     "TPSystemValue": 2017
    },
    {
     
     "CodeDescription": "KKH",
     "TPSystemValue": 2018
    },
    {
     
     "CodeDescription": "KKKL TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H722"
    },
    {
     
     "CodeDescription": "KLATENCOR SINGAPORE PTE LTD",
     "TPSystemValue": 2019
    },
    {
     
     "CodeDescription": "KLM",
     "TPSystemValue": 2020
    },
    {
     
     "CodeDescription": "KLM ROYAL DUTCH AIRLINES",
     "TPSystemValue": 2021
    },
    {
     
     "CodeDescription": "KMLINK PTE LTD",
     "TPSystemValue": 2022
    },
    {
     
     "CodeDescription": "KMP PTE LTD",
     "TPSystemValue": "09K"
    },
    {
     
     "CodeDescription": "KMP PTE LTD LAMIPAK KMP PTE LTD",
     "TPSystemValue": 2023
    },
    {
     
     "CodeDescription": "KMS TRAVEL PTE. LTD.",
     "TPSystemValue": "H381"
    },
    {
     
     "CodeDescription": "KN JURONG TRAVEL PTE. LTD.",
     "TPSystemValue": "H779"
    },
    {
     
     "CodeDescription": "KNIGHT AUTO PRECISION ENGRG PTE LTD",
     "TPSystemValue": 2024
    },
    {
     
     "CodeDescription": "KNIGHT FRANK ESTATE MANAGEMENT",
     "TPSystemValue": 2025
    },
    {
     
     "CodeDescription": "KNIGHT FRANK PROPERTY NETWORK PTE LTD",
     "TPSystemValue": 2026
    },
    {
     
     "CodeDescription": "KNIGHT FRANK PTE LTD",
     "TPSystemValue": 2027
    },
    {
     
     "CodeDescription": "KNOTS SOLUTIONS PRIVATE LIMITED SINGAPORE BRANCH",
     "TPSystemValue": 2028
    },
    {
     
     "CodeDescription": "KNOWLEDGE CONNECTION PTE LTD",
     "TPSystemValue": 2029
    },
    {
     
     "CodeDescription": "KODAK SINGAPORE PTE LIMITED",
     "TPSystemValue": 2030
    },
    {
     
     "CodeDescription": "KOH BROTHERS BUILDING & CIVIL ENGINEERIN",
     "TPSystemValue": 2031
    },
    {
     
     "CodeDescription": "KOI CAFE GROUP (S) PTE. LTD.",
     "TPSystemValue": "10K"
    },
    {
     
     "CodeDescription": "KOI CAFE GROUP(S) PTE LTD",
     "TPSystemValue": 2032
    },
    {
     
     "CodeDescription": "KOI MING ENTERPRISE PTE LTD",
     "TPSystemValue": 2033
    },
    {
     
     "CodeDescription": "KOI MING ENTERPRISE PTE. LTD.",
     "TPSystemValue": "11K"
    },
    {
     
     "CodeDescription": "KOINOAIR TRAVEL AND TOURS PTE LTD",
     "TPSystemValue": "H473"
    },
    {
     
     "CodeDescription": "KOK CHANG MARINE SERVICES PTE LTD",
     "TPSystemValue": 2034
    },
    {
     
     "CodeDescription": "KOK CHANG SCAFFOLDING PTE LTD",
     "TPSystemValue": 2035
    },
    {
     
     "CodeDescription": "KOK TANG CONSTRUCTION PTE LTD",
     "TPSystemValue": 2036
    },
    {
     
     "CodeDescription": "KOMALA S",
     "TPSystemValue": 2037
    },
    {
     
     "CodeDescription": "KOMALA S PTE LTD",
     "TPSystemValue": 2038
    },
    {
     
     "CodeDescription": "KOMOLO MOTORCYCLES PTE LTD",
     "TPSystemValue": 2039
    },
    {
     
     "CodeDescription": "KONE PTE LTD",
     "TPSystemValue": 2040
    },
    {
     
     "CodeDescription": "KONG SAM HO TRAVEL SERVICE PTE LTD",
     "TPSystemValue": "H149"
    },
    {
     
     "CodeDescription": "KONGSBERS MARITIME (S) PTE LTD",
     "TPSystemValue": 2041
    },
    {
     
     "CodeDescription": "KOON CONSTRUCTION & TRANSPORT CO PTE LTD",
     "TPSystemValue": 2042
    },
    {
     
     "CodeDescription": "KOPITIAM INVESTMENT PTE LTD",
     "TPSystemValue": 2043
    },
    {
     
     "CodeDescription": "KOREA DEVELOPMENT BANK, THE",
     "TPSystemValue": "K02"
    },
    {
     
     "CodeDescription": "KOREA EXCHANGE BANK",
     "TPSystemValue": "K03"
    },
    {
     
     "CodeDescription": "KOREA INTERNATIONAL TRAVEL COMPANY (SINGAPORE) PTE LTD",
     "TPSystemValue": "H268"
    },
    {
     
     "CodeDescription": "KPK QUANTITY SURVEYORS (SINGAPORE) PTE LTD",
     "TPSystemValue": 2044
    },
    {
     
     "CodeDescription": "KPMG",
     "TPSystemValue": 2045
    },
    {
     
     "CodeDescription": "KPMG ADVISORY LLP",
     "TPSystemValue": 3660
    },
    {
     
     "CodeDescription": "KPMG LLP KPMG ADVISORY SERVICES PTE LTD",
     "TPSystemValue": 2046
    },
    {
     
     "CodeDescription": "KPMG SERVICES PTE LTD",
     "TPSystemValue": 2047
    },
    {
     
     "CodeDescription": "KRAFT FOOD ASIA PACIFIC",
     "TPSystemValue": 2048
    },
    {
     
     "CodeDescription": "KREUZ SUBSEA MARINE PTE LTD",
     "TPSystemValue": 2049
    },
    {
     
     "CodeDescription": "KREUZ SUBSEA PTE LTD",
     "TPSystemValue": 2050
    },
    {
     
     "CodeDescription": "KRIS INFOTECH",
     "TPSystemValue": 2051
    },
    {
     
     "CodeDescription": "KRISH TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "H566"
    },
    {
     
     "CodeDescription": "KS DISTRIBUTION PTE LTD",
     "TPSystemValue": 2053
    },
    {
     
     "CodeDescription": "KS DRILLING PTE LTD",
     "TPSystemValue": 3661
    },
    {
     
     "CodeDescription": "KS ENERGY LIMITED",
     "TPSystemValue": 3662
    },
    {
     
     "CodeDescription": "KS FLOW CONTROL PTE LTD",
     "TPSystemValue": 2054
    },
    {
     
     "CodeDescription": "KSB SINGAPORE ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2055
    },
    {
     
     "CodeDescription": "KSB SPORE(ASIA PACIFIC) PL",
     "TPSystemValue": 2056
    },
    {
     
     "CodeDescription": "KSCGP JURIS LLP",
     "TPSystemValue": 2057
    },
    {
     
     "CodeDescription": "KSP INFOSYSTEM PTE LTD",
     "TPSystemValue": 2058
    },
    {
     
     "CodeDescription": "KSR RESTAURANT & CATERING SERVICES",
     "TPSystemValue": 2059
    },
    {
     
     "CodeDescription": "KTL GLOBAL LIMITED",
     "TPSystemValue": 2060
    },
    {
     
     "CodeDescription": "KTL OFFSHORE PTE LTD",
     "TPSystemValue": 2061
    },
    {
     
     "CodeDescription": "KUEHNE + NAGEL (ASIA PACIFIC) MANAGEMENT PTE. LTD.",
     "TPSystemValue": "12K"
    },
    {
     
     "CodeDescription": "KUEHNE NAGEL ASIA PACIFIC MANAGEMENT PTE LTD",
     "TPSystemValue": 2062
    },
    {
     
     "CodeDescription": "KUEHNE NAGEL PTE LTD",
     "TPSystemValue": 2063
    },
    {
     
     "CodeDescription": "KULICKE & SOFFA PTE LTD",
     "TPSystemValue": 2065
    },
    {
     
     "CodeDescription": "KULICKE SOFFA HOLDING COMPANY PTE LTD",
     "TPSystemValue": 2064
    },
    {
     
     "CodeDescription": "KUOK (SINGAPORE) LIMITED",
     "TPSystemValue": 2066
    },
    {
     
     "CodeDescription": "KUONI GTS (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H240"
    },
    {
     
     "CodeDescription": "KURIHARA KOGYO CO LTD",
     "TPSystemValue": 2067
    },
    {
     
     "CodeDescription": "KWE-KINTETSU WORLD EXPRESS (S) PTE LTD",
     "TPSystemValue": 2068
    },
    {
     
     "CodeDescription": "KWONG NGEE ENGINEERING PTE LTD",
     "TPSystemValue": 2069
    },
    {
     
     "CodeDescription": "KWONG WAI SHUI HOSPITAL",
     "TPSystemValue": 2070
    },
    {
     
     "CodeDescription": "KYOCERA ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2071
    },
    {
     
     "CodeDescription": "KYOCERA DOCUMENT SOLUTIONS SINGAPORE PTE LTD",
     "TPSystemValue": 3663
    },
    {
     
     "CodeDescription": "L & RUI CONCEPT GROUP PTE LTD",
     "TPSystemValue": 2072
    },
    {
     
     "CodeDescription": "L CAPITAL SINGAPORE PTE LTD",
     "TPSystemValue": 2073
    },
    {
     
     "CodeDescription": "L&RUI CONCEPT GROUP PTE.LTD.",
     "TPSystemValue": "01L"
    },
    {
     
     "CodeDescription": "L.G.E TRAVEL PTE. LTD.",
     "TPSystemValue": "H835"
    },
    {
     
     "CodeDescription": "LAI LAI CONSTRUCTION PTE LTD",
     "TPSystemValue": 2075
    },
    {
     
     "CodeDescription": "LAI LING CONSTRUCTION PTE LTD",
     "TPSystemValue": 2076
    },
    {
     
     "CodeDescription": "LAI METALS PTE LTD",
     "TPSystemValue": 2077
    },
    {
     
     "CodeDescription": "LAM RESEARCH SINGAPORE PTE LTD",
     "TPSystemValue": 2078
    },
    {
     
     "CodeDescription": "LAM SOON (S) PTE LTD",
     "TPSystemValue": 2079
    },
    {
     
     "CodeDescription": "LANCO ENTERPRISE PL LANCO INTENATIONAL PL",
     "TPSystemValue": 2080
    },
    {
     
     "CodeDescription": "LAND BANK OF TAIWAN",
     "TPSystemValue": "L01"
    },
    {
     
     "CodeDescription": "LAND TRANPORT AUTHORITY",
     "TPSystemValue": 2081
    },
    {
     
     "CodeDescription": "LAND TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "H512"
    },
    {
     
     "CodeDescription": "LANDESBANK BADEN-WURTTEMBERG",
     "TPSystemValue": "L02"
    },
    {
     
     "CodeDescription": "LANGMAN HOLIDAY PTE. LTD.",
     "TPSystemValue": "H796"
    },
    {
     
     "CodeDescription": "LANTIQ ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2082
    },
    {
     
     "CodeDescription": "LANTRO (S) PTE LTD",
     "TPSystemValue": 2083
    },
    {
     
     "CodeDescription": "LANTROVISION (S) LTD",
     "TPSystemValue": 2084
    },
    {
     
     "CodeDescription": "LANXESS PTE LTD",
     "TPSystemValue": 2085
    },
    {
     
     "CodeDescription": "LAO CHEAH AUTO SERVICES & TRADING PTE LTD",
     "TPSystemValue": 2086
    },
    {
     
     "CodeDescription": "LAPAN LAPAN TRAVEL PTE LTD",
     "TPSystemValue": "H207"
    },
    {
     
     "CodeDescription": "LAS VEGAS ENTERTAINMENT PTE. LTD.",
     "TPSystemValue": "H586"
    },
    {
     
     "CodeDescription": "LASALLE COLLEGE OF THE ARTS LIMITED",
     "TPSystemValue": 2087
    },
    {
     
     "CodeDescription": "LASALLE INVESTMENT MANAGEMENT",
     "TPSystemValue": 2088
    },
    {
     
     "CodeDescription": "LASIA INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H422"
    },
    {
     
     "CodeDescription": "LATTICE SG PTE LTD",
     "TPSystemValue": 2089
    },
    {
     
     "CodeDescription": "LAWRENCE QUAHE & WOO LLC",
     "TPSystemValue": "02L"
    },
    {
     
     "CodeDescription": "LAWRENCE QUAHE WOO LLC",
     "TPSystemValue": 2090
    },
    {
     
     "CodeDescription": "LBG-NGT CORPORATION PTE LTD",
     "TPSystemValue": 2091
    },
    {
     
     "CodeDescription": "LC TRAVEL PLANNERS PTE LTD",
     "TPSystemValue": 2092
    },
    {
     
     "CodeDescription": "LDR PTE. LTD.",
     "TPSystemValue": "H493"
    },
    {
     
     "CodeDescription": "LEAD TRAVEL PTE LTD",
     "TPSystemValue": "H311"
    },
    {
     
     "CodeDescription": "LEADING BIO-ENERGY (S) PTE LTD",
     "TPSystemValue": 2093
    },
    {
     
     "CodeDescription": "LEE KAI TECK TRAVEL SERVICE PTE LTD",
     "TPSystemValue": "H068"
    },
    {
     
     "CodeDescription": "LEE LEE",
     "TPSystemValue": 2094
    },
    {
     
     "CodeDescription": "LEEDEN LIMITED",
     "TPSystemValue": 2095
    },
    {
     
     "CodeDescription": "LEGEND TRAVEL PTE. LTD.",
     "TPSystemValue": "H649"
    },
    {
     
     "CodeDescription": "LEGO HOUSE PTE LTD",
     "TPSystemValue": 2096
    },
    {
     
     "CodeDescription": "LEI SHING HONG SINGAPORE PL",
     "TPSystemValue": 2097
    },
    {
     
     "CodeDescription": "LEICA GEO SYSTEM PTE LTD",
     "TPSystemValue": 2098
    },
    {
     
     "CodeDescription": "LEICA INSTRUMENT PTE LTD",
     "TPSystemValue": 2099
    },
    {
     
     "CodeDescription": "LEIGHTON CONTRACTORS ASIA LIMITED SINGAPORE BRA",
     "TPSystemValue": 3664
    },
    {
     
     "CodeDescription": "LEIGHTON OFFSHORE PTE LTD",
     "TPSystemValue": 3665
    },
    {
     
     "CodeDescription": "LEN CHWENG MARINE ENGINEEERING AND CONSTRUCTION",
     "TPSystemValue": "03L"
    },
    {
     
     "CodeDescription": "LEN CHWENG MARINE ENGINEERING AND CONSTRUCTION",
     "TPSystemValue": 2100
    },
    {
     
     "CodeDescription": "LEN MENG ENGINEERING & CONSTRUCTION PTE LTD",
     "TPSystemValue": "04L"
    },
    {
     
     "CodeDescription": "LEN MENG ENGINEERING CONSTRUCTION PTE LTD",
     "TPSystemValue": 2101
    },
    {
     
     "CodeDescription": "LEND LEASE ASIA HOLDINGS PTE LTD",
     "TPSystemValue": 2102
    },
    {
     
     "CodeDescription": "LENG SERVICES PTE LTD",
     "TPSystemValue": 2104
    },
    {
     
     "CodeDescription": "LENG SERVICES PTE. LTD.",
     "TPSystemValue": "06L"
    },
    {
     
     "CodeDescription": "LENOVO SINGAPORE PTE LTD",
     "TPSystemValue": 2105
    },
    {
     
     "CodeDescription": "LEO BURNETT",
     "TPSystemValue": 2106
    },
    {
     
     "CodeDescription": "LEO PHARMA ASIA PTE LTD",
     "TPSystemValue": 2107
    },
    {
     
     "CodeDescription": "LEO PROPERTY MANAGEMENT PTE LTD",
     "TPSystemValue": 2108
    },
    {
     
     "CodeDescription": "LEO TECH SERVICES PTE LTD",
     "TPSystemValue": 2109
    },
    {
     
     "CodeDescription": "LEO TECH SERVICES PTE. LTD.",
     "TPSystemValue": "07L"
    },
    {
     
     "CodeDescription": "LEO TRAVEL & TRANSPORTATION PTE LTD",
     "TPSystemValue": "H057"
    },
    {
     
     "CodeDescription": "LEONG SIEW WENG ENGINEERING PTE LTD",
     "TPSystemValue": 2110
    },
    {
     
     "CodeDescription": "LES AMIS HOLDINGS PTE LTD",
     "TPSystemValue": 2111
    },
    {
     
     "CodeDescription": "LEVI STRAUSS ASIA PACIFIC DIVISION",
     "TPSystemValue": 2112
    },
    {
     
     "CodeDescription": "LEX BUILD",
     "TPSystemValue": 2113
    },
    {
     
     "CodeDescription": "LEX TRAVEL PTE LTD",
     "TPSystemValue": "H313"
    },
    {
     
     "CodeDescription": "LEXISNEXIS",
     "TPSystemValue": 2114
    },
    {
     
     "CodeDescription": "LEXMARK INTERNATIONAL SINGAPORE PTE LTD",
     "TPSystemValue": 2115
    },
    {
     
     "CodeDescription": "LEY CHOON CONSTRUCTIONS AND ENGINEERING PTE LTD",
     "TPSystemValue": 2116
    },
    {
     
     "CodeDescription": "LF KONG BROTHERS ENGINEERING",
     "TPSystemValue": 2117
    },
    {
     
     "CodeDescription": "LG ELECTRONICS SINGAPORE PTE LTD",
     "TPSystemValue": 2119
    },
    {
     
     "CodeDescription": "LGT BANK",
     "TPSystemValue": 2120
    },
    {
     
     "CodeDescription": "LI TRAVEL AGENT PTE. LTD.",
     "TPSystemValue": "H469"
    },
    {
     
     "CodeDescription": "LIBERTY INSURANCE",
     "TPSystemValue": 2122
    },
    {
     
     "CodeDescription": "LIEBHERR SINGAPORE PTE LTD",
     "TPSystemValue": 2123
    },
    {
     
     "CodeDescription": "LIFE TECHNOLOGIES HOLDINGS PTE LTD",
     "TPSystemValue": 2124
    },
    {
     
     "CodeDescription": "LIFESPA SLENDER S BODYRITE PTE LTD",
     "TPSystemValue": 2125
    },
    {
     
     "CodeDescription": "LIGHTFOOT TRAVEL PTE. LTD.",
     "TPSystemValue": "H527"
    },
    {
     
     "CodeDescription": "LIJING HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H782"
    },
    {
     
     "CodeDescription": "LIM KIM HAI ELECTRIC CO S PTE LTD",
     "TPSystemValue": 2127
    },
    {
     
     "CodeDescription": "LIM TAN SECURITIES PTE LTD",
     "TPSystemValue": 2126
    },
    {
     
     "CodeDescription": "LIMRA TRAVELS PTE. LTD.",
     "TPSystemValue": "H830"
    },
    {
     
     "CodeDescription": "LINCOTRADE & ASSOCIATES PTE LTD",
     "TPSystemValue": 2128
    },
    {
     
     "CodeDescription": "LINDE GAS SINGAPORE PTE LTD",
     "TPSystemValue": 2129
    },
    {
     
     "CodeDescription": "LINEAR TECHNOLOGY PTE LTD",
     "TPSystemValue": 2130
    },
    {
     
     "CodeDescription": "LINKLATERS LLP (LINKLATERS ALLEN & GLEDHILL PL)",
     "TPSystemValue": 2131
    },
    {
     
     "CodeDescription": "LINKUP INTERNATIONAL (SINGAPORE) PTE LTD",
     "TPSystemValue": "H254"
    },
    {
     
     "CodeDescription": "LISOON INDUSTRIAL ENGINEERING PTE LTD",
     "TPSystemValue": 2132
    },
    {
     
     "CodeDescription": "LISOON MARINE ENGINEERING PTE LTD",
     "TPSystemValue": 2133
    },
    {
     
     "CodeDescription": "LITHO-LAV PRODUCTS PTE LTD",
     "TPSystemValue": 2134
    },
    {
     
     "CodeDescription": "LIU TRAVEL PTE. LTD.",
     "TPSystemValue": "H464"
    },
    {
     
     "CodeDescription": "LIVING OCEANS PTE. LTD.",
     "TPSystemValue": "H579"
    },
    {
     
     "CodeDescription": "LKW MECHANICAL ENGRG PTE LTD",
     "TPSystemValue": 2135
    },
    {
     
     "CodeDescription": "LLIANZ SE REINSURANCE BRANCH APAC",
     "TPSystemValue": 1384
    },
    {
     
     "CodeDescription": "LLOYD S REGISTER ASIA",
     "TPSystemValue": 2137
    },
    {
     
     "CodeDescription": "LLOYDS TSB BANK PLC",
     "TPSystemValue": "L03"
    },
    {
     
     "CodeDescription": "LLOYDS TSB MERCHANT BANK LIMITED",
     "TPSystemValue": 3789
    },
    {
     
     "CodeDescription": "LLYOD ANDREW BUILDERS PTE LTD",
     "TPSystemValue": 3776
    },
    {
     
     "CodeDescription": "LOKOPOKO PTE. LTD.",
     "TPSystemValue": "H523"
    },
    {
     
     "CodeDescription": "LONG BEACH MARINA PTE LTD",
     "TPSystemValue": 2139
    },
    {
     
     "CodeDescription": "LONZA BIOLOGIC TUAS PTE LTD",
     "TPSystemValue": 3666
    },
    {
     
     "CodeDescription": "LONZA BIOLOGICS PTE LTD",
     "TPSystemValue": 2140
    },
    {
     
     "CodeDescription": "LOOK ASIA HOLIDAYS PTE LTD",
     "TPSystemValue": "H304"
    },
    {
     
     "CodeDescription": "LOOK SINGAPORE TOUR PTE. LTD.",
     "TPSystemValue": "H372"
    },
    {
     
     "CodeDescription": "LOREAL SINGAPORE PTE LTD",
     "TPSystemValue": 2141
    },
    {
     
     "CodeDescription": "LOTUS ASIA TOURS PTE. LTD.",
     "TPSystemValue": "H440"
    },
    {
     
     "CodeDescription": "LOUIS DREYFUS COMMODITIES ASIA PTE LTD",
     "TPSystemValue": 2142
    },
    {
     
     "CodeDescription": "LOVELY LANDSCAPE & CONSTRUCTION PTE LTD",
     "TPSystemValue": 2143
    },
    {
     
     "CodeDescription": "LOY SUM JUAN RESTAURANT",
     "TPSystemValue": 2144
    },
    {
     
     "CodeDescription": "LRD EDUCATION (PTE.) LTD.",
     "TPSystemValue": "H460"
    },
    {
     
     "CodeDescription": "LRUI CONCEPT GROUP PTE LTD",
     "TPSystemValue": 2074
    },
    {
     
     "CodeDescription": "LSK ENGINEERING (S) PTE LTD",
     "TPSystemValue": 2145
    },
    {
     
     "CodeDescription": "LSL ENGINEERING P L",
     "TPSystemValue": "12L"
    },
    {
     
     "CodeDescription": "LSL ENGINEERING PTE LTD",
     "TPSystemValue": 2146
    },
    {
     
     "CodeDescription": "LTA",
     "TPSystemValue": 2147
    },
    {
     
     "CodeDescription": "LUBITRADE DISTRIBUTION PTE LTD",
     "TPSystemValue": 2148
    },
    {
     
     "CodeDescription": "LUBRITRADE TRADING PTE LTD",
     "TPSystemValue": 2149
    },
    {
     
     "CodeDescription": "LUCASFILM ANIMATION COMPANY SINGAPORE BV",
     "TPSystemValue": 2150
    },
    {
     
     "CodeDescription": "LUCENT TECHNOLOGIES SINGAPORE PTE LTD",
     "TPSystemValue": 2151
    },
    {
     
     "CodeDescription": "LUCKY LAND TRAVEL AND TOURS PTE. LTD.",
     "TPSystemValue": "H678"
    },
    {
     
     "CodeDescription": "LUM CHANG HOLDINGS LIMITED",
     "TPSystemValue": 2152
    },
    {
     
     "CodeDescription": "LUNIK ENGINEERING SERVICE CO P L",
     "TPSystemValue": 2153
    },
    {
     
     "CodeDescription": "LUSI GROUP PTE LTD",
     "TPSystemValue": 2154
    },
    {
     
     "CodeDescription": "LUX ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2155
    },
    {
     
     "CodeDescription": "LUXEXPLORER PTE. LTD",
     "TPSystemValue": "H741"
    },
    {
     
     "CodeDescription": "LUXURY TOURS & TRAVEL PTE LTD",
     "TPSystemValue": "H130"
    },
    {
     
     "CodeDescription": "LVMH FRAGRANCES AND COSMETICS SINGAPORE PTE LTD",
     "TPSystemValue": 2156
    },
    {
     
     "CodeDescription": "M Z TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H478"
    },
    {
     
     "CodeDescription": "M.I.C.E. MATTERS PTE. LTD.",
     "TPSystemValue": "H387"
    },
    {
     
     "CodeDescription": "M.O. TOURIST (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H715"
    },
    {
     
     "CodeDescription": "M+W ZANDER",
     "TPSystemValue": 2159
    },
    {
     
     "CodeDescription": "M1 LIMITED",
     "TPSystemValue": 2160
    },
    {
     
     "CodeDescription": "M1 MOBILE",
     "TPSystemValue": 3667
    },
    {
     
     "CodeDescription": "M1 NET LIMITED",
     "TPSystemValue": 3668
    },
    {
     
     "CodeDescription": "M1 SHOP PTE LTD",
     "TPSystemValue": 2161
    },
    {
     
     "CodeDescription": "MAA TRAVEL PTE. LTD.",
     "TPSystemValue": "H752"
    },
    {
     
     "CodeDescription": "MACCINE PTE LTD",
     "TPSystemValue": 2162
    },
    {
     
     "CodeDescription": "MACGREGOR PLIMSOLL PTE LTD",
     "TPSystemValue": 2163
    },
    {
     
     "CodeDescription": "MACHINOVATE PTE. LTD.",
     "TPSystemValue": "H696"
    },
    {
     
     "CodeDescription": "MACQUARIE BANK LIMITED SINGAPORE BRANCH",
     "TPSystemValue": 2164
    },
    {
     
     "CodeDescription": "MACQUARIE CAPITAL SECURITIES S PTE LIMITED",
     "TPSystemValue": 2165
    },
    {
     
     "CodeDescription": "MACQUARIE SECURITIES",
     "TPSystemValue": 2166
    },
    {
     
     "CodeDescription": "MAERSK",
     "TPSystemValue": 2167
    },
    {
     
     "CodeDescription": "MAERSK DRILLING HOLDINGS SINGAPORE",
     "TPSystemValue": 3669
    },
    {
     
     "CodeDescription": "MAERSK SINGAPORE PTE LTD",
     "TPSystemValue": 2168
    },
    {
     
     "CodeDescription": "MAERSK TANEERS SINGAPORE PL",
     "TPSystemValue": 2169
    },
    {
     
     "CodeDescription": "MAGICAL HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H397"
    },
    {
     
     "CodeDescription": "MAHINDRA SATYAM",
     "TPSystemValue": 2170
    },
    {
     
     "CodeDescription": "MAJESTIC TRAVEL (PTE) LTD",
     "TPSystemValue": "H225"
    },
    {
     
     "CodeDescription": "MAJLIS UGAMA ISLAM SINGAPURA",
     "TPSystemValue": 2171
    },
    {
     
     "CodeDescription": "MAJU TRAVEL SERVICE PTE LTD",
     "TPSystemValue": "H140"
    },
    {
     
     "CodeDescription": "MAKINO ASIA PTE LTD",
     "TPSystemValue": 2172
    },
    {
     
     "CodeDescription": "MALAYSIA DAIRY INDUSTRIES PTE LTD",
     "TPSystemValue": 2174
    },
    {
     
     "CodeDescription": "MALEMA SINGAPORE PTE LTD",
     "TPSystemValue": 2175
    },
    {
     
     "CodeDescription": "MALIFAX TECHNOLOGIES (S) PTE LTD",
     "TPSystemValue": 2176
    },
    {
     
     "CodeDescription": "MALKIN MAXWELL LLP",
     "TPSystemValue": 2177
    },
    {
     
     "CodeDescription": "MANAGEMENT SERVICES PTE LTD",
     "TPSystemValue": 2178
    },
    {
     
     "CodeDescription": "MANDARIN ORIENTAL SINGAPORE",
     "TPSystemValue": 2179
    },
    {
     
     "CodeDescription": "MANLIN COMMUNICATIONS PTE. LTD.",
     "TPSystemValue": "H386"
    },
    {
     
     "CodeDescription": "MANN+HUMMEL FILTER TECHNOLOGY (SEA) PL",
     "TPSystemValue": 2181
    },
    {
     
     "CodeDescription": "MANPOWER STAFFING",
     "TPSystemValue": 2182
    },
    {
     
     "CodeDescription": "MANUFACTURING INTEGRATION TECHNOLOGY LTD",
     "TPSystemValue": 2183
    },
    {
     
     "CodeDescription": "MANULIFE (SINGAPORE) PTE LTD",
     "TPSystemValue": 2184
    },
    {
     
     "CodeDescription": "MANULIFE ASSET MANAGEMENT SG PTE LTD",
     "TPSystemValue": 2185
    },
    {
     
     "CodeDescription": "MANULIFE FINANCIAL",
     "TPSystemValue": 2186
    },
    {
     
     "CodeDescription": "MANULIFE SINGAPORE PTE LTD",
     "TPSystemValue": 2187
    },
    {
     
     "CodeDescription": "MAPLE AVIATION PTE LTD",
     "TPSystemValue": "H150"
    },
    {
     
     "CodeDescription": "MAPLE TRAVEL PTE. LTD.",
     "TPSystemValue": "H461"
    },
    {
     
     "CodeDescription": "MAPLETREE INVESTMENTS PTE LTD",
     "TPSystemValue": 2188
    },
    {
     
     "CodeDescription": "MAPO ENGINEERING PTE LTD",
     "TPSystemValue": 2189
    },
    {
     
     "CodeDescription": "MAPO MARINE PTE. LTD",
     "TPSystemValue": 2190
    },
    {
     
     "CodeDescription": "MAPO MARINE PTE. LTD.",
     "TPSystemValue": "02M"
    },
    {
     
     "CodeDescription": "MAPO MARINE SERVICES PTE LTD",
     "TPSystemValue": 2191
    },
    {
     
     "CodeDescription": "MARCO POLO EXPRESS PTE LTD",
     "TPSystemValue": "H159"
    },
    {
     
     "CodeDescription": "MARCO POLO MARINE PTE LTD",
     "TPSystemValue": 3670
    },
    {
     
     "CodeDescription": "MARCUS EVANS (SINGAPORE) PTE LTD",
     "TPSystemValue": 2192
    },
    {
     
     "CodeDescription": "MARIANA EXPRESS LINES PTE LTD",
     "TPSystemValue": 2193
    },
    {
     
     "CodeDescription": "MARINA BAY SANDS PTE LTD",
     "TPSystemValue": 2194
    },
    {
     
     "CodeDescription": "MARINA PROPERTIES PTE LTD",
     "TPSystemValue": 2195
    },
    {
     
     "CodeDescription": "MARINA TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "H731"
    },
    {
     
     "CodeDescription": "MARINE & OFFSHORE INTEGRATED LOGISTICS",
     "TPSystemValue": 2196
    },
    {
     
     "CodeDescription": "MARINE EQUIPMENTS PTE LTD",
     "TPSystemValue": 3671
    },
    {
     
     "CodeDescription": "MARINE LINE TECHNOLOGY PTE LTD",
     "TPSystemValue": 2197
    },
    {
     
     "CodeDescription": "MARINE LINE TECHNOLOGY PTE. LTD.",
     "TPSystemValue": "03M"
    },
    {
     
     "CodeDescription": "MARITIME PORT AUTHORITY OF SINGAPOR",
     "TPSystemValue": 2198
    },
    {
     
     "CodeDescription": "MARKOMO PRINT MEDIA PTE LTD",
     "TPSystemValue": 2199
    },
    {
     
     "CodeDescription": "MARLIN DIVERS PTE. LTD.",
     "TPSystemValue": "H657"
    },
    {
     
     "CodeDescription": "MARQUIP WARD UNITED ASIA PTE LTD",
     "TPSystemValue": 2200
    },
    {
     
     "CodeDescription": "MARRIOTT INTERNATIONAL",
     "TPSystemValue": "H012"
    },
    {
     
     "CodeDescription": "MARRIOTT VACATION CLUB ASIA PACIFIC PL",
     "TPSystemValue": 2201
    },
    {
     
     "CodeDescription": "MARRIOTT VACATIONS WORLDWIDE",
     "TPSystemValue": "H017"
    },
    {
     
     "CodeDescription": "MARS TRAVEL (S) PTE LTD",
     "TPSystemValue": "H134"
    },
    {
     
     "CodeDescription": "MARSH SINGAPORE PTE LTD",
     "TPSystemValue": 2202
    },
    {
     
     "CodeDescription": "MARSH(S) PTE LTD",
     "TPSystemValue": 2203
    },
    {
     
     "CodeDescription": "MARSHALL CAVENDISH",
     "TPSystemValue": 2204
    },
    {
     
     "CodeDescription": "MARSHI TRAVEL PRIVATE LIMITED",
     "TPSystemValue": "H836"
    },
    {
     
     "CodeDescription": "MARVELL ASIA PTE LTD",
     "TPSystemValue": 2205
    },
    {
     
     "CodeDescription": "MARY CHIA HOLDINGS LIMITED",
     "TPSystemValue": 2206
    },
    {
     
     "CodeDescription": "MARY KAYSINGAPORE PRIVATE LIMITED",
     "TPSystemValue": 2207
    },
    {
     
     "CodeDescription": "MAS",
     "TPSystemValue": 2208
    },
    {
     
     "CodeDescription": "MASTER EXPRESS ENGINEERING PTE LTD",
     "TPSystemValue": 2209
    },
    {
     
     "CodeDescription": "MASTER TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H233"
    },
    {
     
     "CodeDescription": "MASTERCARD ASIAPACIFIC PTE LTD",
     "TPSystemValue": 2210
    },
    {
     
     "CodeDescription": "MASTERCARD TRAVEL SOLUTIONS SINGAPORE PTE. LTD.",
     "TPSystemValue": "H705"
    },
    {
     
     "CodeDescription": "MASTEREIGN LEARNING JOURNEYS PTE. LTD.",
     "TPSystemValue": "H499"
    },
    {
     
     "CodeDescription": "MATRIX CELLULAR PTE. LTD.",
     "TPSystemValue": "H455"
    },
    {
     
     "CodeDescription": "MATROLINK TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H192"
    },
    {
     
     "CodeDescription": "MATSUSHITA ELECTRIC",
     "TPSystemValue": 2212
    },
    {
     
     "CodeDescription": "MATSUSHITA GREATWALL CORPORATION P L",
     "TPSystemValue": "05M"
    },
    {
     
     "CodeDescription": "MATSUSHITA GREATWALL CORPORATION PRIVATE LIMITED",
     "TPSystemValue": 2213
    },
    {
     
     "CodeDescription": "MATSUSHITA TECHNOLOGY (S) PTE LTD",
     "TPSystemValue": 2214
    },
    {
     
     "CodeDescription": "MATSUSHITU GREATWALL CORPORATION",
     "TPSystemValue": 2215
    },
    {
     
     "CodeDescription": "MATTRESS INTERNATIONAL PTE LTD",
     "TPSystemValue": 2216
    },
    {
     
     "CodeDescription": "MAXHILL MANAGEMENTS SERVICES PTE LTD",
     "TPSystemValue": 2217
    },
    {
     
     "CodeDescription": "MAXTOR PERIPHERALS (S) PTE LTD",
     "TPSystemValue": 2218
    },
    {
     
     "CodeDescription": "MAXWELL CHAMBER PTE LTD",
     "TPSystemValue": 2219
    },
    {
     
     "CodeDescription": "MAYBANK",
     "TPSystemValue": 2220
    },
    {
     
     "CodeDescription": "MAYBANK KIM TNG SECURITIES PTE LTD",
     "TPSystemValue": 2221
    },
    {
     
     "CodeDescription": "MBS",
     "TPSystemValue": 2223
    },
    {
     
     "CodeDescription": "MCAFEE SINGAPORE PTE LTD",
     "TPSystemValue": 2224
    },
    {
     
     "CodeDescription": "MCC TRANSPORT SINGAPORE PTE LTD",
     "TPSystemValue": 2225
    },
    {
     
     "CodeDescription": "MCCANN WORLD GROUP (SINGAPORE) PTE LTD",
     "TPSystemValue": 2226
    },
    {
     
     "CodeDescription": "MCCONNELL DOWELL SOUTH EAST ASIA PTE LTD",
     "TPSystemValue": 2227
    },
    {
     
     "CodeDescription": "MCCOY COMPONENTS PTE LTD",
     "TPSystemValue": 2228
    },
    {
     
     "CodeDescription": "MCDERMOTT ASIA PACIFIC LIMITED",
     "TPSystemValue": 2229
    },
    {
     
     "CodeDescription": "MCDONALD S RESTAURANTS PTE LTD",
     "TPSystemValue": 2231
    },
    {
     
     "CodeDescription": "MCDONALDS INDUSTRIES (SG) PTE LTD",
     "TPSystemValue": 2230
    },
    {
     
     "CodeDescription": "MCGLAREN TECHNOLOGIES & MAANGEMENT PL",
     "TPSystemValue": 2232
    },
    {
     
     "CodeDescription": "MCGRAW-HILL ASIAN HOLDINGS SINGAPORE PL",
     "TPSystemValue": 2233
    },
    {
     
     "CodeDescription": "MCI WORLDCOM ASIA PTE LTD",
     "TPSystemValue": 2234
    },
    {
     
     "CodeDescription": "MCKINSEY & COMPANY SINGAPORE",
     "TPSystemValue": 2235
    },
    {
     
     "CodeDescription": "MCL LAND LIMITED",
     "TPSystemValue": 2236
    },
    {
     
     "CodeDescription": "MCYS",
     "TPSystemValue": 2237
    },
    {
     
     "CodeDescription": "MD CONSTRUCTION ENGINEERING PTE LTD",
     "TPSystemValue": 2157
    },
    {
     
     "CodeDescription": "MDIS",
     "TPSystemValue": 2238
    },
    {
     
     "CodeDescription": "MDL MARINE HOLDINGS PTE LTD",
     "TPSystemValue": 3672
    },
    {
     
     "CodeDescription": "MEA TECHNOLOGIES PTE LTD",
     "TPSystemValue": 2239
    },
    {
     
     "CodeDescription": "MECOMB SINGAPORE LIMITED",
     "TPSystemValue": 2240
    },
    {
     
     "CodeDescription": "MED TRAVEL PTE. LTD.",
     "TPSystemValue": "H656"
    },
    {
     
     "CodeDescription": "MEDIA DATA SYSTEMS PRIVATE LIMITED",
     "TPSystemValue": 2241
    },
    {
     
     "CodeDescription": "MEDIA DEVELOPMENT AUTHORITY",
     "TPSystemValue": 2242
    },
    {
     
     "CodeDescription": "MEDIACOM SINGAPORE",
     "TPSystemValue": 2243
    },
    {
     
     "CodeDescription": "MEDIACORP ENTERPRISES PTE LTD",
     "TPSystemValue": 2244
    },
    {
     
     "CodeDescription": "MEDIALINK PRINTING SERVICES PTE LTD",
     "TPSystemValue": 2245
    },
    {
     
     "CodeDescription": "MEDIARING LTD",
     "TPSystemValue": 2246
    },
    {
     
     "CodeDescription": "MEDIASOFT INTEGRATION PTE LTD",
     "TPSystemValue": 2247
    },
    {
     
     "CodeDescription": "MEDIATEK SINGAPORE PTE LTD",
     "TPSystemValue": 2248
    },
    {
     
     "CodeDescription": "MEDITERRANEAN SHIPPING CO ASIA REGIONAL OFFICE PL",
     "TPSystemValue": 2249
    },
    {
     
     "CodeDescription": "MEDTRONIC INTERNATIONAL LTD",
     "TPSystemValue": 2250
    },
    {
     
     "CodeDescription": "MEDTRONIC SINGAPORE OPERATIONS PTE LTD",
     "TPSystemValue": 2251
    },
    {
     
     "CodeDescription": "MEGA AUTO PTE LTD",
     "TPSystemValue": 2252
    },
    {
     
     "CodeDescription": "MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD",
     "TPSystemValue": "M02"
    },
    {
     
     "CodeDescription": "MEGACHEM LIMITED",
     "TPSystemValue": 2253
    },
    {
     
     "CodeDescription": "MEGALINE TRAVEL PTE. LTD.",
     "TPSystemValue": "H352"
    },
    {
     
     "CodeDescription": "MEGATOP TRAVEL CONSULTANTS PTE LTD",
     "TPSystemValue": "H165"
    },
    {
     
     "CodeDescription": "MEGAWAY ENGINEERING &TRADING PTE LTD",
     "TPSystemValue": 2254
    },
    {
     
     "CodeDescription": "MEGGITT AEROSPACE ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2255
    },
    {
     
     "CodeDescription": "MEIDEN SINGAPORE PTE LTD",
     "TPSystemValue": 2256
    },
    {
     
     "CodeDescription": "MEINHARDT SINGAPORE PTE LTD",
     "TPSystemValue": 2257
    },
    {
     
     "CodeDescription": "MELIORIS PRIVATE LIMITED",
     "TPSystemValue": 2258
    },
    {
     
     "CodeDescription": "MENCAST HOLDINGS LTD",
     "TPSystemValue": 3805
    },
    {
     
     "CodeDescription": "MENLO WORLDWIDE ASIA PACIFIC PL",
     "TPSystemValue": 2259
    },
    {
     
     "CodeDescription": "MENTOR MEDIA LTD",
     "TPSystemValue": 2260
    },
    {
     
     "CodeDescription": "MEP SYSTEMS PTE LTD",
     "TPSystemValue": 2261
    },
    {
     
     "CodeDescription": "MERCEDES BENZ",
     "TPSystemValue": 2262
    },
    {
     
     "CodeDescription": "MERCER (SINGAPORE) PTE LTD",
     "TPSystemValue": 2263
    },
    {
     
     "CodeDescription": "MERCHANT SOLUTION PTE LTD",
     "TPSystemValue": 2264
    },
    {
     
     "CodeDescription": "MERCK (S) PTE LTD",
     "TPSystemValue": 2265
    },
    {
     
     "CodeDescription": "MERCK SHARP & DOHME",
     "TPSystemValue": 2266
    },
    {
     
     "CodeDescription": "MERCURI URVAL",
     "TPSystemValue": 3806
    },
    {
     
     "CodeDescription": "MERCURIA ENERGY TRADING PTE LTD",
     "TPSystemValue": 2267
    },
    {
     
     "CodeDescription": "MERIAL ASIA PTE LTD",
     "TPSystemValue": 2268
    },
    {
     
     "CodeDescription": "MERIDIAN INTERNATIONAL TRAVEL PTE. LTD.",
     "TPSystemValue": "H544"
    },
    {
     
     "CodeDescription": "MERIDIEN TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "H404"
    },
    {
     
     "CodeDescription": "MERITOR HEAVY VEHICALE SYSTEMS PL",
     "TPSystemValue": 2269
    },
    {
     
     "CodeDescription": "MERRIL LYNCH GLOBAL SERVICES PTE LTD",
     "TPSystemValue": 2270
    },
    {
     
     "CodeDescription": "MERRILL LYNCH",
     "TPSystemValue": 2271
    },
    {
     
     "CodeDescription": "MERRILL LYNCH GLOBAL SERVICES. PTE LTD",
     "TPSystemValue": 2272
    },
    {
     
     "CodeDescription": "MERRILL LYNCH INTERNATIONAL BANK LTD",
     "TPSystemValue": 2273
    },
    {
     
     "CodeDescription": "MERRILL LYNCH SINGAPORE PTE LTD",
     "TPSystemValue": 2274
    },
    {
     
     "CodeDescription": "METAL ONE STAINLESS ASIA",
     "TPSystemValue": 2275
    },
    {
     
     "CodeDescription": "METHODIST WELFARE SEREVICES",
     "TPSystemValue": 2276
    },
    {
     
     "CodeDescription": "METRO TOURS SINGAPORE PTE LTD",
     "TPSystemValue": "H238"
    },
    {
     
     "CodeDescription": "MEWAH OILS FATS PTE LTD",
     "TPSystemValue": 2278
    },
    {
     
     "CodeDescription": "MF GLOBAL SINGAPORE PTE LIMITED",
     "TPSystemValue": 2279
    },
    {
     
     "CodeDescription": "MF GLOBAL SINGAPORE PTE. LIMITED",
     "TPSystemValue": "08M"
    },
    {
     
     "CodeDescription": "MFA",
     "TPSystemValue": 2280
    },
    {
     
     "CodeDescription": "MHA",
     "TPSystemValue": 2281
    },
    {
     
     "CodeDescription": "MHE-DEMAG",
     "TPSystemValue": 2282
    },
    {
     
     "CodeDescription": "MI LTD",
     "TPSystemValue": 2283
    },
    {
     
     "CodeDescription": "MI SHOP LIMITED",
     "TPSystemValue": 2284
    },
    {
     
     "CodeDescription": "MI TRAVEL PTE. LTD.",
     "TPSystemValue": "H688"
    },
    {
     
     "CodeDescription": "MICA",
     "TPSystemValue": 2285
    },
    {
     
     "CodeDescription": "MICHAEL PAGE INTERNATIONAL PL",
     "TPSystemValue": 2286
    },
    {
     
     "CodeDescription": "MICHELIN ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2287
    },
    {
     
     "CodeDescription": "MICLYN ESPRESS OFFSHORE PTE LTD",
     "TPSystemValue": 2288
    },
    {
     
     "CodeDescription": "MICLYN EXPRESS OFFSHORE PTE LTD",
     "TPSystemValue": "09M"
    },
    {
     
     "CodeDescription": "MICRO 2000 TECHNOLOGY PL",
     "TPSystemValue": 2289
    },
    {
     
     "CodeDescription": "MICRO SEMI CONDUCTOR PTE LTD",
     "TPSystemValue": 2290
    },
    {
     
     "CodeDescription": "MICROFITS PTE LTD",
     "TPSystemValue": 2291
    },
    {
     
     "CodeDescription": "MICRON",
     "TPSystemValue": 2292
    },
    {
     
     "CodeDescription": "MICRON SEMICONDUCTOR ASIA PTE LTD",
     "TPSystemValue": 2293
    },
    {
     
     "CodeDescription": "MICRONCLEAN SINGAPORE PTE LTD",
     "TPSystemValue": 3673
    },
    {
     
     "CodeDescription": "MICROSFIDELIO SINGAPORE PTE LTD",
     "TPSystemValue": 2294
    },
    {
     
     "CodeDescription": "MICROSOFT OPERATIONS PTE LTD",
     "TPSystemValue": 2295
    },
    {
     
     "CodeDescription": "MICROSOFT SINGAPORE PTE LTD",
     "TPSystemValue": 2296
    },
    {
     
     "CodeDescription": "MICROTECH ENGINEERING PTE LTD",
     "TPSystemValue": 2297
    },
    {
     
     "CodeDescription": "MIDEA ELECTRIC TRADING SG CO PTE LTD",
     "TPSystemValue": 2298
    },
    {
     
     "CodeDescription": "MIDEA SINGAPORE TRADING CO PTE LTD",
     "TPSystemValue": 3674
    },
    {
     
     "CodeDescription": "MIDPOINT PROPERTIES LIMITED",
     "TPSystemValue": 2299
    },
    {
     
     "CodeDescription": "MIKUNI ENGINEERING ( S) PTE LTD",
     "TPSystemValue": 2300
    },
    {
     
     "CodeDescription": "MILAGRO TRAVEL PTE. LTD.",
     "TPSystemValue": "H555"
    },
    {
     
     "CodeDescription": "MIL-COM AEROSPACE PTE LTD",
     "TPSystemValue": 2301
    },
    {
     
     "CodeDescription": "MILLENNIUM AND COPTHORNE INTL LTD",
     "TPSystemValue": 2302
    },
    {
     
     "CodeDescription": "MILLENNIUM CAPITAL MANAGEMENT",
     "TPSystemValue": 2303
    },
    {
     
     "CodeDescription": "MILLENNIUM TOURS & TRAVEL PTE. LTD.",
     "TPSystemValue": "H424"
    },
    {
     
     "CodeDescription": "MILLION BUILT PTE LTD",
     "TPSystemValue": 2304
    },
    {
     
     "CodeDescription": "MILLIONBUILT PTE LTD",
     "TPSystemValue": 2305
    },
    {
     
     "CodeDescription": "MILLWARD BROWN INTERNATIONAL PVT LTD",
     "TPSystemValue": 2306
    },
    {
     
     "CodeDescription": "MILLYN EXPRESS OFFSHORE PTE LTD",
     "TPSystemValue": 2307
    },
    {
     
     "CodeDescription": "MIN GHEE AUTO PTE LTD",
     "TPSystemValue": 2308
    },
    {
     
     "CodeDescription": "MINCO PRODUCTS ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2309
    },
    {
     
     "CodeDescription": "MIND CENTRE PTE LTD",
     "TPSystemValue": 2310
    },
    {
     
     "CodeDescription": "MIND CENTRE PTE. LTD.",
     "TPSystemValue": "10M"
    },
    {
     
     "CodeDescription": "MINDEF",
     "TPSystemValue": 2311
    },
    {
     
     "CodeDescription": "MINDEF INFORMATION SERVICE DIVISOR",
     "TPSystemValue": 3675
    },
    {
     
     "CodeDescription": "MINDS",
     "TPSystemValue": 2312
    },
    {
     
     "CodeDescription": "MINDSHARE SINGAPORE PTE LTD",
     "TPSystemValue": 2313
    },
    {
     
     "CodeDescription": "MINDTECK SINGAPORE PTE LTD",
     "TPSystemValue": 2314
    },
    {
     
     "CodeDescription": "MINDWAVE SOLUTIONS PTE LTD",
     "TPSystemValue": 2315
    },
    {
     
     "CodeDescription": "MINISTRY HOME AFFAIR",
     "TPSystemValue": 2316
    },
    {
     
     "CodeDescription": "MINISTRY OF COMMUNITY DEVELOPMENT",
     "TPSystemValue": 2317
    },
    {
     
     "CodeDescription": "MINISTRY OF DEFENCE",
     "TPSystemValue": 2318
    },
    {
     
     "CodeDescription": "MINISTRY OF EDUCATION",
     "TPSystemValue": 2319
    },
    {
     
     "CodeDescription": "MINISTRY OF ENVIRONMENT & WATER RESOURCE",
     "TPSystemValue": 2320
    },
    {
     
     "CodeDescription": "MINISTRY OF FINANACE",
     "TPSystemValue": 2321
    },
    {
     
     "CodeDescription": "MINISTRY OF FOREIGN AFFAIRS",
     "TPSystemValue": 2322
    },
    {
     
     "CodeDescription": "MINISTRY OF HEALTH HOLDINGS PTE LTD",
     "TPSystemValue": 2323
    },
    {
     
     "CodeDescription": "MINISTRY OF HOME AFFAIRS",
     "TPSystemValue": 2324
    },
    {
     
     "CodeDescription": "MINISTRY OF INFORMATION COMMUNICATIONS",
     "TPSystemValue": 2325
    },
    {
     
     "CodeDescription": "MINISTRY OF LAW",
     "TPSystemValue": 2326
    },
    {
     
     "CodeDescription": "MINISTRY OF MANPOWER",
     "TPSystemValue": 2327
    },
    {
     
     "CodeDescription": "MINISTRY OF NATIONAL DEVLOPMENT",
     "TPSystemValue": 2328
    },
    {
     
     "CodeDescription": "MINISTRY OF SOCIAL & FAMILY DEVELOPMENT",
     "TPSystemValue": 2329
    },
    {
     
     "CodeDescription": "MINISTRY OF THE ENVIRONMENT & WATER RES",
     "TPSystemValue": 2330
    },
    {
     
     "CodeDescription": "MINISTRY OF TRADE & INDUSTRY",
     "TPSystemValue": 2331
    },
    {
     
     "CodeDescription": "MINISTRY OF TRANSPORT",
     "TPSystemValue": 2332
    },
    {
     
     "CodeDescription": "MINMETALS SHIPPING S PTE LTD",
     "TPSystemValue": 3676
    },
    {
     
     "CodeDescription": "MISC SALARY AC - ITEM PROCESSING",
     "TPSystemValue": "12M"
    },
    {
     
     "CodeDescription": "MISUMI SOUTH EAST ASIA PTE LTD",
     "TPSystemValue": 2334
    },
    {
     
     "CodeDescription": "MISYS",
     "TPSystemValue": 2335
    },
    {
     
     "CodeDescription": "MITSUBISHI CATERPILLAR FORKLIFT ASIA PL",
     "TPSystemValue": 2336
    },
    {
     
     "CodeDescription": "MITSUBISHI CHEMICAL INFONICS PTE LTD",
     "TPSystemValue": 2337
    },
    {
     
     "CodeDescription": "MITSUBISHI CORP PTE LTD",
     "TPSystemValue": 2338
    },
    {
     
     "CodeDescription": "MITSUBISHI ELECTRIC ASIA PTE LTD",
     "TPSystemValue": 2339
    },
    {
     
     "CodeDescription": "MITSUBISHI ELEVATOR SINGAPORE PTE LTD",
     "TPSystemValue": 2340
    },
    {
     
     "CodeDescription": "MITSUBISHI UFJ TRUST & BANKING CORPORATION",
     "TPSystemValue": "M03"
    },
    {
     
     "CodeDescription": "MITSUBISHI UFJ TRUST INTERNATIONAL LTD S BRANCH",
     "TPSystemValue": 2342
    },
    {
     
     "CodeDescription": "MITSUI & CO (ASIA PACIFIC) PTE LTD",
     "TPSystemValue": 2343
    },
    {
     
     "CodeDescription": "MITSUI CHEMICALS ASIA PACIFIC LTD",
     "TPSystemValue": 2344
    },
    {
     
     "CodeDescription": "MITSUI CO POWER INFRASTRUCTURE DEVELOPMENT L",
     "TPSystemValue": 3677
    },
    {
     
     "CodeDescription": "MITSUI ELECTRONICS ASIA PTE LTD",
     "TPSystemValue": 2345
    },
    {
     
     "CodeDescription": "MITSUI HIGH TEC SINGAPORE PTE LTD",
     "TPSystemValue": 2346
    },
    {
     
     "CodeDescription": "MITSUI PHENOLS SINGAPORE PTE LTD",
     "TPSystemValue": 2347
    },
    {
     
     "CodeDescription": "MITSUI SUMITOMO INSURANCE SING PTE LTD",
     "TPSystemValue": 2348
    },
    {
     
     "CodeDescription": "MIZUHO CORPORATE BANK, LTD",
     "TPSystemValue": "M04"
    },
    {
     
     "CodeDescription": "MIZUHO CORPORATE BANK,LTD",
     "TPSystemValue": 2349
    },
    {
     
     "CodeDescription": "MKS PRECIOUS METALS SINGAPORE PTE LTD",
     "TPSystemValue": 2350
    },
    {
     
     "CodeDescription": "MLT ENGINEERING PTE. LTD.",
     "TPSystemValue": "14M"
    },
    {
     
     "CodeDescription": "MMI HOLDINGS LIMITED",
     "TPSystemValue": 2352
    },
    {
     
     "CodeDescription": "MN COMPUTER SYSTEM (S) PTE LTD",
     "TPSystemValue": 2353
    },
    {
     
     "CodeDescription": "MOBILE ONE PTE LTD AUTOMOBILE ONE PTE LTD",
     "TPSystemValue": 2354
    },
    {
     
     "CodeDescription": "MOBILEONE LTD",
     "TPSystemValue": 2355
    },
    {
     
     "CodeDescription": "MOD",
     "TPSystemValue": 2356
    },
    {
     
     "CodeDescription": "MODEC INC",
     "TPSystemValue": 2357
    },
    {
     
     "CodeDescription": "MODEL OFFSHORE PRODUCTION SYSTEM (S) PL",
     "TPSystemValue": 2358
    },
    {
     
     "CodeDescription": "MODERN MONTESSORI INTERNATIONAL PTE LTD",
     "TPSystemValue": 2359
    },
    {
     
     "CodeDescription": "MODUSLINK PTE LTD",
     "TPSystemValue": 2360
    },
    {
     
     "CodeDescription": "MOE",
     "TPSystemValue": 2361
    },
    {
     
     "CodeDescription": "MOET HENNESSY ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2362
    },
    {
     
     "CodeDescription": "MOF MINISTRY OF FINANCE",
     "TPSystemValue": 2363
    },
    {
     
     "CodeDescription": "MOH",
     "TPSystemValue": 2364
    },
    {
     
     "CodeDescription": "MOH HOLDINGS PTE LTD",
     "TPSystemValue": 2365
    },
    {
     
     "CodeDescription": "MOHAMED MUSTAFA & SAMSUDDIN CO PTE",
     "TPSystemValue": 2366
    },
    {
     
     "CodeDescription": "MOHH PTE LTD",
     "TPSystemValue": 2367
    },
    {
     
     "CodeDescription": "MOI INTERNATIONAL SINGAPORE PTE LTD",
     "TPSystemValue": 2368
    },
    {
     
     "CodeDescription": "MOIEX (S) P L",
     "TPSystemValue": 2369
    },
    {
     
     "CodeDescription": "MOL TANKSHIP MANAGEMENT (ASIA) PTE LTD",
     "TPSystemValue": 2370
    },
    {
     
     "CodeDescription": "MOLEX FAR EAST SOUTH MANAGEMENT PTE LTD",
     "TPSystemValue": 2371
    },
    {
     
     "CodeDescription": "MOLEX SINGAPORE PTE LTD",
     "TPSystemValue": 2372
    },
    {
     
     "CodeDescription": "MONDIAL ASSISTANCE (ASIA) PTE LTD",
     "TPSystemValue": 2373
    },
    {
     
     "CodeDescription": "MONETARY AUTHORITY OF SINGAPORE",
     "TPSystemValue": 2374
    },
    {
     
     "CodeDescription": "MONSANTO SINGAPORE CO PTE LTD",
     "TPSystemValue": 2375
    },
    {
     
     "CodeDescription": "MOODY S ANALYTICS SINGAPORE PTE LTD",
     "TPSystemValue": 2376
    },
    {
     
     "CodeDescription": "MOODY S SINGAPORE PTE LTD",
     "TPSystemValue": 2377
    },
    {
     
     "CodeDescription": "MOORE STEPHENS LLP",
     "TPSystemValue": 2378
    },
    {
     
     "CodeDescription": "MORGAN STANLEY",
     "TPSystemValue": 2379
    },
    {
     
     "CodeDescription": "MORGAN STANLEY DEAN WRITTER (S) PTE LTD",
     "TPSystemValue": 2380
    },
    {
     
     "CodeDescription": "MOTOR IMAGE ENTERPRISES PTE LTD",
     "TPSystemValue": 2381
    },
    {
     
     "CodeDescription": "MOTOROLA ELECTRONICS PTE LTD",
     "TPSystemValue": 2383
    },
    {
     
     "CodeDescription": "MOTOROLA HOLDINGS PTE LTD",
     "TPSystemValue": 2382
    },
    {
     
     "CodeDescription": "MOTOROLA MOBILITY SINGAPORE PTE LTD",
     "TPSystemValue": 2384
    },
    {
     
     "CodeDescription": "MOTOROLA SOLUTIONS SINGAPORE PTE LTD",
     "TPSystemValue": 2385
    },
    {
     
     "CodeDescription": "MOTT MACDONALDS SINGAPORE PTE LIMITED",
     "TPSystemValue": 2386
    },
    {
     
     "CodeDescription": "MOUNT ALVERNIA HOSPITAL",
     "TPSystemValue": 2387
    },
    {
     
     "CodeDescription": "MOUNT ELIZEBETH HOSPITAL",
     "TPSystemValue": 2388
    },
    {
     
     "CodeDescription": "MOUNT FABER LEISURE GROUP PTE. LTD.",
     "TPSystemValue": "H045"
    },
    {
     
     "CodeDescription": "MOUNT FABER LESISURE GROUP PTE LTD",
     "TPSystemValue": 2389
    },
    {
     
     "CodeDescription": "MOUNT KAWI PTE LTD",
     "TPSystemValue": 2390
    },
    {
     
     "CodeDescription": "MOVEMENT FOR THE INTELLECTUALLY DIS",
     "TPSystemValue": 2391
    },
    {
     
     "CodeDescription": "MOVIN JOURNEY (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H812"
    },
    {
     
     "CodeDescription": "MP BIOMEDICALS ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2392
    },
    {
     
     "CodeDescription": "MPA",
     "TPSystemValue": 2393
    },
    {
     
     "CodeDescription": "MPHASIS PTE LTD",
     "TPSystemValue": 2394
    },
    {
     
     "CodeDescription": "MSD",
     "TPSystemValue": 2395
    },
    {
     
     "CodeDescription": "MSD INTL GMBH",
     "TPSystemValue": 2396
    },
    {
     
     "CodeDescription": "MSD TECHNOLOGY SINGAPORE PTE LTD",
     "TPSystemValue": 2397
    },
    {
     
     "CodeDescription": "MSIG HOLDINGS ASIA PTE LTD",
     "TPSystemValue": 2398
    },
    {
     
     "CodeDescription": "MSIG INSURANCE SINGAPORE PTE LTD",
     "TPSystemValue": 2399
    },
    {
     
     "CodeDescription": "MSN SHIPPING AGENCIES PTE LTD",
     "TPSystemValue": 2400
    },
    {
     
     "CodeDescription": "MST GOLF (SINGAPORE) PTE LTD",
     "TPSystemValue": "16M"
    },
    {
     
     "CodeDescription": "MTU ASIA PTE LTD",
     "TPSystemValue": 2401
    },
    {
     
     "CodeDescription": "MTV ASIA LDC",
     "TPSystemValue": 2402
    },
    {
     
     "CodeDescription": "MTW SINAGPORE PTE LTD",
     "TPSystemValue": 2403
    },
    {
     
     "CodeDescription": "MUHIBBAH TRAVEL-TOURS & TRADING PTE LTD",
     "TPSystemValue": "H132"
    },
    {
     
     "CodeDescription": "MUJI SINGAPORE PTE LTD",
     "TPSystemValue": 2405
    },
    {
     
     "CodeDescription": "MULTI FINELINE ELECTRONIX",
     "TPSystemValue": 3678
    },
    {
     
     "CodeDescription": "MULTI FIX FASTENERS PTE LTD",
     "TPSystemValue": 2406
    },
    {
     
     "CodeDescription": "MULTIBASE CONSTRUCTION PTE LTD",
     "TPSystemValue": 3784
    },
    {
     
     "CodeDescription": "MULTI-FIX FASTENERS PTE LTD",
     "TPSystemValue": 2407
    },
    {
     
     "CodeDescription": "MUN SIONG ENGINEERING LIMITED",
     "TPSystemValue": 2408
    },
    {
     
     "CodeDescription": "MUNICH MANAGEMENT PTE LTD",
     "TPSystemValue": 2409
    },
    {
     
     "CodeDescription": "MUNICH REINSURANCE SINGAPORE",
     "TPSystemValue": 2410
    },
    {
     
     "CodeDescription": "MURATA ELECTRONICS SINGAPORE PTE LTD",
     "TPSystemValue": 2411
    },
    {
     
     "CodeDescription": "MURATA MACHINERY SINGAPORE PTE LTD",
     "TPSystemValue": 2412
    },
    {
     
     "CodeDescription": "MUREX SEA PTD LTD",
     "TPSystemValue": 2413
    },
    {
     
     "CodeDescription": "MUSLIM MISSIONARY SOCIETY SINGAPORE",
     "TPSystemValue": 2414
    },
    {
     
     "CodeDescription": "MUSTAFA AIR TRAVEL PTE LTD",
     "TPSystemValue": "H171"
    },
    {
     
     "CodeDescription": "MUTSUSHITA GREATWALL CORP",
     "TPSystemValue": 2415
    },
    {
     
     "CodeDescription": "MW SINGAPORE PTE LTD",
     "TPSystemValue": 2158
    },
    {
     
     "CodeDescription": "MWH CONSULTANTS (S) PTE LTD",
     "TPSystemValue": 2416
    },
    {
     
     "CodeDescription": "MYANMAR TRAVELS & TOURS PTE LTD",
     "TPSystemValue": "H190"
    },
    {
     
     "CodeDescription": "MYSTIFLY GLOBAL CONSOLIDATION & TECHNOLOGY SERVICES PTE. LTD.",
     "TPSystemValue": "H838"
    },
    {
     
     "CodeDescription": "NACHI INDUSTRIES PTE LTD",
     "TPSystemValue": 2417
    },
    {
     
     "CodeDescription": "NAFA",
     "TPSystemValue": 2418
    },
    {
     
     "CodeDescription": "NAGASE SINGAPORE PTE LTD",
     "TPSystemValue": 2419
    },
    {
     
     "CodeDescription": "NALCO PACIFIC P L",
     "TPSystemValue": 2420
    },
    {
     
     "CodeDescription": "NAM HO TRAVEL SERVICE (SINGAPORE) PRIVATE LIMITED",
     "TPSystemValue": "H039"
    },
    {
     
     "CodeDescription": "NAM HONG PRIVATE LIMITED",
     "TPSystemValue": 3679
    },
    {
     
     "CodeDescription": "NANYANG ACADEMY OF FINE ARTS",
     "TPSystemValue": 2422
    },
    {
     
     "CodeDescription": "NANYANG GIRLS HIGH SCHOOL",
     "TPSystemValue": 2423
    },
    {
     
     "CodeDescription": "NANYANG INSTITUTE OF MANAGEMENT PL",
     "TPSystemValue": 2424
    },
    {
     
     "CodeDescription": "NANYANG INTERNATIONAL TRAVEL (PTE.) LTD.",
     "TPSystemValue": "H301"
    },
    {
     
     "CodeDescription": "NANYANG POLYTECHNIC INTERNATIONAL PRIVATE LIMITED",
     "TPSystemValue": 2425
    },
    {
     
     "CodeDescription": "NANYANG TECHNOLOGICAL UNIVERSITY",
     "TPSystemValue": 2426
    },
    {
     
     "CodeDescription": "NANYANG TRAVEL (S) PTE. LTD.",
     "TPSystemValue": "H343"
    },
    {
     
     "CodeDescription": "NAPIER HEALTHCARE SOLUTION PTE LTD",
     "TPSystemValue": 2427
    },
    {
     
     "CodeDescription": "NASHFIELD ENGINEERING CONSTRUCTION PL",
     "TPSystemValue": 2428
    },
    {
     
     "CodeDescription": "NATIONAL AUSTRALIA BANK",
     "TPSystemValue": 2429
    },
    {
     
     "CodeDescription": "NATIONAL AUSTRALIA BANK LTD",
     "TPSystemValue": "N01"
    },
    {
     
     "CodeDescription": "NATIONAL BANK OF KUWAIT",
     "TPSystemValue": 2430
    },
    {
     
     "CodeDescription": "NATIONAL BANK OF KUWAIT SAK",
     "TPSystemValue": "N02"
    },
    {
     
     "CodeDescription": "NATIONAL CANCER CENTER",
     "TPSystemValue": 2431
    },
    {
     
     "CodeDescription": "NATIONAL CAR RENTALS (PRIVATE) LIMITED",
     "TPSystemValue": 2432
    },
    {
     
     "CodeDescription": "NATIONAL COMPUTERS SYSTEMS PTE LTD",
     "TPSystemValue": 2433
    },
    {
     
     "CodeDescription": "NATIONAL COUNCIL OF SOCIAL SERVICE",
     "TPSystemValue": 2434
    },
    {
     
     "CodeDescription": "NATIONAL CRIME PREVENTION COUNCIL",
     "TPSystemValue": 3680
    },
    {
     
     "CodeDescription": "NATIONAL DENTAL CENTRE OF SINGAPORE PTE LTD",
     "TPSystemValue": 2435
    },
    {
     
     "CodeDescription": "NATIONAL ENVIRONMENT AGENCY",
     "TPSystemValue": 2436
    },
    {
     
     "CodeDescription": "NATIONAL HEALTHCARE GROUP PHARMACY",
     "TPSystemValue": 2437
    },
    {
     
     "CodeDescription": "NATIONAL HEALTHCARE GROUP POLYCLINIC",
     "TPSystemValue": 2438
    },
    {
     
     "CodeDescription": "NATIONAL HEALTHCARE GROUP PTE LTD",
     "TPSystemValue": 3681
    },
    {
     
     "CodeDescription": "NATIONAL HEART CENTRE OF SPORE PTE LTD",
     "TPSystemValue": 2439
    },
    {
     
     "CodeDescription": "NATIONAL HERITAGE BOARD",
     "TPSystemValue": 2440
    },
    {
     
     "CodeDescription": "NATIONAL INSTITUTE OF EDUCATION",
     "TPSystemValue": 2441
    },
    {
     
     "CodeDescription": "NATIONAL INSTRUMENTS ASIA PACIFIC PTE LT",
     "TPSystemValue": 2442
    },
    {
     
     "CodeDescription": "NATIONAL KIDNEY FOUNDATION",
     "TPSystemValue": 2443
    },
    {
     
     "CodeDescription": "NATIONAL NEUROSCIENCE INSTITUTE",
     "TPSystemValue": 2444
    },
    {
     
     "CodeDescription": "NATIONAL OILWELL VARCO PTE LTD",
     "TPSystemValue": 2445
    },
    {
     
     "CodeDescription": "NATIONAL OXYGEN PTE LTD",
     "TPSystemValue": 2446
    },
    {
     
     "CodeDescription": "NATIONAL PARK BOARD",
     "TPSystemValue": 2447
    },
    {
     
     "CodeDescription": "NATIONAL SKIN CENTRE",
     "TPSystemValue": 2448
    },
    {
     
     "CodeDescription": "NATIONAL STARCH PTE LTD",
     "TPSystemValue": 2449
    },
    {
     
     "CodeDescription": "NATIONAL TRADES UNION CONGRESS",
     "TPSystemValue": 2450
    },
    {
     
     "CodeDescription": "NATIONAL UNIVERSITY HEALTH SYSTEMS PTE LTD",
     "TPSystemValue": 2451
    },
    {
     
     "CodeDescription": "NATIONAL UNIVERSITY HOSPITAL SINGAPORE PTE LTD",
     "TPSystemValue": 2452
    },
    {
     
     "CodeDescription": "NATIONAL UNIVERSITY OF SINGAPORE",
     "TPSystemValue": 2453
    },
    {
     
     "CodeDescription": "NATISIS",
     "TPSystemValue": 2454
    },
    {
     
     "CodeDescription": "NATIXIS",
     "TPSystemValue": "N03"
    },
    {
     
     "CodeDescription": "NATSTEEL",
     "TPSystemValue": 2455
    },
    {
     
     "CodeDescription": "NATURAL COOL AIRCONDITIONING AND ENGINEERING PTE L",
     "TPSystemValue": 3682
    },
    {
     
     "CodeDescription": "NATURAL COOL HOLDINGS LIMITED",
     "TPSystemValue": 2456
    },
    {
     
     "CodeDescription": "NAVIGATOUR PTE LTD",
     "TPSystemValue": "H331"
    },
    {
     
     "CodeDescription": "NAVTEQ PTE LTD",
     "TPSystemValue": 2457
    },
    {
     
     "CodeDescription": "NCI GOLF PTE LTD",
     "TPSystemValue": 2458
    },
    {
     
     "CodeDescription": "NCR SINGAPORE PTE LTD",
     "TPSystemValue": 2459
    },
    {
     
     "CodeDescription": "NCS COMMUNICATIONS ENGINEERING PTE LTD",
     "TPSystemValue": 2460
    },
    {
     
     "CodeDescription": "NCS PTE LTD",
     "TPSystemValue": 2461
    },
    {
     
     "CodeDescription": "NEA",
     "TPSystemValue": 2462
    },
    {
     
     "CodeDescription": "NEC ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2463
    },
    {
     
     "CodeDescription": "NEFFUL SINGAPORE LOGISTICS PTE LTD",
     "TPSystemValue": 2464
    },
    {
     
     "CodeDescription": "NELCO PRODUCTS PTE LTD",
     "TPSystemValue": 2465
    },
    {
     
     "CodeDescription": "NEPES ELECTRONICS NEPES PTE LTD",
     "TPSystemValue": 2466
    },
    {
     
     "CodeDescription": "NEPTUNE ORIENT LINES LTD",
     "TPSystemValue": 2467
    },
    {
     
     "CodeDescription": "NEPTUNE SHIPMANAGEMENT SERVICES PTE LTD",
     "TPSystemValue": 2468
    },
    {
     
     "CodeDescription": "NERA TELECOMMUNICATIONS LTD",
     "TPSystemValue": 2469
    },
    {
     
     "CodeDescription": "NESTE OIL SINGAPORE PTE LTD",
     "TPSystemValue": 2470
    },
    {
     
     "CodeDescription": "NESTLE TREASURY CENTRE ASIA PACIFIC PTE LTD",
     "TPSystemValue": 3683
    },
    {
     
     "CodeDescription": "NETE2 ASIA PTE LTD",
     "TPSystemValue": 2471
    },
    {
     
     "CodeDescription": "NETPLUS COMMUNICATIONS PTE LTD",
     "TPSystemValue": 2472
    },
    {
     
     "CodeDescription": "NETS (S) PTE LTD",
     "TPSystemValue": 2473
    },
    {
     
     "CodeDescription": "NETWORK FOR ELECTRICIAN TRASNFERS",
     "TPSystemValue": 2474
    },
    {
     
     "CodeDescription": "NEUTRAL TECHNOLOGIES PTE LTD",
     "TPSystemValue": 2475
    },
    {
     
     "CodeDescription": "NEW CENTURY TOURS CORPORATION PTE LTD",
     "TPSystemValue": "H054"
    },
    {
     
     "CodeDescription": "NEW EFFECT PTE. LTD.",
     "TPSystemValue": "H818"
    },
    {
     
     "CodeDescription": "NEW ERA ENTERTAINMENT PTE LTD",
     "TPSystemValue": 2476
    },
    {
     
     "CodeDescription": "NEW MARITIME PTE LTD",
     "TPSystemValue": 2477
    },
    {
     
     "CodeDescription": "NEW NANYANG GROUP PTE. LTD.",
     "TPSystemValue": "H772"
    },
    {
     
     "CodeDescription": "NEW REGAL MARINE PTE LTD",
     "TPSystemValue": 2478
    },
    {
     
     "CodeDescription": "NEW SHAN TRAVEL SERVICE PTE LTD",
     "TPSystemValue": 2479
    },
    {
     
     "CodeDescription": "NEW SHAN TRAVEL SERVICE PTE. LTD.",
     "TPSystemValue": "H053"
    },
    {
     
     "CodeDescription": "NEW TREND LIFESTYLE PTE LTD",
     "TPSystemValue": 2480
    },
    {
     
     "CodeDescription": "NEW TREND LIFESTYLE PTE. LTD.",
     "TPSystemValue": "02N"
    },
    {
     
     "CodeDescription": "NEW URBAN MALE.COM PTE LTD",
     "TPSystemValue": 2481
    },
    {
     
     "CodeDescription": "NEW YORK SKIN SOLUTIONS PL",
     "TPSystemValue": 2482
    },
    {
     
     "CodeDescription": "NEWAY TRAVEL SERVICE PTE. LTD.",
     "TPSystemValue": "H491"
    },
    {
     
     "CodeDescription": "NEWCRUZ INTERNATIONAL PTE LTD",
     "TPSystemValue": 2483
    },
    {
     
     "CodeDescription": "NEWCRUZ OFFSHORE MARINE PTE LTD",
     "TPSystemValue": 2484
    },
    {
     
     "CodeDescription": "NEWCRUZ SHIPBUILDING ENGINEERING P L",
     "TPSystemValue": 2485
    },
    {
     
     "CodeDescription": "NEWEDGE FINANCIAL SINGAPORE PTE LTD",
     "TPSystemValue": 2486
    },
    {
     
     "CodeDescription": "NEWMAN & GOH PROPERTY CONSULT",
     "TPSystemValue": 2487
    },
    {
     
     "CodeDescription": "NEWSTATE STENHOUSE S PTE LTD",
     "TPSystemValue": 2488
    },
    {
     
     "CodeDescription": "NEWSTEAD TECHNOLOGY PTE LTD",
     "TPSystemValue": 2489
    },
    {
     
     "CodeDescription": "NEWTECH ENGEG CONSTRUCTION PTE LTD",
     "TPSystemValue": 2490
    },
    {
     
     "CodeDescription": "NEWTREND",
     "TPSystemValue": 2492
    },
    {
     
     "CodeDescription": "NEWURBANMALE.COM PTE LTD",
     "TPSystemValue": 2493
    },
    {
     
     "CodeDescription": "NEWURBANMALE.COM PTE. LTD.",
     "TPSystemValue": "04N"
    },
    {
     
     "CodeDescription": "NEWWAY TRAVEL SINGAPORE PRIVATE LIMITED",
     "TPSystemValue": "H494"
    },
    {
     
     "CodeDescription": "NEXWAVE TECHNOLOGIES PTE LTD",
     "TPSystemValue": 2494
    },
    {
     
     "CodeDescription": "NEXWAVE TELECOMS PTE LTD",
     "TPSystemValue": 2495
    },
    {
     
     "CodeDescription": "NGEE ANN POLYTECHNIC",
     "TPSystemValue": 2496
    },
    {
     
     "CodeDescription": "NIKE GLOBAL TRADING PTE LTD",
     "TPSystemValue": 3684
    },
    {
     
     "CodeDescription": "NIKE INC",
     "TPSystemValue": 2497
    },
    {
     
     "CodeDescription": "NIKKIN METAL INDUSTRIES PTE LTD",
     "TPSystemValue": 2498
    },
    {
     
     "CodeDescription": "NIKON SINGAPORE PTE LTD",
     "TPSystemValue": 2499
    },
    {
     
     "CodeDescription": "NILFISK ADVANCE (S) PTE LTD",
     "TPSystemValue": 2500
    },
    {
     
     "CodeDescription": "NINA TRAVEL PTE. LTD.",
     "TPSystemValue": "H606"
    },
    {
     
     "CodeDescription": "NIOMETRICS PTE LTD",
     "TPSystemValue": 2501
    },
    {
     
     "CodeDescription": "NIPPECRAFT LIMITED",
     "TPSystemValue": 2502
    },
    {
     
     "CodeDescription": "NIPPON EXPRESS (SINGAPORE) PTE LTD",
     "TPSystemValue": "H060"
    },
    {
     
     "CodeDescription": "NIPPON EXPRESS SINGAPORE PTE LTD",
     "TPSystemValue": 2503
    },
    {
     
     "CodeDescription": "NIPPON KAIJI KYOKAI PTE LTD",
     "TPSystemValue": 2504
    },
    {
     
     "CodeDescription": "NIPPON PAINT SINGAPORE PL",
     "TPSystemValue": 2505
    },
    {
     
     "CodeDescription": "NIPPON STEEL",
     "TPSystemValue": 2506
    },
    {
     
     "CodeDescription": "NIPPON STEEL ENGINEERING CO LTD",
     "TPSystemValue": 2507
    },
    {
     
     "CodeDescription": "NIRWANA PTE. LTD.",
     "TPSystemValue": "H425"
    },
    {
     
     "CodeDescription": "NISSHO ODYSSEY SHIP MANAGEMENT PTE LTD",
     "TPSystemValue": 2508
    },
    {
     
     "CodeDescription": "NISSIN FOODS (ASIA) PTE LTD",
     "TPSystemValue": 2509
    },
    {
     
     "CodeDescription": "NITYO INFOTECH SERVICES PTE LTD",
     "TPSystemValue": 2510
    },
    {
     
     "CodeDescription": "NNT TECHNOLOGY (S) PTE LTD",
     "TPSystemValue": 2511
    },
    {
     
     "CodeDescription": "NO ROC_INTERNATIONAL ENTERPRISE SINGAPORE",
     "TPSystemValue": 2512
    },
    {
     
     "CodeDescription": "NO ROC_PUBLIC SERVICES INTERNATIONAL",
     "TPSystemValue": 2513
    },
    {
     
     "CodeDescription": "NO ROCSIMEI CARE CENTRE",
     "TPSystemValue": 2514
    },
    {
     
     "CodeDescription": "NO SIGNBOARD SEAFOOD RESTAURANT PTE LTD",
     "TPSystemValue": 2515
    },
    {
     
     "CodeDescription": "NOBLE CAPITAL RESOURCES (ASIA) PTE LTD",
     "TPSystemValue": 2516
    },
    {
     
     "CodeDescription": "NOBLE DENTON SINGAPORE PTELTD",
     "TPSystemValue": 2517
    },
    {
     
     "CodeDescription": "NOBLE GROUP",
     "TPSystemValue": 2518
    },
    {
     
     "CodeDescription": "NOBLE RESOURCES PTE LTD",
     "TPSystemValue": 2519
    },
    {
     
     "CodeDescription": "NOK ASIA COMPANY PTE LTD",
     "TPSystemValue": 2520
    },
    {
     
     "CodeDescription": "NOKIA HOLDINGS (SINGAPORE) PTE LTD",
     "TPSystemValue": 2521
    },
    {
     
     "CodeDescription": "NOKIA SIEMENS NETWORKS HOLDINGS SINGAPORE PTE LTD",
     "TPSystemValue": 2522
    },
    {
     
     "CodeDescription": "NOKIA SIEMENS NETWORKS SINGAPORE PTE LTD",
     "TPSystemValue": 2523
    },
    {
     
     "CodeDescription": "NOMURA ASSET MANAGEMENT SINGAPORE LTD",
     "TPSystemValue": 2524
    },
    {
     
     "CodeDescription": "NOMURA SINGAPORE SG",
     "TPSystemValue": 2525
    },
    {
     
     "CodeDescription": "NOOR MOHAMAD SERVICES & TRAVEL PTE LTD",
     "TPSystemValue": "H282"
    },
    {
     
     "CodeDescription": "NORDAM SINGAPORE PTE LTD",
     "TPSystemValue": 2526
    },
    {
     
     "CodeDescription": "NORDEA BANK FINLAND PLC",
     "TPSystemValue": "N05"
    },
    {
     
     "CodeDescription": "NORDEA BANK FINLAND PLC SINGAPORE",
     "TPSystemValue": 2528
    },
    {
     
     "CodeDescription": "NORDIC FLOW CONTROL PTE LTD",
     "TPSystemValue": 2529
    },
    {
     
     "CodeDescription": "NORINCHUKIN BANK, THE",
     "TPSystemValue": "N06"
    },
    {
     
     "CodeDescription": "NORSAFE SINGAPORE PTE LTD",
     "TPSystemValue": 2530
    },
    {
     
     "CodeDescription": "NORTEL NETWORKS PTE LTD",
     "TPSystemValue": 2531
    },
    {
     
     "CodeDescription": "NORTHERN TRUST COMPANY, THE",
     "TPSystemValue": "N07"
    },
    {
     
     "CodeDescription": "NORTHWEST AIRLINE",
     "TPSystemValue": 2532
    },
    {
     
     "CodeDescription": "NORTON ROSE ASIA LLP",
     "TPSystemValue": 2533
    },
    {
     
     "CodeDescription": "NOV ASEP ELMAR PTE LTD",
     "TPSystemValue": 2534
    },
    {
     
     "CodeDescription": "NOV FGS SINGAPORE PTE LTD",
     "TPSystemValue": 2535
    },
    {
     
     "CodeDescription": "NOVARTIS INSTITUTE FOR TROPICAL DISEASES",
     "TPSystemValue": 2537
    },
    {
     
     "CodeDescription": "NOVARTIS S PHARMACEUTICAL MANUFACTURING PL",
     "TPSystemValue": 2538
    },
    {
     
     "CodeDescription": "NOVARTIS SINGAPORE PTE LTD",
     "TPSystemValue": 2536
    },
    {
     
     "CodeDescription": "NSF",
     "TPSystemValue": 2539
    },
    {
     
     "CodeDescription": "NSK AT WORK (EVENTS & TOURS) PTE. LTD.",
     "TPSystemValue": "H476"
    },
    {
     
     "CodeDescription": "NSL CHEMICALS LTD",
     "TPSystemValue": 2540
    },
    {
     
     "CodeDescription": "NSL OILCHEM SERVICES PTE LTD",
     "TPSystemValue": 2541
    },
    {
     
     "CodeDescription": "NSSPL",
     "TPSystemValue": 2542
    },
    {
     
     "CodeDescription": "NTA TRAVEL (S) PTE LTD",
     "TPSystemValue": "H133"
    },
    {
     
     "CodeDescription": "NTAN CORPORATE ADVISORY PRIVATE LTD",
     "TPSystemValue": 2543
    },
    {
     
     "CodeDescription": "NTU (NANYANG TECHNOLOGICAL UNIVERSITY)",
     "TPSystemValue": 2544
    },
    {
     
     "CodeDescription": "NTUC",
     "TPSystemValue": 2545
    },
    {
     
     "CodeDescription": "NTUC ADMINISTRATION AND RESEARCH",
     "TPSystemValue": 2546
    },
    {
     
     "CodeDescription": "NTUC CHILDCARE CO-OPERATIVE LTD.",
     "TPSystemValue": 2547
    },
    {
     
     "CodeDescription": "NTUC COMFORT",
     "TPSystemValue": 2548
    },
    {
     
     "CodeDescription": "NTUC FAIR PRICE CO-OPERATIVE LTD",
     "TPSystemValue": 2549
    },
    {
     
     "CodeDescription": "NTUC INCOME",
     "TPSystemValue": 2550
    },
    {
     
     "CodeDescription": "NTUC INCOME ASSET MANAGEMENT LTD",
     "TPSystemValue": 3685
    },
    {
     
     "CodeDescription": "NTUC INCOME ENTERPRISES PTE LTD",
     "TPSystemValue": 2551
    },
    {
     
     "CodeDescription": "NTUC LEARNING HUB PTE LTD",
     "TPSystemValue": 2552
    },
    {
     
     "CodeDescription": "NUANCE-WATSON (SINGAPORE) PTE LTD",
     "TPSystemValue": 2553
    },
    {
     
     "CodeDescription": "NUBEE PTE LTD",
     "TPSystemValue": 2554
    },
    {
     
     "CodeDescription": "NUCLEUS CONNECT PTE LTD",
     "TPSystemValue": 2555
    },
    {
     
     "CodeDescription": "NUCLEUS SOFTWARE SOLUTIONS P L",
     "TPSystemValue": 2556
    },
    {
     
     "CodeDescription": "NUH - NATIONAL UNIVERSITY HOSPITAL",
     "TPSystemValue": 2557
    },
    {
     
     "CodeDescription": "NUHS",
     "TPSystemValue": 2558
    },
    {
     
     "CodeDescription": "NULOGIC PTE. LTD.",
     "TPSystemValue": 2559
    },
    {
     
     "CodeDescription": "NUMONYX PTE LTD",
     "TPSystemValue": 2560
    },
    {
     
     "CodeDescription": "NURHIKMAH TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H211"
    },
    {
     
     "CodeDescription": "NUSS",
     "TPSystemValue": 2561
    },
    {
     
     "CodeDescription": "NUTUNE SINGAPORE PTE LTD",
     "TPSystemValue": 2562
    },
    {
     
     "CodeDescription": "NXP SEMICONDUCTORS SINGAPORE PTE LTD",
     "TPSystemValue": 2563
    },
    {
     
     "CodeDescription": "NYENBURGH PTE LTD",
     "TPSystemValue": 2564
    },
    {
     
     "CodeDescription": "NYK BULKSHIP ASIA PTE LTD",
     "TPSystemValue": 2565
    },
    {
     
     "CodeDescription": "NYK BUSINESS SYSTEMS COLTD",
     "TPSystemValue": 3686
    },
    {
     
     "CodeDescription": "NYK FTC SINGAPORE PTE LTD",
     "TPSystemValue": 2566
    },
    {
     
     "CodeDescription": "NYK GROUP SOUTH EAST ASIA PTE LTD",
     "TPSystemValue": 2567
    },
    {
     
     "CodeDescription": "NYK LINE ASIA P L",
     "TPSystemValue": 2568
    },
    {
     
     "CodeDescription": "NYK SHIPMANGEMENT PTE LTD",
     "TPSystemValue": 2569
    },
    {
     
     "CodeDescription": "NYP",
     "TPSystemValue": 2570
    },
    {
     
     "CodeDescription": "OAC INSURANCE",
     "TPSystemValue": 2571
    },
    {
     
     "CodeDescription": "OAKWELL ENGINEERING LIMITED",
     "TPSystemValue": 2572
    },
    {
     
     "CodeDescription": "OANDA ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2573
    },
    {
     
     "CodeDescription": "OBTECH ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2574
    },
    {
     
     "CodeDescription": "OCBC BANK",
     "TPSystemValue": 2575
    },
    {
     
     "CodeDescription": "OCBC BULLION AND FUTURES LIMITED",
     "TPSystemValue": 2576
    },
    {
     
     "CodeDescription": "OCBC PROPERTY SERVICES PTE LTD",
     "TPSystemValue": 2577
    },
    {
     
     "CodeDescription": "OCBC SECRITIES PTE LTD",
     "TPSystemValue": 2578
    },
    {
     
     "CodeDescription": "OCE SINGAPORE PTE LTD",
     "TPSystemValue": 2579
    },
    {
     
     "CodeDescription": "OCEAN MELODY TRAVEL SERVICES PRIVATE LIMITED",
     "TPSystemValue": "H442"
    },
    {
     
     "CodeDescription": "OCEAN PEARL SHIPPING & SERVICES PTE LTD",
     "TPSystemValue": 2580
    },
    {
     
     "CodeDescription": "OCEAN SKY INTERNATIONAL PTE LTD",
     "TPSystemValue": 2581
    },
    {
     
     "CodeDescription": "OCEAN TANKERS PTE LTD",
     "TPSystemValue": 2582
    },
    {
     
     "CodeDescription": "OCEAN WAY ENGINEERING CONSTRUCTION PL",
     "TPSystemValue": 2583
    },
    {
     
     "CodeDescription": "OCEAN WAY ENGINEERINGCONSTRUCTION PTE LTD",
     "TPSystemValue": "01O"
    },
    {
     
     "CodeDescription": "OCEANEERING INTERNATIONAL PTE LTD",
     "TPSystemValue": 2584
    },
    {
     
     "CodeDescription": "OCEANIC GOLD PHILIPPINES PTE LTD",
     "TPSystemValue": 3687
    },
    {
     
     "CodeDescription": "OCEANIC OFFSHORE ENGINEERING PTE LTD",
     "TPSystemValue": 2585
    },
    {
     
     "CodeDescription": "OCLANER ASSET MANAGEMENT PTE LTD",
     "TPSystemValue": 2586
    },
    {
     
     "CodeDescription": "OCONNORS SINGAPORE PTE LTD",
     "TPSystemValue": 2587
    },
    {
     
     "CodeDescription": "OCTOPUS CONCEPTS & FRANCHISE PTE LTD",
     "TPSystemValue": 2588
    },
    {
     
     "CodeDescription": "OCTOPUS ENTERTAINMENT PTE LTD",
     "TPSystemValue": 2589
    },
    {
     
     "CodeDescription": "OCTOPUS GROUP HOLDINGS PTE LTD",
     "TPSystemValue": 2590
    },
    {
     
     "CodeDescription": "OCULUS LIMITED",
     "TPSystemValue": 2591
    },
    {
     
     "CodeDescription": "OERLIKON SOLAR SINGAPORE PTE LTD",
     "TPSystemValue": 2593
    },
    {
     
     "CodeDescription": "OFFSHORE SEALS ASIA PTE LTD",
     "TPSystemValue": 2594
    },
    {
     
     "CodeDescription": "OGAWA HEALTH-CARE PTE LTD",
     "TPSystemValue": 2595
    },
    {
     
     "CodeDescription": "OGILVY & MATHER ADVERTISING ASIA PACIFIC",
     "TPSystemValue": 2596
    },
    {
     
     "CodeDescription": "OGILVY ONE",
     "TPSystemValue": 2597
    },
    {
     
     "CodeDescription": "O-HOLISTIC TOURS PTE. LTD.",
     "TPSystemValue": "H757"
    },
    {
     
     "CodeDescription": "OIL AND GAS SINGAPORE PTE LTD",
     "TPSystemValue": 2598
    },
    {
     
     "CodeDescription": "OIL SPILL RESPONSE LIMITED",
     "TPSystemValue": 2599
    },
    {
     
     "CodeDescription": "OIL STATES INDUSTRIES ASIA PTE LTD",
     "TPSystemValue": 2600
    },
    {
     
     "CodeDescription": "OILTANKING SINGAPORE PTE LD",
     "TPSystemValue": 2601
    },
    {
     
     "CodeDescription": "OKH HOLDING PTE LTD",
     "TPSystemValue": 2602
    },
    {
     
     "CodeDescription": "OLAM INTERNATIONAL LIMITED",
     "TPSystemValue": 2603
    },
    {
     
     "CodeDescription": "OLDENDORFF CARRIERS SINGAPORE PTE LTD",
     "TPSystemValue": 3688
    },
    {
     
     "CodeDescription": "OLYMPUS SINGAPORE PTE LTD",
     "TPSystemValue": 2604
    },
    {
     
     "CodeDescription": "OLYMPUS TECHNOLOGIES SINGAPORE PTE LTD",
     "TPSystemValue": 2605
    },
    {
     
     "CodeDescription": "OM GROUP",
     "TPSystemValue": 2606
    },
    {
     
     "CodeDescription": "OMD SINGAPORE PTE LTD",
     "TPSystemValue": 2607
    },
    {
     
     "CodeDescription": "OMEGA CONSTRUCTION & ENGINEERING PTE LTD",
     "TPSystemValue": 2608
    },
    {
     
     "CodeDescription": "OMEGA INTEGRATION PTE LTD",
     "TPSystemValue": 3792
    },
    {
     
     "CodeDescription": "OMEGA TOURS AND TRAVEL PTE LTD",
     "TPSystemValue": "H367"
    },
    {
     
     "CodeDescription": "OMNI MOLD PTE LTD",
     "TPSystemValue": 2610
    },
    {
     
     "CodeDescription": "OMNIAR PTE LTD",
     "TPSystemValue": 2611
    },
    {
     
     "CodeDescription": "OMNITECH",
     "TPSystemValue": 2612
    },
    {
     
     "CodeDescription": "OMNITECH SERVICES PTE LTD",
     "TPSystemValue": 2613
    },
    {
     
     "CodeDescription": "OMO",
     "TPSystemValue": 2614
    },
    {
     
     "CodeDescription": "OMPS PTE LTD",
     "TPSystemValue": 2615
    },
    {
     
     "CodeDescription": "OMRON ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2616
    },
    {
     
     "CodeDescription": "OMRON ELECTRONICS PTE LTD",
     "TPSystemValue": 2617
    },
    {
     
     "CodeDescription": "OMRON HEALTHCARE SINGAPORE PTE LTD",
     "TPSystemValue": 2618
    },
    {
     
     "CodeDescription": "ON DEMAND AGILITY SOLUTIONS PTE LTD",
     "TPSystemValue": 2619
    },
    {
     
     "CodeDescription": "ONASUS PTE LTD",
     "TPSystemValue": 2620
    },
    {
     
     "CodeDescription": "ONE 15 MARINA CLUB",
     "TPSystemValue": 2621
    },
    {
     
     "CodeDescription": "ONE ADVENTURE PTE. LTD.",
     "TPSystemValue": "H712"
    },
    {
     
     "CodeDescription": "ONE BERRY TECHNOLOGIES PTE LTD",
     "TPSystemValue": 2622
    },
    {
     
     "CodeDescription": "ONE MARINA PROPERTY SERVICES PTE LTD",
     "TPSystemValue": 2623
    },
    {
     
     "CodeDescription": "ONE15 LUXURY YACHTING PTE. LTD.",
     "TPSystemValue": "H451"
    },
    {
     
     "CodeDescription": "ONEBERRY TECHNOLOGIES PTE LTD",
     "TPSystemValue": 2624
    },
    {
     
     "CodeDescription": "ONEBERRY TECHNOLOGIES PTE. LTD.",
     "TPSystemValue": "04O"
    },
    {
     
     "CodeDescription": "ONG & ONG PTE LTD",
     "TPSystemValue": 2625
    },
    {
     
     "CodeDescription": "ONG FIRST TRADITION PTE LTD",
     "TPSystemValue": 2626
    },
    {
     
     "CodeDescription": "ONN WAH PRECISION MACHINING PTE LTD",
     "TPSystemValue": 2627
    },
    {
     
     "CodeDescription": "ONN WAH TECHNOLOGY PTE LTD",
     "TPSystemValue": 2628
    },
    {
     
     "CodeDescription": "OOCL SINGAPORE PTE LTD",
     "TPSystemValue": 2629
    },
    {
     
     "CodeDescription": "OP3 INTERNATIONAL PTE LTD",
     "TPSystemValue": 2630
    },
    {
     
     "CodeDescription": "OPET TRADE SINGAPORE PTE LTD",
     "TPSystemValue": 2631
    },
    {
     
     "CodeDescription": "OPTIMUM SOLUTIONS SINGAPORE PTE LTD",
     "TPSystemValue": 2632
    },
    {
     
     "CodeDescription": "OPTIQUE PARIS MIKI SINGAPORE PTE LTD",
     "TPSystemValue": 2633
    },
    {
     
     "CodeDescription": "OPUS IT SERVICES PTE LTD",
     "TPSystemValue": 2634
    },
    {
     
     "CodeDescription": "OR KIM PEOW CONTRACTORS PTE LTD",
     "TPSystemValue": 2635
    },
    {
     
     "CodeDescription": "ORACLE CORPORATION SINGAPORE PTE LTD",
     "TPSystemValue": 3689
    },
    {
     
     "CodeDescription": "ORACLE FINANCIAL SERVICES CONSULTING PTE LTD",
     "TPSystemValue": 3807
    },
    {
     
     "CodeDescription": "ORACLE FINANCIAL SERVICES PTE LTD",
     "TPSystemValue": 3690
    },
    {
     
     "CodeDescription": "ORACLE FINANCIAL SERVICES SOFTWARE PTE LTD",
     "TPSystemValue": 2636
    },
    {
     
     "CodeDescription": "ORACLE SINGAPORE PTE LTD",
     "TPSystemValue": 2637
    },
    {
     
     "CodeDescription": "ORACLE SYSTEMS SEA (S) PL",
     "TPSystemValue": 2638
    },
    {
     
     "CodeDescription": "ORANGE BUSINESS SERVICES",
     "TPSystemValue": 2639
    },
    {
     
     "CodeDescription": "ORANGE TEE_COM PTE LTD",
     "TPSystemValue": 2640
    },
    {
     
     "CodeDescription": "ORANGE TRAVEL PTE. LTD.",
     "TPSystemValue": "H452"
    },
    {
     
     "CodeDescription": "ORANGETEE HOLDINGS PTE LTD",
     "TPSystemValue": 3691
    },
    {
     
     "CodeDescription": "ORANGETEE.COM PTE LTD",
     "TPSystemValue": 2641
    },
    {
     
     "CodeDescription": "ORCHARD HOTEL SINGAPORE",
     "TPSystemValue": 2642
    },
    {
     
     "CodeDescription": "ORCHARD TURN DEVELOPMENTS PTE LTD",
     "TPSystemValue": 2643
    },
    {
     
     "CodeDescription": "ORCHID COUNTRY CLUB",
     "TPSystemValue": 3692
    },
    {
     
     "CodeDescription": "ORDNANCE DEVELOPMENT & ENGINEERING",
     "TPSystemValue": 2644
    },
    {
     
     "CodeDescription": "OREGON PRIME MARKETING (INTERNATIONAL) PTE LTD",
     "TPSystemValue": 2645
    },
    {
     
     "CodeDescription": "ORICA INTERNATIONAL PTE LTD",
     "TPSystemValue": 2646
    },
    {
     
     "CodeDescription": "ORIENT EUROPHARMA PTE LTD",
     "TPSystemValue": 2647
    },
    {
     
     "CodeDescription": "ORIENT GLOBAL SINGAPORE PTE LTD",
     "TPSystemValue": 2648
    },
    {
     
     "CodeDescription": "ORIENT OVERSEAS CONTAINER LINE LTD",
     "TPSystemValue": 2649
    },
    {
     
     "CodeDescription": "OSIM INTERNATIONAL LTD",
     "TPSystemValue": 2650
    },
    {
     
     "CodeDescription": "OSINET GLOBAL SERVICES PTE LTD",
     "TPSystemValue": 2651
    },
    {
     
     "CodeDescription": "OSINET INTERNATIONAL PTE LTD",
     "TPSystemValue": 3779
    },
    {
     
     "CodeDescription": "OTIS ELAVATOR S PTE LTD",
     "TPSystemValue": 2652
    },
    {
     
     "CodeDescription": "OTO BODYCARE PTE LTD",
     "TPSystemValue": 2653
    },
    {
     
     "CodeDescription": "OTTO MARINE LIMITED",
     "TPSystemValue": 2654
    },
    {
     
     "CodeDescription": "OTTO SOLUTIONS PTE LTD",
     "TPSystemValue": 2655
    },
    {
     
     "CodeDescription": "OVERSEA CHINESE BANKING CORPORATION LTD",
     "TPSystemValue": 2656
    },
    {
     
     "CodeDescription": "OVERSEA-CHINESE BANKING CORPORATION LIMITED",
     "TPSystemValue": "O01"
    },
    {
     
     "CodeDescription": "OVERSEAS FAMILY SCHOLL PTE. LTD.",
     "TPSystemValue": 2657
    },
    {
     
     "CodeDescription": "OVERSEAS UNION BANK",
     "TPSystemValue": 2658
    },
    {
     
     "CodeDescription": "P & T CONSULTANTS PTE LTD",
     "TPSystemValue": 2659
    },
    {
     
     "CodeDescription": "P.I.L. CONSORTIUM PTE. LTD.",
     "TPSystemValue": "01P"
    },
    {
     
     "CodeDescription": "P.I.L. CONSTRUCTION PTE LTD",
     "TPSystemValue": 2661
    },
    {
     
     "CodeDescription": "P.S.E ENGINEERING & CONSTRUCTION PTE LTD",
     "TPSystemValue": 2662
    },
    {
     
     "CodeDescription": "P3 PROJECTS PTE LTD",
     "TPSystemValue": 1334
    },
    {
     
     "CodeDescription": "PACBRIDGE CAPITAL PARTNERS (SG) PL",
     "TPSystemValue": 1207
    },
    {
     
     "CodeDescription": "PACC SHIP MANAGERS PTE LTD",
     "TPSystemValue": 3693
    },
    {
     
     "CodeDescription": "PACIFIC ARENA PTE. LTD.",
     "TPSystemValue": "H075"
    },
    {
     
     "CodeDescription": "PACIFIC HEALTHCARE HOLDINGS LTD",
     "TPSystemValue": 2663
    },
    {
     
     "CodeDescription": "PACIFIC INTERNATIONAL LINES PTE LTD",
     "TPSystemValue": 2664
    },
    {
     
     "CodeDescription": "PACIFIC INTERNET",
     "TPSystemValue": 2665
    },
    {
     
     "CodeDescription": "PACIFIC RESOURCES TRADING PTE LTD",
     "TPSystemValue": 1237
    },
    {
     
     "CodeDescription": "PACIFIC STAR ASSET MANAGEMENT GP PL",
     "TPSystemValue": 1175
    },
    {
     
     "CodeDescription": "PACIFIC STAR HOLDINGS PTE. LTD.",
     "TPSystemValue": "02P"
    },
    {
     
     "CodeDescription": "PACIFIC WORLD MEETINGS & EVENTS SINGAPORE PTE. LTD.",
     "TPSystemValue": "H620"
    },
    {
     
     "CodeDescription": "PACIFIC WORLD SINGAPORE PTE LTD",
     "TPSystemValue": 313
    },
    {
     
     "CodeDescription": "PACNET GLOBAL SINGAPORE PTE LTD",
     "TPSystemValue": 743
    },
    {
     
     "CodeDescription": "PACNET INTERNET S PTE LTD",
     "TPSystemValue": 500
    },
    {
     
     "CodeDescription": "PACORINI METALS (ASIA) PTE LTD",
     "TPSystemValue": 917
    },
    {
     
     "CodeDescription": "PAC-WEST TRAVEL (S) PTE LTD",
     "TPSystemValue": "H198"
    },
    {
     
     "CodeDescription": "PALM TRAVEL PTE LTD",
     "TPSystemValue": "H166"
    },
    {
     
     "CodeDescription": "PAMCO SERVICES PTE LTD",
     "TPSystemValue": 279
    },
    {
     
     "CodeDescription": "PAN AFRICA RESOURCES PTE LTD",
     "TPSystemValue": 3694
    },
    {
     
     "CodeDescription": "PAN ASIA LOGISTICS S PTE LTD",
     "TPSystemValue": 2666
    },
    {
     
     "CodeDescription": "PAN ASIA RESOURCES PTE LTD",
     "TPSystemValue": 476
    },
    {
     
     "CodeDescription": "PAN GREATWAYS ENGINEERING ASIA PTE LTD",
     "TPSystemValue": 2667
    },
    {
     
     "CodeDescription": "PAN GREATWAYS ENGINEERING PTE LTD",
     "TPSystemValue": "03P"
    },
    {
     
     "CodeDescription": "PAN OCEAN MARINE PTE LTD",
     "TPSystemValue": 2668
    },
    {
     
     "CodeDescription": "PAN PACIFIC HOTEL",
     "TPSystemValue": 2669
    },
    {
     
     "CodeDescription": "PAN RESOURCES PTE LTD",
     "TPSystemValue": 3808
    },
    {
     
     "CodeDescription": "PAN UNITED CARRIERS PTE LTD",
     "TPSystemValue": 961
    },
    {
     
     "CodeDescription": "PAN UNITED CONCRETE PL",
     "TPSystemValue": 3695
    },
    {
     
     "CodeDescription": "PAN UNITED SHIPPING",
     "TPSystemValue": 3696
    },
    {
     
     "CodeDescription": "PAN YOMA TRAVEL PTE. LTD.",
     "TPSystemValue": "H453"
    },
    {
     
     "CodeDescription": "PANALPINA WORLD TRANSPORT (SG) PTE LTD",
     "TPSystemValue": 128
    },
    {
     
     "CodeDescription": "PANASIA SURGERY PTE LTD",
     "TPSystemValue": 3697
    },
    {
     
     "CodeDescription": "PANASONIC ASIA PACIFIC PTE LTD",
     "TPSystemValue": 94
    },
    {
     
     "CodeDescription": "PANASONIC ELECTRIC WORKS APC PL",
     "TPSystemValue": 338
    },
    {
     
     "CodeDescription": "PANASONIC FACTORY SOLUTIONS APC PL",
     "TPSystemValue": 146
    },
    {
     
     "CodeDescription": "PANASONIC INDUSTRIAL DEVICES SEMICONDUCTOR ASIA PT",
     "TPSystemValue": 3698
    },
    {
     
     "CodeDescription": "PANASONIC INDUSTRIAL DEVICES SINGAPORE",
     "TPSystemValue": 1432
    },
    {
     
     "CodeDescription": "PANASONIC REFRIGERATION DEVICES S PL",
     "TPSystemValue": 127
    },
    {
     
     "CodeDescription": "PANASONIC SEMICONDUCTOR ASIA PTE LTD",
     "TPSystemValue": 144
    },
    {
     
     "CodeDescription": "PANASONIC SOLUTIONS",
     "TPSystemValue": 126
    },
    {
     
     "CodeDescription": "PANDA TRAVEL AGENCY PTE LTD",
     "TPSystemValue": "H113"
    },
    {
     
     "CodeDescription": "PANDUIT SINGAPORE PTE LTD",
     "TPSystemValue": 2670
    },
    {
     
     "CodeDescription": "PAP COMMUNITY FOUNDATION",
     "TPSystemValue": 2671
    },
    {
     
     "CodeDescription": "PAPE ENGINEERING PTE LTD",
     "TPSystemValue": 1159
    },
    {
     
     "CodeDescription": "PARADISE TOURS PTE. LTD.",
     "TPSystemValue": "H793"
    },
    {
     
     "CodeDescription": "PARALLELS SOFTWARE PTE LTD",
     "TPSystemValue": 1247
    },
    {
     
     "CodeDescription": "PARK HOTELS & RESORTS",
     "TPSystemValue": "H016"
    },
    {
     
     "CodeDescription": "PARKER HANNIFIN SINGAPORE PL",
     "TPSystemValue": 117
    },
    {
     
     "CodeDescription": "PARKWAY HOSPITAL SINGAPORE PTE LTD",
     "TPSystemValue": 932
    },
    {
     
     "CodeDescription": "PARKWAY SHENTON PTE LTD",
     "TPSystemValue": 529
    },
    {
     
     "CodeDescription": "PARSONS BRINCKERHOFF PTE LTD",
     "TPSystemValue": 2672
    },
    {
     
     "CodeDescription": "PARTHNON DERIVATIVES PTE LTD",
     "TPSystemValue": 954
    },
    {
     
     "CodeDescription": "PARTNERS GROUP (SINGAPORE) PTE LTD",
     "TPSystemValue": 2673
    },
    {
     
     "CodeDescription": "PASSION TOURS PTE. LTD.",
     "TPSystemValue": "H570"
    },
    {
     
     "CodeDescription": "PATHOLOGY CLINICAL LABORATORY PTE LTD",
     "TPSystemValue": 2674
    },
    {
     
     "CodeDescription": "PATRICK MIRANDAH CO ASIA PTE LTD",
     "TPSystemValue": 3809
    },
    {
     
     "CodeDescription": "PAVEWAY EXPLORER HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H296"
    },
    {
     
     "CodeDescription": "PAYPAL PTE LTD",
     "TPSystemValue": 993
    },
    {
     
     "CodeDescription": "PCS SECURITY PTE LTD",
     "TPSystemValue": 2675
    },
    {
     
     "CodeDescription": "PEARSON EDUCATION (SINGAPORE) PTE LTD",
     "TPSystemValue": 206
    },
    {
     
     "CodeDescription": "PEC LTD",
     "TPSystemValue": 193
    },
    {
     
     "CodeDescription": "PEDRO GROUP PTE LTD",
     "TPSystemValue": 1007
    },
    {
     
     "CodeDescription": "PEGASUS TRAVEL MANAGEMENT PTE. LTD.",
     "TPSystemValue": "H416"
    },
    {
     
     "CodeDescription": "PELCO ASIA PACIFIC PTE LTD",
     "TPSystemValue": 931
    },
    {
     
     "CodeDescription": "PEMAC PTE LTD",
     "TPSystemValue": 2677
    },
    {
     
     "CodeDescription": "PENANSHIN SHIPPING PTE LTD",
     "TPSystemValue": 2678
    },
    {
     
     "CodeDescription": "PENG HENG COACH SERVICES PRIVATE LIMITED",
     "TPSystemValue": "H639"
    },
    {
     
     "CodeDescription": "PENGUIN SHIPYARD INTERNATIONAL PTE LTD",
     "TPSystemValue": 775
    },
    {
     
     "CodeDescription": "PENTA OCEAN CONSTRUCTION CO LTD",
     "TPSystemValue": 2679
    },
    {
     
     "CodeDescription": "PENTAGON SYSTEMS PTE LTD",
     "TPSystemValue": "H188"
    },
    {
     
     "CodeDescription": "PENTEL (SINGAPORE) PTE LTD",
     "TPSystemValue": 161
    },
    {
     
     "CodeDescription": "PEOPLE S PUBLISHING PTE LTD",
     "TPSystemValue": 569
    },
    {
     
     "CodeDescription": "PEOPLE TRAVEL PTE. LTD.",
     "TPSystemValue": "H647"
    },
    {
     
     "CodeDescription": "PEOPLES ASSOCIATION",
     "TPSystemValue": 3096
    },
    {
     
     "CodeDescription": "PEPPERL & FUCHS MFG PTE LTD",
     "TPSystemValue": 2680
    },
    {
     
     "CodeDescription": "PERFECT TRAVEL (99) PTE LTD",
     "TPSystemValue": "H274"
    },
    {
     
     "CodeDescription": "PERFORMANCE MOTORS LTD",
     "TPSystemValue": 2681
    },
    {
     
     "CodeDescription": "PERKINELMER SINGAPORE PTE LTD",
     "TPSystemValue": 623
    },
    {
     
     "CodeDescription": "PEROT SYSTEMS SINGAPORE PTE LTD",
     "TPSystemValue": 560
    },
    {
     
     "CodeDescription": "PERTAMINA ENERGY SERVICES PTE LTD",
     "TPSystemValue": 401
    },
    {
     
     "CodeDescription": "PESKO ENGINEERING PTE LTD",
     "TPSystemValue": 2682
    },
    {
     
     "CodeDescription": "PETREDEC SERVICES (ASIA) PTE LTD",
     "TPSystemValue": 486
    },
    {
     
     "CodeDescription": "PETROBRAS SINGAPORE PTE LTD",
     "TPSystemValue": 1045
    },
    {
     
     "CodeDescription": "PETROCHEMICAL CORPORATION OF SINGAPORE",
     "TPSystemValue": 2683
    },
    {
     
     "CodeDescription": "PETRO-DIAMOND SINGAPORE (PTE) LTD",
     "TPSystemValue": 323
    },
    {
     
     "CodeDescription": "PETROFAC SOUTH EAST ASIA PTE LTD",
     "TPSystemValue": 1322
    },
    {
     
     "CodeDescription": "PETROLEUM GEO-SERVICES ASIA PACIFIC PTD",
     "TPSystemValue": 2684
    },
    {
     
     "CodeDescription": "PETRONS SINGAPORE TRADING PTE LTD",
     "TPSystemValue": 1277
    },
    {
     
     "CodeDescription": "PETROTEC PTE LTD",
     "TPSystemValue": 1087
    },
    {
     
     "CodeDescription": "PEVKIN ELMER",
     "TPSystemValue": 2685
    },
    {
     
     "CodeDescription": "PFEIFFER VACUUM SINGAPORE PTE LTD",
     "TPSystemValue": 1297
    },
    {
     
     "CodeDescription": "PFIZER ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2686
    },
    {
     
     "CodeDescription": "PFIZER PRIVATE LIMITED",
     "TPSystemValue": 29
    },
    {
     
     "CodeDescription": "PG INTERNATIONAL",
     "TPSystemValue": 2660
    },
    {
     
     "CodeDescription": "PGE MARINE PTE LTD",
     "TPSystemValue": 2687
    },
    {
     
     "CodeDescription": "PGE MARINE PTE. LTD.",
     "TPSystemValue": "06P"
    },
    {
     
     "CodeDescription": "PGI INFOTECH PTE LTD",
     "TPSystemValue": 2688
    },
    {
     
     "CodeDescription": "PHD SINGAPORE PTE LTD",
     "TPSystemValue": 860
    },
    {
     
     "CodeDescription": "PHILIP MORRIS SINGAPORE",
     "TPSystemValue": 2689
    },
    {
     
     "CodeDescription": "PHILIP SECURITIES PTE LTD",
     "TPSystemValue": 2690
    },
    {
     
     "CodeDescription": "PHILIPPINE AIRLINE",
     "TPSystemValue": 2691
    },
    {
     
     "CodeDescription": "PHILIPPINE NATIONAL BANK",
     "TPSystemValue": "P01"
    },
    {
     
     "CodeDescription": "PHILIPS ELECTRONICS SINGAPORE PTE LTD",
     "TPSystemValue": 612
    },
    {
     
     "CodeDescription": "PHILIPS SECURITIES PTE LTD",
     "TPSystemValue": 2692
    },
    {
     
     "CodeDescription": "PHILIPS SINGAPORE PTE LTD",
     "TPSystemValue": 2693
    },
    {
     
     "CodeDescription": "PHILLIP CAPITAL",
     "TPSystemValue": 2694
    },
    {
     
     "CodeDescription": "PHILLIP MORRIS INTERNATIONAL",
     "TPSystemValue": 2695
    },
    {
     
     "CodeDescription": "PHILLIP SECURITIES PTE LTD",
     "TPSystemValue": 2696
    },
    {
     
     "CodeDescription": "PHLLIP SECURITIES PTE LTD",
     "TPSystemValue": 104
    },
    {
     
     "CodeDescription": "PHOENIX COMMUNICATIONS PTE LTD",
     "TPSystemValue": 561
    },
    {
     
     "CodeDescription": "PHOENIX CONTACT (SEA) PTE LTD",
     "TPSystemValue": 2697
    },
    {
     
     "CodeDescription": "PHOENIX HOLIDAY PTE. LTD.",
     "TPSystemValue": "H625"
    },
    {
     
     "CodeDescription": "PHOTRONICS SINGAPORE PTE LTD",
     "TPSystemValue": 2698
    },
    {
     
     "CodeDescription": "PHYA (S) TRAVEL SERVICE",
     "TPSystemValue": "H036"
    },
    {
     
     "CodeDescription": "PI COURT PTE LTD",
     "TPSystemValue": 2699
    },
    {
     
     "CodeDescription": "PIAS",
     "TPSystemValue": 2700
    },
    {
     
     "CodeDescription": "PICO ART INTERNATIONAL PTE LTD",
     "TPSystemValue": 118
    },
    {
     
     "CodeDescription": "PIL CONSORTIUM PTE LTD",
     "TPSystemValue": 903
    },
    {
     
     "CodeDescription": "PIL TRAVELS (PRIVATE) LIMITED",
     "TPSystemValue": "H055"
    },
    {
     
     "CodeDescription": "PILGRIMAGE TOURS PTE. LTD.",
     "TPSystemValue": "H378"
    },
    {
     
     "CodeDescription": "PINNACLE TRAVEL SERVICES PTE LTD",
     "TPSystemValue": "H258"
    },
    {
     
     "CodeDescription": "PINNACLE WORLD TRANSPORT PTE LTD",
     "TPSystemValue": 366
    },
    {
     
     "CodeDescription": "PIONEER ELECTRONICS ASIACENTRE PTE LTD",
     "TPSystemValue": 413
    },
    {
     
     "CodeDescription": "PIONEER SMITH (S) PTE LTD",
     "TPSystemValue": 986
    },
    {
     
     "CodeDescription": "PITCREW EVENTS PRIVATE LIMITED",
     "TPSystemValue": "H524"
    },
    {
     
     "CodeDescription": "PJE GROUP PTE. LTD.",
     "TPSystemValue": "H266"
    },
    {
     
     "CodeDescription": "PLANET TRAVEL PTE LTD",
     "TPSystemValue": "H260"
    },
    {
     
     "CodeDescription": "PLANETSPARK PTE LTD",
     "TPSystemValue": 1236
    },
    {
     
     "CodeDescription": "PLANT ELECTRICAL INSTRUMENTATION PL",
     "TPSystemValue": 2701
    },
    {
     
     "CodeDescription": "PLANT ENGINEERING CONSTRUCTION PTE LTD",
     "TPSystemValue": 2702
    },
    {
     
     "CodeDescription": "PLATINUM TOURS & TRAVEL PRIVATE LIMITED",
     "TPSystemValue": "H603"
    },
    {
     
     "CodeDescription": "PMG ASIA PACIFIC PTE LTD",
     "TPSystemValue": 491
    },
    {
     
     "CodeDescription": "PN REALTY PTE LTD",
     "TPSystemValue": 2703
    },
    {
     
     "CodeDescription": "POD CONTACT TELECOMMUNICATION",
     "TPSystemValue": 1397
    },
    {
     
     "CodeDescription": "POH HENG JEWELLERY (PTE) LTD",
     "TPSystemValue": 2704
    },
    {
     
     "CodeDescription": "POKKA CORPORATION (S) PTE LTD",
     "TPSystemValue": 2705
    },
    {
     
     "CodeDescription": "POKKA SALES MARKETING PTE LTD",
     "TPSystemValue": 643
    },
    {
     
     "CodeDescription": "POLARIS SOFTWARE LAB PTE LTD",
     "TPSystemValue": 583
    },
    {
     
     "CodeDescription": "POLICE FORCE",
     "TPSystemValue": 2706
    },
    {
     
     "CodeDescription": "POLYCOM ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2707
    },
    {
     
     "CodeDescription": "POLYONE SINGAPORE PTE LTD",
     "TPSystemValue": 372
    },
    {
     
     "CodeDescription": "POPULAR BOOK CO (PTE) LTD",
     "TPSystemValue": "07P"
    },
    {
     
     "CodeDescription": "POPULAR BOOK COMPANY PTE LTD",
     "TPSystemValue": 102
    },
    {
     
     "CodeDescription": "PORT OF SINGAPORE AUTHORITY",
     "TPSystemValue": 2708
    },
    {
     
     "CodeDescription": "POSITRONIC ASIA PTE LTD",
     "TPSystemValue": 501
    },
    {
     
     "CodeDescription": "POSSEHL ELECTRONICS SINGAPORE PTE LTD",
     "TPSystemValue": 360
    },
    {
     
     "CodeDescription": "POSSIBLE WORLDWIDE PTE LTD",
     "TPSystemValue": 698
    },
    {
     
     "CodeDescription": "POSTER CONNECTION PTE LTD",
     "TPSystemValue": 3699
    },
    {
     
     "CodeDescription": "POWER AUTOMATION PTE LTD",
     "TPSystemValue": 2709
    },
    {
     
     "CodeDescription": "POWER SERAYA LIMITED",
     "TPSystemValue": 2710
    },
    {
     
     "CodeDescription": "POWER SUPPLY LIMITED",
     "TPSystemValue": 2711
    },
    {
     
     "CodeDescription": "POWERGRID PTE LTD",
     "TPSystemValue": 2712
    },
    {
     
     "CodeDescription": "POWERPLUS GROUP PTE LTD",
     "TPSystemValue": 949
    },
    {
     
     "CodeDescription": "POWERSERAYA LTD",
     "TPSystemValue": 2713
    },
    {
     
     "CodeDescription": "P-PARKING INTERNATIONAL PTE LTD",
     "TPSystemValue": 723
    },
    {
     
     "CodeDescription": "PPL SHIPYARD PTE LTD",
     "TPSystemValue": 630
    },
    {
     
     "CodeDescription": "PRATT & WHITNEY CANADA (SEA) PTE LT",
     "TPSystemValue": 2714
    },
    {
     
     "CodeDescription": "PRATT & WHITNEY PTE LTD",
     "TPSystemValue": 2715
    },
    {
     
     "CodeDescription": "PRATT & WHITNEY SERVICES PTE LTD",
     "TPSystemValue": 2716
    },
    {
     
     "CodeDescription": "PREMIUM AUTOMOBILES PTE LTD",
     "TPSystemValue": 687
    },
    {
     
     "CodeDescription": "PREMIUMRICH ENGINEERING PTE LTD",
     "TPSystemValue": 1056
    },
    {
     
     "CodeDescription": "PRESTIGE CONSOLIDATION PTE LTD",
     "TPSystemValue": 1281
    },
    {
     
     "CodeDescription": "PRESTIGE PRODUCTS DISTRIBUTION PTE LTD",
     "TPSystemValue": 2717
    },
    {
     
     "CodeDescription": "PRICE SOLUTIONS SINGAPORE PTE LTD",
     "TPSystemValue": 2
    },
    {
     
     "CodeDescription": "PRICEWATERHOUSE COOPERS PRICEWATERHOUSECOOPERS AS",
     "TPSystemValue": 3700
    },
    {
     
     "CodeDescription": "PRICEWATERHOUSECOOPERS",
     "TPSystemValue": 2838
    },
    {
     
     "CodeDescription": "PRICEWATERHOUSECOOPERS CORPORATE FINANCE PTE LTD",
     "TPSystemValue": 3701
    },
    {
     
     "CodeDescription": "PRICEWATERHOUSECOOPERS GHRS PTE LTD",
     "TPSystemValue": 515
    },
    {
     
     "CodeDescription": "PRICEWATERHOUSECOOPERS HUMAN RESOURCE SERVICES PTE",
     "TPSystemValue": 3702
    },
    {
     
     "CodeDescription": "PRICEWATERHOUSECOOPERS MANAGEMENT CONSULTANTS PTE",
     "TPSystemValue": 3703
    },
    {
     
     "CodeDescription": "PRIMA LTD",
     "TPSystemValue": 2718
    },
    {
     
     "CodeDescription": "PRIME AIR GLOBAL PTE. LTD.",
     "TPSystemValue": "H701"
    },
    {
     
     "CodeDescription": "PRIME CRUISE ASIA PTE. LTD.",
     "TPSystemValue": "H179"
    },
    {
     
     "CodeDescription": "PRIME EXPRESS TRAVEL PTE LTD",
     "TPSystemValue": "H230"
    },
    {
     
     "CodeDescription": "PRIME TRAVEL & TOUR PTE. LTD.",
     "TPSystemValue": "H079"
    },
    {
     
     "CodeDescription": "PRIMESTAFF MANAGEMENT SERVICES PTE LTD",
     "TPSystemValue": 496
    },
    {
     
     "CodeDescription": "PRIMULA TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H202"
    },
    {
     
     "CodeDescription": "PRINCE LANDSCAPE & CONSTRUCTION PTE LTD",
     "TPSystemValue": 2719
    },
    {
     
     "CodeDescription": "PRINCE S LANDSCAPE AND CONSTRUCTION PL",
     "TPSystemValue": 521
    },
    {
     
     "CodeDescription": "PRINCE S PTE LTD",
     "TPSystemValue": 2720
    },
    {
     
     "CodeDescription": "PRINCES LANDSCAPE AND CONSTRUCTION PTE LTD",
     "TPSystemValue": "10P"
    },
    {
     
     "CodeDescription": "PRINT MEDIA COMMUNICATIONS PTE LTD",
     "TPSystemValue": 1235
    },
    {
     
     "CodeDescription": "PRM MARINE ENGINEERING PTE LTD",
     "TPSystemValue": 1082
    },
    {
     
     "CodeDescription": "PRO TREATMENT",
     "TPSystemValue": 1425
    },
    {
     
     "CodeDescription": "PROCESSWORKS PTE LTD",
     "TPSystemValue": 916
    },
    {
     
     "CodeDescription": "PROCOMP PRINTSET PTE LTD",
     "TPSystemValue": 244
    },
    {
     
     "CodeDescription": "PROCTER & CAMBLE",
     "TPSystemValue": 2721
    },
    {
     
     "CodeDescription": "PROCTER & GAMBLE (S) PTE LTD",
     "TPSystemValue": 2722
    },
    {
     
     "CodeDescription": "PROCTER & GAMBLE ASIA PTE LTD",
     "TPSystemValue": 2723
    },
    {
     
     "CodeDescription": "PROCTER & GAMBLE INTERNATIONAL",
     "TPSystemValue": 2724
    },
    {
     
     "CodeDescription": "PROFESSIONAL INVESTMENT ADVISORY SERVICE",
     "TPSystemValue": 2725
    },
    {
     
     "CodeDescription": "PROJECT OMNI PTE. LTD.",
     "TPSystemValue": "H360"
    },
    {
     
     "CodeDescription": "PROPNEX REALTY PTE LTD",
     "TPSystemValue": 690
    },
    {
     
     "CodeDescription": "PROSAFE PRODUCTION SERVICES PTE LTD",
     "TPSystemValue": 2726
    },
    {
     
     "CodeDescription": "PROSPER ENVIRONMENTAL & ENGINEERING PL",
     "TPSystemValue": 1126
    },
    {
     
     "CodeDescription": "PROSPER MARINE",
     "TPSystemValue": 2727
    },
    {
     
     "CodeDescription": "PROSPER MARINE OFFSHORE PTE LTD",
     "TPSystemValue": 1033
    },
    {
     
     "CodeDescription": "PROSPER MARINE PTE LTD",
     "TPSystemValue": 825
    },
    {
     
     "CodeDescription": "PROSPERITY STEEL UNITED SINGAPORE PL",
     "TPSystemValue": 875
    },
    {
     
     "CodeDescription": "PROVENANCE GLOBAL PTE LTD",
     "TPSystemValue": 1344
    },
    {
     
     "CodeDescription": "PROVIDENCE CAPITAL ADVISORY PTE LTD",
     "TPSystemValue": 1239
    },
    {
     
     "CodeDescription": "PRUDENTIAL ADVISORY SERVICES PTE LTD",
     "TPSystemValue": 3704
    },
    {
     
     "CodeDescription": "PRUDENTIAL ASSET MANAGEMENT LTD",
     "TPSystemValue": 477
    },
    {
     
     "CodeDescription": "PRUDENTIAL ASSURANCE CO SPORE (PTE) LTD",
     "TPSystemValue": "12P"
    },
    {
     
     "CodeDescription": "PRUDENTIAL ASSURANCE COMPANY S PL",
     "TPSystemValue": 356
    },
    {
     
     "CodeDescription": "PRUDENTIAL INSURANCE SINGAPORE",
     "TPSystemValue": 2728
    },
    {
     
     "CodeDescription": "PRUDENTIAL SERVICES (S) PTE LTD",
     "TPSystemValue": 2729
    },
    {
     
     "CodeDescription": "PSA CORPORATION LIMITED",
     "TPSystemValue": 613
    },
    {
     
     "CodeDescription": "PSA MARINE PTE LTD",
     "TPSystemValue": 2730
    },
    {
     
     "CodeDescription": "PSB CORPORATION (S) PTE LTD",
     "TPSystemValue": 2731
    },
    {
     
     "CodeDescription": "PSE ENGINEERING & CONSTRUCTION",
     "TPSystemValue": 2732
    },
    {
     
     "CodeDescription": "PSE SAFETY AND INDUSTRIAL SERVICES",
     "TPSystemValue": 1378
    },
    {
     
     "CodeDescription": "PSE SAFETY CORPORATION PTE LTD",
     "TPSystemValue": 1199
    },
    {
     
     "CodeDescription": "P-SERV PTE LTD",
     "TPSystemValue": 357
    },
    {
     
     "CodeDescription": "PT BANK MANDIRI (PERSERO) TBK",
     "TPSystemValue": "P02"
    },
    {
     
     "CodeDescription": "PT BANK MANDIRI MANDIRI CAPITAL PL",
     "TPSystemValue": 1138
    },
    {
     
     "CodeDescription": "PT BANK NEGARA INDONESIA (PERSERO) TBK",
     "TPSystemValue": "P03"
    },
    {
     
     "CodeDescription": "PTBANK NEGARA INDONESIA PERSERO TBK",
     "TPSystemValue": 3411
    },
    {
     
     "CodeDescription": "PTC SYSTEM (S) PTE LTD",
     "TPSystemValue": 384
    },
    {
     
     "CodeDescription": "PTERIS GLOBAL LIMITED",
     "TPSystemValue": 2733
    },
    {
     
     "CodeDescription": "PTT INTERNATIONAL TRADING PTE LTD",
     "TPSystemValue": 758
    },
    {
     
     "CodeDescription": "PUBLIC SERVICE DIVISION MINISTRY OF TRADE",
     "TPSystemValue": 3705
    },
    {
     
     "CodeDescription": "PUBLIC UTILITIES BOARD",
     "TPSystemValue": 3790
    },
    {
     
     "CodeDescription": "PUBLICIS SINGAPORE",
     "TPSystemValue": 1420
    },
    {
     
     "CodeDescription": "PURETECH ENGNEERING P L",
     "TPSystemValue": 2734
    },
    {
     
     "CodeDescription": "PURPLE COACH TOUR PTE. LTD.",
     "TPSystemValue": "H774"
    },
    {
     
     "CodeDescription": "PV DRILLING OVERSEAS COMPANY PRIVATE LTD",
     "TPSystemValue": 3706
    },
    {
     
     "CodeDescription": "PVR FOODS PTE LTD",
     "TPSystemValue": 2735
    },
    {
     
     "CodeDescription": "PWC PRICEWATER HOUSE COOPERS INT AL LTD",
     "TPSystemValue": 2736
    },
    {
     
     "CodeDescription": "PWD CONSULTANTS PTE LTD",
     "TPSystemValue": 2737
    },
    {
     
     "CodeDescription": "PWS EAST ASIA PTE LTD",
     "TPSystemValue": 616
    },
    {
     
     "CodeDescription": "PYH PTE LTD",
     "TPSystemValue": 989
    },
    {
     
     "CodeDescription": "PYO CRUISE PTE. LTD.",
     "TPSystemValue": "H085"
    },
    {
     
     "CodeDescription": "Q N Q ENTERPRISE PTE LTD",
     "TPSystemValue": 1330
    },
    {
     
     "CodeDescription": "QATAR NATIONAL BANK S.A.Q.",
     "TPSystemValue": "Q01"
    },
    {
     
     "CodeDescription": "QB NET INTERNATIONAL PTE LTD",
     "TPSystemValue": 2738
    },
    {
     
     "CodeDescription": "QBE INSURANCE (INTERNATIONAL) LIMITED",
     "TPSystemValue": 237
    },
    {
     
     "CodeDescription": "QES (SINGAPORE) PTE LTD",
     "TPSystemValue": 419
    },
    {
     
     "CodeDescription": "QQ TRAVEL PTE. LTD.",
     "TPSystemValue": "H611"
    },
    {
     
     "CodeDescription": "QSS SAFETY PTODUCTS (S) PTE LTD",
     "TPSystemValue": 2739
    },
    {
     
     "CodeDescription": "QT INSTRUMENTS (S) PTE LTD",
     "TPSystemValue": 480
    },
    {
     
     "CodeDescription": "QUALCOMM CDMA TECHNOLOGIES APAC PL",
     "TPSystemValue": 707
    },
    {
     
     "CodeDescription": "QUANTUM AUTOMATION PTE LTD",
     "TPSystemValue": 2740
    },
    {
     
     "CodeDescription": "QUANTUM STORAGE SINGAPORE PTE LTD",
     "TPSystemValue": 773
    },
    {
     
     "CodeDescription": "QUANTUM TECHNOLOGIES GLOBAL PTE LTD",
     "TPSystemValue": 1254
    },
    {
     
     "CodeDescription": "QUENTINS F AND B SINGAPORE PTE LTD",
     "TPSystemValue": 2741
    },
    {
     
     "CodeDescription": "QUENTINS F&B (S) (PTE.) LTD.",
     "TPSystemValue": "01Q"
    },
    {
     
     "CodeDescription": "QUEST LABORATORIES PTE LTD",
     "TPSystemValue": 488
    },
    {
     
     "CodeDescription": "QUEST OIL & GAS PTE LTD",
     "TPSystemValue": 1117
    },
    {
     
     "CodeDescription": "QUEST SOFTWARE SINGAPORE PTE LTD",
     "TPSystemValue": 2742
    },
    {
     
     "CodeDescription": "QUEST TECHNOLOGY (S) PTE LTD",
     "TPSystemValue": 2743
    },
    {
     
     "CodeDescription": "QUICKFLO LOGISTICS PTE LTD",
     "TPSystemValue": 2744
    },
    {
     
     "CodeDescription": "QUINNOX SOLUTIONS PTE LTD",
     "TPSystemValue": 1040
    },
    {
     
     "CodeDescription": "QU'INS TRAVEL AND TOURS PTE. LTD.",
     "TPSystemValue": "H369"
    },
    {
     
     "CodeDescription": "QUINTILES EAST ASIA PTE LTD",
     "TPSystemValue": 499
    },
    {
     
     "CodeDescription": "QUIPPO PRAKASH PTE LTD",
     "TPSystemValue": 1163
    },
    {
     
     "CodeDescription": "QUIX PTE LTD",
     "TPSystemValue": 702
    },
    {
     
     "CodeDescription": "QUOTIENT TRAVELPLANNER PTE. LTD.",
     "TPSystemValue": "H454"
    },
    {
     
     "CodeDescription": "QXY RESOURCES PTE. LTD.",
     "TPSystemValue": 925
    },
    {
     
     "CodeDescription": "R E N SUPPLIES & SERVICES PTE. LTD.",
     "TPSystemValue": "H441"
    },
    {
     
     "CodeDescription": "R F T PTE. LTD.",
     "TPSystemValue": "H719"
    },
    {
     
     "CodeDescription": "R T C LOGISTICS PTE LTD",
     "TPSystemValue": 238
    },
    {
     
     "CodeDescription": "R1 INTERNATIONAL PTE LTD",
     "TPSystemValue": 790
    },
    {
     
     "CodeDescription": "RABO BANK",
     "TPSystemValue": 2745
    },
    {
     
     "CodeDescription": "RABOBANK",
     "TPSystemValue": "R01"
    },
    {
     
     "CodeDescription": "RABOBANK NOMINEES SINGAPORE PTE LTD",
     "TPSystemValue": 3707
    },
    {
     
     "CodeDescription": "RADHA TRAVELS & TOURS PTE. LTD.",
     "TPSystemValue": "H398"
    },
    {
     
     "CodeDescription": "RADIANCE COMMUNICATIONS PTE LTD",
     "TPSystemValue": 2746
    },
    {
     
     "CodeDescription": "RADIO CORPORATION OF SINGAPORE PTE LTD",
     "TPSystemValue": 2747
    },
    {
     
     "CodeDescription": "RADISSION (HK) PRIVATE LTD",
     "TPSystemValue": 1205
    },
    {
     
     "CodeDescription": "RAFFEMET PTE LTD",
     "TPSystemValue": 506
    },
    {
     
     "CodeDescription": "RAFFLES AIR PTE. LTD.",
     "TPSystemValue": "H486"
    },
    {
     
     "CodeDescription": "RAFFLES CAMPUS PTE LTD",
     "TPSystemValue": 704
    },
    {
     
     "CodeDescription": "RAFFLES COUNTRY CLUB",
     "TPSystemValue": 2748
    },
    {
     
     "CodeDescription": "RAFFLES HEALTH INSURANCE PTE LTD",
     "TPSystemValue": 3708
    },
    {
     
     "CodeDescription": "RAFFLES HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H430"
    },
    {
     
     "CodeDescription": "RAFFLES HOSPITAL PTE LTD",
     "TPSystemValue": 434
    },
    {
     
     "CodeDescription": "RAFFLES HOTEL",
     "TPSystemValue": 2749
    },
    {
     
     "CodeDescription": "RAFFLES INSTITUTION",
     "TPSystemValue": 2750
    },
    {
     
     "CodeDescription": "RAFFLES MEDICAL GROUP LTD",
     "TPSystemValue": 324
    },
    {
     
     "CodeDescription": "RAFFLES QUAY ASSET MANAGEMENT PTE LTD",
     "TPSystemValue": 770
    },
    {
     
     "CodeDescription": "RAFFLES SHIP CHARTERING PTE LTD",
     "TPSystemValue": 1170
    },
    {
     
     "CodeDescription": "RAFFLES TRAVEL GROUP PTE. LTD.",
     "TPSystemValue": "H699"
    },
    {
     
     "CodeDescription": "RAFFLESIA TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H791"
    },
    {
     
     "CodeDescription": "RAIFFEISEN BANK INTERNATIONAL",
     "TPSystemValue": 2751
    },
    {
     
     "CodeDescription": "RAIFFEISEN ZENTRALBANK OESTERREICH AG",
     "TPSystemValue": "R02"
    },
    {
     
     "CodeDescription": "RAINBOW AQUARIUM PTE LTD",
     "TPSystemValue": 459
    },
    {
     
     "CodeDescription": "RAINBOW CENTRE",
     "TPSystemValue": 2752
    },
    {
     
     "CodeDescription": "RAINBOWS & SUNSHINE PTE. LTD.",
     "TPSystemValue": "H806"
    },
    {
     
     "CodeDescription": "RAINTREE MEDICAL PTE LTD",
     "TPSystemValue": 368
    },
    {
     
     "CodeDescription": "RAJ PRASANNA & PARTNERS",
     "TPSystemValue": 1423
    },
    {
     
     "CodeDescription": "RAJAH AND TAN LLP",
     "TPSystemValue": 2753
    },
    {
     
     "CodeDescription": "RAKKI BUILD TECH",
     "TPSystemValue": 3782
    },
    {
     
     "CodeDescription": "RAKKI ENGINEERING",
     "TPSystemValue": 1308
    },
    {
     
     "CodeDescription": "RAMEN PLAY PTE LTD",
     "TPSystemValue": 1217
    },
    {
     
     "CodeDescription": "RAMESH TRAVEL SERVICE",
     "TPSystemValue": "H033"
    },
    {
     
     "CodeDescription": "RANDSTAD PTE LIMITED",
     "TPSystemValue": 438
    },
    {
     
     "CodeDescription": "RANGOON AIR TRAVEL PTE. LTD.",
     "TPSystemValue": "H726"
    },
    {
     
     "CodeDescription": "RANKINE & HILL (SINGAPORE) PTE LTD",
     "TPSystemValue": 168
    },
    {
     
     "CodeDescription": "RAVE GROUP INT'L PTE. LTD.",
     "TPSystemValue": "H831"
    },
    {
     
     "CodeDescription": "RAYCO TECHNOLOGIES PTE LTD",
     "TPSystemValue": 140
    },
    {
     
     "CodeDescription": "RAZER ASIA PACIFIC PTE LTD",
     "TPSystemValue": 908
    },
    {
     
     "CodeDescription": "RBC DEXIA INVESTOR SERVICES SINGAPORE P L IN MEMB",
     "TPSystemValue": 3709
    },
    {
     
     "CodeDescription": "RBC DEXIA TRUST SERVICES SINGAPORE LTD",
     "TPSystemValue": 510
    },
    {
     
     "CodeDescription": "RBS",
     "TPSystemValue": 2754
    },
    {
     
     "CodeDescription": "RBS COUTTS",
     "TPSystemValue": 2755
    },
    {
     
     "CodeDescription": "RC COMPONENTS PTE LTD",
     "TPSystemValue": 861
    },
    {
     
     "CodeDescription": "RC HOTELS (PTE) LTD",
     "TPSystemValue": 2756
    },
    {
     
     "CodeDescription": "RCHANGI GENERAL HOSPITAL PTE LTD",
     "TPSystemValue": 678
    },
    {
     
     "CodeDescription": "RCL FEEDER PTE LTD",
     "TPSystemValue": 295
    },
    {
     
     "CodeDescription": "READERS DIGEST ASIA PTE LTD",
     "TPSystemValue": 1053
    },
    {
     
     "CodeDescription": "READY SOFTWARE PTE LTD",
     "TPSystemValue": 444
    },
    {
     
     "CodeDescription": "REBORNE PTE LTD",
     "TPSystemValue": 864
    },
    {
     
     "CodeDescription": "REC CELLS PTE LTD",
     "TPSystemValue": 2757
    },
    {
     
     "CodeDescription": "REC MODULES PTE LTD",
     "TPSystemValue": 2758
    },
    {
     
     "CodeDescription": "REC SITE SERVICES PTE LTD",
     "TPSystemValue": 1143
    },
    {
     
     "CodeDescription": "REC WAFER PTE LTD",
     "TPSystemValue": 2759
    },
    {
     
     "CodeDescription": "RECKITT BENCKISER SINGAPORE PTE LTD",
     "TPSystemValue": 132
    },
    {
     
     "CodeDescription": "RECRUIT EXPRESS PTE LTD",
     "TPSystemValue": 537
    },
    {
     
     "CodeDescription": "RECRUIT PARTNERS ASIA",
     "TPSystemValue": 1402
    },
    {
     
     "CodeDescription": "RECRUITPLUS CONSULTING PTE LTD",
     "TPSystemValue": 937
    },
    {
     
     "CodeDescription": "RECTITUDE PTE LTD",
     "TPSystemValue": 640
    },
    {
     
     "CodeDescription": "RED APPLE TRAVEL (S) PTE. LTD.",
     "TPSystemValue": "H516"
    },
    {
     
     "CodeDescription": "RED ELEMENT HEALTH INTERNATIONAL PL",
     "TPSystemValue": 1299
    },
    {
     
     "CodeDescription": "REDA PRODUCTION SYSTEMS",
     "TPSystemValue": 1396
    },
    {
     
     "CodeDescription": "REDWOODS ADVANCE PTE LTD",
     "TPSystemValue": 973
    },
    {
     
     "CodeDescription": "REE ENGINEERING (2005) PTE LTD",
     "TPSystemValue": 2760
    },
    {
     
     "CodeDescription": "REEBONZ PTE LTD",
     "TPSystemValue": 1219
    },
    {
     
     "CodeDescription": "REED ELSEVIER",
     "TPSystemValue": 1366
    },
    {
     
     "CodeDescription": "REED EXHIBITIONS SINGAPORE PTE LTD",
     "TPSystemValue": 2761
    },
    {
     
     "CodeDescription": "REED HAMILTON PTE LTD",
     "TPSystemValue": 1319
    },
    {
     
     "CodeDescription": "REGENCY STEEL ASIA PTE LTD",
     "TPSystemValue": 909
    },
    {
     
     "CodeDescription": "REGENCY TRAVEL PTE LTD",
     "TPSystemValue": "H147"
    },
    {
     
     "CodeDescription": "REGINA METAL PTE LTD",
     "TPSystemValue": 1100
    },
    {
     
     "CodeDescription": "REGION DIVERSIFIED(S) PTE LTD",
     "TPSystemValue": 2762
    },
    {
     
     "CodeDescription": "REGUS MANAGEMENT SINGAPORE PTE LTD",
     "TPSystemValue": 1279
    },
    {
     
     "CodeDescription": "REMAX",
     "TPSystemValue": 2763
    },
    {
     
     "CodeDescription": "RENAISSANCE SOLUTIONS PTE LTD",
     "TPSystemValue": 627
    },
    {
     
     "CodeDescription": "RENAISSANCE TRAVEL PTE LTD",
     "TPSystemValue": "H256"
    },
    {
     
     "CodeDescription": "RENDEZVOUS RESTAURANT HOCK LOCK KEE",
     "TPSystemValue": 1361
    },
    {
     
     "CodeDescription": "RENESAS ELECTRONICS (S) PTE LTD",
     "TPSystemValue": 2764
    },
    {
     
     "CodeDescription": "RENESAS SEMICONDUCTOR SINGAPORE PTE LTD",
     "TPSystemValue": 2765
    },
    {
     
     "CodeDescription": "RENTOKIL INITIAL SINGAPORE PTE LTD",
     "TPSystemValue": 22
    },
    {
     
     "CodeDescription": "REPUBLIC AUTO PTE LTD",
     "TPSystemValue": 622
    },
    {
     
     "CodeDescription": "REPUBLIC OF SINGAPORE AIR FORCE",
     "TPSystemValue": 2766
    },
    {
     
     "CodeDescription": "REPUBLIC OF SINGAPORE NAVY",
     "TPSystemValue": 2767
    },
    {
     
     "CodeDescription": "REPUBLIC POLYTECHNIC",
     "TPSystemValue": 3710
    },
    {
     
     "CodeDescription": "REPUBLIC POLYTECHNIC INTERNATIONAL PL",
     "TPSystemValue": 968
    },
    {
     
     "CodeDescription": "RESEARCH IN MOTION SINGAPORE PTE LTD",
     "TPSystemValue": 2768
    },
    {
     
     "CodeDescription": "RESORT CONNECTION TOURS & TRAVEL PTE. LTD.",
     "TPSystemValue": "H729"
    },
    {
     
     "CodeDescription": "RESORT LEISURE TRAVEL PRIVATE LTD.",
     "TPSystemValue": "H766"
    },
    {
     
     "CodeDescription": "RESORT VENTURE PTE. LTD.",
     "TPSystemValue": "H403"
    },
    {
     
     "CodeDescription": "RESORTS WORLD AT SENTOSA PTE LTD",
     "TPSystemValue": 966
    },
    {
     
     "CodeDescription": "RESORTS WORLD MARKETING PTE LTD",
     "TPSystemValue": 2769
    },
    {
     
     "CodeDescription": "RESOURCE TRAVEL PTE. LTD.",
     "TPSystemValue": "H684"
    },
    {
     
     "CodeDescription": "REST & RELAX HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H362"
    },
    {
     
     "CodeDescription": "RET TOURS PTE. LTD.",
     "TPSystemValue": "H617"
    },
    {
     
     "CodeDescription": "REUTERS ASIA PTE LTD",
     "TPSystemValue": 2770
    },
    {
     
     "CodeDescription": "REVENUE VALLEY PTE LTD",
     "TPSystemValue": 3711
    },
    {
     
     "CodeDescription": "REVEPLANNER TRAVEL PTE. LTD.",
     "TPSystemValue": "H646"
    },
    {
     
     "CodeDescription": "REXY TRAVEL PTE. LTD.",
     "TPSystemValue": "H546"
    },
    {
     
     "CodeDescription": "REZSOURCE (S) PTE LTD",
     "TPSystemValue": 1256
    },
    {
     
     "CodeDescription": "RGL SHIPPING PTE LTD",
     "TPSystemValue": 1077
    },
    {
     
     "CodeDescription": "RHB BANK BERAHD",
     "TPSystemValue": 2771
    },
    {
     
     "CodeDescription": "RHB BANK BERHAD",
     "TPSystemValue": "R03"
    },
    {
     
     "CodeDescription": "RHODIA ASIA PACIFIC (S) PTE LTD",
     "TPSystemValue": 2772
    },
    {
     
     "CodeDescription": "RIC MARINE PTE LTD",
     "TPSystemValue": 2773
    },
    {
     
     "CodeDescription": "RICHARD TRAVEL PTE LTD",
     "TPSystemValue": "H336"
    },
    {
     
     "CodeDescription": "RICHARDSON ELECTRONICS PTE LTD",
     "TPSystemValue": 2774
    },
    {
     
     "CodeDescription": "RICHEMONT LUXURY SINGAPORE PTE LTD",
     "TPSystemValue": 269
    },
    {
     
     "CodeDescription": "RICKY BROTHERS SCHOOL BUS SERVICE",
     "TPSystemValue": 1368
    },
    {
     
     "CodeDescription": "RICOH ASIA PACIFIC PTE LTD",
     "TPSystemValue": 576
    },
    {
     
     "CodeDescription": "RICOH SINGAPORE PTE LTD",
     "TPSystemValue": 502
    },
    {
     
     "CodeDescription": "RIDER LEVEIT BUCKNALL LLP",
     "TPSystemValue": 2775
    },
    {
     
     "CodeDescription": "RIDEWELL TRAVEL PTE. LTD.",
     "TPSystemValue": "H101"
    },
    {
     
     "CodeDescription": "RIFE ENGINNEERING PTE LTD",
     "TPSystemValue": 2776
    },
    {
     
     "CodeDescription": "RIGELSOFT (PTE.) LTD.",
     "TPSystemValue": 1135
    },
    {
     
     "CodeDescription": "RIGHT TECH (S) PTE LTD",
     "TPSystemValue": 584
    },
    {
     
     "CodeDescription": "RIKVIN PTE LTD",
     "TPSystemValue": 1097
    },
    {
     
     "CodeDescription": "RIKVIN PTE. LTD.",
     "TPSystemValue": "02R"
    },
    {
     
     "CodeDescription": "RIM POLYMERS INDUSTRIES PTE LTD",
     "TPSystemValue": 2777
    },
    {
     
     "CodeDescription": "RIO TINTO ALCAN PTE LTD",
     "TPSystemValue": 1004
    },
    {
     
     "CodeDescription": "RIO TINTO MINERALS ASIA PTE LTD",
     "TPSystemValue": 69
    },
    {
     
     "CodeDescription": "RIO TINTO PROCUREMENT SINGAPORE PL",
     "TPSystemValue": 1071
    },
    {
     
     "CodeDescription": "RIO TINTO SHIPPING ASIA PTE LTD",
     "TPSystemValue": 1136
    },
    {
     
     "CodeDescription": "RIO TINTO SINGAPORE HOLDINGS PTE LTD",
     "TPSystemValue": 1027
    },
    {
     
     "CodeDescription": "RIO TINTO SINGAPORE PTE LTD",
     "TPSystemValue": 188
    },
    {
     
     "CodeDescription": "RISE TOURS & TRAVEL PTE. LTD.",
     "TPSystemValue": "H784"
    },
    {
     
     "CodeDescription": "RJ TRAVELS PTE. LTD.",
     "TPSystemValue": "H776"
    },
    {
     
     "CodeDescription": "RMA CONTRACTS PTE LTD",
     "TPSystemValue": 540
    },
    {
     
     "CodeDescription": "RMA GROUP",
     "TPSystemValue": 2779
    },
    {
     
     "CodeDescription": "RMG TOURS PTE LTD",
     "TPSystemValue": "H067"
    },
    {
     
     "CodeDescription": "RMG TRAVEL PTE LTD",
     "TPSystemValue": "H119"
    },
    {
     
     "CodeDescription": "ROBERT BOSCH PTE LTD",
     "TPSystemValue": 2780
    },
    {
     
     "CodeDescription": "ROBERT BOSCH SECURITY SOLUTIONS PTE LTD",
     "TPSystemValue": 2781
    },
    {
     
     "CodeDescription": "ROBERT BOSCH SOUTH EAST ASIA PTE LTD",
     "TPSystemValue": 19
    },
    {
     
     "CodeDescription": "ROBERT HALF INTERNATIONAL LTD",
     "TPSystemValue": 2782
    },
    {
     
     "CodeDescription": "ROBERT WALTERS SINGAPORE PTE LTD",
     "TPSystemValue": 621
    },
    {
     
     "CodeDescription": "ROBERT, WANG & LOO LLP",
     "TPSystemValue": 3117
    },
    {
     
     "CodeDescription": "ROBINSON CO S PTE LTD",
     "TPSystemValue": 2783
    },
    {
     
     "CodeDescription": "ROBUST & LIFE COMPACT PTE LTD",
     "TPSystemValue": 842
    },
    {
     
     "CodeDescription": "ROCHE DIAGNOSTICS ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2784
    },
    {
     
     "CodeDescription": "ROCHE SINGAPORE PTE LTD",
     "TPSystemValue": 75
    },
    {
     
     "CodeDescription": "ROCHE SINGAPORE TECHNICAL OPERATION PL",
     "TPSystemValue": 2785
    },
    {
     
     "CodeDescription": "ROCKWELL AUTOMATION APAC BUSINESS PL",
     "TPSystemValue": 1020
    },
    {
     
     "CodeDescription": "ROCKWELL COLLINS SOUTHEAST ASIA PTE LTD",
     "TPSystemValue": 777
    },
    {
     
     "CodeDescription": "ROCKWOOL BUILDING MATERIALS SINGAPORE PTE LTD",
     "TPSystemValue": 3712
    },
    {
     
     "CodeDescription": "RODYK DAVIDSON LLP",
     "TPSystemValue": 3087
    },
    {
     
     "CodeDescription": "ROHDE & SCHWARZ ASIA PTE LTD",
     "TPSystemValue": 2786
    },
    {
     
     "CodeDescription": "ROHM AND HAAS SINGAPORE PTE LTD",
     "TPSystemValue": 2787
    },
    {
     
     "CodeDescription": "ROHM ELECTONIC PTE LTD",
     "TPSystemValue": 2788
    },
    {
     
     "CodeDescription": "ROHM SEMICONDUCTOR SINGAPORE PTE LTD",
     "TPSystemValue": 2789
    },
    {
     
     "CodeDescription": "ROLLSROYCE SINGAPORE PTE LTD",
     "TPSystemValue": 249
    },
    {
     
     "CodeDescription": "RONG DE DISTRIBUTION PTE LTD",
     "TPSystemValue": 2790
    },
    {
     
     "CodeDescription": "RONG DU BUILDING CONSTRUCTIONS PL",
     "TPSystemValue": 1257
    },
    {
     
     "CodeDescription": "ROTARY ENGINEERING LIMITED",
     "TPSystemValue": 162
    },
    {
     
     "CodeDescription": "ROVIA PTE. LIMITED",
     "TPSystemValue": "H660"
    },
    {
     
     "CodeDescription": "ROYAL BANK OF SCOTLAND",
     "TPSystemValue": 2791
    },
    {
     
     "CodeDescription": "ROYAL BANK OF SCOTLAND N.V., THE",
     "TPSystemValue": "R05"
    },
    {
     
     "CodeDescription": "ROYAL BANK OF SCOTLAND PLC, THE",
     "TPSystemValue": "R06"
    },
    {
     
     "CodeDescription": "ROYAL CARIBBEAN CRUISES (ASIA) PTE. LTD.",
     "TPSystemValue": "H449"
    },
    {
     
     "CodeDescription": "ROYAL WINGS TRAVEL SINGAPORE PTE. LTD.",
     "TPSystemValue": "H730"
    },
    {
     
     "CodeDescription": "ROYAL&SUN ALLIANCE INSURANCE (S) LTD",
     "TPSystemValue": 2792
    },
    {
     
     "CodeDescription": "RS COMPONENTS PTE LTD",
     "TPSystemValue": 2793
    },
    {
     
     "CodeDescription": "RSAF",
     "TPSystemValue": 2794
    },
    {
     
     "CodeDescription": "RSM CHIO LIM LLP",
     "TPSystemValue": 3109
    },
    {
     
     "CodeDescription": "RSM CHIO LIM STONE FOREST",
     "TPSystemValue": 2795
    },
    {
     
     "CodeDescription": "RSN",
     "TPSystemValue": 2796
    },
    {
     
     "CodeDescription": "RSP ARCHITECTS PLANNING AND ENGINEERING",
     "TPSystemValue": 2797
    },
    {
     
     "CodeDescription": "R-TECH PTE LTD",
     "TPSystemValue": 1058
    },
    {
     
     "CodeDescription": "RUBATO PTE LTD",
     "TPSystemValue": 3783
    },
    {
     
     "CodeDescription": "RUBICON OFFSHORE INTERNATIONAL PTE LTD",
     "TPSystemValue": 2798
    },
    {
     
     "CodeDescription": "RUIXIN EDUTECH PTE. LTD.",
     "TPSystemValue": "H433"
    },
    {
     
     "CodeDescription": "RUSSELL REYNOLDS ASSOCIATES (S) PL",
     "TPSystemValue": 1301
    },
    {
     
     "CodeDescription": "RUSSELL ROCKS PTE. LTD.",
     "TPSystemValue": "H375"
    },
    {
     
     "CodeDescription": "RYE TRAVEL SERVICES PTE LTD",
     "TPSystemValue": "H446"
    },
    {
     
     "CodeDescription": "S & I SYSTEMS PTE LTD",
     "TPSystemValue": 2799
    },
    {
     
     "CodeDescription": "S C T AUTO SERVICES PTE LTD",
     "TPSystemValue": 1154
    },
    {
     
     "CodeDescription": "S INSTITUTE OF MANUFACTURING TECHN",
     "TPSystemValue": 592
    },
    {
     
     "CodeDescription": "S M S TRAVEL AND TOURS PTE. LTD.",
     "TPSystemValue": "H514"
    },
    {
     
     "CodeDescription": "S M SYSTEMS PTE LTD",
     "TPSystemValue": 567
    },
    {
     
     "CodeDescription": "S P JAIN SCHOOL OF GLOBAL MANAGEMENT",
     "TPSystemValue": 2800
    },
    {
     
     "CodeDescription": "S POLYTECHNIC INTERNATIONAL PTE LTD",
     "TPSystemValue": 952
    },
    {
     
     "CodeDescription": "S UNIVERSITY OF TECHNOLOGY AND DESIGN",
     "TPSystemValue": 1230
    },
    {
     
     "CodeDescription": "S.E.A. BUSINESS & EDUCATIONAL TRAVEL PTE. LTD.",
     "TPSystemValue": "H673"
    },
    {
     
     "CodeDescription": "S.J. TRAVEL PTE. LTD.",
     "TPSystemValue": "H669"
    },
    {
     
     "CodeDescription": "S.K. HOLIDAYS PTE LTD",
     "TPSystemValue": "H144"
    },
    {
     
     "CodeDescription": "S.M.I. TRAVEL SINGAPORE PTE LTD",
     "TPSystemValue": "H125"
    },
    {
     
     "CodeDescription": "SA SA COSMETIC CO. (S) PTE LTD",
     "TPSystemValue": 588
    },
    {
     
     "CodeDescription": "SAA ARCHITECTS PTE LTD",
     "TPSystemValue": 2839
    },
    {
     
     "CodeDescription": "SAATCHI & SAATCHI PTE LTD",
     "TPSystemValue": 43
    },
    {
     
     "CodeDescription": "SABIC ASIA PACIFIC PTE LTD",
     "TPSystemValue": 398
    },
    {
     
     "CodeDescription": "SABIC INNOVATIVE PLASTIC SEA PTE LTD",
     "TPSystemValue": 152
    },
    {
     
     "CodeDescription": "SABIC INNOVATIVE PLASTICS SINGAPORE PL",
     "TPSystemValue": 349
    },
    {
     
     "CodeDescription": "SABRE INTERNATIONAL LLC",
     "TPSystemValue": 3418
    },
    {
     
     "CodeDescription": "SABRE TECHNOLOGY PTE LTD",
     "TPSystemValue": 2840
    },
    {
     
     "CodeDescription": "SABRE TRAVEL PTE LTD",
     "TPSystemValue": "H242"
    },
    {
     
     "CodeDescription": "SAESL",
     "TPSystemValue": 2841
    },
    {
     
     "CodeDescription": "SAF",
     "TPSystemValue": 2842
    },
    {
     
     "CodeDescription": "SAFE2TRAVEL PTE LTD",
     "TPSystemValue": 730
    },
    {
     
     "CodeDescription": "SAFER WIRELINE OPERATIONS R&D PL",
     "TPSystemValue": 754
    },
    {
     
     "CodeDescription": "SAFEWAY REGENT PTE LTD",
     "TPSystemValue": 2843
    },
    {
     
     "CodeDescription": "SAFRA NATIONAL SERVICE ASSOCIATION",
     "TPSystemValue": 2812
    },
    {
     
     "CodeDescription": "SAGE PUBLICATIONS ASIA-PACIFIC PTE LTD",
     "TPSystemValue": 1068
    },
    {
     
     "CodeDescription": "SAGINOMIYA SINGAPORE PTE LTD",
     "TPSystemValue": 3713
    },
    {
     
     "CodeDescription": "SAI TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H533"
    },
    {
     
     "CodeDescription": "SAIPEM SINGAPORE PTE LTD",
     "TPSystemValue": 165
    },
    {
     
     "CodeDescription": "SAKAE HOLDINGS LTD",
     "TPSystemValue": 562
    },
    {
     
     "CodeDescription": "SAKURAYA FOODS PTE LTD",
     "TPSystemValue": 284
    },
    {
     
     "CodeDescription": "SALVO PTE LTD",
     "TPSystemValue": 2844
    },
    {
     
     "CodeDescription": "SAM LAM EQUIPMENT SERVICES PTE LTD",
     "TPSystemValue": 2845
    },
    {
     
     "CodeDescription": "SAMEPAGE TRAVEL PTE. LTD.",
     "TPSystemValue": "H180"
    },
    {
     
     "CodeDescription": "SAMINA-SCI SYSTEMS SINGAPORE PL",
     "TPSystemValue": 2846
    },
    {
     
     "CodeDescription": "SAMSUNG ASIA PL",
     "TPSystemValue": 2847
    },
    {
     
     "CodeDescription": "SAMSUNG C & T SPORE PL",
     "TPSystemValue": 2848
    },
    {
     
     "CodeDescription": "SAMSUNG C&T SINGAPORE PL",
     "TPSystemValue": 2849
    },
    {
     
     "CodeDescription": "SAMSUNG SDS ASIA PACIFIC PTE LTD",
     "TPSystemValue": 2850
    },
    {
     
     "CodeDescription": "SAMUDERA SHIPPING LINE LTD",
     "TPSystemValue": 451
    },
    {
     
     "CodeDescription": "SAMWOH CORPORATION PTE LTD",
     "TPSystemValue": 257
    },
    {
     
     "CodeDescription": "SAN HUP SENG ENGINEERING PTE LTD",
     "TPSystemValue": 2851
    },
    {
     
     "CodeDescription": "SAN HUP SENG ENGINEERING PTE. LTD.",
     "TPSystemValue": "01S"
    },
    {
     
     "CodeDescription": "SANDEN INTERNATIONAL (S) PTE LTD",
     "TPSystemValue": 2852
    },
    {
     
     "CodeDescription": "SANDVIK SOUTH EAST ASIA PTE LTD",
     "TPSystemValue": 48
    },
    {
     
     "CodeDescription": "SANHAI INTERNATIONAL TRADING PTE LTD",
     "TPSystemValue": 1335
    },
    {
     
     "CodeDescription": "SANKEI TRAVEL SERVICE (S) PTE. LTD.",
     "TPSystemValue": "H090"
    },
    {
     
     "CodeDescription": "SANKO ELECTRICAL ENGINEERING COMPANY PTE. LTD.",
     "TPSystemValue": "04S"
    },
    {
     
     "CodeDescription": "SANKO MARINE ENGINEERING (S) PTE LTD",
     "TPSystemValue": "05S"
    },
    {
     
     "CodeDescription": "SANKO MARINE ENGINEERING(S) PL",
     "TPSystemValue": 2854
    },
    {
     
     "CodeDescription": "SANKO SINGAPORE PTE LTD",
     "TPSystemValue": 2855
    },
    {
     
     "CodeDescription": "SANKYU (S) P L",
     "TPSystemValue": 2856
    },
    {
     
     "CodeDescription": "SANMINASCI ELECTRONICS PTE LTD",
     "TPSystemValue": 150
    },
    {
     
     "CodeDescription": "SANMINASCI SYSTEMS SINGAPORE PTE LTD",
     "TPSystemValue": 226
    },
    {
     
     "CodeDescription": "SANOFIAVENTIS SINGAPORE PTE LTD",
     "TPSystemValue": 599
    },
    {
     
     "CodeDescription": "SANS MARINE ENGINEERING SERVICE",
     "TPSystemValue": "06S"
    },
    {
     
     "CodeDescription": "SAN'S TOURS & CAR RENTALS",
     "TPSystemValue": "H035"
    },
    {
     
     "CodeDescription": "SANTA FE RELOCATION SERVICES (S) PL",
     "TPSystemValue": 230
    },
    {
     
     "CodeDescription": "SANY TRAVEL & TOUR (1992) PTE LTD",
     "TPSystemValue": "H156"
    },
    {
     
     "CodeDescription": "SANYO AIRCOND MANUFACTURING S PL",
     "TPSystemValue": 267
    },
    {
     
     "CodeDescription": "SANYO ASIA PTE LTD",
     "TPSystemValue": 2857
    },
    {
     
     "CodeDescription": "SANYO ELECTRONICS (S) PTE LTD",
     "TPSystemValue": 2858
    },
    {
     
     "CodeDescription": "SANYO ENGINEERING & CONSTRUCTION",
     "TPSystemValue": 2859
    },
    {
     
     "CodeDescription": "SAP ASIA PTE LTD",
     "TPSystemValue": 327
    },
    {
     
     "CodeDescription": "SAPPHIRE PACIFIC PTE LTD",
     "TPSystemValue": 1264
    },
    {
     
     "CodeDescription": "SASCO",
     "TPSystemValue": 2860
    },
    {
     
     "CodeDescription": "SASS ATLANTIC PTE LTD",
     "TPSystemValue": 2861
    },
    {
     
     "CodeDescription": "SATCOM & SENSOR SYSTEM",
     "TPSystemValue": 2862
    },
    {
     
     "CodeDescription": "SATGURU TRAVEL & TOURS AND SHIVAM GENERAL TRADING PTE. LTD.",
     "TPSystemValue": "H615"
    },
    {
     
     "CodeDescription": "SATHYA SAI SOCIAL SERVICE",
     "TPSystemValue": 2863
    },
    {
     
     "CodeDescription": "SATHYA SHIPPING & LOGISTICS PTE LTD",
     "TPSystemValue": 1198
    },
    {
     
     "CodeDescription": "SATO ASIA PACIFIC PTE LTD",
     "TPSystemValue": 280
    },
    {
     
     "CodeDescription": "SATO GLOBAL BUSINESS SERVICES PTE LTD",
     "TPSystemValue": 1231
    },
    {
     
     "CodeDescription": "SATO KOGYO(S) PTE LTD",
     "TPSystemValue": 2864
    },
    {
     
     "CodeDescription": "SATS AIRPORT SERVICES PTE LTD",
     "TPSystemValue": 2865
    },
    {
     
     "CodeDescription": "SATS LTD",
     "TPSystemValue": 82
    },
    {
     
     "CodeDescription": "SATYAM COMPUTER SERVICES LIMITED",
     "TPSystemValue": 3069
    },
    {
     
     "CodeDescription": "SAVE THE CHILDREN ASIA REGIONAL OFF LTD",
     "TPSystemValue": 1300
    },
    {
     
     "CodeDescription": "SAVILLS RESIDENTIAL PTE LTD",
     "TPSystemValue": 607
    },
    {
     
     "CodeDescription": "SAVVIS SINGAPORE COMPANY PTE LTD",
     "TPSystemValue": 2866
    },
    {
     
     "CodeDescription": "SAXO CAPITAL MARKETS PTE LTD",
     "TPSystemValue": 1031
    },
    {
     
     "CodeDescription": "SB HOLIDAYZ PTE. LTD.",
     "TPSystemValue": "H585"
    },
    {
     
     "CodeDescription": "SB TRAVELS & TOURS PTE. LTD.",
     "TPSystemValue": "H420"
    },
    {
     
     "CodeDescription": "SBM TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H837"
    },
    {
     
     "CodeDescription": "SBS TRANSIT LTD",
     "TPSystemValue": 424
    },
    {
     
     "CodeDescription": "SCANTRUCK ENGINEERING PTE LTD",
     "TPSystemValue": 213
    },
    {
     
     "CodeDescription": "SCB",
     "TPSystemValue": 1
    },
    {
     
     "CodeDescription": "SCDF",
     "TPSystemValue": 2867
    },
    {
     
     "CodeDescription": "SCENIC TRAVEL PTE LTD",
     "TPSystemValue": "H127"
    },
    {
     
     "CodeDescription": "SCG SINGAPORE TRADING PTE LTD",
     "TPSystemValue": 736
    },
    {
     
     "CodeDescription": "SCHAEFER SYSTEMS INTERNATIONAL PTE. LTD",
     "TPSystemValue": 2868
    },
    {
     
     "CodeDescription": "SCHAEFFLER SINGAPORE PTE LTD",
     "TPSystemValue": 557
    },
    {
     
     "CodeDescription": "SCHENKER (ASIA PACIFIC) PTE LTD",
     "TPSystemValue": 2869
    },
    {
     
     "CodeDescription": "SCHENKER SINGAPORE PTE LTD",
     "TPSystemValue": 66
    },
    {
     
     "CodeDescription": "SCHERING PLOUGH LTD",
     "TPSystemValue": 2870
    },
    {
     
     "CodeDescription": "SCHINDLER LIFT SINGAPORE PTE LTD",
     "TPSystemValue": 2871
    },
    {
     
     "CodeDescription": "SCHLUMBERGER",
     "TPSystemValue": 2872
    },
    {
     
     "CodeDescription": "SCHLUMBERGER OILFIELD S PTE LTD",
     "TPSystemValue": 752
    },
    {
     
     "CodeDescription": "SCHNEIDER ELECTRIC BUILDINGS S PL",
     "TPSystemValue": 680
    },
    {
     
     "CodeDescription": "SCHNEIDER ELECTRIC INDUSTRIAL DEVEPT P L",
     "TPSystemValue": 2873
    },
    {
     
     "CodeDescription": "SCHNEIDER ELECTRIC IT SINGAPORE PL",
     "TPSystemValue": 527
    },
    {
     
     "CodeDescription": "SCHNEIDER ELECTRIC LOGISTICS ASIA PL",
     "TPSystemValue": 572
    },
    {
     
     "CodeDescription": "SCHNEIDER ELECTRIC SEA PTE LTD",
     "TPSystemValue": 2874
    },
    {
     
     "CodeDescription": "SCHNEIDER ELECTRIC SINGAPORE PTE LTD",
     "TPSystemValue": 158
    },
    {
     
     "CodeDescription": "SCHNEIDER ELECTRIC SOUTH EAST ASIA PL",
     "TPSystemValue": 86
    },
    {
     
     "CodeDescription": "SCHOOL OF THE ARTS SINGAPORE",
     "TPSystemValue": 1417
    },
    {
     
     "CodeDescription": "SCHOTT ELECTRONIC PACKAGING ASIA PTE LTD",
     "TPSystemValue": 2875
    },
    {
     
     "CodeDescription": "SCHRODER INVESTMENT MANAGEMENT S LTD",
     "TPSystemValue": 406
    },
    {
     
     "CodeDescription": "SCIENCE AND ENGINEERING INSTITUTES",
     "TPSystemValue": 1134
    },
    {
     
     "CodeDescription": "SCIENCE CENTRE BOARD",
     "TPSystemValue": 3097
    },
    {
     
     "CodeDescription": "SCIENCE CENTRE SINGAPORE",
     "TPSystemValue": 2876
    },
    {
     
     "CodeDescription": "SCIENTE CONSULTING PTE LTD",
     "TPSystemValue": 2877
    },
    {
     
     "CodeDescription": "SCL SYSTEM ENTERPRISE PTE. LTD.",
     "TPSystemValue": "07S"
    },
    {
     
     "CodeDescription": "SCL SYSTEM ENTREPRISE PTE LTD",
     "TPSystemValue": 957
    },
    {
     
     "CodeDescription": "SCOOT PTE LTD",
     "TPSystemValue": 1331
    },
    {
     
     "CodeDescription": "SCOPE LOGISTIC & ENGINEERING PTE LTD",
     "TPSystemValue": 606
    },
    {
     
     "CodeDescription": "SDV LOGISTIC (S) PTE LTD",
     "TPSystemValue": 2878
    },
    {
     
     "CodeDescription": "SEA CRUSINE PTE LTD",
     "TPSystemValue": 2879
    },
    {
     
     "CodeDescription": "SEA CUISINE (CENTURY) PTE LTD",
     "TPSystemValue": 1296
    },
    {
     
     "CodeDescription": "SEA CUISINE MARINA BAY PTE LTD",
     "TPSystemValue": 3786
    },
    {
     
     "CodeDescription": "SEA MARITIME SERVICES PTE LTD",
     "TPSystemValue": 1351
    },
    {
     
     "CodeDescription": "SEA WHEEL TRAVEL PTE LTD",
     "TPSystemValue": "H216"
    },
    {
     
     "CodeDescription": "SEA2SEA INTERNATIONAL PTE LTD",
     "TPSystemValue": 965
    },
    {
     
     "CodeDescription": "SEACAD TECHNOLOGIES PTE LTD",
     "TPSystemValue": 2880
    },
    {
     
     "CodeDescription": "SEACARE MARITIME MEDICAL CENTRE PTE LTD",
     "TPSystemValue": 580
    },
    {
     
     "CodeDescription": "SEACO ASIA PTE LTD",
     "TPSystemValue": 3714
    },
    {
     
     "CodeDescription": "SEADRILL MANAGEMENT (S) PTE LTD",
     "TPSystemValue": 2881
    },
    {
     
     "CodeDescription": "SEAGATE",
     "TPSystemValue": 2882
    },
    {
     
     "CodeDescription": "SEAGATE PTE LTD",
     "TPSystemValue": 2883
    },
    {
     
     "CodeDescription": "SEAGATE SINGAPORE INTERNATIONAL",
     "TPSystemValue": 2884
    },
    {
     
     "CodeDescription": "SEAGATE TECHNOLOGY INTERNATIONAL",
     "TPSystemValue": 2822
    },
    {
     
     "CodeDescription": "SEAGLE MARINE PTE LTD",
     "TPSystemValue": 820
    },
    {
     
     "CodeDescription": "SEAGULL MARINE PTE LTD",
     "TPSystemValue": 899
    },
    {
     
     "CodeDescription": "SEAL CONTROL PTE LTD",
     "TPSystemValue": 3715
    },
    {
     
     "CodeDescription": "SEALED AIR (SINGAPORE) PTE LTD",
     "TPSystemValue": 246
    },
    {
     
     "CodeDescription": "SEAMAX TRAVEL PTE LTD",
     "TPSystemValue": "H184"
    },
    {
     
     "CodeDescription": "SEAMEO REGIONAL LANGUAGE SCHOOL",
     "TPSystemValue": 3071
    },
    {
     
     "CodeDescription": "SEARCHWORKS PTE LTD",
     "TPSystemValue": 814
    },
    {
     
     "CodeDescription": "SEAWORLD MARINE PTE LTD",
     "TPSystemValue": 1076
    },
    {
     
     "CodeDescription": "SEAWORLD MARINE PTE. LTD.",
     "TPSystemValue": "09S"
    },
    {
     
     "CodeDescription": "SEIKI TRAVEL PTE LTD",
     "TPSystemValue": "H261"
    },
    {
     
     "CodeDescription": "SEIKO INSTRUMENT SINGAPORE PTE LTD",
     "TPSystemValue": 2885
    },
    {
     
     "CodeDescription": "SELACORP PTE LTD",
     "TPSystemValue": 857
    },
    {
     
     "CodeDescription": "SELECT GROUP LIMITED",
     "TPSystemValue": 497
    },
    {
     
     "CodeDescription": "SEMBAWANG ENGINEERS CONSTRUCTORS PL",
     "TPSystemValue": 209
    },
    {
     
     "CodeDescription": "SEMBAWANG SHIPYARD",
     "TPSystemValue": 2886
    },
    {
     
     "CodeDescription": "SEMBCORP",
     "TPSystemValue": 1376
    },
    {
     
     "CodeDescription": "SEMBCORP COGEN PTE LTD",
     "TPSystemValue": 626
    },
    {
     
     "CodeDescription": "SEMBCORP DESIGN & CONSTRUCTION PTE LTD",
     "TPSystemValue": 2887
    },
    {
     
     "CodeDescription": "SEMBCORP ENGINEERS & CONSTRUCTORS P L",
     "TPSystemValue": 2888
    },
    {
     
     "CodeDescription": "SEMBCORP INDUSTRIES LTD",
     "TPSystemValue": 654
    },
    {
     
     "CodeDescription": "SEMBCORP INDUSTRIES SINGAPORE",
     "TPSystemValue": 3716
    },
    {
     
     "CodeDescription": "SEMBCORP MARINE PTE LTD",
     "TPSystemValue": 2889
    },
    {
     
     "CodeDescription": "SEMBCORP PARKS MANAGEMENT PTE LTD",
     "TPSystemValue": 2890
    },
    {
     
     "CodeDescription": "SEMBCORP UTILLITIES PTE LTD",
     "TPSystemValue": 2891
    },
    {
     
     "CodeDescription": "SEMICONDUCTOR TECHNOLOGIES INSTRUMENT",
     "TPSystemValue": 2892
    },
    {
     
     "CodeDescription": "SENNHEISER CONSUMER ELECTORNICS GMBH",
     "TPSystemValue": 3084
    },
    {
     
     "CodeDescription": "SENNHEISER ELECTRONIC ASIA PTE LTD",
     "TPSystemValue": 421
    },
    {
     
     "CodeDescription": "SENOKO ENERGY PTE LTD",
     "TPSystemValue": 1181
    },
    {
     
     "CodeDescription": "SENTOSA LEISURE GROUP",
     "TPSystemValue": 2893
    },
    {
     
     "CodeDescription": "SENTOSA LEISURE MANAGEMENT PTE LTD",
     "TPSystemValue": 2894
    },
    {
     
     "CodeDescription": "SENZ INSTRUMENTS PTE LTD",
     "TPSystemValue": 1347
    },
    {
     
     "CodeDescription": "SEPHORA SINGAPORE PTE LTD",
     "TPSystemValue": 1195
    },
    {
     
     "CodeDescription": "SERANGOON AIR TRAVEL PTE LTD",
     "TPSystemValue": 2895
    },
    {
     
     "CodeDescription": "SERCEL SINGAPORE PRIVATE LTD",
     "TPSystemValue": 387
    },
    {
     
     "CodeDescription": "SERVICE SOURCE INTERNATIONAL",
     "TPSystemValue": 2896
    },
    {
     
     "CodeDescription": "SERVICESOURCE INTERNATIONAL (S) PL",
     "TPSystemValue": 1208
    },
    {
     
     "CodeDescription": "SERVLINK TECHNOLOGY RESOURCES PTE LTD",
     "TPSystemValue": 2897
    },
    {
     
     "CodeDescription": "SETCLEAR PTE LTD",
     "TPSystemValue": 1083
    },
    {
     
     "CodeDescription": "SETCLEAR PTE. LTD.",
     "TPSystemValue": "10S"
    },
    {
     
     "CodeDescription": "SETSCO SERVICES PTE LTD",
     "TPSystemValue": 2898
    },
    {
     
     "CodeDescription": "SEWELL MARINE",
     "TPSystemValue": 2899
    },
    {
     
     "CodeDescription": "SEYU PTE LTD",
     "TPSystemValue": 1112
    },
    {
     
     "CodeDescription": "SEYU PTE. LTD.",
     "TPSystemValue": "11S"
    },
    {
     
     "CodeDescription": "SF Consulting",
     "TPSystemValue": 176
    },
    {
     
     "CodeDescription": "SGH",
     "TPSystemValue": 2900
    },
    {
     
     "CodeDescription": "SGS TESTING & CONTROL SERVICES (S) P L",
     "TPSystemValue": 2901
    },
    {
     
     "CodeDescription": "SHA TRAVEL & TOUR PTE LTD",
     "TPSystemValue": "H177"
    },
    {
     
     "CodeDescription": "SHAHID TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H344"
    },
    {
     
     "CodeDescription": "SHAHIDAH TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H195"
    },
    {
     
     "CodeDescription": "SHALOM TOUR PTE. LTD.",
     "TPSystemValue": "H645"
    },
    {
     
     "CodeDescription": "SHANGRILA HOTEL LIMITED",
     "TPSystemValue": 24
    },
    {
     
     "CodeDescription": "SHANTI TRAVEL ASIA PRIVATE LIMITED",
     "TPSystemValue": "H694"
    },
    {
     
     "CodeDescription": "SHAO FOAK ENGINEERING PTE LTD",
     "TPSystemValue": 2902
    },
    {
     
     "CodeDescription": "SHAO FOOK ENGINEERING PTE. LTD.",
     "TPSystemValue": "12S"
    },
    {
     
     "CodeDescription": "SHATEC INSTITUTE",
     "TPSystemValue": 1400
    },
    {
     
     "CodeDescription": "SHELL",
     "TPSystemValue": 2903
    },
    {
     
     "CodeDescription": "SHELL CHEMICAL SERAYA PTE LTD",
     "TPSystemValue": 2904
    },
    {
     
     "CodeDescription": "SHELL INFOTECH PTE LTD",
     "TPSystemValue": 2905
    },
    {
     
     "CodeDescription": "SHELL PETROLEUM PTE LTD",
     "TPSystemValue": 2906
    },
    {
     
     "CodeDescription": "SHENG SIONG SUPERMARKET",
     "TPSystemValue": 2907
    },
    {
     
     "CodeDescription": "SHERATON TOWERS SINGAPORE HOTEL",
     "TPSystemValue": 1359
    },
    {
     
     "CodeDescription": "SHILLA TRAVEL RETAIL PTE. LTD.",
     "TPSystemValue": "H661"
    },
    {
     
     "CodeDescription": "SHIMA RESTAURANT PTE LTD",
     "TPSystemValue": 167
    },
    {
     
     "CodeDescription": "SHIMADZU ASIA PACIFICPTE LTD",
     "TPSystemValue": 331
    },
    {
     
     "CodeDescription": "SHIMANO (S) PTE LTD",
     "TPSystemValue": 2908
    },
    {
     
     "CodeDescription": "SHIMIZU COPORATION",
     "TPSystemValue": 2813
    },
    {
     
     "CodeDescription": "SHIN HONG ENGINEERING PTE LTD",
     "TPSystemValue": 2909
    },
    {
     
     "CodeDescription": "SHIN KAIGAI TRAVEL SERVICE PTE LTD",
     "TPSystemValue": "H128"
    },
    {
     
     "CodeDescription": "SHIN KUSHIYA PTE LTD",
     "TPSystemValue": 2910
    },
    {
     
     "CodeDescription": "SHIN NIPPON AIR TECHNOLOGIES CO LTD",
     "TPSystemValue": 3414
    },
    {
     
     "CodeDescription": "SHIN-ETSU (S) PL",
     "TPSystemValue": 2911
    },
    {
     
     "CodeDescription": "SHINHAN BANK",
     "TPSystemValue": "S01"
    },
    {
     
     "CodeDescription": "SHIPCO TRANSPORT PTE LTD",
     "TPSystemValue": 149
    },
    {
     
     "CodeDescription": "SHOOKLIN & BOK LLP",
     "TPSystemValue": 2912
    },
    {
     
     "CodeDescription": "SHOP N SAVE PTE LTD",
     "TPSystemValue": 242
    },
    {
     
     "CodeDescription": "SHORA MARVELS PTE. LTD.",
     "TPSystemValue": "H765"
    },
    {
     
     "CodeDescription": "SHORRA DENKO HD SINGAPORE PTE LTD",
     "TPSystemValue": 2913
    },
    {
     
     "CodeDescription": "SHOWA DENKO",
     "TPSystemValue": 2914
    },
    {
     
     "CodeDescription": "SHOWA DENKO HD SINGAPORE PTE LTD",
     "TPSystemValue": 840
    },
    {
     
     "CodeDescription": "SHOWER DYNAMIC PTE LTD",
     "TPSystemValue": 747
    },
    {
     
     "CodeDescription": "SHRI ACADEMY PTE LTD",
     "TPSystemValue": 1141
    },
    {
     
     "CodeDescription": "SIA ENGINEERING COMPANY",
     "TPSystemValue": "H032"
    },
    {
     
     "CodeDescription": "SIA ENGINEERING COMPANY LIMITED",
     "TPSystemValue": 194
    },
    {
     
     "CodeDescription": "SIA PTE LTD",
     "TPSystemValue": 2915
    },
    {
     
     "CodeDescription": "SIAEC",
     "TPSystemValue": 2916
    },
    {
     
     "CodeDescription": "SIAM COMMERCIAL BANK PUBLIC COMPANY LIMITED, THE",
     "TPSystemValue": "S02"
    },
    {
     
     "CodeDescription": "SIAM EXPRESS PTE. LTD.",
     "TPSystemValue": "H098"
    },
    {
     
     "CodeDescription": "SIBELCO ASIA PTE LTD",
     "TPSystemValue": 472
    },
    {
     
     "CodeDescription": "SIDLEY AUSTIN LLP",
     "TPSystemValue": 3110
    },
    {
     
     "CodeDescription": "SIEMENS",
     "TPSystemValue": 2917
    },
    {
     
     "CodeDescription": "SIEMENS BUSINESS SERVICES PTE LTD",
     "TPSystemValue": 2918
    },
    {
     
     "CodeDescription": "SIEMENS COMPONENTS PTE LTD",
     "TPSystemValue": 2919
    },
    {
     
     "CodeDescription": "SIEMENS INDUSTRY SOFTWARE PTE LTD",
     "TPSystemValue": 636
    },
    {
     
     "CodeDescription": "SIEMENS MEDICAL INSTRUMENTS PTE LTD",
     "TPSystemValue": 2920
    },
    {
     
     "CodeDescription": "SIEMENS PTE LTD",
     "TPSystemValue": 2921
    },
    {
     
     "CodeDescription": "SIGI INTERNATIONAL PTE LTD",
     "TPSystemValue": 1270
    },
    {
     
     "CodeDescription": "SIGMA BUILDERS PTE LTD",
     "TPSystemValue": 785
    },
    {
     
     "CodeDescription": "SIGMA ELEVATOR SINGAPORE PTE LTD",
     "TPSystemValue": 2922
    },
    {
     
     "CodeDescription": "SIGMA SECURITY & HR CONSULTANTS PTE LTD",
     "TPSystemValue": 2923
    },
    {
     
     "CodeDescription": "SILICOMP ASIA PTE LTD",
     "TPSystemValue": 483
    },
    {
     
     "CodeDescription": "SILICON LABORATORIES INT P L",
     "TPSystemValue": 2924
    },
    {
     
     "CodeDescription": "SILICON MANUFACTURING PARTNERS PL",
     "TPSystemValue": 2925
    },
    {
     
     "CodeDescription": "SILICONIX SINGAPORE PTE LTD",
     "TPSystemValue": 1316
    },
    {
     
     "CodeDescription": "SILK ROSE PTE LTD",
     "TPSystemValue": 762
    },
    {
     
     "CodeDescription": "SILKAIR SINGAPORE PTE LTD",
     "TPSystemValue": 2926
    },
    {
     
     "CodeDescription": "SILTRONIC SAMSUNG WAFER PTE LTD",
     "TPSystemValue": 1065
    },
    {
     
     "CodeDescription": "SILTRONICS SINGAPORE PTE LTD",
     "TPSystemValue": 628
    },
    {
     
     "CodeDescription": "SILVER STAR TRAVEL PTE. LTD.",
     "TPSystemValue": "H636"
    },
    {
     
     "CodeDescription": "SILVER TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "H762"
    },
    {
     
     "CodeDescription": "SILVERDALE CAPITAL SERVICES PTE LTD",
     "TPSystemValue": 2927
    },
    {
     
     "CodeDescription": "SILVERLAKE INFORMATIONS SYSTEMS PTE LTD",
     "TPSystemValue": 1311
    },
    {
     
     "CodeDescription": "SIM LIAN CONSTRUCTION CO PL",
     "TPSystemValue": 2928
    },
    {
     
     "CodeDescription": "SIM UNIVERSITY PTE LTD",
     "TPSystemValue": 2929
    },
    {
     
     "CodeDescription": "SIMATECH SHIPPING PTE LTD",
     "TPSystemValue": 892
    },
    {
     
     "CodeDescription": "SIME DARBY SERVICES PTE LTD",
     "TPSystemValue": "H071"
    },
    {
     
     "CodeDescription": "SIMPLYWILLS PTE LTD",
     "TPSystemValue": 1249
    },
    {
     
     "CodeDescription": "SIMPSON SPENCE & YOUNG",
     "TPSystemValue": 2931
    },
    {
     
     "CodeDescription": "SIMTECH",
     "TPSystemValue": 2932
    },
    {
     
     "CodeDescription": "SIMULATION SOFTWARE & TECHNOLOGY PL",
     "TPSystemValue": 811
    },
    {
     
     "CodeDescription": "SIN HENG HEAVY MACHINERY LIMITED",
     "TPSystemValue": 2933
    },
    {
     
     "CodeDescription": "SIN KIAT METAL WORKS PTE LTD",
     "TPSystemValue": 1102
    },
    {
     
     "CodeDescription": "SIN U LIAN TRAVEL PTE. LTD.",
     "TPSystemValue": "H764"
    },
    {
     
     "CodeDescription": "SINAIR TRAVEL AGENCY (PTE) LTD",
     "TPSystemValue": "H050"
    },
    {
     
     "CodeDescription": "SINCERE",
     "TPSystemValue": 2934
    },
    {
     
     "CodeDescription": "SINCERE SHIPPING PTE LTD",
     "TPSystemValue": 1157
    },
    {
     
     "CodeDescription": "SINCERE WATCH LIMITED",
     "TPSystemValue": 125
    },
    {
     
     "CodeDescription": "SINDAR TRAVEL PTE. LTD.",
     "TPSystemValue": "H709"
    },
    {
     
     "CodeDescription": "SINDOMAS TRAVEL & TOUR PTE. LTD.",
     "TPSystemValue": "H462"
    },
    {
     
     "CodeDescription": "SING HEALTH PTE LTD",
     "TPSystemValue": 2935
    },
    {
     
     "CodeDescription": "SING INVESTMENTS & FINANCE LIMITED",
     "TPSystemValue": "S03"
    },
    {
     
     "CodeDescription": "SINGA TRAVEL AND TOURS PTE. LTD.",
     "TPSystemValue": "H640"
    },
    {
     
     "CodeDescription": "SINGAPORE AERO ENGINE SERVICES PL",
     "TPSystemValue": 685
    },
    {
     
     "CodeDescription": "SINGAPORE AEROSPACE MANUFACTURING PTE LT",
     "TPSystemValue": 2936
    },
    {
     
     "CodeDescription": "SINGAPORE AIRLINES",
     "TPSystemValue": "H031"
    },
    {
     
     "CodeDescription": "SINGAPORE AIRLINES LIMITED",
     "TPSystemValue": 74
    },
    {
     
     "CodeDescription": "SINGAPORE AIRPORT TERMINAL SERVICES LTD",
     "TPSystemValue": 2937
    },
    {
     
     "CodeDescription": "SINGAPORE AMERICAN SCHOOL",
     "TPSystemValue": 2938
    },
    {
     
     "CodeDescription": "SINGAPORE ARM FORCES",
     "TPSystemValue": 2939
    },
    {
     
     "CodeDescription": "SINGAPORE ARRIVALS PTE. LTD.",
     "TPSystemValue": "H809"
    },
    {
     
     "CodeDescription": "SINGAPORE AUTOMOTIVE ENGINEERING PL",
     "TPSystemValue": 2940
    },
    {
     
     "CodeDescription": "SINGAPORE CABLE MANUFACTURERS PTE LTD",
     "TPSystemValue": 2941
    },
    {
     
     "CodeDescription": "SINGAPORE CABLE VISION LTD",
     "TPSystemValue": 2942
    },
    {
     
     "CodeDescription": "SINGAPORE CHINA BUSINESS AND CULTURE DEVELOPMENT CENTRE (PTE. LTD.)",
     "TPSystemValue": "H614"
    },
    {
     
     "CodeDescription": "SINGAPORE CIVIL DEFENCE FORCE",
     "TPSystemValue": 2943
    },
    {
     
     "CodeDescription": "SINGAPORE COACH SERVICES PTE. LTD.",
     "TPSystemValue": "H675"
    },
    {
     
     "CodeDescription": "SINGAPORE COMPUTER SYSTEM LIMITED",
     "TPSystemValue": 2944
    },
    {
     
     "CodeDescription": "SINGAPORE CUSTOMS",
     "TPSystemValue": 2946
    },
    {
     
     "CodeDescription": "SINGAPORE DAI ICHI PL",
     "TPSystemValue": 2947
    },
    {
     
     "CodeDescription": "SINGAPORE DEPARTMENT OF STATISTICS",
     "TPSystemValue": 2948
    },
    {
     
     "CodeDescription": "SINGAPORE DISCOVERY CENTRE LTD",
     "TPSystemValue": "H227"
    },
    {
     
     "CodeDescription": "SINGAPORE DUCKTOURS PRIVATE LIMITED",
     "TPSystemValue": "H318"
    },
    {
     
     "CodeDescription": "SINGAPORE EPSON INDUSTRIAL PTE LTD",
     "TPSystemValue": 2949
    },
    {
     
     "CodeDescription": "SINGAPORE EXCHANGE LIMITED",
     "TPSystemValue": 2950
    },
    {
     
     "CodeDescription": "SINGAPORE EXHIBITION SERVICES PTE LTD",
     "TPSystemValue": 2951
    },
    {
     
     "CodeDescription": "SINGAPORE EYE RESEARCH INSTITUTE",
     "TPSystemValue": 610
    },
    {
     
     "CodeDescription": "SINGAPORE FLYER PTE. LTD.",
     "TPSystemValue": 879
    },
    {
     
     "CodeDescription": "SINGAPORE FOOD INDUSTRIES",
     "TPSystemValue": 2952
    },
    {
     
     "CodeDescription": "SINGAPORE GENERAL HOSPITAL PTE LTD",
     "TPSystemValue": 296
    },
    {
     
     "CodeDescription": "SINGAPORE GOLD FORTUNE TRAVEL PTE. LTD.",
     "TPSystemValue": "H770"
    },
    {
     
     "CodeDescription": "SINGAPORE HEALTH SERVICES PL",
     "TPSystemValue": 2953
    },
    {
     
     "CodeDescription": "SINGAPORE HISTORY CONSULTANTS PTE. LTD.",
     "TPSystemValue": "H755"
    },
    {
     
     "CodeDescription": "SINGAPORE IMMIGRATION & REGISTRATION",
     "TPSystemValue": 2954
    },
    {
     
     "CodeDescription": "SINGAPORE INDIAN CHAMBER OF C I",
     "TPSystemValue": 11
    },
    {
     
     "CodeDescription": "SINGAPORE INSTITUTE OF MANAGEMENT PL",
     "TPSystemValue": 571
    },
    {
     
     "CodeDescription": "SINGAPORE INSTITUTE OF TECHNOLOGY",
     "TPSystemValue": 1240
    },
    {
     
     "CodeDescription": "SINGAPORE INTERNATIONAL TRAVEL SERVICE PTE. LTD.",
     "TPSystemValue": "H532"
    },
    {
     
     "CodeDescription": "SINGAPORE ISLAND BANK LIMITED",
     "TPSystemValue": "S04"
    },
    {
     
     "CodeDescription": "SINGAPORE KEPIN TOUR PTE. LTD.",
     "TPSystemValue": "H583"
    },
    {
     
     "CodeDescription": "SINGAPORE KOBE PTE LTD",
     "TPSystemValue": 116
    },
    {
     
     "CodeDescription": "SINGAPORE LAND AUTHORITY",
     "TPSystemValue": 2955
    },
    {
     
     "CodeDescription": "SINGAPORE LETTO TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "H738"
    },
    {
     
     "CodeDescription": "SINGAPORE MANAGEMENT UNIVERSITY",
     "TPSystemValue": 716
    },
    {
     
     "CodeDescription": "SINGAPORE MARRIOTT HOTEL",
     "TPSystemValue": 2956
    },
    {
     
     "CodeDescription": "SINGAPORE MEDICAL COUNCIL",
     "TPSystemValue": 3424
    },
    {
     
     "CodeDescription": "SINGAPORE MEDICAL GROUP LIMITED",
     "TPSystemValue": 2957
    },
    {
     
     "CodeDescription": "SINGAPORE MERCANTILE EXCHANGE PTE LTD",
     "TPSystemValue": "16S"
    },
    {
     
     "CodeDescription": "SINGAPORE MERCHANTILE EXCHANGE PTE LTD",
     "TPSystemValue": 1075
    },
    {
     
     "CodeDescription": "SINGAPORE -MIT ALLIANCE FOR RESEARCH AND",
     "TPSystemValue": 2958
    },
    {
     
     "CodeDescription": "SINGAPORE MRT LIMITED",
     "TPSystemValue": 2959
    },
    {
     
     "CodeDescription": "SINGAPORE NATIONAL CO-OPERATIVE FEDERATI",
     "TPSystemValue": 2960
    },
    {
     
     "CodeDescription": "SINGAPORE NATIONAL EMPLOYERS FEDERATION",
     "TPSystemValue": 2961
    },
    {
     
     "CodeDescription": "SINGAPORE NATIONAL EYE CENTRE PTE LTD",
     "TPSystemValue": 318
    },
    {
     
     "CodeDescription": "SINGAPORE NAVY",
     "TPSystemValue": 2962
    },
    {
     
     "CodeDescription": "SINGAPORE ORIENTAL MOTOR PL",
     "TPSystemValue": 2963
    },
    {
     
     "CodeDescription": "SINGAPORE OXYGEN AIR LIQUID PTE LTD",
     "TPSystemValue": 2964
    },
    {
     
     "CodeDescription": "SINGAPORE PETROLEUM CO LTD",
     "TPSystemValue": 2965
    },
    {
     
     "CodeDescription": "SINGAPORE PILING AND CIVIL ENGINEERING",
     "TPSystemValue": 2966
    },
    {
     
     "CodeDescription": "SINGAPORE POLICE FORCE",
     "TPSystemValue": 2967
    },
    {
     
     "CodeDescription": "SINGAPORE POOLS PRIVATE LIMITED",
     "TPSystemValue": 42
    },
    {
     
     "CodeDescription": "SINGAPORE POWER LIMITED",
     "TPSystemValue": 474
    },
    {
     
     "CodeDescription": "SINGAPORE POWERGRID PTE LTD",
     "TPSystemValue": 2968
    },
    {
     
     "CodeDescription": "SINGAPORE PRECISION INDUSTRIES PTE LTD",
     "TPSystemValue": 178
    },
    {
     
     "CodeDescription": "SINGAPORE PRESS HOLDINGS FOUNDATION LIMITED",
     "TPSystemValue": 3718
    },
    {
     
     "CodeDescription": "SINGAPORE PRESS HOLDINGS LIMITED",
     "TPSystemValue": 241
    },
    {
     
     "CodeDescription": "SINGAPORE PRESS HOLDINGS OVERSEAS LIMITED",
     "TPSystemValue": 3717
    },
    {
     
     "CodeDescription": "SINGAPORE PRISON SER-CLUSTER A",
     "TPSystemValue": 2969
    },
    {
     
     "CodeDescription": "SINGAPORE PRISON SERVICE",
     "TPSystemValue": 2970
    },
    {
     
     "CodeDescription": "SINGAPORE RED CROSS SOCIETY",
     "TPSystemValue": 3719
    },
    {
     
     "CodeDescription": "SINGAPORE REFINING CO PTE LTD",
     "TPSystemValue": 2971
    },
    {
     
     "CodeDescription": "SINGAPORE REINSURANCE CORPORATION LTD",
     "TPSystemValue": 84
    },
    {
     
     "CodeDescription": "SINGAPORE SHARING TRAVEL PTE. LTD.",
     "TPSystemValue": "H725"
    },
    {
     
     "CodeDescription": "SINGAPORE SIGHTSEEING PTE LTD",
     "TPSystemValue": "H043"
    },
    {
     
     "CodeDescription": "SINGAPORE SPORT COUNCIL",
     "TPSystemValue": 2972
    },
    {
     
     "CodeDescription": "SINGAPORE SPORTS SCHOOL LTD",
     "TPSystemValue": 2973
    },
    {
     
     "CodeDescription": "SINGAPORE SWIMMING CLUB",
     "TPSystemValue": 2806
    },
    {
     
     "CodeDescription": "SINGAPORE SYNPHONY OCHESTRA",
     "TPSystemValue": 2974
    },
    {
     
     "CodeDescription": "SINGAPORE TAKADA INDUSTRIES PTE LTD",
     "TPSystemValue": 2975
    },
    {
     
     "CodeDescription": "SINGAPORE TECHNOLOGIES",
     "TPSystemValue": 2976
    },
    {
     
     "CodeDescription": "SINGAPORE TECHNOLOGIES AEROSPACE",
     "TPSystemValue": 2977
    },
    {
     
     "CodeDescription": "SINGAPORE TECHNOLOGIES ELECTRONICS LTD",
     "TPSystemValue": 49
    },
    {
     
     "CodeDescription": "SINGAPORE TECHNOLOGIES KINETICS LTD",
     "TPSystemValue": 70
    },
    {
     
     "CodeDescription": "SINGAPORE TECHNOLOGIES LOGISTICS PTE LTD",
     "TPSystemValue": 2978
    },
    {
     
     "CodeDescription": "SINGAPORE TECHNOLOGIES MARINE LTD",
     "TPSystemValue": 41
    },
    {
     
     "CodeDescription": "SINGAPORE TECHNOLOGY KINETICS PL",
     "TPSystemValue": 2979
    },
    {
     
     "CodeDescription": "SINGAPORE TELECOMMUNICATIONS LIMITED",
     "TPSystemValue": 409
    },
    {
     
     "CodeDescription": "SINGAPORE TEST SERVICES PTE LTD",
     "TPSystemValue": 2980
    },
    {
     
     "CodeDescription": "SINGAPORE TOURISM BOARD",
     "TPSystemValue": 3098
    },
    {
     
     "CodeDescription": "SINGAPORE TRAVEL HUB PTE. LTD.",
     "TPSystemValue": "H843"
    },
    {
     
     "CodeDescription": "SINGAPORE TURF CLUB",
     "TPSystemValue": 2981
    },
    {
     
     "CodeDescription": "SINGAPORE WORKFORCE DEVELOPMENT AGENCY",
     "TPSystemValue": 2982
    },
    {
     
     "CodeDescription": "SINGAPORE ZOO",
     "TPSystemValue": 2983
    },
    {
     
     "CodeDescription": "SINGAPORE ZOOLOGICAL GARDENS",
     "TPSystemValue": "H047"
    },
    {
     
     "CodeDescription": "SINGAPURA FINANCE LTD",
     "TPSystemValue": "S05"
    },
    {
     
     "CodeDescription": "SINGATOUR PTE LTD",
     "TPSystemValue": "H322"
    },
    {
     
     "CodeDescription": "SINGBIZ TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "H511"
    },
    {
     
     "CodeDescription": "SINGEXPRESS TRAVEL PTE. LTD.",
     "TPSystemValue": "H508"
    },
    {
     
     "CodeDescription": "SINGHEALTH",
     "TPSystemValue": 2984
    },
    {
     
     "CodeDescription": "SINGO SERVICES PTE LTD",
     "TPSystemValue": 2985
    },
    {
     
     "CodeDescription": "SINGTEL",
     "TPSystemValue": 2986
    },
    {
     
     "CodeDescription": "SINGTEL HELIO PTE LTD",
     "TPSystemValue": 745
    },
    {
     
     "CodeDescription": "SINGTEL YELLOW PAGES PTE LTD",
     "TPSystemValue": 2987
    },
    {
     
     "CodeDescription": "SINJAYA FOOD ENTERPRISE PTE. LTD.",
     "TPSystemValue": 967
    },
    {
     
     "CodeDescription": "SIN-MYANMAR TRAVEL PTE LTD",
     "TPSystemValue": "H285"
    },
    {
     
     "CodeDescription": "SINO ELITE TRAVEL SERVICES PTE. LTD.",
     "TPSystemValue": "H789"
    },
    {
     
     "CodeDescription": "SINOAMERICA TOURS CORPORATION PTE LTD",
     "TPSystemValue": 3720
    },
    {
     
     "CodeDescription": "SINO-AMERICA TOURS CORPORATION PTE. LTD.",
     "TPSystemValue": "H064"
    },
    {
     
     "CodeDescription": "SINO-WAY TRAVEL PTE. LTD.",
     "TPSystemValue": "H448"
    },
    {
     
     "CodeDescription": "SISTIC COM PTE LTD",
     "TPSystemValue": 2988
    },
    {
     
     "CodeDescription": "SITA",
     "TPSystemValue": 2989
    },
    {
     
     "CodeDescription": "SITA WORLD TRAVEL PTE LTD",
     "TPSystemValue": "H038"
    },
    {
     
     "CodeDescription": "SIX CONTINENTS TRAVEL PTE. LTD.",
     "TPSystemValue": "H445"
    },
    {
     
     "CodeDescription": "SIX STARS TOURS AND SERVICES PTE. LTD.",
     "TPSystemValue": "H413"
    },
    {
     
     "CodeDescription": "SIX TEE ENGINEERING GROUPS PTE LTD",
     "TPSystemValue": 915
    },
    {
     
     "CodeDescription": "SIX TEE ENGINEERING GROUPS PTE. LTD.",
     "TPSystemValue": "17S"
    },
    {
     
     "CodeDescription": "SIXTEE ENGINEERING GROUP PTE LTD",
     "TPSystemValue": 2990
    },
    {
     
     "CodeDescription": "SJ HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H592"
    },
    {
     
     "CodeDescription": "SK C&C CO. LTD. SINGAPORE BRANCH",
     "TPSystemValue": 3118
    },
    {
     
     "CodeDescription": "SK GAS INTERNATIONAL PTE LTD",
     "TPSystemValue": 1145
    },
    {
     
     "CodeDescription": "SK HYNIX ASIA PTE LTD",
     "TPSystemValue": 3721
    },
    {
     
     "CodeDescription": "SKANDINAVISKA ENSKILDA BANKEN AB (PUBL)",
     "TPSystemValue": "S06"
    },
    {
     
     "CodeDescription": "SKF ASIA PACIFIC PTE LTD",
     "TPSystemValue": 85
    },
    {
     
     "CodeDescription": "SKILLTEC METAL ENGINEERING PL (SME)",
     "TPSystemValue": 1282
    },
    {
     
     "CodeDescription": "SKILLTEC PRECISION ENGINEERING PTE LTD",
     "TPSystemValue": 740
    },
    {
     
     "CodeDescription": "SKILLTECH ALUMINIUM CONSTRUCTION PL",
     "TPSystemValue": 979
    },
    {
     
     "CodeDescription": "SKIN INC SOLUTION PTE LTD",
     "TPSystemValue": 1132
    },
    {
     
     "CodeDescription": "SKY CENTRE AIR TRAVEL PTE LTD",
     "TPSystemValue": "H143"
    },
    {
     
     "CodeDescription": "SKY FITNESS & WELLNESS CONSULTANCY PL",
     "TPSystemValue": 1306
    },
    {
     
     "CodeDescription": "SKY FITNESS PTE LTD",
     "TPSystemValue": 1014
    },
    {
     
     "CodeDescription": "SKY PREMIUM INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H695"
    },
    {
     
     "CodeDescription": "SKY TOUR KOREA PTE LTD",
     "TPSystemValue": "H293"
    },
    {
     
     "CodeDescription": "SKYLINE INTERNATIONAL TRAVEL PTE. LTD.",
     "TPSystemValue": "H700"
    },
    {
     
     "CodeDescription": "SKYLINE TRAVEL AND CONSULTING PTE. LTD.",
     "TPSystemValue": "H628"
    },
    {
     
     "CodeDescription": "SKYMECH AUTOMATION & ENGINEERING PL",
     "TPSystemValue": 311
    },
    {
     
     "CodeDescription": "SKYWORLD TRAVEL TOURS & TRADING PTE. LTD.",
     "TPSystemValue": "H401"
    },
    {
     
     "CodeDescription": "SLP INTERNATIONAL PROPERTY CONSULTANTS",
     "TPSystemValue": 863
    },
    {
     
     "CodeDescription": "SLPINTERNATIONALPROPERTY CONSULTANTS PL",
     "TPSystemValue": 2991
    },
    {
     
     "CodeDescription": "SM FOUNDATION MACHINERY PTE LTD",
     "TPSystemValue": 2992
    },
    {
     
     "CodeDescription": "SM FOUNDATION MACHINERY PTE. LTD.",
     "TPSystemValue": "18S"
    },
    {
     
     "CodeDescription": "SM SYSTEM CONTROL PTE LTD",
     "TPSystemValue": 3773
    },
    {
     
     "CodeDescription": "SMA INSTITUTE OF HIGHER LEARNING PL",
     "TPSystemValue": 871
    },
    {
     
     "CodeDescription": "SMA INSTITUTE OF HIGHER LEARNING PTE. LTD.",
     "TPSystemValue": "19S"
    },
    {
     
     "CodeDescription": "SMA SCHOOL OF MANAGEMENT PTE LTD",
     "TPSystemValue": 2993
    },
    {
     
     "CodeDescription": "SMART - SYS PTE LTD",
     "TPSystemValue": 862
    },
    {
     
     "CodeDescription": "SMART AUTOMOBILE PTE LTD",
     "TPSystemValue": 2994
    },
    {
     
     "CodeDescription": "SMBC",
     "TPSystemValue": 2995
    },
    {
     
     "CodeDescription": "SMC MANAGEMENT CONSULTANTS",
     "TPSystemValue": 859
    },
    {
     
     "CodeDescription": "SMC MANUFACTURING (S) PTE LTD",
     "TPSystemValue": 2996
    },
    {
     
     "CodeDescription": "SMC MARINE MANAGEMENT PTE LTD",
     "TPSystemValue": 1177
    },
    {
     
     "CodeDescription": "SMC PNEUMATICS SEA PTE LTD",
     "TPSystemValue": 97
    },
    {
     
     "CodeDescription": "SMILING TRAVEL PTE. LTD.",
     "TPSystemValue": "H801"
    },
    {
     
     "CodeDescription": "SMITHS DETECTION (ASIA PACIFIC) PTE LTD",
     "TPSystemValue": 2997
    },
    {
     
     "CodeDescription": "SMM PTE LTD",
     "TPSystemValue": 2998
    },
    {
     
     "CodeDescription": "SMOE PTE LTD",
     "TPSystemValue": 2999
    },
    {
     
     "CodeDescription": "SMRT BUSES LTD",
     "TPSystemValue": 200
    },
    {
     
     "CodeDescription": "SMRT CORPORATION LTD",
     "TPSystemValue": 720
    },
    {
     
     "CodeDescription": "SMRT INVESTMENTS PTE LTD",
     "TPSystemValue": 722
    },
    {
     
     "CodeDescription": "SMRT LTD",
     "TPSystemValue": 3000
    },
    {
     
     "CodeDescription": "SMRT TAXIS PTE LTD",
     "TPSystemValue": 3722
    },
    {
     
     "CodeDescription": "SMRT TRAINS LTD",
     "TPSystemValue": 282
    },
    {
     
     "CodeDescription": "SMTC GLOBAL (SINGAPORE) PTE LTD",
     "TPSystemValue": 3001
    },
    {
     
     "CodeDescription": "SMU",
     "TPSystemValue": 3002
    },
    {
     
     "CodeDescription": "SMX PTE LTD",
     "TPSystemValue": 3003
    },
    {
     
     "CodeDescription": "SO GOOD TOUR PTE. LTD.",
     "TPSystemValue": "H584"
    },
    {
     
     "CodeDescription": "SOCIETE AIR FRANCE",
     "TPSystemValue": 2826
    },
    {
     
     "CodeDescription": "SOCIETY FOR THE PHYSICALLY DISABLED",
     "TPSystemValue": 2807
    },
    {
     
     "CodeDescription": "SOITEC SINGAPORE PTE LTD",
     "TPSystemValue": 3005
    },
    {
     
     "CodeDescription": "SOJITH ASIA PTE LTD",
     "TPSystemValue": 663
    },
    {
     
     "CodeDescription": "SOLELY CONTRUCTION PTE LTD",
     "TPSystemValue": 3780
    },
    {
     
     "CodeDescription": "SOLIDIANCE ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1139
    },
    {
     
     "CodeDescription": "SONY ELECTRONICS ASIA PACIFIC PTE LTD",
     "TPSystemValue": 403
    },
    {
     
     "CodeDescription": "SONY ELECTRONICS SINGAPORE PTE LTD",
     "TPSystemValue": 341
    },
    {
     
     "CodeDescription": "SOON CHEONG HANG TRADING PTE LTD",
     "TPSystemValue": 410
    },
    {
     
     "CodeDescription": "SOON LIAN HARDWARE PTE LTD",
     "TPSystemValue": 231
    },
    {
     
     "CodeDescription": "SOUTHERN CROSS INTERNATIONAL (S) PTE. LTD.",
     "TPSystemValue": "H428"
    },
    {
     
     "CodeDescription": "SOUTHWEST AIRLINES",
     "TPSystemValue": "H026"
    },
    {
     
     "CodeDescription": "SP JAIN CENTRE OF MANAGEMENT PL",
     "TPSystemValue": 1023
    },
    {
     
     "CodeDescription": "SP SERVICES",
     "TPSystemValue": 3723
    },
    {
     
     "CodeDescription": "SPA ESPIRIT GROUP PTE LTD",
     "TPSystemValue": 1096
    },
    {
     
     "CodeDescription": "SPA VILLA LEISURE PTE. LTD.",
     "TPSystemValue": "H500"
    },
    {
     
     "CodeDescription": "SPACE MATRIX DESIGN CONSULTANTS PTE LTD",
     "TPSystemValue": 774
    },
    {
     
     "CodeDescription": "SPD SCIENTIFIC PTE LTD",
     "TPSystemValue": 458
    },
    {
     
     "CodeDescription": "SPE NETWOTKS ASIA PTE LTD",
     "TPSystemValue": 604
    },
    {
     
     "CodeDescription": "SPECTRUM WORLDWIDE EVENTS (SG)PTE LTD",
     "TPSystemValue": 904
    },
    {
     
     "CodeDescription": "SPEEDMARK CONSOLIDATION SERVICE PTE LTD",
     "TPSystemValue": 251
    },
    {
     
     "CodeDescription": "SPEEDMARK LOGISTICS PTE LTD",
     "TPSystemValue": 769
    },
    {
     
     "CodeDescription": "SPIRIT AIRLINES",
     "TPSystemValue": "H030"
    },
    {
     
     "CodeDescription": "SPLASH PRODUCTIONS PTE LTD",
     "TPSystemValue": 3811
    },
    {
     
     "CodeDescription": "SPLASH PRODUCTIONS PTE. LTD.",
     "TPSystemValue": "20S"
    },
    {
     
     "CodeDescription": "SPLENDID ENGINEERING PTE LTD",
     "TPSystemValue": 550
    },
    {
     
     "CodeDescription": "SPLENDOUR HOLIDAYS PTE LTD",
     "TPSystemValue": "H263"
    },
    {
     
     "CodeDescription": "SPREETRIP PTE. LTD.",
     "TPSystemValue": "H509"
    },
    {
     
     "CodeDescription": "SPRING ENGINEERING PTE LTD",
     "TPSystemValue": 3724
    },
    {
     
     "CodeDescription": "SPRING HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H552"
    },
    {
     
     "CodeDescription": "SPRING SINGAPORE",
     "TPSystemValue": 3006
    },
    {
     
     "CodeDescription": "SPRINT INTERNATIONAL COMMUNICATIONS SINGAPORE PTE",
     "TPSystemValue": 3725
    },
    {
     
     "CodeDescription": "SPUR SHIPPING PTE LTD",
     "TPSystemValue": 644
    },
    {
     
     "CodeDescription": "SQL VIEW PTE LTD",
     "TPSystemValue": 407
    },
    {
     
     "CodeDescription": "SRI MAJU TOURS & TRAVEL PTE LTD",
     "TPSystemValue": "H333"
    },
    {
     
     "CodeDescription": "SRI MULIA TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H706"
    },
    {
     
     "CodeDescription": "SS CORP ICT SERVICES PTE LTD",
     "TPSystemValue": 374
    },
    {
     
     "CodeDescription": "SS KIM ENTERPRISES PTE LTD",
     "TPSystemValue": 3810
    },
    {
     
     "CodeDescription": "SSANGYONG ENGINEERING & CONSTRUCTION CO.",
     "TPSystemValue": 3007
    },
    {
     
     "CodeDescription": "SSH CORPORATION LTD",
     "TPSystemValue": 113
    },
    {
     
     "CodeDescription": "SSH CORPORATION PNG PTE LTD",
     "TPSystemValue": 3726
    },
    {
     
     "CodeDescription": "SSMC SYSTEMS ON SILICON MANUFACTURING",
     "TPSystemValue": 674
    },
    {
     
     "CodeDescription": "ST AEROSPACE ENGINEERING PTE LTD",
     "TPSystemValue": 3008
    },
    {
     
     "CodeDescription": "ST AIRPORT SERVICES PTE LTD",
     "TPSystemValue": 3727
    },
    {
     
     "CodeDescription": "ST ANDREW COMMUNITY HOSPITAL",
     "TPSystemValue": 3009
    },
    {
     
     "CodeDescription": "ST ANDREWS COMMUNITY HOSPITAL",
     "TPSystemValue": 3010
    },
    {
     
     "CodeDescription": "ST ARCHITECTS & ENGINEERS PTE LTD",
     "TPSystemValue": 411
    },
    {
     
     "CodeDescription": "ST ASSET MANAGEMENT LTD",
     "TPSystemValue": 815
    },
    {
     
     "CodeDescription": "ST AVIATION SERVICES PTE LTD",
     "TPSystemValue": 3011
    },
    {
     
     "CodeDescription": "ST COMPUTER SYSTEMS & SERVICES LTD",
     "TPSystemValue": 3012
    },
    {
     
     "CodeDescription": "ST ELECTRONICS (E-SERVICES) PTE LTD",
     "TPSystemValue": 3013
    },
    {
     
     "CodeDescription": "ST ELECTRONICS (E-SERVICES) PTE. LTD.",
     "TPSystemValue": "H443"
    },
    {
     
     "CodeDescription": "ST ELECTRONICS DIGITAL MEDIA PTE LTD",
     "TPSystemValue": 3728
    },
    {
     
     "CodeDescription": "ST ELECTRONICS INFOCOMM SYSTEMS PL",
     "TPSystemValue": 265
    },
    {
     
     "CodeDescription": "ST ELECTRONICS INFOSECURITY PTE LTD",
     "TPSystemValue": 688
    },
    {
     
     "CodeDescription": "ST ELECTRONICS PTE LTD",
     "TPSystemValue": 3014
    },
    {
     
     "CodeDescription": "ST ELECTRONICSINFOSOFTWARE SYSTEMS PL",
     "TPSystemValue": 262
    },
    {
     
     "CodeDescription": "ST ELECTRONICSSATCOM SENSORS SYS PL",
     "TPSystemValue": 390
    },
    {
     
     "CodeDescription": "ST ENGINEERING AND TECHNOLOGY SOLUTIONS PTE LTD",
     "TPSystemValue": 3729
    },
    {
     
     "CodeDescription": "ST ENGINEERING PTE LTD",
     "TPSystemValue": 3015
    },
    {
     
     "CodeDescription": "ST ERICSSON ASIA PACIFIC PTE LTD",
     "TPSystemValue": 3016
    },
    {
     
     "CodeDescription": "ST KINETICS PTE LTD",
     "TPSystemValue": 171
    },
    {
     
     "CodeDescription": "ST LEE TRANSPORT PTE. LTD.",
     "TPSystemValue": "H814"
    },
    {
     
     "CodeDescription": "ST LOGISTICS PTE LTD",
     "TPSystemValue": 941
    },
    {
     
     "CodeDescription": "ST LUKE HOSPITAL",
     "TPSystemValue": 3017
    },
    {
     
     "CodeDescription": "ST MARINE LTD",
     "TPSystemValue": 3018
    },
    {
     
     "CodeDescription": "ST MEDICAL SERVICES PTE LTD",
     "TPSystemValue": 570
    },
    {
     
     "CodeDescription": "ST MICROELECTRONICS PTE LTD",
     "TPSystemValue": 53
    },
    {
     
     "CodeDescription": "ST PAPER RESOURCES PTE LTD",
     "TPSystemValue": 565
    },
    {
     
     "CodeDescription": "ST REGIS HOTEL SINGAPORE",
     "TPSystemValue": 3019
    },
    {
     
     "CodeDescription": "ST SYNTHESIS PTE LTD",
     "TPSystemValue": 291
    },
    {
     
     "CodeDescription": "ST TRAVEL PTE. LTD.",
     "TPSystemValue": "H526"
    },
    {
     
     "CodeDescription": "STA TRAVEL (PTE) LTD",
     "TPSystemValue": "H109"
    },
    {
     
     "CodeDescription": "STAHL ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1049
    },
    {
     
     "CodeDescription": "STAMFORD LAW CORPORATION",
     "TPSystemValue": 3020
    },
    {
     
     "CodeDescription": "STAMFORD TRAVEL PTE LTD",
     "TPSystemValue": "H176"
    },
    {
     
     "CodeDescription": "STANDARD BANK PLC",
     "TPSystemValue": "S09"
    },
    {
     
     "CodeDescription": "STANDARD CAPITAL PTE. LTD.",
     "TPSystemValue": 1304
    },
    {
     
     "CodeDescription": "STANDARD FORM PTE LTD",
     "TPSystemValue": 190
    },
    {
     
     "CodeDescription": "STANDARD MERCHANT BANK (ASIA) LIMITED",
     "TPSystemValue": 797
    },
    {
     
     "CodeDescription": "STAR ASIA TRAVEL SERVICE PTE. LTD.",
     "TPSystemValue": "H651"
    },
    {
     
     "CodeDescription": "STAR CITY TOURS PTE LTD",
     "TPSystemValue": "H292"
    },
    {
     
     "CodeDescription": "STAR CRUISE TRAVEL SERVICE PTE LTD",
     "TPSystemValue": "H235"
    },
    {
     
     "CodeDescription": "STAR EXPRESS TRAVEL (S'PORE) PTE LTD",
     "TPSystemValue": "H107"
    },
    {
     
     "CodeDescription": "STAR HOLIDAY MART PTE LTD",
     "TPSystemValue": 3772
    },
    {
     
     "CodeDescription": "STAR PUBLISHING PTE LTD",
     "TPSystemValue": 845
    },
    {
     
     "CodeDescription": "STAR PUBLISHING PTE. LTD.",
     "TPSystemValue": "21S"
    },
    {
     
     "CodeDescription": "STAR ROUTE PTE LTD",
     "TPSystemValue": "H346"
    },
    {
     
     "CodeDescription": "STAR TOURS PTE. LTD.",
     "TPSystemValue": "H616"
    },
    {
     
     "CodeDescription": "STAR WORLD AVIATION (S) PTE LTD",
     "TPSystemValue": "H368"
    },
    {
     
     "CodeDescription": "STARBUCKS COFFEE SINGAPORE PTE LTD",
     "TPSystemValue": 301
    },
    {
     
     "CodeDescription": "STARCOM MEDIA WORLDWIDE",
     "TPSystemValue": 1387
    },
    {
     
     "CodeDescription": "STARHUB CABLE VISION LTD",
     "TPSystemValue": 386
    },
    {
     
     "CodeDescription": "STARHUB LTD",
     "TPSystemValue": 651
    },
    {
     
     "CodeDescription": "STARISLAND TRAVEL PTE. LTD.",
     "TPSystemValue": "H827"
    },
    {
     
     "CodeDescription": "STARLIGHT TRAVEL PTE. LTD.",
     "TPSystemValue": "H740"
    },
    {
     
     "CodeDescription": "STARLITE MARINE ENGINEERING PTE LTD",
     "TPSystemValue": 3021
    },
    {
     
     "CodeDescription": "STARLITE MARINE ENGINEERING PTE. LTD.",
     "TPSystemValue": "22S"
    },
    {
     
     "CodeDescription": "STARMALL PROPERTY MANAGEMENT PTE LTD",
     "TPSystemValue": 524
    },
    {
     
     "CodeDescription": "STARMART EXPRESS AIR ASIA LINER PTE. LTD.",
     "TPSystemValue": "H497"
    },
    {
     
     "CodeDescription": "STARPAL PTE LTD",
     "TPSystemValue": 3022
    },
    {
     
     "CodeDescription": "STARVISION INFORMATION TECHNOLOGY PL",
     "TPSystemValue": 549
    },
    {
     
     "CodeDescription": "STARWORLD INFOTECH PTE. LTD.",
     "TPSystemValue": "H468"
    },
    {
     
     "CodeDescription": "STATE BANK OF INDIA",
     "TPSystemValue": "S11"
    },
    {
     
     "CodeDescription": "STATE STREET BANK",
     "TPSystemValue": 3023
    },
    {
     
     "CodeDescription": "STATE STREET BANK AND TRUST COMPANY",
     "TPSystemValue": "S12"
    },
    {
     
     "CodeDescription": "STATS CHIPPAC LTD",
     "TPSystemValue": 479
    },
    {
     
     "CodeDescription": "STEELMO ENGINEERING PTE LTD",
     "TPSystemValue": 259
    },
    {
     
     "CodeDescription": "STELCOMMS PTE LTD",
     "TPSystemValue": 929
    },
    {
     
     "CodeDescription": "STELOP PTE LTD",
     "TPSystemValue": 470
    },
    {
     
     "CodeDescription": "STEMCOR PTE LTD",
     "TPSystemValue": 3730
    },
    {
     
     "CodeDescription": "STEPHENSON HARWOOD",
     "TPSystemValue": 1424
    },
    {
     
     "CodeDescription": "STERIA ASIA PTE LTD",
     "TPSystemValue": 709
    },
    {
     
     "CodeDescription": "STERICSSON ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1164
    },
    {
     
     "CodeDescription": "STERLING LAW CORPORATION",
     "TPSystemValue": 852
    },
    {
     
     "CodeDescription": "STGS TOURS PTE. LTD.",
     "TPSystemValue": "H819"
    },
    {
     
     "CodeDescription": "STIEFEL LABORATORIES PTE LTD",
     "TPSystemValue": 221
    },
    {
     
     "CodeDescription": "STMICROELECTRONICS ASIA PACIFIC PTE LTD",
     "TPSystemValue": 465
    },
    {
     
     "CodeDescription": "STOLZ ENGINEERING PTE LTD",
     "TPSystemValue": 3024
    },
    {
     
     "CodeDescription": "STONE & WEBSTER ASIA INC SINGAPORE",
     "TPSystemValue": 3025
    },
    {
     
     "CodeDescription": "STONE APPLE SOLUTIONS PTE LTD NCS",
     "TPSystemValue": 1241
    },
    {
     
     "CodeDescription": "STONE FOREST",
     "TPSystemValue": 3026
    },
    {
     
     "CodeDescription": "STONE FOREST ACCOUNTSERVE PTE LTD",
     "TPSystemValue": 578
    },
    {
     
     "CodeDescription": "STONE FOREST CORPORATE ADVISORY PL",
     "TPSystemValue": 1070
    },
    {
     
     "CodeDescription": "STONE FOREST IT PTE LTD",
     "TPSystemValue": 639
    },
    {
     
     "CodeDescription": "STRAITS CONSTRUCTION PTE LTD",
     "TPSystemValue": 3027
    },
    {
     
     "CodeDescription": "STRAITS FINANCIAL PTE LTD",
     "TPSystemValue": 1156
    },
    {
     
     "CodeDescription": "STRONTIUM TECHNOLOGY PTE LTD",
     "TPSystemValue": 808
    },
    {
     
     "CodeDescription": "STRONTIUM TECHNOLOGY PTE. LTD.",
     "TPSystemValue": "25S"
    },
    {
     
     "CodeDescription": "STUDIO MILOU SINGAPORE PTE LTD",
     "TPSystemValue": 1160
    },
    {
     
     "CodeDescription": "STUN SERVICES PTE LTD",
     "TPSystemValue": 1275
    },
    {
     
     "CodeDescription": "STUTTGART AUTO PTE LTD",
     "TPSystemValue": 248
    },
    {
     
     "CodeDescription": "STVE PTE LTD",
     "TPSystemValue": 293
    },
    {
     
     "CodeDescription": "SUBORDINATE COURTS",
     "TPSystemValue": 3028
    },
    {
     
     "CodeDescription": "SUBRA TT LAW LLC",
     "TPSystemValue": 1291
    },
    {
     
     "CodeDescription": "SUCCESS FOREVER CONSTR & MNT PTE LTD",
     "TPSystemValue": 3029
    },
    {
     
     "CodeDescription": "SUCCESS HUMAN RESOURCES CENTRE PTE LTD",
     "TPSystemValue": 1024
    },
    {
     
     "CodeDescription": "SUE PRIVATE NURSES AGENCY PTE LTD",
     "TPSystemValue": 787
    },
    {
     
     "CodeDescription": "SULZER CHEMTECH PTE LTD",
     "TPSystemValue": 287
    },
    {
     
     "CodeDescription": "SUMITOMO CHEMICAL ASIA PTE LTD",
     "TPSystemValue": 361
    },
    {
     
     "CodeDescription": "SUMITOMO CHEMICAL SINGAPORE PTE LTD",
     "TPSystemValue": 563
    },
    {
     
     "CodeDescription": "SUMITOMO CORPORATION ASIA PTE LTD",
     "TPSystemValue": 3030
    },
    {
     
     "CodeDescription": "SUMITOMO METAL MINNING ASIA PACIFIC",
     "TPSystemValue": 3031
    },
    {
     
     "CodeDescription": "SUMITOMO MITSUI CONSTRUCTION CO LTD",
     "TPSystemValue": 3033
    },
    {
     
     "CodeDescription": "SUMITOMO TRUST & BANKING CO LTD, THE",
     "TPSystemValue": "S14"
    },
    {
     
     "CodeDescription": "SUMMER HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H521"
    },
    {
     
     "CodeDescription": "SUMMERWEILI COATING",
     "TPSystemValue": 1385
    },
    {
     
     "CodeDescription": "SUMMIT TRAVEL PTE LTD",
     "TPSystemValue": "H146"
    },
    {
     
     "CodeDescription": "SUMUNITED ENGINEERING (M&E) PTE LTD",
     "TPSystemValue": 1108
    },
    {
     
     "CodeDescription": "SUN CITY MAINTENANCE PTE LTD",
     "TPSystemValue": 3034
    },
    {
     
     "CodeDescription": "SUN LIGHT ENGINEERING PTE LTD",
     "TPSystemValue": 3035
    },
    {
     
     "CodeDescription": "SUN MICROSYSTEMS PTE LTD",
     "TPSystemValue": 3036
    },
    {
     
     "CodeDescription": "SUN-CITY TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H342"
    },
    {
     
     "CodeDescription": "SUNFLOWER READERS",
     "TPSystemValue": 1399
    },
    {
     
     "CodeDescription": "SUNG HSIEN TRAVEL PTE LTD",
     "TPSystemValue": "H215"
    },
    {
     
     "CodeDescription": "SUNGARD",
     "TPSystemValue": 3037
    },
    {
     
     "CodeDescription": "SUNGARD SYSTEM ACCESS (S) PTE LTD",
     "TPSystemValue": 3038
    },
    {
     
     "CodeDescription": "SUNGARD SYSTEMS SINGAPORE PTE LTD",
     "TPSystemValue": 3039
    },
    {
     
     "CodeDescription": "SUN-GEE TRAVEL PTE LTD",
     "TPSystemValue": 3040
    },
    {
     
     "CodeDescription": "SUNJI TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H391"
    },
    {
     
     "CodeDescription": "SUNNINGDALE TECH LTD",
     "TPSystemValue": 3041
    },
    {
     
     "CodeDescription": "SUNNY HOLIDAYS PTE LTD",
     "TPSystemValue": "H142"
    },
    {
     
     "CodeDescription": "SUNNY ISLAND TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H112"
    },
    {
     
     "CodeDescription": "SUNRAY WOODCRAFT CONSTRUCTION PTE LTD",
     "TPSystemValue": 3042
    },
    {
     
     "CodeDescription": "SUNRISE BUSINESS CENTRE PTE. LTD.",
     "TPSystemValue": "H803"
    },
    {
     
     "CodeDescription": "SUNSHINE ASIA (SA) PRIVATE LIMITED",
     "TPSystemValue": "H357"
    },
    {
     
     "CodeDescription": "SUNSHINE TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "H506"
    },
    {
     
     "CodeDescription": "SUNSHINE TRAVEL PTE. LTD.",
     "TPSystemValue": "H667"
    },
    {
     
     "CodeDescription": "SUNTEC SINGAPORE",
     "TPSystemValue": 3043
    },
    {
     
     "CodeDescription": "SUNWAY CONCRETE PRODUCTS (S) PTE LTD",
     "TPSystemValue": 3044
    },
    {
     
     "CodeDescription": "SUNWAY CONCRETE PTE LTD",
     "TPSystemValue": 3045
    },
    {
     
     "CodeDescription": "SUPER COFFEE CORPORATION PTE. LTD.",
     "TPSystemValue": 1193
    },
    {
     
     "CodeDescription": "SUPER TRAVELS PTE LTD",
     "TPSystemValue": "H088"
    },
    {
     
     "CodeDescription": "SUPERIOR COACH & TOUR PTE LTD",
     "TPSystemValue": "H189"
    },
    {
     
     "CodeDescription": "SUPERMARKET CREATIVES",
     "TPSystemValue": 3046
    },
    {
     
     "CodeDescription": "SUPER-NICE EXPRESS PTE LTD",
     "TPSystemValue": "H221"
    },
    {
     
     "CodeDescription": "SUPREME COURT",
     "TPSystemValue": 3047
    },
    {
     
     "CodeDescription": "SUPREME HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H668"
    },
    {
     
     "CodeDescription": "SUPREME TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H470"
    },
    {
     
     "CodeDescription": "SURBANA",
     "TPSystemValue": 3048
    },
    {
     
     "CodeDescription": "SURBANA CONSULTANTS PTE LTD",
     "TPSystemValue": 3049
    },
    {
     
     "CodeDescription": "SURBANA CORPORATION PTE LTD",
     "TPSystemValue": 3050
    },
    {
     
     "CodeDescription": "SURBANA INTERNATIONAL CONSULTANTS PL",
     "TPSystemValue": 872
    },
    {
     
     "CodeDescription": "SURBANA SITE SUPERVISOR PTE LTD",
     "TPSystemValue": 3051
    },
    {
     
     "CodeDescription": "SURVIVAL CRAFT INSPECTORATE PTE LTD",
     "TPSystemValue": 1158
    },
    {
     
     "CodeDescription": "SUTL CORPORATION (NORTH EAST ASIA) PL",
     "TPSystemValue": 91
    },
    {
     
     "CodeDescription": "SUTL CORPORATION PTE LTD",
     "TPSystemValue": 3731
    },
    {
     
     "CodeDescription": "SUTL MARINA DEVELOPMENT PTE LTD",
     "TPSystemValue": 946
    },
    {
     
     "CodeDescription": "SVENSKA HANDELSBANKEN AB",
     "TPSystemValue": "S15"
    },
    {
     
     "CodeDescription": "SWANLIN 2000",
     "TPSystemValue": 3052
    },
    {
     
     "CodeDescription": "SWANLIN ASIA PTE LTD",
     "TPSystemValue": 3053
    },
    {
     
     "CodeDescription": "SWANLIN MARINE ENGINEERING PTE LTD",
     "TPSystemValue": 3054
    },
    {
     
     "CodeDescription": "SWANLIN OFFSHORE PTE LTD",
     "TPSystemValue": 3055
    },
    {
     
     "CodeDescription": "SWAROVSKI",
     "TPSystemValue": 3056
    },
    {
     
     "CodeDescription": "SWATCH GROUP",
     "TPSystemValue": 3057
    },
    {
     
     "CodeDescription": "SWEE HUAT HENG ENGINEERING PTE LTD",
     "TPSystemValue": 396
    },
    {
     
     "CodeDescription": "SWIBER",
     "TPSystemValue": 3058
    },
    {
     
     "CodeDescription": "SWIBER CORPORATE SERVICES PTE LTD",
     "TPSystemValue": 1295
    },
    {
     
     "CodeDescription": "SWIBER HOLDING LIMITED",
     "TPSystemValue": 950
    },
    {
     
     "CodeDescription": "SWIBER MARINE PTE LTD",
     "TPSystemValue": 3732
    },
    {
     
     "CodeDescription": "SWIBER OFFSHORE CONSTRUCTION PTE LTD",
     "TPSystemValue": 838
    },
    {
     
     "CodeDescription": "SWIBER OFFSHORE MARINE PTE LTD",
     "TPSystemValue": 575
    },
    {
     
     "CodeDescription": "SWIRE PACIFIC OFFSHORE OPERATIONS PL",
     "TPSystemValue": 665
    },
    {
     
     "CodeDescription": "SWIRE PACIFIC OFFSHORE SERVICES PL",
     "TPSystemValue": 106
    },
    {
     
     "CodeDescription": "SWISS BAKE PTE LTD",
     "TPSystemValue": 877
    },
    {
     
     "CodeDescription": "SWISS POST INTERNATIONAL SG PTE LTD",
     "TPSystemValue": 849
    },
    {
     
     "CodeDescription": "SWISS POST INTERNATIONAL SINGAPORE PTE.LTD.",
     "TPSystemValue": "26S"
    },
    {
     
     "CodeDescription": "SWISS PRECISION INDUSTRIES PTE LTD",
     "TPSystemValue": 3059
    },
    {
     
     "CodeDescription": "SWISS REINSURANCE COMPANY LTD",
     "TPSystemValue": 2831
    },
    {
     
     "CodeDescription": "SWISS TREATS PTE LTD",
     "TPSystemValue": 676
    },
    {
     
     "CodeDescription": "SWISSOTEL MERCHANT COURT SINGAPORE",
     "TPSystemValue": 1398
    },
    {
     
     "CodeDescription": "SWISS-TREATS PTE LTD",
     "TPSystemValue": 3060
    },
    {
     
     "CodeDescription": "SWTS PTE LTD",
     "TPSystemValue": 3061
    },
    {
     
     "CodeDescription": "SYBASE (SINGAPORE) PTE LTD",
     "TPSystemValue": 3062
    },
    {
     
     "CodeDescription": "SYMANTEC ASIA PACIFIC PTE LTD",
     "TPSystemValue": 868
    },
    {
     
     "CodeDescription": "SYMAROM ASIA PTE LTD",
     "TPSystemValue": 855
    },
    {
     
     "CodeDescription": "SYMRISE ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1176
    },
    {
     
     "CodeDescription": "SYNERGY ATWORK PTE LTD",
     "TPSystemValue": 3063
    },
    {
     
     "CodeDescription": "SYNGENTA",
     "TPSystemValue": 3064
    },
    {
     
     "CodeDescription": "SYNGENTA ASIA PACIFIC PTE LTD",
     "TPSystemValue": 625
    },
    {
     
     "CodeDescription": "SYNOVATE PTE LTD",
     "TPSystemValue": 3065
    },
    {
     
     "CodeDescription": "SYSMEX ASIA PACIFIC PTE LTD",
     "TPSystemValue": 646
    },
    {
     
     "CodeDescription": "SYSTEMINDO ASIA PACIFIC PTE LTD",
     "TPSystemValue": 1263
    },
    {
     
     "CodeDescription": "SYSTEMS ON SILICON MANUFACTURING PTE LTD",
     "TPSystemValue": 3066
    },
    {
     
     "CodeDescription": "SYSTEX SOUTHASIA PTE LTD",
     "TPSystemValue": 3067
    },
    {
     
     "CodeDescription": "T T GOURMET CUISINE PTE LTD",
     "TPSystemValue": 3733
    },
    {
     
     "CodeDescription": "TABLEAU ASIA PACIFIC PTE LTD",
     "TPSystemValue": 3812
    },
    {
     
     "CodeDescription": "TACTIC ENGINEERING PTE LTD",
     "TPSystemValue": 3119
    },
    {
     
     "CodeDescription": "TACTICS SYSTEM ENGRG PL",
     "TPSystemValue": 3120
    },
    {
     
     "CodeDescription": "TAGIT PTE LTD",
     "TPSystemValue": 3121
    },
    {
     
     "CodeDescription": "TAIZHONG SINGAPORE PTE LTD",
     "TPSystemValue": 3734
    },
    {
     
     "CodeDescription": "TAKENAKA CORPORATION",
     "TPSystemValue": 3122
    },
    {
     
     "CodeDescription": "TALENSE PTE LTD",
     "TPSystemValue": 3123
    },
    {
     
     "CodeDescription": "TAMPINES TRAVEL PTE. LTD.",
     "TPSystemValue": "H444"
    },
    {
     
     "CodeDescription": "TAN PENG CHIN LLC",
     "TPSystemValue": 3124
    },
    {
     
     "CodeDescription": "TAN TOCK SENG HOSPITAL PTE LTD",
     "TPSystemValue": 3125
    },
    {
     
     "CodeDescription": "TANAH MERAH COUNTRY CLUB",
     "TPSystemValue": 3126
    },
    {
     
     "CodeDescription": "TANAKA ELECTRONICS SINGAPORE PTE LTD",
     "TPSystemValue": 3127
    },
    {
     
     "CodeDescription": "TANGLIN CLUB",
     "TPSystemValue": 3128
    },
    {
     
     "CodeDescription": "TANGLIN TRUST SCHOOL",
     "TPSystemValue": 3129
    },
    {
     
     "CodeDescription": "TANMOY TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H692"
    },
    {
     
     "CodeDescription": "TAO S RESTAURANT",
     "TPSystemValue": 3130
    },
    {
     
     "CodeDescription": "TAOS RESTAURANT",
     "TPSystemValue": "01T"
    },
    {
     
     "CodeDescription": "TARGET HOLIDAY TOURS PTE LTD",
     "TPSystemValue": "H210"
    },
    {
     
     "CodeDescription": "TARKUS INTERIORS PTE LTD",
     "TPSystemValue": 3131
    },
    {
     
     "CodeDescription": "TAT HIN BUILDERS",
     "TPSystemValue": 3132
    },
    {
     
     "CodeDescription": "TAT HONG BUILDING",
     "TPSystemValue": 3735
    },
    {
     
     "CodeDescription": "TAT HONG HOLDINGS LTD",
     "TPSystemValue": 3133
    },
    {
     
     "CodeDescription": "TAT LEONG TRAVEL PTE LTD",
     "TPSystemValue": "H115"
    },
    {
     
     "CodeDescription": "TATA",
     "TPSystemValue": 3134
    },
    {
     
     "CodeDescription": "TATA COMMUNICATIONS",
     "TPSystemValue": 3135
    },
    {
     
     "CodeDescription": "TATA COMMUNICATIONS INTERNATIONAL PTE LTD",
     "TPSystemValue": 3736
    },
    {
     
     "CodeDescription": "TATA COMMUNICATIONS SERVICES INTERNATIONAL PTE LTD",
     "TPSystemValue": 3737
    },
    {
     
     "CodeDescription": "TATA CONSULTANCY",
     "TPSystemValue": 3136
    },
    {
     
     "CodeDescription": "TATA CONSULTANCY SERVICES",
     "TPSystemValue": 3137
    },
    {
     
     "CodeDescription": "TATA CONSULTANCY SERVICES ASIA P PTE LTD",
     "TPSystemValue": 3138
    },
    {
     
     "CodeDescription": "TAYLOR NELSON SOFRES S PTE LTD",
     "TPSystemValue": 3139
    },
    {
     
     "CodeDescription": "TBC HR CONSULTING S PTE LTD",
     "TPSystemValue": 3140
    },
    {
     
     "CodeDescription": "TBWA",
     "TPSystemValue": 3141
    },
    {
     
     "CodeDescription": "TCS",
     "TPSystemValue": 3142
    },
    {
     
     "CodeDescription": "TCT AUTOCARE",
     "TPSystemValue": 3143
    },
    {
     
     "CodeDescription": "TDK-EPC PTE LTD",
     "TPSystemValue": 3144
    },
    {
     
     "CodeDescription": "TEAMBUILD CONSTRUCTION P L",
     "TPSystemValue": 3145
    },
    {
     
     "CodeDescription": "TECH MAHINDRA",
     "TPSystemValue": 3146
    },
    {
     
     "CodeDescription": "TECH SEMICONDUCTOR S P L",
     "TPSystemValue": 3147
    },
    {
     
     "CodeDescription": "TECHCOM CONSTR & TRADING PTE LTD",
     "TPSystemValue": 3148
    },
    {
     
     "CodeDescription": "TECHCOM CONSTRUCTION & TRADING PL",
     "TPSystemValue": 3149
    },
    {
     
     "CodeDescription": "TECHCOM CONSTRUCTION & TREADING PTE LTD",
     "TPSystemValue": 3151
    },
    {
     
     "CodeDescription": "TECHNOLITE (S) PTE LTD",
     "TPSystemValue": 3152
    },
    {
     
     "CodeDescription": "TELECHOICE INTERNATIONAL LIMITED",
     "TPSystemValue": 3738
    },
    {
     
     "CodeDescription": "TELECOM EQUIPMENT PTE LTD",
     "TPSystemValue": 3153
    },
    {
     
     "CodeDescription": "TELEDIRECT",
     "TPSystemValue": 3154
    },
    {
     
     "CodeDescription": "TELEDIRECT PTE LTD",
     "TPSystemValue": 3155
    },
    {
     
     "CodeDescription": "TELEMARKETING ASIA (SINGAPORE) PTE LTD",
     "TPSystemValue": 3156
    },
    {
     
     "CodeDescription": "TELESONIC SINGAPORE PTE LTD",
     "TPSystemValue": 3739
    },
    {
     
     "CodeDescription": "TELLUS ENGINEERING CONSTRUCTION",
     "TPSystemValue": 3158
    },
    {
     
     "CodeDescription": "TELLUS M E SERVICES (JS)",
     "TPSystemValue": 3159
    },
    {
     
     "CodeDescription": "TELLUS MARINE ENGINEERING PTE LTD",
     "TPSystemValue": 3160
    },
    {
     
     "CodeDescription": "TELLUS MARINE ENGINEERING SERVICES",
     "TPSystemValue": 3161
    },
    {
     
     "CodeDescription": "TELLUS OCEANIC PRO PTE LTD",
     "TPSystemValue": 3162
    },
    {
     
     "CodeDescription": "TELLUS OFFSHORE INDUSTRIES PTE LTD",
     "TPSystemValue": 3163
    },
    {
     
     "CodeDescription": "TELLUS SHIPBUILDING AND REPAIR SERVICES",
     "TPSystemValue": 3740
    },
    {
     
     "CodeDescription": "TEMASEK HOLDINGS",
     "TPSystemValue": 3164
    },
    {
     
     "CodeDescription": "TEMASEK HOLDINGS PTE LTD",
     "TPSystemValue": 3165
    },
    {
     
     "CodeDescription": "TEMASEK POLY",
     "TPSystemValue": 3166
    },
    {
     
     "CodeDescription": "TEMASEK POLYTECHNIC",
     "TPSystemValue": 3167
    },
    {
     
     "CodeDescription": "TEMENOS SINGAPORE PTE LTD",
     "TPSystemValue": 3168
    },
    {
     
     "CodeDescription": "TEN GROUP SINGAPORE PTE. LTD.",
     "TPSystemValue": "H641"
    },
    {
     
     "CodeDescription": "TENET INSURANCE COMPANY LTD",
     "TPSystemValue": 3169
    },
    {
     
     "CodeDescription": "TEQUILA ASIAPACIFIC SINGAPORE PTE LTD",
     "TPSystemValue": 3741
    },
    {
     
     "CodeDescription": "TERRABIT NETWORKS PTE LTD",
     "TPSystemValue": 3777
    },
    {
     
     "CodeDescription": "TERRAPINN P L",
     "TPSystemValue": 3170
    },
    {
     
     "CodeDescription": "TERUMO CORPORATION SINGAPORE BR",
     "TPSystemValue": 3172
    },
    {
     
     "CodeDescription": "TERUMO SINGAPORE PTE LTD",
     "TPSystemValue": 3742
    },
    {
     
     "CodeDescription": "TESA TAPE ASIA PACIFIC PTE LTD",
     "TPSystemValue": 3173
    },
    {
     
     "CodeDescription": "T-EST PTE LTD",
     "TPSystemValue": 3174
    },
    {
     
     "CodeDescription": "TETRA PACK (SINGAPORE)",
     "TPSystemValue": 3175
    },
    {
     
     "CodeDescription": "TETRA PAK SOUTH EAST ASIA PTE LTD",
     "TPSystemValue": 3176
    },
    {
     
     "CodeDescription": "TETRAPACK JURONG PTE LTD",
     "TPSystemValue": 3177
    },
    {
     
     "CodeDescription": "TEXAS INSTRUMENTS",
     "TPSystemValue": 3178
    },
    {
     
     "CodeDescription": "TG TOURS PTE. LTD.",
     "TPSystemValue": "H556"
    },
    {
     
     "CodeDescription": "THAI ACCENT",
     "TPSystemValue": 3179
    },
    {
     
     "CodeDescription": "THAI EXPRESS",
     "TPSystemValue": 3180
    },
    {
     
     "CodeDescription": "THAI EXPRESS CONCEPTS PTE LTD",
     "TPSystemValue": 3181
    },
    {
     
     "CodeDescription": "THAKRAL BROTHERS PTE LTD",
     "TPSystemValue": 3182
    },
    {
     
     "CodeDescription": "THALES SINGAPORE PTE LTD",
     "TPSystemValue": 3791
    },
    {
     
     "CodeDescription": "THALES SOLUTIONS ASIA PTE LTD",
     "TPSystemValue": 3183
    },
    {
     
     "CodeDescription": "THANJAI TOURS & TRAVELS PTE. LTD.",
     "TPSystemValue": "H590"
    },
    {
     
     "CodeDescription": "THATZ INTERNATIONAL PTE LTD",
     "TPSystemValue": 3184
    },
    {
     
     "CodeDescription": "THE APPOINTMENT GROUP PTE. LTD.",
     "TPSystemValue": "H829"
    },
    {
     
     "CodeDescription": "THE BALLET & MUSIC COMPANY PTE. LTD.",
     "TPSystemValue": "05T"
    },
    {
     
     "CodeDescription": "THE BALLET MUSIC COMPANY PTE LTD",
     "TPSystemValue": 3185
    },
    {
     
     "CodeDescription": "THE BANK OF EAST ASIA, LIMITED",
     "TPSystemValue": 3186
    },
    {
     
     "CodeDescription": "THE BANK OF NEW YORK MELLON",
     "TPSystemValue": 3187
    },
    {
     
     "CodeDescription": "THE BANK OF NOVA SCOTIA",
     "TPSystemValue": 3188
    },
    {
     
     "CodeDescription": "THE BANK OF TOKYO",
     "TPSystemValue": 3189
    },
    {
     
     "CodeDescription": "THE BLUE BANANA CLUB PTE LTD",
     "TPSystemValue": 3190
    },
    {
     
     "CodeDescription": "THE BODY SHOP INTERNATIONAL ASIA PACIFIC PTE LIM",
     "TPSystemValue": 3743
    },
    {
     
     "CodeDescription": "THE BODY SHOP PTE LTD",
     "TPSystemValue": 3191
    },
    {
     
     "CodeDescription": "THE BOSTON CONSULTING GROUP",
     "TPSystemValue": 3192
    },
    {
     
     "CodeDescription": "THE BURSAR-SINGAPORE POLYTECHNIC",
     "TPSystemValue": "06T"
    },
    {
     
     "CodeDescription": "THE CHINA NAVIGATION CO PTE LTD",
     "TPSystemValue": 3193
    },
    {
     
     "CodeDescription": "THE CLINICAL LABORATORY PTE LTD",
     "TPSystemValue": 3194
    },
    {
     
     "CodeDescription": "THE COFFEE BEAN & TEA LEAF (S) PTE LTD",
     "TPSystemValue": 3195
    },
    {
     
     "CodeDescription": "THE DIAMOND GUARANTEE",
     "TPSystemValue": 3196
    },
    {
     
     "CodeDescription": "THE ESPLANADE CO LTD",
     "TPSystemValue": 3197
    },
    {
     
     "CodeDescription": "THE EXPERIENCES FIRM PTE. LTD.",
     "TPSystemValue": "H540"
    },
    {
     
     "CodeDescription": "THE FULLERTON HOTEL",
     "TPSystemValue": 3198
    },
    {
     
     "CodeDescription": "THE GOLD LABEL PTE LTD",
     "TPSystemValue": 3199
    },
    {
     
     "CodeDescription": "THE GRAND HYATT HOTEL",
     "TPSystemValue": 3200
    },
    {
     
     "CodeDescription": "THE GREAT EASTERN LIFE ASSURANCE CO LTD",
     "TPSystemValue": 3201
    },
    {
     
     "CodeDescription": "THE HAPPY PEOPLE CO PTE LTD",
     "TPSystemValue": 982
    },
    {
     
     "CodeDescription": "THE HAPPY PEOPLE CO. PTE. LTD.",
     "TPSystemValue": "07T"
    },
    {
     
     "CodeDescription": "THE LEARNING LAB",
     "TPSystemValue": 3202
    },
    {
     
     "CodeDescription": "THE LITTLE ENTREPRENEUR PTE. LTD.",
     "TPSystemValue": "H337"
    },
    {
     
     "CodeDescription": "THE NATIONAL KIDNEY FOUNDATION",
     "TPSystemValue": 788
    },
    {
     
     "CodeDescription": "THE NIELSEN COMPANY SINGAPORE PTE LTD",
     "TPSystemValue": 3203
    },
    {
     
     "CodeDescription": "THE NORTHERN TRUST COMPANY",
     "TPSystemValue": 3204
    },
    {
     
     "CodeDescription": "THE OCEANIC PETROLEUM SOURCE PTE LTD",
     "TPSystemValue": 926
    },
    {
     
     "CodeDescription": "THE ONE TRAVEL & TOURS PTE. LTD.",
     "TPSystemValue": "H751"
    },
    {
     
     "CodeDescription": "THE POLYOLEFIN COMPANY (S) PTE LTD",
     "TPSystemValue": 3205
    },
    {
     
     "CodeDescription": "THE RITZ-CARLTON, MILLENIA SINGAPORE",
     "TPSystemValue": 1373
    },
    {
     
     "CodeDescription": "THE ROTI PRATA HOUSE PTE LTD",
     "TPSystemValue": 3206
    },
    {
     
     "CodeDescription": "THE ROYAL BANK OF SCOTLAND",
     "TPSystemValue": 3207
    },
    {
     
     "CodeDescription": "THE SALVATION ARMY",
     "TPSystemValue": 3208
    },
    {
     
     "CodeDescription": "THE SCENE HAIR AND BEAUTY PTE LTD",
     "TPSystemValue": 3209
    },
    {
     
     "CodeDescription": "THE SEAFOOD INTERNATIONAL MARKET",
     "TPSystemValue": 3210
    },
    {
     
     "CodeDescription": "THE SIAM COMMERCIAL BANK PUBLIC COMPANY LIMITED",
     "TPSystemValue": 3744
    },
    {
     
     "CodeDescription": "THE SONG OF INDIA PTE LTD",
     "TPSystemValue": 3211
    },
    {
     
     "CodeDescription": "THE SWATCH GROUP S.E.A. (S) PTE LTD",
     "TPSystemValue": 512
    },
    {
     
     "CodeDescription": "THE TANGLIN CLUB",
     "TPSystemValue": 1356
    },
    {
     
     "CodeDescription": "THE TIMBERLAND COMPANY ASIAPAC",
     "TPSystemValue": 3212
    },
    {
     
     "CodeDescription": "THE TRAVEL CORPORATION (2011) PTE. LTD.",
     "TPSystemValue": "H187"
    },
    {
     
     "CodeDescription": "THE TRAVELLER DMC PTE. LTD.",
     "TPSystemValue": "H388"
    },
    {
     
     "CodeDescription": "THE UFO TRAVELER PTE. LTD.",
     "TPSystemValue": "H748"
    },
    {
     
     "CodeDescription": "THE WALSHE GROUP (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H408"
    },
    {
     
     "CodeDescription": "THEMEWERKS PTE LTD",
     "TPSystemValue": 3213
    },
    {
     
     "CodeDescription": "THOHA TRAVELS & TOURS PTE. LTD.",
     "TPSystemValue": "H513"
    },
    {
     
     "CodeDescription": "THOMSON MEDICAL CENTRE",
     "TPSystemValue": 3214
    },
    {
     
     "CodeDescription": "THOMSON MEDICAL CENTRE LTD",
     "TPSystemValue": 3215
    },
    {
     
     "CodeDescription": "THOMSON MEDICAL SG",
     "TPSystemValue": 3216
    },
    {
     
     "CodeDescription": "THOMSON PRENATAL DIAGNOSTIC LABORATORY PTE LTD",
     "TPSystemValue": 3745
    },
    {
     
     "CodeDescription": "THOMSON REUTERS",
     "TPSystemValue": 3217
    },
    {
     
     "CodeDescription": "THOMSON REUTERS ASIA PL",
     "TPSystemValue": 3218
    },
    {
     
     "CodeDescription": "THOMSON REUTERS ASIA PTE LTD",
     "TPSystemValue": 3219
    },
    {
     
     "CodeDescription": "THOMSON REUTERS ASIA PTE. LTD.",
     "TPSystemValue": "08T"
    },
    {
     
     "CodeDescription": "THONG AIK FURNITURE & CONST PTE LTD",
     "TPSystemValue": 3220
    },
    {
     
     "CodeDescription": "THONG AIK FURNITURE & CONST. PTE. LTD.",
     "TPSystemValue": "10T"
    },
    {
     
     "CodeDescription": "THONG SENG ENGINEERING CO PTE LTD",
     "TPSystemValue": 3221
    },
    {
     
     "CodeDescription": "THRIVEANIC GLOBAL PTE.LTD.",
     "TPSystemValue": 1288
    },
    {
     
     "CodeDescription": "THYMN PTE LTD",
     "TPSystemValue": 3223
    },
    {
     
     "CodeDescription": "TIAN SWEE ENGINEERING CONSTRUCTION PTE L",
     "TPSystemValue": 3224
    },
    {
     
     "CodeDescription": "TIEN WAH PRESS PTE LTD",
     "TPSystemValue": 3747
    },
    {
     
     "CodeDescription": "TIFFANY & CO",
     "TPSystemValue": 3225
    },
    {
     
     "CodeDescription": "TIGER AIRWAYS SINGAPORE PTE LTD",
     "TPSystemValue": 905
    },
    {
     
     "CodeDescription": "TIGER EXPORT PTE LTD",
     "TPSystemValue": 783
    },
    {
     
     "CodeDescription": "TIMES PRINTERS",
     "TPSystemValue": 3226
    },
    {
     
     "CodeDescription": "TIMES PUBLISHING LTD",
     "TPSystemValue": 3227
    },
    {
     
     "CodeDescription": "TIMESWORLD TRAVEL & EDUCATIONAL TOURS PTE LTD",
     "TPSystemValue": "H199"
    },
    {
     
     "CodeDescription": "TIONG SENG CONTRACTORS PTE LTD",
     "TPSystemValue": 3228
    },
    {
     
     "CodeDescription": "TIONG WOON CRANE TRANSPORT PTE LTD",
     "TPSystemValue": 3229
    },
    {
     
     "CodeDescription": "TLK EMPLOYMENT SPECIALIST LLP",
     "TPSystemValue": 3077
    },
    {
     
     "CodeDescription": "TM FOUZY TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H234"
    },
    {
     
     "CodeDescription": "TMC ACADEMY",
     "TPSystemValue": 3230
    },
    {
     
     "CodeDescription": "TMC EDUCATION CORPORATION LTD",
     "TPSystemValue": 3748
    },
    {
     
     "CodeDescription": "TMS LINK EXPRESS PTE LTD",
     "TPSystemValue": "H330"
    },
    {
     
     "CodeDescription": "TNS ASIA PACIFIC PTE LTD",
     "TPSystemValue": 803
    },
    {
     
     "CodeDescription": "TNT EXPRESS WORLDWIDE SINGAPORE PTE LTD",
     "TPSystemValue": 3231
    },
    {
     
     "CodeDescription": "TOKIO MARINE ASIA PTE LTD",
     "TPSystemValue": 3232
    },
    {
     
     "CodeDescription": "TOKIO MARINE LIFE INSURANCE SINGAPORE",
     "TPSystemValue": 3233
    },
    {
     
     "CodeDescription": "TOKYO EXECUTIVE RECRUITMENT",
     "TPSystemValue": 3234
    },
    {
     
     "CodeDescription": "TOLARAM CORPORATION LIMITED",
     "TPSystemValue": 3235
    },
    {
     
     "CodeDescription": "TOLL ASIA PTE LTD",
     "TPSystemValue": 3236
    },
    {
     
     "CodeDescription": "TOLL GLOBAL EXPRESS SINGAPORE PTE LTD",
     "TPSystemValue": 3237
    },
    {
     
     "CodeDescription": "TOLL GLOBAL FORWARDING SINGAPORE PTE LIMITED",
     "TPSystemValue": 3749
    },
    {
     
     "CodeDescription": "TOLL LOGISTIC ASIA PTE LTD",
     "TPSystemValue": 3238
    },
    {
     
     "CodeDescription": "TOLL OFFSHORE PETROLEUM SERVICES PL",
     "TPSystemValue": 60
    },
    {
     
     "CodeDescription": "TOMTEC NDT MARINE SERVICES PL",
     "TPSystemValue": 3239
    },
    {
     
     "CodeDescription": "TOP WIN TRAVEL PTE. LTD.",
     "TPSystemValue": "H629"
    },
    {
     
     "CodeDescription": "TOP3 MEDIA PTE LTD",
     "TPSystemValue": 3787
    },
    {
     
     "CodeDescription": "TOPZONE CONSTRUCTION ENGINEERING PTE LTD",
     "TPSystemValue": 3241
    },
    {
     
     "CodeDescription": "TORONTO-DOMINION BANK, THE",
     "TPSystemValue": "T01"
    },
    {
     
     "CodeDescription": "TOSHIBA (S) PL",
     "TPSystemValue": 3242
    },
    {
     
     "CodeDescription": "TOSHIBA DATA DYNAMICS PTE LTD",
     "TPSystemValue": 3243
    },
    {
     
     "CodeDescription": "TOSHIBA ELECTRONICS ASIA SPORE PTE LTD",
     "TPSystemValue": 3244
    },
    {
     
     "CodeDescription": "TOSHIBA GLOBAL COMMERCE SOLUTION",
     "TPSystemValue": 3426
    },
    {
     
     "CodeDescription": "TOSHIBA SINGAPORE PTE LTD",
     "TPSystemValue": 3245
    },
    {
     
     "CodeDescription": "TOTAL OIL ASIA PACIFIC LTD",
     "TPSystemValue": 3246
    },
    {
     
     "CodeDescription": "TOTE BOARD",
     "TPSystemValue": 3247
    },
    {
     
     "CodeDescription": "TOUR CONNECTIONS PTE LTD",
     "TPSystemValue": "H181"
    },
    {
     
     "CodeDescription": "TOUR EAST SINGAPORE (1996) PTE LTD",
     "TPSystemValue": "H262"
    },
    {
     
     "CodeDescription": "TOUR ROYALE SERVICES PTE LTD",
     "TPSystemValue": "H174"
    },
    {
     
     "CodeDescription": "TOURIST MOBILE TRAVEL CENTRE PTE LTD",
     "TPSystemValue": "H139"
    },
    {
     
     "CodeDescription": "TOURLAND TRAVEL PTE. LTD.",
     "TPSystemValue": "H612"
    },
    {
     
     "CodeDescription": "TOURMASTERS PTE LTD",
     "TPSystemValue": "H196"
    },
    {
     
     "CodeDescription": "TOURSU1 TRAVEL PTE. LTD.",
     "TPSystemValue": "H758"
    },
    {
     
     "CodeDescription": "TOURWORLD TRAVEL & CRUISE PTE LTD",
     "TPSystemValue": "H122"
    },
    {
     
     "CodeDescription": "TOYOTA MOTOR ASIA PACIFIC",
     "TPSystemValue": 3248
    },
    {
     
     "CodeDescription": "TOYOTA MOTOR ASIA PACIFIC PTE LTD",
     "TPSystemValue": 3249
    },
    {
     
     "CodeDescription": "TOYOU TRAVEL PTE. LTD.",
     "TPSystemValue": "H769"
    },
    {
     
     "CodeDescription": "TPLUS INTERNATIONAL PTE. LTD.",
     "TPSystemValue": "H613"
    },
    {
     
     "CodeDescription": "TRACTORS MACHINERY INTERNATIONAL PTE LTD",
     "TPSystemValue": 3750
    },
    {
     
     "CodeDescription": "TRACTORS SINGAPORE LIMITED",
     "TPSystemValue": 3250
    },
    {
     
     "CodeDescription": "TRACTORS SINGAPORE LTD",
     "TPSystemValue": 3251
    },
    {
     
     "CodeDescription": "TRACTORS SPORE LTD",
     "TPSystemValue": 3252
    },
    {
     
     "CodeDescription": "TRADEWINDS TOURS & TRAVEL PRIVATE LIMITED",
     "TPSystemValue": "H208"
    },
    {
     
     "CodeDescription": "TRAFIGURA PTE LTD",
     "TPSystemValue": 3253
    },
    {
     
     "CodeDescription": "TRANE SINGAPORE",
     "TPSystemValue": 3254
    },
    {
     
     "CodeDescription": "TRANS EUROKARS PTE LTD",
     "TPSystemValue": 3255
    },
    {
     
     "CodeDescription": "TRANS UNION HOLIDAYS PTE LTD",
     "TPSystemValue": "H194"
    },
    {
     
     "CodeDescription": "TRANSCAB",
     "TPSystemValue": 3256
    },
    {
     
     "CodeDescription": "TRANSGLOBE TRAVEL SERVICE PTE LTD",
     "TPSystemValue": "H105"
    },
    {
     
     "CodeDescription": "TRANSINEX PTE LTD",
     "TPSystemValue": "H049"
    },
    {
     
     "CodeDescription": "TRANSTAR TRAVEL PTE LTD",
     "TPSystemValue": "H077"
    },
    {
     
     "CodeDescription": "TRANS-WIND TRAVEL PTE. LTD.",
     "TPSystemValue": "H382"
    },
    {
     
     "CodeDescription": "TRAVCO WORLD EVENTS PTE. LTD.",
     "TPSystemValue": "H515"
    },
    {
     
     "CodeDescription": "TRAVCOACH PTE LTD",
     "TPSystemValue": "H052"
    },
    {
     
     "CodeDescription": "TRAVEL 21 (S) PTE LTD",
     "TPSystemValue": "H164"
    },
    {
     
     "CodeDescription": "TRAVEL ALLIANCE HOLDING PTE. LTD.",
     "TPSystemValue": "H541"
    },
    {
     
     "CodeDescription": "TRAVEL ALLIANCES PTE. LTD.",
     "TPSystemValue": "H480"
    },
    {
     
     "CodeDescription": "TRAVEL COMPANION (S) PTE. LTD.",
     "TPSystemValue": "H756"
    },
    {
     
     "CodeDescription": "TRAVEL CUE MANAGEMENT PTE. LTD.",
     "TPSystemValue": "H624"
    },
    {
     
     "CodeDescription": "TRAVEL GSH PTE LTD",
     "TPSystemValue": "H212"
    },
    {
     
     "CodeDescription": "TRAVEL HAVEN PTE LTD",
     "TPSystemValue": "H246"
    },
    {
     
     "CodeDescription": "TRAVEL HOLIDAYS (S) PTE LTD",
     "TPSystemValue": "H287"
    },
    {
     
     "CodeDescription": "TRAVEL HUB PTE LTD",
     "TPSystemValue": "H354"
    },
    {
     
     "CodeDescription": "TRAVEL MEDIA PTE LTD",
     "TPSystemValue": "H259"
    },
    {
     
     "CodeDescription": "TRAVEL SHOP (PTE) LTD",
     "TPSystemValue": "H175"
    },
    {
     
     "CodeDescription": "TRAVEL STAR PTE. LTD.",
     "TPSystemValue": "H384"
    },
    {
     
     "CodeDescription": "TRAVEL THOUGHTS PTE. LTD.",
     "TPSystemValue": "H691"
    },
    {
     
     "CodeDescription": "TRAVEL Z2000 TOURS PTE. LTD.",
     "TPSystemValue": "H528"
    },
    {
     
     "CodeDescription": "TRAVELBEE PTE. LTD.",
     "TPSystemValue": "H724"
    },
    {
     
     "CodeDescription": "TRAVELCENTERS OF AMERICA",
     "TPSystemValue": "H013"
    },
    {
     
     "CodeDescription": "TRAVELEX SINGAPORE PTE LTD",
     "TPSystemValue": 3257
    },
    {
     
     "CodeDescription": "TRAVELGOLF & LEISURE SERVICES PTE LTD",
     "TPSystemValue": "H276"
    },
    {
     
     "CodeDescription": "TRAVELLER FOLIO PTE. LTD.",
     "TPSystemValue": "H565"
    },
    {
     
     "CodeDescription": "TRAVELLER101 PTE. LTD.",
     "TPSystemValue": "H595"
    },
    {
     
     "CodeDescription": "TRAVELLERANDEXPLORER PTE. LTD.",
     "TPSystemValue": "H392"
    },
    {
     
     "CodeDescription": "TRAVELMORE (PTE) LTD",
     "TPSystemValue": "H070"
    },
    {
     
     "CodeDescription": "TRAVELOKA SERVICES PTE. LTD.",
     "TPSystemValue": "H833"
    },
    {
     
     "CodeDescription": "TRAVELWAYS PTE LTD",
     "TPSystemValue": "H229"
    },
    {
     
     "CodeDescription": "TRAVELWISE PTE. LTD.",
     "TPSystemValue": "H380"
    },
    {
     
     "CodeDescription": "TRAVELZONE NETWORK SERVICES PTE. LTD.",
     "TPSystemValue": "H421"
    },
    {
     
     "CodeDescription": "TRAVIX TRAVEL SINGAPORE PTE. LTD.",
     "TPSystemValue": "H596"
    },
    {
     
     "CodeDescription": "TREKKERS PTE. LTD.",
     "TPSystemValue": "H341"
    },
    {
     
     "CodeDescription": "TREKNOLOGY BIKES 3 PRIVATE LIMITED",
     "TPSystemValue": "13T"
    },
    {
     
     "CodeDescription": "TREKNOLOGY BIKES 3 PTE LTD",
     "TPSystemValue": 3258
    },
    {
     
     "CodeDescription": "TRELLEBORG SINGAPORE PTE LTD",
     "TPSystemValue": 3751
    },
    {
     
     "CodeDescription": "TREND SINGAPORE WEST PTE LTD",
     "TPSystemValue": 3259
    },
    {
     
     "CodeDescription": "TRIANGLE AUTO PTE LTD",
     "TPSystemValue": 3260
    },
    {
     
     "CodeDescription": "TRIBE PTE. LTD.",
     "TPSystemValue": "H733"
    },
    {
     
     "CodeDescription": "TRICHOKARE PTE LTD",
     "TPSystemValue": 3813
    },
    {
     
     "CodeDescription": "TRICOR SINGAPORE PTE LTD",
     "TPSystemValue": 3261
    },
    {
     
     "CodeDescription": "TRIIP PTE. LTD.",
     "TPSystemValue": "H811"
    },
    {
     
     "CodeDescription": "TRIMARINE INTERNATIONAL PTE LTD",
     "TPSystemValue": 3752
    },
    {
     
     "CodeDescription": "TRIMECH TECHNOLOGY PTE LTD",
     "TPSystemValue": 3262
    },
    {
     
     "CodeDescription": "TRINITY CHRISTIAN CENTRE",
     "TPSystemValue": 3263
    },
    {
     
     "CodeDescription": "TRIPANZEE.COM PTE. LTD.",
     "TPSystemValue": "H743"
    },
    {
     
     "CodeDescription": "TRIPBUILDER PTE. LTD.",
     "TPSystemValue": "H763"
    },
    {
     
     "CodeDescription": "TRIPS INTERNATIONAL PTE LTD",
     "TPSystemValue": "H278"
    },
    {
     
     "CodeDescription": "TRIQUINT INTERNATIONAL PTE LTD",
     "TPSystemValue": 3753
    },
    {
     
     "CodeDescription": "TRISHAW UNCLE PTE. LTD.",
     "TPSystemValue": "H323"
    },
    {
     
     "CodeDescription": "TRITECH GROUP LIMITED",
     "TPSystemValue": 3754
    },
    {
     
     "CodeDescription": "TRI-WAYS TOURS & TRAVEL PTE LTD",
     "TPSystemValue": "H283"
    },
    {
     
     "CodeDescription": "TROPICAL POINT TRAVEL (S) PTE LTD",
     "TPSystemValue": "H204"
    },
    {
     
     "CodeDescription": "TRUE FITNESS PTE LTD",
     "TPSystemValue": 3264
    },
    {
     
     "CodeDescription": "TRUE YOGA PTE LTD",
     "TPSystemValue": 3265
    },
    {
     
     "CodeDescription": "TRUST ENERGY RESOURCES PVT LTD",
     "TPSystemValue": 3266
    },
    {
     
     "CodeDescription": "TRUST LINK EXPRESS LLP",
     "TPSystemValue": 3267
    },
    {
     
     "CodeDescription": "TRUST ONE TRAVEL (SIN) PTE. LTD.",
     "TPSystemValue": "H750"
    },
    {
     
     "CodeDescription": "TRW AFTERMARKET ASIA PACIFIC PTE LTD",
     "TPSystemValue": 3755
    },
    {
     
     "CodeDescription": "TSYSTEMS SINGAPORE PTE LTD",
     "TPSystemValue": 3270
    },
    {
     
     "CodeDescription": "TT INTERNATIONAL",
     "TPSystemValue": 3271
    },
    {
     
     "CodeDescription": "TTG ASIA MEDIA PTE LTD",
     "TPSystemValue": "H300"
    },
    {
     
     "CodeDescription": "TTI ELECTRONICS ASIA PTE TLD",
     "TPSystemValue": 3272
    },
    {
     
     "CodeDescription": "TTJ DESIGN & ENGINEERING PTE LTD",
     "TPSystemValue": 3273
    },
    {
     
     "CodeDescription": "TTM TRAVEL AGENCY PTE LTD",
     "TPSystemValue": "H121"
    },
    {
     
     "CodeDescription": "TTSH",
     "TPSystemValue": 3274
    },
    {
     
     "CodeDescription": "TUAS POWER GENERATION PTE LTD",
     "TPSystemValue": 3275
    },
    {
     
     "CodeDescription": "TUAS POWER LTD",
     "TPSystemValue": 3276
    },
    {
     
     "CodeDescription": "TUFFI-IMD PTE LTD",
     "TPSystemValue": 471
    },
    {
     
     "CodeDescription": "TULLETT PREBON (SINGAPORE) LTD",
     "TPSystemValue": 3277
    },
    {
     
     "CodeDescription": "TURBINE OVERHAUL SERVICES",
     "TPSystemValue": 3278
    },
    {
     
     "CodeDescription": "TUV SUB PSB PTE LTD",
     "TPSystemValue": 3279
    },
    {
     
     "CodeDescription": "TUV SUD ASIA PACIFIC PTE LTD",
     "TPSystemValue": 3280
    },
    {
     
     "CodeDescription": "TUV SUD PSB PTE LTD",
     "TPSystemValue": 3281
    },
    {
     
     "CodeDescription": "TVI HOLIDAYS PTE LTD",
     "TPSystemValue": "H241"
    },
    {
     
     "CodeDescription": "TWELVE TRAVEL PTE LTD",
     "TPSystemValue": "H571"
    },
    {
     
     "CodeDescription": "TYCO ELECTRONICS SINGAPORE PTE LTD",
     "TPSystemValue": 566
    },
    {
     
     "CodeDescription": "TYCO FIRE SECURITY & SERVICES PTE L",
     "TPSystemValue": 3282
    },
    {
     
     "CodeDescription": "TYCO FLOW CONTROL PTE LTD",
     "TPSystemValue": 3283
    },
    {
     
     "CodeDescription": "U & I TRAVEL AND TOURS (S) PTE LTD",
     "TPSystemValue": "H257"
    },
    {
     
     "CodeDescription": "U21GLOBAL GRADUATE SCHOOL PTE LTD",
     "TPSystemValue": 793
    },
    {
     
     "CodeDescription": "U3 INFOTECH PTE LTD",
     "TPSystemValue": 833
    },
    {
     
     "CodeDescription": "UBISOFT SINGAPORE PTE LTD",
     "TPSystemValue": 3284
    },
    {
     
     "CodeDescription": "UBM ENGINEERING SERVICE(S) PTE LTD",
     "TPSystemValue": 3285
    },
    {
     
     "CodeDescription": "UBM MEDICA ASIA PL",
     "TPSystemValue": 3286
    },
    {
     
     "CodeDescription": "UBS AG",
     "TPSystemValue": "U01"
    },
    {
     
     "CodeDescription": "UBS AG SINGAPORE",
     "TPSystemValue": 3287
    },
    {
     
     "CodeDescription": "UCO BANK",
     "TPSystemValue": "U02"
    },
    {
     
     "CodeDescription": "UES HOLDINGS PTE LTD",
     "TPSystemValue": 33
    },
    {
     
     "CodeDescription": "UFA SPORTS ASIA PTE LTD",
     "TPSystemValue": 1287
    },
    {
     
     "CodeDescription": "UFINITY PTE LTD",
     "TPSystemValue": 3288
    },
    {
     
     "CodeDescription": "UFJ BANK LIMITED",
     "TPSystemValue": 3289
    },
    {
     
     "CodeDescription": "UGL SERVICES ESMACO OPERATIONS PTE LTD",
     "TPSystemValue": 3756
    },
    {
     
     "CodeDescription": "UGL SERVICES PREMAS OPERATIONS LIMITED",
     "TPSystemValue": 3757
    },
    {
     
     "CodeDescription": "UGL SINGAPORE PTE LTD",
     "TPSystemValue": 52
    },
    {
     
     "CodeDescription": "UIB ASIA",
     "TPSystemValue": 3290
    },
    {
     
     "CodeDescription": "UIC MANAGEMENT SERVICES PL",
     "TPSystemValue": 3291
    },
    {
     
     "CodeDescription": "ULTIMATE DISPLAY SYSTEM PL",
     "TPSystemValue": 3292
    },
    {
     
     "CodeDescription": "ULTRA CLEAN ASIA PACIFIC P L",
     "TPSystemValue": 3293
    },
    {
     
     "CodeDescription": "UMC CORPORATION",
     "TPSystemValue": 3294
    },
    {
     
     "CodeDescription": "UMC SERVICE MASTER",
     "TPSystemValue": 3758
    },
    {
     
     "CodeDescription": "UML",
     "TPSystemValue": 3295
    },
    {
     
     "CodeDescription": "UMS SEMICONDUCTOR P L",
     "TPSystemValue": 3296
    },
    {
     
     "CodeDescription": "UMW EQUIPMENT AND ENGINEERING PTE LTD",
     "TPSystemValue": 3297
    },
    {
     
     "CodeDescription": "UNDERWATER WORLD SINGAPORE PTE LTD",
     "TPSystemValue": 3298
    },
    {
     
     "CodeDescription": "UNICABLE PTE LTD",
     "TPSystemValue": 172
    },
    {
     
     "CodeDescription": "UNICOM DATABANK PTE LTD",
     "TPSystemValue": 3299
    },
    {
     
     "CodeDescription": "UNICON INTERNATIONAL LTD",
     "TPSystemValue": 3300
    },
    {
     
     "CodeDescription": "UNICORN INTERNATIONAL PTE LTD",
     "TPSystemValue": 3301
    },
    {
     
     "CodeDescription": "UNIDIVE MARINE SERVICES PTE LTD",
     "TPSystemValue": 3302
    },
    {
     
     "CodeDescription": "UNIDUX ELECTRONIC LIMITED",
     "TPSystemValue": 3303
    },
    {
     
     "CodeDescription": "UNIFIED COMMUNICATIONS PTE LTD",
     "TPSystemValue": 3304
    },
    {
     
     "CodeDescription": "UNIGRADE TRADING PTE LTD",
     "TPSystemValue": 255
    },
    {
     
     "CodeDescription": "UNIGROUP WORLDWIDE (S) PTE LTD",
     "TPSystemValue": 3305
    },
    {
     
     "CodeDescription": "UNILEVER ASIA PRIVATE LIMITED",
     "TPSystemValue": 353
    },
    {
     
     "CodeDescription": "UNILIB CONTROL SYSTEM PTE LTD",
     "TPSystemValue": 348
    },
    {
     
     "CodeDescription": "UNILIB ENTERPRISES PTE LTD",
     "TPSystemValue": 201
    },
    {
     
     "CodeDescription": "UNION DE BANQUES ARABES ET FRANCAISES",
     "TPSystemValue": "U04"
    },
    {
     
     "CodeDescription": "UNION STEEL HOLDINGS LIMITED",
     "TPSystemValue": 934
    },
    {
     
     "CodeDescription": "UNIQ LUXE PTE. LTD.",
     "TPSystemValue": "H795"
    },
    {
     
     "CodeDescription": "UNIQUE TOURIST SERVICE (PTE.) LTD.",
     "TPSystemValue": "H065"
    },
    {
     
     "CodeDescription": "UNISOFTINFOTECH PTE LTD",
     "TPSystemValue": 503
    },
    {
     
     "CodeDescription": "UNISTEEL TECHNOLOGIES LTD",
     "TPSystemValue": 3306
    },
    {
     
     "CodeDescription": "UNIT 4 ASIA PACIFIC PTE LTD",
     "TPSystemValue": 726
    },
    {
     
     "CodeDescription": "UNITED AIRLINES INC",
     "TPSystemValue": 3307
    },
    {
     
     "CodeDescription": "UNITED AVIATION SINGAPORE PTE LTD",
     "TPSystemValue": 906
    },
    {
     
     "CodeDescription": "UNITED BUNKERING AND TRADING ASIA",
     "TPSystemValue": 3308
    },
    {
     
     "CodeDescription": "UNITED ENGINEERS LIMITED",
     "TPSystemValue": 7
    },
    {
     
     "CodeDescription": "UNITED MICROELECTRONICS CORPORATION",
     "TPSystemValue": 3073
    },
    {
     
     "CodeDescription": "UNITED OVERSEAS BANK",
     "TPSystemValue": 10
    },
    {
     
     "CodeDescription": "UNITED OVERSEAS BANK LIMITED",
     "TPSystemValue": "U05"
    },
    {
     
     "CodeDescription": "UNITED PARCEL SERVICE SINGAPORE PTE LTD",
     "TPSystemValue": 3309
    },
    {
     
     "CodeDescription": "UNITED PREMAS GROUP OF COMPANIES",
     "TPSystemValue": 3310
    },
    {
     
     "CodeDescription": "UNITED PREMAS LIMITED",
     "TPSystemValue": 3759
    },
    {
     
     "CodeDescription": "UNITED PROJECTS CONSULTANTS PTE LTD",
     "TPSystemValue": 3311
    },
    {
     
     "CodeDescription": "UNITED TEST AND ASSEMBLY CENTRE LTD",
     "TPSystemValue": 632
    },
    {
     
     "CodeDescription": "UNITED TOOLING & ENGINEERING",
     "TPSystemValue": 1411
    },
    {
     
     "CodeDescription": "UNITED TRAVELS AND TOURS PTE. LTD.",
     "TPSystemValue": "H459"
    },
    {
     
     "CodeDescription": "UNITED WORLD COLLEGE OF SOUTH EAST ASIA",
     "TPSystemValue": 65
    },
    {
     
     "CodeDescription": "UNITOR CYLINDER PTE LTD",
     "TPSystemValue": 944
    },
    {
     
     "CodeDescription": "UNIVAC PRECISION ENGINEERING PTE LTD",
     "TPSystemValue": 164
    },
    {
     
     "CodeDescription": "UNIVERSAL FLY TRAVEL GROUP PTE. LTD.",
     "TPSystemValue": "H710"
    },
    {
     
     "CodeDescription": "UNIVERSAL NAVIGATION PTE LTD",
     "TPSystemValue": 518
    },
    {
     
     "CodeDescription": "UNIVERSAL OIL PRODUCTS ASIA PTE LTD",
     "TPSystemValue": 543
    },
    {
     
     "CodeDescription": "UNIVERSAL TRAVEL CORPORATION PTE LTD",
     "TPSystemValue": "H062"
    },
    {
     
     "CodeDescription": "UNIVONNA PTE LTD",
     "TPSystemValue": 865
    },
    {
     
     "CodeDescription": "UNUSUAL EXPEDITION PTE. LTD.",
     "TPSystemValue": "H747"
    },
    {
     
     "CodeDescription": "UNUSUAL GROUP OF COMPANIES",
     "TPSystemValue": 3312
    },
    {
     
     "CodeDescription": "UOB TRAVEL PLANNERS PTE LTD",
     "TPSystemValue": "H063"
    },
    {
     
     "CodeDescription": "UPS ASIA GROUP PTE LTD",
     "TPSystemValue": 1338
    },
    {
     
     "CodeDescription": "UR TRAVEL PTE. LTD.",
     "TPSystemValue": "H423"
    },
    {
     
     "CodeDescription": "URA",
     "TPSystemValue": 3313
    },
    {
     
     "CodeDescription": "UTAC",
     "TPSystemValue": 3314
    },
    {
     
     "CodeDescription": "UTC AEROSPACE SYSTEMS",
     "TPSystemValue": 3760
    },
    {
     
     "CodeDescription": "UTI WORLDWIDE (S) PTE LTD",
     "TPSystemValue": 3315
    },
    {
     
     "CodeDescription": "UTRACON STRUCTURAL SYSTEMS PTE LTD",
     "TPSystemValue": 645
    },
    {
     
     "CodeDescription": "V.SHIPS ASIA GROUP PTE. LTD.",
     "TPSystemValue": "H623"
    },
    {
     
     "CodeDescription": "VACANCES (S) PTE LTD",
     "TPSystemValue": "H183"
    },
    {
     
     "CodeDescription": "VACATION HUB PTE. LTD.",
     "TPSystemValue": "H839"
    },
    {
     
     "CodeDescription": "VACATION PLANNERS PTE LTD",
     "TPSystemValue": "H136"
    },
    {
     
     "CodeDescription": "VACATION SINGAPORE DMC PTE LTD",
     "TPSystemValue": "H131"
    },
    {
     
     "CodeDescription": "VACE PTE LTD",
     "TPSystemValue": 1003
    },
    {
     
     "CodeDescription": "VALIRAM GROUP (LUXURY VENTURES PTE LTD)",
     "TPSystemValue": 227
    },
    {
     
     "CodeDescription": "VALUE ADS PTE LTD",
     "TPSystemValue": 1209
    },
    {
     
     "CodeDescription": "VALUE HYDROCARBON PTE. LTD.",
     "TPSystemValue": 1309
    },
    {
     
     "CodeDescription": "VAN LEEUWEN PIPE & TUBE FAR EAST PL",
     "TPSystemValue": 3316
    },
    {
     
     "CodeDescription": "VANSIN TRADING & ENGINEERING PL",
     "TPSystemValue": 3317
    },
    {
     
     "CodeDescription": "VANTAGE ENGINEERING PTE LTD",
     "TPSystemValue": 1064
    },
    {
     
     "CodeDescription": "VANTEC WORLD TRANSPORT",
     "TPSystemValue": 286
    },
    {
     
     "CodeDescription": "VAR CONNETZ PTE LTD",
     "TPSystemValue": 1174
    },
    {
     
     "CodeDescription": "VARADA MARINE PTE LTD",
     "TPSystemValue": 1006
    },
    {
     
     "CodeDescription": "VARIAN SEMI CONDUCTOR",
     "TPSystemValue": 3318
    },
    {
     
     "CodeDescription": "VASTER NEW MEDIA & BUSINESS CONSULTANCY PTE. LTD.",
     "TPSystemValue": "H834"
    },
    {
     
     "CodeDescription": "VDL ENABLING TECHNOLOGIES GROUP",
     "TPSystemValue": 3319
    },
    {
     
     "CodeDescription": "VECTOR INFO TECH PL",
     "TPSystemValue": 3320
    },
    {
     
     "CodeDescription": "VEGA T S PTE. LTD.",
     "TPSystemValue": "H412"
    },
    {
     
     "CodeDescription": "VENTURE CORPORATION",
     "TPSystemValue": 3321
    },
    {
     
     "CodeDescription": "VEOLIA WATER SOLUTIONS TECH SEA PL",
     "TPSystemValue": 270
    },
    {
     
     "CodeDescription": "VERIFONE SINGAPORE PTE LTD",
     "TPSystemValue": 3322
    },
    {
     
     "CodeDescription": "VERIGY PTE LTD",
     "TPSystemValue": 3323
    },
    {
     
     "CodeDescription": "VERISTA SINGPORE PTE LTD",
     "TPSystemValue": 996
    },
    {
     
     "CodeDescription": "VERIZON COMMUNICATIONS SINGAPORE PTE LTD",
     "TPSystemValue": 3324
    },
    {
     
     "CodeDescription": "VERVE TECHNOLOGY SERVICES PE LTD",
     "TPSystemValue": 1342
    },
    {
     
     "CodeDescription": "VESTAS ASIA PTE LTD",
     "TPSystemValue": 3325
    },
    {
     
     "CodeDescription": "VETCO GRAY PTE LTD",
     "TPSystemValue": 3326
    },
    {
     
     "CodeDescription": "VICKERS BALLAS HOLDINGS PTE LTD",
     "TPSystemValue": 3327
    },
    {
     
     "CodeDescription": "VICTORIA TRAVEL & COACH TOUR PTE. LTD.",
     "TPSystemValue": "H298"
    },
    {
     
     "CodeDescription": "VICTORY TRAVEL PTE LTD",
     "TPSystemValue": "H244"
    },
    {
     
     "CodeDescription": "VIGCON CONSTRUCTION PTE LTD",
     "TPSystemValue": 344
    },
    {
     
     "CodeDescription": "VIKING ENGINEERING PTE. LTD.",
     "TPSystemValue": 120
    },
    {
     
     "CodeDescription": "VIKING INERT GAS PTE LTD",
     "TPSystemValue": 1280
    },
    {
     
     "CodeDescription": "VINACAPITAL",
     "TPSystemValue": 1243
    },
    {
     
     "CodeDescription": "VINTAGE TOURS & TRAVEL PTE. LTD.",
     "TPSystemValue": "H593"
    },
    {
     
     "CodeDescription": "VIRTUAL HR PTE LTD",
     "TPSystemValue": 573
    },
    {
     
     "CodeDescription": "VIRTUSA SINGAPORE PTE LTD",
     "TPSystemValue": 1328
    },
    {
     
     "CodeDescription": "VISA WORLDWIDE PTE LIMITED",
     "TPSystemValue": 1128
    },
    {
     
     "CodeDescription": "VISA WORLDWIDE PTE. LIMITED",
     "TPSystemValue": "01V"
    },
    {
     
     "CodeDescription": "VISHAY INTERTECHNOLOGY ASIA PTE LTD",
     "TPSystemValue": 3761
    },
    {
     
     "CodeDescription": "VISHAY INTERTECHNOLOGY INC",
     "TPSystemValue": 2834
    },
    {
     
     "CodeDescription": "VISION MARINE ENGINEEERING",
     "TPSystemValue": 3328
    },
    {
     
     "CodeDescription": "VISION MARINE ENGINEERING",
     "TPSystemValue": "02V"
    },
    {
     
     "CodeDescription": "VISION MARINE ENGINEERING PTE. LTD.",
     "TPSystemValue": "03V"
    },
    {
     
     "CodeDescription": "VISION SYSTEM PTE LTD",
     "TPSystemValue": 493
    },
    {
     
     "CodeDescription": "VISUAL STUDIO SINGAPORE PTE LTD",
     "TPSystemValue": 1067
    },
    {
     
     "CodeDescription": "VITAL SOLUTIONS PTE LTD",
     "TPSystemValue": 705
    },
    {
     
     "CodeDescription": "VITALITY INTERNATIONAL SERVICES ASIA PL",
     "TPSystemValue": 1349
    },
    {
     
     "CodeDescription": "VITOL ASIA PTE LTD",
     "TPSystemValue": 3329
    },
    {
     
     "CodeDescription": "VIVA LIGHTINGS PTE LTD",
     "TPSystemValue": 1203
    },
    {
     
     "CodeDescription": "VIVOO HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H564"
    },
    {
     
     "CodeDescription": "VIZ TECHNOLOGIES PTE LTD",
     "TPSystemValue": 1030
    },
    {
     
     "CodeDescription": "V-KEY PTE LTD",
     "TPSystemValue": 1333
    },
    {
     
     "CodeDescription": "VMK ENGINEERING PTE LTD",
     "TPSystemValue": 1060
    },
    {
     
     "CodeDescription": "VMWARE SINGAPORE PTE LTD",
     "TPSystemValue": 885
    },
    {
     
     "CodeDescription": "VNG SINGAPORE PTE LTD",
     "TPSystemValue": 1348
    },
    {
     
     "CodeDescription": "VOBILE PTE LTD",
     "TPSystemValue": 1285
    },
    {
     
     "CodeDescription": "VODAFONE GLOBAL ENTERPRISE S PL",
     "TPSystemValue": 1173
    },
    {
     
     "CodeDescription": "VOLEX (ASIA) PTE LTD",
     "TPSystemValue": 3330
    },
    {
     
     "CodeDescription": "VOLKSWAGEN GROUP SINGAPORE PTE LTD",
     "TPSystemValue": 3331
    },
    {
     
     "CodeDescription": "VOLTERRA ASIA PTE LTD",
     "TPSystemValue": 847
    },
    {
     
     "CodeDescription": "VOLVO EAST ASIA PTE LTD",
     "TPSystemValue": 3332
    },
    {
     
     "CodeDescription": "VOPAK TERMINALS SINGAPORE PTE LTD",
     "TPSystemValue": 3333
    },
    {
     
     "CodeDescription": "VOYAGER TRAVEL PTE LTD",
     "TPSystemValue": "H102"
    },
    {
     
     "CodeDescription": "VP BANK SINGAPORE LTD",
     "TPSystemValue": 1078
    },
    {
     
     "CodeDescription": "VS TRAVEL PTE. LTD.",
     "TPSystemValue": "H652"
    },
    {
     
     "CodeDescription": "VSERVE INTERNATIONAL PTE LTD",
     "TPSystemValue": 3762
    },
    {
     
     "CodeDescription": "VSL SINGAPORE PTE LTD",
     "TPSystemValue": 3334
    },
    {
     
     "CodeDescription": "VT TRAVEL PTE. LTD.",
     "TPSystemValue": "H417"
    },
    {
     
     "CodeDescription": "VTB CAPITAL PLC",
     "TPSystemValue": "V01"
    },
    {
     
     "CodeDescription": "V-TECH COMPUTERS PL",
     "TPSystemValue": 3335
    },
    {
     
     "CodeDescription": "VUI LID CONSTRUCTION PTE LTD",
     "TPSystemValue": 412
    },
    {
     
     "CodeDescription": "VULPES INVESTMENT PTE LTD",
     "TPSystemValue": 1278
    },
    {
     
     "CodeDescription": "V-WORKZ INTERNATIONAL PTE LTD",
     "TPSystemValue": 894
    },
    {
     
     "CodeDescription": "W GROUP PTE. LTD.",
     "TPSystemValue": "H560"
    },
    {
     
     "CodeDescription": "W SINGAPORE SENTOSA COVE HOTEL",
     "TPSystemValue": 1431
    },
    {
     
     "CodeDescription": "W&T COACH & TRAVEL PTE. LTD.",
     "TPSystemValue": "H778"
    },
    {
     
     "CodeDescription": "WAI TRAVEL PTE. LTD.",
     "TPSystemValue": "H622"
    },
    {
     
     "CodeDescription": "WAIKIKI TRAVEL PTE. LTD.",
     "TPSystemValue": "H376"
    },
    {
     
     "CodeDescription": "WALLEM SHIPPING SINGAPORE PTE LIMITED",
     "TPSystemValue": 3763
    },
    {
     
     "CodeDescription": "WALLENIUS LINES SINGAPORE PTE LTD",
     "TPSystemValue": 204
    },
    {
     
     "CodeDescription": "WALT DISNEY S PORE PTE LTD",
     "TPSystemValue": 3336
    },
    {
     
     "CodeDescription": "WALTON INTERNATIONAL GROUP S PTE LTD",
     "TPSystemValue": 545
    },
    {
     
     "CodeDescription": "WAN HAI LINES SINGAPORE PTE LTD",
     "TPSystemValue": 383
    },
    {
     
     "CodeDescription": "WAN YANG HEALTH PRODUCTS & FOOD",
     "TPSystemValue": 3337
    },
    {
     
     "CodeDescription": "WANDEE ENGINEERING",
     "TPSystemValue": 1363
    },
    {
     
     "CodeDescription": "WANDERLUST TRAVEL PTE. LTD.",
     "TPSystemValue": "H759"
    },
    {
     
     "CodeDescription": "WANG CAFGROUP PL (IFI ACADEMY PL)",
     "TPSystemValue": 988
    },
    {
     
     "CodeDescription": "WANG FATT OIL & GAS CONSTRUCTION PL",
     "TPSystemValue": 603
    },
    {
     
     "CodeDescription": "WANHE INTERNATIONAL SINGAPORE PTE. LTD.",
     "TPSystemValue": "H353"
    },
    {
     
     "CodeDescription": "WANXIANG RESOURCES (SINGAPORE) PL",
     "TPSystemValue": 3338
    },
    {
     
     "CodeDescription": "WAREHOUSING & DISTRIBUTION PTE LTD",
     "TPSystemValue": 3339
    },
    {
     
     "CodeDescription": "WARTSILA SINGAPORE PTE LTD",
     "TPSystemValue": 3340
    },
    {
     
     "CodeDescription": "WASCO ENGINEERING SERVICES S",
     "TPSystemValue": 3341
    },
    {
     
     "CodeDescription": "WATAMI FOOD SERVICE SINGAPORE PTE LTD",
     "TPSystemValue": 1191
    },
    {
     
     "CodeDescription": "WATCHDATA TECHNOLOGIES PTE LTD",
     "TPSystemValue": 738
    },
    {
     
     "CodeDescription": "WATERLAND SERVICES PTE LTD",
     "TPSystemValue": 878
    },
    {
     
     "CodeDescription": "WATSON, FARLEY & WILLIAMS LLP",
     "TPSystemValue": 3342
    },
    {
     
     "CodeDescription": "WBL CORPORATION LIMITED",
     "TPSystemValue": 8
    },
    {
     
     "CodeDescription": "WD MEDIA SINGAPORE PTE LTD",
     "TPSystemValue": 1271
    },
    {
     
     "CodeDescription": "WDA SINGAPORE WORK FORCE DEV AGENCY",
     "TPSystemValue": 3343
    },
    {
     
     "CodeDescription": "WE INVESTMENT",
     "TPSystemValue": 1429
    },
    {
     
     "CodeDescription": "WEARNES DEVELOPMENT PRIVATE LIMITED",
     "TPSystemValue": 14
    },
    {
     
     "CodeDescription": "WEATHERFORD ASIA PACIFIC PTE LTD",
     "TPSystemValue": 307
    },
    {
     
     "CodeDescription": "WEBBE INTERNATIONAL (ASIA) PTE LTD",
     "TPSystemValue": 3344
    },
    {
     
     "CodeDescription": "WEE SENG MARINE & ENGINEERING PTE LTD",
     "TPSystemValue": 1090
    },
    {
     
     "CodeDescription": "WEI SIANG DESIGN CONSTRUCTION PTE LTD",
     "TPSystemValue": 1148
    },
    {
     
     "CodeDescription": "WEI XING TRAVEL PTE LTD",
     "TPSystemValue": "H173"
    },
    {
     
     "CodeDescription": "WEIKE (S) PTE LTD",
     "TPSystemValue": 3345
    },
    {
     
     "CodeDescription": "WEIKEN.COM INTERIOR DESIGN PTE LTD",
     "TPSystemValue": "02W"
    },
    {
     
     "CodeDescription": "WEIKENCOM INTERIOR DESIGN PTE LTD",
     "TPSystemValue": 1133
    },
    {
     
     "CodeDescription": "WEIRDOFREAKO OUTDOOR PTE. LTD.",
     "TPSystemValue": "H785"
    },
    {
     
     "CodeDescription": "WELCH ALLYN SINGAPORE PTE LTD",
     "TPSystemValue": 1214
    },
    {
     
     "CodeDescription": "WELL & ABLE GLOBAL PTE LTD",
     "TPSystemValue": 1343
    },
    {
     
     "CodeDescription": "WELLINGTON INTERNATIONAL MANAGEMENT",
     "TPSystemValue": 3346
    },
    {
     
     "CodeDescription": "WENG STONE CONSTRUCTION TRADING PL",
     "TPSystemValue": 1015
    },
    {
     
     "CodeDescription": "WEST PHARMACEUTICAL SVCS SINGAPORE PL",
     "TPSystemValue": 3347
    },
    {
     
     "CodeDescription": "WESTCON GROUP PTE. LTD.",
     "TPSystemValue": 3348
    },
    {
     
     "CodeDescription": "WESTECH ELECTRONICS LIMITED",
     "TPSystemValue": 3349
    },
    {
     
     "CodeDescription": "WESTERN ASSET MANAGEMENT CO PTE LTD",
     "TPSystemValue": 748
    },
    {
     
     "CodeDescription": "WESTERN DIGITAL PTE LTD",
     "TPSystemValue": 3350
    },
    {
     
     "CodeDescription": "WESTERN UNION SERVICES SINGAPORE PL",
     "TPSystemValue": 895
    },
    {
     
     "CodeDescription": "WESTLAKE CHARTERING PTE LTD",
     "TPSystemValue": 3351
    },
    {
     
     "CodeDescription": "WESTLB AG",
     "TPSystemValue": "W02"
    },
    {
     
     "CodeDescription": "WESTMINSTER TRAVEL SINGAPORE PTE LTD",
     "TPSystemValue": 92
    },
    {
     
     "CodeDescription": "WESTPAC SINGAPORE LIMITED",
     "TPSystemValue": 3764
    },
    {
     
     "CodeDescription": "WG CONSULTANTS PTE LTD",
     "TPSystemValue": 1346
    },
    {
     
     "CodeDescription": "WHEELOCK PROPERTIES SINGAPORE LTD",
     "TPSystemValue": 3352
    },
    {
     
     "CodeDescription": "WHITE & CASE LLP",
     "TPSystemValue": 763
    },
    {
     
     "CodeDescription": "WHITE DOG CAFE",
     "TPSystemValue": 3353
    },
    {
     
     "CodeDescription": "WHIZ ADVENTURES PTE. LTD.",
     "TPSystemValue": "H414"
    },
    {
     
     "CodeDescription": "WHIZ LINK MANPOWER",
     "TPSystemValue": 1394
    },
    {
     
     "CodeDescription": "WHIZZWORK TECHNOLOGY PTE LTD",
     "TPSystemValue": 3354
    },
    {
     
     "CodeDescription": "WHOLESALE PARTNERS PTE. LTD.",
     "TPSystemValue": "H535"
    },
    {
     
     "CodeDescription": "WHOLESOME DELIGHTS PTE LTD",
     "TPSystemValue": 1289
    },
    {
     
     "CodeDescription": "WILD HONEY 2 PTE LTD",
     "TPSystemValue": 1336
    },
    {
     
     "CodeDescription": "WILHELMSEN SHIPS SERVICE S PTE LTD",
     "TPSystemValue": 111
    },
    {
     
     "CodeDescription": "WILLIAM ENG PTE LTD",
     "TPSystemValue": 1025
    },
    {
     
     "CodeDescription": "WILLIS (SINGAPORE) PTE. LTD.",
     "TPSystemValue": 3355
    },
    {
     
     "CodeDescription": "WILLOWGLEN SERVICES PTE LTD",
     "TPSystemValue": 271
    },
    {
     
     "CodeDescription": "WILMAR INTERNATIONAL LIMITED",
     "TPSystemValue": 695
    },
    {
     
     "CodeDescription": "WILMAR TRADING CHINA PTE LTD",
     "TPSystemValue": 3765
    },
    {
     
     "CodeDescription": "WILMAR TRADING PTE LTD",
     "TPSystemValue": 3766
    },
    {
     
     "CodeDescription": "WILMAX CONTROL SYSTEMS PTE LTD",
     "TPSystemValue": 3356
    },
    {
     
     "CodeDescription": "WILSON & ASSOCIATES LLC",
     "TPSystemValue": 3086
    },
    {
     
     "CodeDescription": "WILSON PARKING SERVICE SINGAPORE PTE LTD",
     "TPSystemValue": 3357
    },
    {
     
     "CodeDescription": "WIN SHIN ENGINEERING PTE LTD",
     "TPSystemValue": 3358
    },
    {
     
     "CodeDescription": "WINCOR NIXDORF PTE LTD",
     "TPSystemValue": 3359
    },
    {
     
     "CodeDescription": "WINDSOR AIRMOTIVE ASIA",
     "TPSystemValue": 347
    },
    {
     
     "CodeDescription": "WING ON TRAVEL SERVICE PTE LTD",
     "TPSystemValue": "H094"
    },
    {
     
     "CodeDescription": "WING TAI BRANDED LIFESTYLE PTE LTD",
     "TPSystemValue": 1109
    },
    {
     
     "CodeDescription": "WINGO TRAVEL PTE LTD",
     "TPSystemValue": "H309"
    },
    {
     
     "CodeDescription": "WINGS TRAVEL MANAGEMENT SG PTE. LTD.",
     "TPSystemValue": "H116"
    },
    {
     
     "CodeDescription": "WINLAND TRAVEL PTE LTD",
     "TPSystemValue": "H092"
    },
    {
     
     "CodeDescription": "WINSOR AIRMOTIVE ASIA PTE LTD",
     "TPSystemValue": 3360
    },
    {
     
     "CodeDescription": "WINSWAY RESOURCES HOLDINGS PRIVATE LTD",
     "TPSystemValue": 1255
    },
    {
     
     "CodeDescription": "WINSYS PTE LTD",
     "TPSystemValue": 668
    },
    {
     
     "CodeDescription": "WIPRO LIMITED SINGAPORE BRANCH",
     "TPSystemValue": 2832
    },
    {
     
     "CodeDescription": "WIRELESS DATA SERVICE(ASIA PACIFIC)PTE L",
     "TPSystemValue": 3361
    },
    {
     
     "CodeDescription": "WIZVISION PTE LTD",
     "TPSystemValue": 729
    },
    {
     
     "CodeDescription": "WLA REGNUM ADVISORY SERVICES PTE LTD",
     "TPSystemValue": 1063
    },
    {
     
     "CodeDescription": "WOH HUP HOLDINGS PTE LTD",
     "TPSystemValue": 3767
    },
    {
     
     "CodeDescription": "WOH HUP PRIVATE LIMITED",
     "TPSystemValue": 12
    },
    {
     
     "CodeDescription": "WOK N STROLL PTE. LTD.",
     "TPSystemValue": "H703"
    },
    {
     
     "CodeDescription": "WONDERS TRAVEL PTE. LTD.",
     "TPSystemValue": "H798"
    },
    {
     
     "CodeDescription": "WONG LEE ASSOCIATES",
     "TPSystemValue": 3781
    },
    {
     
     "CodeDescription": "WONG PARTNERSHIP LLP",
     "TPSystemValue": 3362
    },
    {
     
     "CodeDescription": "WONG WING SOON TRADING",
     "TPSystemValue": 1365
    },
    {
     
     "CodeDescription": "WONGPARTNERSHIP LLP",
     "TPSystemValue": 3100
    },
    {
     
     "CodeDescription": "WOODLANDS TPT SERVICES PTE LTD",
     "TPSystemValue": 3363
    },
    {
     
     "CodeDescription": "WOOPA TRAVELS PTE. LTD.",
     "TPSystemValue": "H822"
    },
    {
     
     "CodeDescription": "WOORI BANK",
     "TPSystemValue": "W04"
    },
    {
     
     "CodeDescription": "WORKFORCE DEVELOPMENT AGENCY",
     "TPSystemValue": 3364
    },
    {
     
     "CodeDescription": "WORKSHOP I.D. & BUILD PTE LTD",
     "TPSystemValue": 976
    },
    {
     
     "CodeDescription": "WORLD CARD SINGAPORE PTE LTD",
     "TPSystemValue": 3365
    },
    {
     
     "CodeDescription": "WORLD EXPRESS LOGISTICS (SINGAPORE) PL",
     "TPSystemValue": 3366
    },
    {
     
     "CodeDescription": "WORLD EXPRESS PTE LTD",
     "TPSystemValue": "H042"
    },
    {
     
     "CodeDescription": "WORLD FUEL SERVICES (S) PTE LTD",
     "TPSystemValue": 3367
    },
    {
     
     "CodeDescription": "WORLD HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H100"
    },
    {
     
     "CodeDescription": "WORLD OF INFORMATION TECHNOLOGY PTE LTD",
     "TPSystemValue": 600
    },
    {
     
     "CodeDescription": "WORLD PEACE INTERNATIONAL (S) PTE LTD",
     "TPSystemValue": 3368
    },
    {
     
     "CodeDescription": "WORLD PILGRIM CENTRE PTE. LTD.",
     "TPSystemValue": "H543"
    },
    {
     
     "CodeDescription": "WORLD SCIENTIFIC PUBLISHING CO PTE LTD",
     "TPSystemValue": 3369
    },
    {
     
     "CodeDescription": "WORLD SPORT GROUP CONSULTING PL",
     "TPSystemValue": 1072
    },
    {
     
     "CodeDescription": "WORLD SPORT GROUP PTE LTD",
     "TPSystemValue": 3768
    },
    {
     
     "CodeDescription": "WORLD VISION INTERNATIONAL",
     "TPSystemValue": 3370
    },
    {
     
     "CodeDescription": "WORLD VISION TRAVEL PTE LTD",
     "TPSystemValue": "H249"
    },
    {
     
     "CodeDescription": "WORLDPASS HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H418"
    },
    {
     
     "CodeDescription": "WORLDWIDE AVIATION SALES PTE LTD",
     "TPSystemValue": "H118"
    },
    {
     
     "CodeDescription": "WORLEY PARSON PTE LTD",
     "TPSystemValue": 3371
    },
    {
     
     "CodeDescription": "WPG HOLDINGS",
     "TPSystemValue": 3372
    },
    {
     
     "CodeDescription": "WPI INTERNATIONAL (S) PTE LTD",
     "TPSystemValue": 3373
    },
    {
     
     "CodeDescription": "WPP SINGAPORE PTE LTD",
     "TPSystemValue": 494
    },
    {
     
     "CodeDescription": "WRAY CONSTRUCTION PTE. LTD.",
     "TPSystemValue": "03W"
    },
    {
     
     "CodeDescription": "WRAY DECOR PTE.LTD.",
     "TPSystemValue": "04W"
    },
    {
     
     "CodeDescription": "WRAYLI IMPORT & EXPORT",
     "TPSystemValue": 1362
    },
    {
     
     "CodeDescription": "WTS TRAVEL & TOURS PTE LTD",
     "TPSystemValue": "H163"
    },
    {
     
     "CodeDescription": "WUNDERMAN PTE LTD",
     "TPSystemValue": 3374
    },
    {
     
     "CodeDescription": "WYETH SINGAPORE",
     "TPSystemValue": 3375
    },
    {
     
     "CodeDescription": "XA TRAVEL (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H704"
    },
    {
     
     "CodeDescription": "XCHANGING SOLUTIONS PTE LTD",
     "TPSystemValue": 3376
    },
    {
     
     "CodeDescription": "XIANG LONG HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H711"
    },
    {
     
     "CodeDescription": "XIN CHENG HOLIDAY PTE. LTD.",
     "TPSystemValue": "H492"
    },
    {
     
     "CodeDescription": "XINLIANHE CONSTRUCTION ENGINEERING (S) PTE LTD",
     "TPSystemValue": "01X"
    },
    {
     
     "CodeDescription": "XINLIANHE CONSTRUCTION ENGR(S) PL",
     "TPSystemValue": 3377
    },
    {
     
     "CodeDescription": "XM ASIA PACIFIC PTE LTD",
     "TPSystemValue": 528
    },
    {
     
     "CodeDescription": "XPRESS HOLDINGS LTD",
     "TPSystemValue": 3378
    },
    {
     
     "CodeDescription": "X-TREKKERS ADVENTURE CONSULTANT PTE LTD",
     "TPSystemValue": "H286"
    },
    {
     
     "CodeDescription": "XYRATEX SINGAPORE PTE LTD",
     "TPSystemValue": 3379
    },
    {
     
     "CodeDescription": "Y.H.H MARINE ENGINEERING PTE. LTD.",
     "TPSystemValue": "01Y"
    },
    {
     
     "CodeDescription": "Y.H.H. MARINE ENGINEERING PTE LTD",
     "TPSystemValue": 3380
    },
    {
     
     "CodeDescription": "Y3 TECHNOLOGIES PTE LTD",
     "TPSystemValue": 3381
    },
    {
     
     "CodeDescription": "YADANAR TRAVEL PTE LTD",
     "TPSystemValue": "H228"
    },
    {
     
     "CodeDescription": "YAHOO SOUTHEAST ASIA PTE LTD",
     "TPSystemValue": 582
    },
    {
     
     "CodeDescription": "YAMAHA MOTOR ASIA PTE LTD",
     "TPSystemValue": 3382
    },
    {
     
     "CodeDescription": "YAMAHA MUSIC (ASIA) PTE LTD",
     "TPSystemValue": 3383
    },
    {
     
     "CodeDescription": "YAMAICHI ELECTRONICS SINGAPORE PTE LTD",
     "TPSystemValue": 285
    },
    {
     
     "CodeDescription": "YAMAKAWA SUPER PTE LTD",
     "TPSystemValue": 446
    },
    {
     
     "CodeDescription": "YAMAKAWA TRADING CO PTE LTD",
     "TPSystemValue": 181
    },
    {
     
     "CodeDescription": "YAMATO SINGAPORE PTE LTD",
     "TPSystemValue": 3384
    },
    {
     
     "CodeDescription": "YAMAZAKI MAZAK SINGAPORE PTE LTD",
     "TPSystemValue": 309
    },
    {
     
     "CodeDescription": "YANG CITY BUILDING CONSTRUC & HOLDINGS",
     "TPSystemValue": 1120
    },
    {
     
     "CodeDescription": "YANG KEE LOGISTICS PTE LTD",
     "TPSystemValue": 3385
    },
    {
     
     "CodeDescription": "YANGTZE MARINE ENGINEERING PTE LTD",
     "TPSystemValue": 3386
    },
    {
     
     "CodeDescription": "YANLORD LAND PTE LTD",
     "TPSystemValue": 3387
    },
    {
     
     "CodeDescription": "YEL ELECTRONICS PTE LTD",
     "TPSystemValue": 519
    },
    {
     
     "CodeDescription": "YENOM HOLDINGS PTE LTD",
     "TPSystemValue": 3769
    },
    {
     
     "CodeDescription": "YEO HIAP SENG LTD",
     "TPSystemValue": 17
    },
    {
     
     "CodeDescription": "YEO-LEONG & PEH LLC",
     "TPSystemValue": 809
    },
    {
     
     "CodeDescription": "YEW SENG HENG ENGINEERING PTE LTD",
     "TPSystemValue": "02Y"
    },
    {
     
     "CodeDescription": "YHI CORPORATION SINGAPORE PTE LTD",
     "TPSystemValue": 717
    },
    {
     
     "CodeDescription": "YJ INTERNATIONAL PTE LTD",
     "TPSystemValue": 3388
    },
    {
     
     "CodeDescription": "YJ WATERPROOFING PTE LTD",
     "TPSystemValue": 3389
    },
    {
     
     "CodeDescription": "YKK AP SINGAPORE PTE LTD",
     "TPSystemValue": 3390
    },
    {
     
     "CodeDescription": "YKT MARINE ENGINEERING PTE LTD",
     "TPSystemValue": 3391
    },
    {
     
     "CodeDescription": "YKT MARINE ENGINEERING PTE. LTD.",
     "TPSystemValue": "03Y"
    },
    {
     
     "CodeDescription": "YMCA",
     "TPSystemValue": 1434
    },
    {
     
     "CodeDescription": "YOKOGAWA ELECTRIC INTERNATIONAL PL",
     "TPSystemValue": 975
    },
    {
     
     "CodeDescription": "YOKOGAWA ENGINEERING ASIA PTE LTD",
     "TPSystemValue": 591
    },
    {
     
     "CodeDescription": "YOLK PTE LTD",
     "TPSystemValue": 999
    },
    {
     
     "CodeDescription": "YONGNAM ENGINEERING CONSTRUCTION PL",
     "TPSystemValue": 89
    },
    {
     
     "CodeDescription": "YOO HAN TRAVEL PTE LTD",
     "TPSystemValue": "H310"
    },
    {
     
     "CodeDescription": "YORK HOTEL (PRIVATE) LTD",
     "TPSystemValue": "05Y"
    },
    {
     
     "CodeDescription": "YORK HOTEL PTE LTD",
     "TPSystemValue": 3393
    },
    {
     
     "CodeDescription": "YORK INTERNATIONAL PTE LTD",
     "TPSystemValue": 3394
    },
    {
     
     "CodeDescription": "YORK TPT EQUIPMENT PTE LTD",
     "TPSystemValue": 3395
    },
    {
     
     "CodeDescription": "YOSUN SINGAPORE PTE LTD",
     "TPSystemValue": 378
    },
    {
     
     "CodeDescription": "YOUTH VISIONS PRIVATE LIMITED",
     "TPSystemValue": "H328"
    },
    {
     
     "CodeDescription": "YOYO HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H654"
    },
    {
     
     "CodeDescription": "YTL HOTELS (SINGAPORE) PTE. LTD.",
     "TPSystemValue": "H563"
    },
    {
     
     "CodeDescription": "YUANDA ALUMINIUM INDUSTRY ENGINEERING",
     "TPSystemValue": 3396
    },
    {
     
     "CodeDescription": "YUANDA CURTAIN WALL SINGAPORE PTE LTD",
     "TPSystemValue": 3397
    },
    {
     
     "CodeDescription": "YULIN HOLIDAY PTE. LTD.",
     "TPSystemValue": "H447"
    },
    {
     
     "CodeDescription": "YUM! RESTAURANTS INTERNATIONAL",
     "TPSystemValue": 3398
    },
    {
     
     "CodeDescription": "YUN ONN COMPANY (PTE) LTD",
     "TPSystemValue": 3399
    },
    {
     
     "CodeDescription": "YUNG SHIN PHARMACEUTICALS (S) PTE LTD",
     "TPSystemValue": 3400
    },
    {
     
     "CodeDescription": "YUNG WAH INDUSTRIAL CO PTE LTD",
     "TPSystemValue": 3401
    },
    {
     
     "CodeDescription": "YUSEN LOGISTICS SINGAPORE PTE LTD",
     "TPSystemValue": 148
    },
    {
     
     "CodeDescription": "YUSEN TRAVEL (SINGAPORE) PTE LTD",
     "TPSystemValue": "H148"
    },
    {
     
     "CodeDescription": "Y-WA BUSINESS SOLUTION PTE LTD",
     "TPSystemValue": 211
    },
    {
     
     "CodeDescription": "ZACD INVESTMENTS PTE. LTD",
     "TPSystemValue": 987
    },
    {
     
     "CodeDescription": "ZARA HOLIDAYS PTE. LTD.",
     "TPSystemValue": "H671"
    },
    {
     
     "CodeDescription": "Z-CAR RENTAL & TRAVEL SERVICE PTE LTD",
     "TPSystemValue": "H096"
    },
    {
     
     "CodeDescription": "ZENACO CO2 (S) PTE LTD",
     "TPSystemValue": 831
    },
    {
     
     "CodeDescription": "ZENITH INFOTECH",
     "TPSystemValue": 3402
    },
    {
     
     "CodeDescription": "ZENSAR TECHNOLOGIES SNGAPORE PTE LTD",
     "TPSystemValue": 642
    },
    {
     
     "CodeDescription": "ZENTEK TECHNOLOGY SINGAPORE PTE LTD",
     "TPSystemValue": 807
    },
    {
     
     "CodeDescription": "ZENXIN TRAVEL PTE. LTD.",
     "TPSystemValue": "H573"
    },
    {
     
     "CodeDescription": "ZEPHYR COMPANY PTE LTD",
     "TPSystemValue": 3403
    },
    {
     
     "CodeDescription": "ZEPHYR SILKSCREEN COMPANY (PTE) LTD",
     "TPSystemValue": 3404
    },
    {
     
     "CodeDescription": "ZF ASIA PACIFIC PTE LTD",
     "TPSystemValue": 3405
    },
    {
     
     "CodeDescription": "ZHAO YANG GEOTECHNIC PTE LTD",
     "TPSystemValue": 3406
    },
    {
     
     "CodeDescription": "ZHEJIANG CONSTRUCTION GROUP (S) PTE LTD",
     "TPSystemValue": 1018
    },
    {
     
     "CodeDescription": "ZHEJIANG CONSTRUCTION INVESTMENT PL",
     "TPSystemValue": 1035
    },
    {
     
     "CodeDescription": "ZHI XIANG TRAVEL & EDUCATION PTE. LTD.",
     "TPSystemValue": "H574"
    },
    {
     
     "CodeDescription": "ZILLION PRIVATE LIMITED",
     "TPSystemValue": "H697"
    },
    {
     
     "CodeDescription": "ZIMMER PTE LTD SINGAPORE",
     "TPSystemValue": 3407
    },
    {
     
     "CodeDescription": "ZINGAMETALL (S) PTE LTD",
     "TPSystemValue": "H264"
    },
    {
     
     "CodeDescription": "ZIPAN RESORT TRAVEL PTE. LTD.",
     "TPSystemValue": "H631"
    },
    {
     
     "CodeDescription": "ZTE SINGAPORE PTE LTD",
     "TPSystemValue": 3408
    },
    {
     
     "CodeDescription": "ZUELLIG PHARMA PTE LTD",
     "TPSystemValue": 358
    },
    {
     
     "CodeDescription": "ZUJI PTE LTD",
     "TPSystemValue": 3409
    },
    {
     
     "CodeDescription": "ZURICH INSURANCE COMPANY LTD",
     "TPSystemValue": 3410
    },
    {
     
     "CodeDescription": "ZURICH INTERNATIONAL LIFE LIMITED",
     "TPSystemValue": 3076
    },
    {
     
     "CodeDescription": "ZWOO MOBILE PTE. LTD.",
     "TPSystemValue": "H682"
    }
    ]

    public WorkType =[
    {
     
     "CodeDescription": "Salaried Partial Commission Earner",
     "TPSystemValue": "S"
    },
    {
     
     "CodeDescription": "Self Employed",
     "TPSystemValue": "E"
    },
    {
     
     "CodeDescription": "100% Commission Earner",
     "TPSystemValue": "C"
    }
    ]
    public jobTitle =[
    {
     "Field Name": "Job title",
     "CodeDescription": "Accountant",
     "TPSystemValue": 11
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "AirforceNavy",
     "TPSystemValue": 91
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "ArtistActorEntertainerModel",
     "TPSystemValue": 48
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Beauty ConsultantHair Stylist",
     "TPSystemValue": 38
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Chartered Architect",
     "TPSystemValue": 3
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Chinese PhysicianChinese Medical Doctor",
     "TPSystemValue": 90
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Civil Servant",
     "TPSystemValue": 73
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "ClericalSecretarial",
     "TPSystemValue": 96
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Coaching StaffAthlete",
     "TPSystemValue": 89
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Computer Operator",
     "TPSystemValue": 30
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Computer ProgrammerSystem Analyst",
     "TPSystemValue": 22
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "ContractorSub ContractorConstruction Executive",
     "TPSystemValue": 36
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "CookChefChef Assistant",
     "TPSystemValue": 98
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "DeliveryCourierTransportational Personnel",
     "TPSystemValue": 76
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "DesignerDraftman",
     "TPSystemValue": 47
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "DoctorDentistMedical & Healthcare Specialist",
     "TPSystemValue": 10
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "ElectricianTechnicianMechanicArtison",
     "TPSystemValue": 21
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Engineer",
     "TPSystemValue": 13
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Financial ConsultantSales Representative",
     "TPSystemValue": 80
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Industrial ForemanLine Leader",
     "TPSystemValue": 44
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Insurance AgentInsurance BrokerInsurance Sales",
     "TPSystemValue": 72
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "JudgeLawyerBarristerSolicitor",
     "TPSystemValue": 2
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "LecturerProfessorHeadmasterPrincipal",
     "TPSystemValue": 19
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Litigation ClerkLegal Clerk",
     "TPSystemValue": 81
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "ManagerExecutive",
     "TPSystemValue": 94
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Marketing Executive",
     "TPSystemValue": 60
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Media DirectorExecutive",
     "TPSystemValue": 49
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Nurse",
     "TPSystemValue": 16
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "OfficerSupervisorAdministrator",
     "TPSystemValue": 95
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "OpticianPharmacistLaboratory OperatorRadiographerChemist",
     "TPSystemValue": 23
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "PilotAircraft CaptainShip Captain",
     "TPSystemValue": 5
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Property NegotiatorReal Estate Agent",
     "TPSystemValue": 77
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "ReporterEditorJournalistTranslator",
     "TPSystemValue": 24
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Retail Sales PersonPromoter",
     "TPSystemValue": 32
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Stock BrokerStock Dealer",
     "TPSystemValue": 75
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "SurveyorValuer",
     "TPSystemValue": 8
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Teacher",
     "TPSystemValue": 34
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "Tour GuideTravel Agent",
     "TPSystemValue": 87
    },
    {
     "Field Name": "Job title",
     "CodeDescription": "WaiterWaitressCaptainBartender",
     "TPSystemValue": 79
    }
    ]
    public natureOfBusiness = [
    {
     "Field Name": "Nature of Business",
     "CodeDescription": "Audit Accounting",
     "TPSystemValue": "NB13"
    },
    {
     "Field Name": "Nature of Business",
     "CodeDescription": "Catering Restaurant Bar",
     "TPSystemValue": "NB26"
    },
    {
     "Field Name": "Nature of Business",
     "CodeDescription": "Central & State Ministry",
     "TPSystemValue": "NB37"
    },
    {
     "Field Name": "Nature of Business",
     "CodeDescription": "Construction",
     "TPSystemValue": "NB18"
    },
    {
     "Field Name": "Nature of Business",
     "CodeDescription": "Finance Banking",
     "TPSystemValue": "NB14"
    },
    {
     "Field Name": "Nature of Business",
     "CodeDescription": "Manufacturing Industrial",
     "TPSystemValue": "NB44"
    },
    {
     "Field Name": "Nature of Business",
     "CodeDescription": "Real Estate Agent",
     "TPSystemValue": "NB65"
    },
    {
     "Field Name": "Nature of Business",
     "CodeDescription": "Telecommunication",
     "TPSystemValue": "NB40"
    },
    {
     "Field Name": "Nature of Business",
     "CodeDescription": "Transportation",
     "TPSystemValue": "NB66"
    },
    {
     "Field Name": "Nature of Business",
     "CodeDescription": "Wholesales",
     "TPSystemValue": "NB56"
    },
    {
     "Field Name": "Nature of Business",
     "CodeDescription": "Others",
     "TPSystemValue": "NB77"
    }
    ]
public Occupation = ["Contractor","Sub Contractor","Construction ExecutiveCook"," Chef"," Chef AssistantDelivery"," Courier"," Transportational PersonnelDesigner"," Draftman Doctor"," Dentist"," Medical & Healthcare Specialist Electrician","Technician","Mechanic","Artison Engineer Financial Consultant","Sales RepresentativeIndustrial Foreman","Line LeaderInsurance Agent","Insurance Broker","Insurance Sales Judge","Lawyer","Barrister","Solicitor Lecturer","Professor","Headmaster","PrincipalLitigation Clerk","Legal Clerk Manager","ExecutiveMarketing ExecutiveMedia Director"," Executive Nurse Officer","Supervisor","Administrator Optician","Pharmacist","Laboratory Operator","Radiographer","Chemist Pilot","Aircraft Captain","Ship CaptainProperty Negotiator","Real Estate Agent Reporter","Editor","Journalist"," Translator Retail Sales Person","Promoter Stock Broker","Stock Dealer Surveyor","Valuer Teacher Tour Guide","Travel Agent Waiter","Waitress","Captain","Bartender"]

}
