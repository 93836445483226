/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
//CORE_REFERENCE_IMPORTS
//append_imports_start

import {
  Component,
  Injector,
  Input,
  Output,
  EventEmitter,
  ViewContainerRef,
  AfterViewInit,
  ViewChild,
  ViewChildren,
} from '@angular/core'; //_splitter_
import { SDBaseService } from 'app/n-services/SDBaseService'; //_splitter_
import { SDPageCommonService } from 'app/n-services/sd-page-common.service'; //_splitter_
import { __NEU_ServiceInvokerService__ } from 'app/n-services/service-caller.service'; //_splitter_
import { Router, ActivatedRoute } from '@angular/router'; //_splitter_
//append_imports_end

@Component({
  selector: 'bh-TermsReview',
  templateUrl: './TermsReview.template.html',
  providers: [
    //appendnew_element_providers
  ],
})
export class TermsReviewComponent {
  page: any = { dep: {} };
  constructor(
    private __page_injector__: Injector,
    private sdService: SDBaseService,
    public __serviceInvoker__: __NEU_ServiceInvokerService__
  ) {
    this.__page_injector__.get(SDPageCommonService).addPageDefaults(this.page);
    this.registerListeners();
    //appendnew_element_inject
  }

  ngOnInit() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    {
      this.sd_503VshoLQirW7sb9(bh);
    }
  }

  private registerListeners() {
    let bh = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);

    //append_listeners
  }

  sd_503VshoLQirW7sb9(bh) {
    try {
      bh = this.sd_i233nwa47RNaNSeM(bh);
      bh = this.sd_HxdPtzcxd7AL4dqR(bh);
      //appendnew_next_sd_503VshoLQirW7sb9
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_503VshoLQirW7sb9');
    }
  }

  gotoThanks(...others) {
    try {
      var bh: any = this.__page_injector__
        .get(SDPageCommonService)
        .constructFlowObject(this);
      bh.input = {};
      bh.local = {};
      bh = this.navigateToReward(bh);
      //appendnew_next_gotoThanks
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_UaePIFIUr836HATi');
    }
  }

  //appendnew_flow_TermsReviewComponent_start

  sd_i233nwa47RNaNSeM(bh) {
    try {
      bh = this.routerDep(bh);
      //appendnew_next_sd_i233nwa47RNaNSeM
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_i233nwa47RNaNSeM');
    }
  }

  routerDep(bh) {
    try {
      this.page.router = this.__page_injector__.get(Router);
      this.page.activatedRoute = this.__page_injector__.get(ActivatedRoute);
      //appendnew_next_routerDep
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_riVEtaBzs0J9rde2');
    }
  }

  sd_HxdPtzcxd7AL4dqR(bh) {
    try {
      const page = this.page;
      page.showmore = false;
      page.fd = [
        {
          lable: 'Name on Card',
          value: 'Evan Tan Young Shen',
          key: '',
        },
        {
          lable: 'Preferred credit limit',
          value: 'Any limit',
          key: '',
        },
        {
          lable: 'Preferred credit limit',
          value: 'Standard Chartered account',
          key: '',
        },
      ];

      page.ld = [
        {
          lable: 'Annual income',
          value: 'S$ 120.000',
          key: '',
        },
        {
          lable: 'Your required loan amount',
          value: 'S$ 500.000',
          key: '',
        },
        {
          lable: 'Loan tenure',
          value: '3 years',
          key: '',
        },
      ];

      page.ed = [
        {
          lable: 'Work type',
          value: 'Salaried / Partial Comission Eaner',
          key: '',
        },
        {
          lable: 'Name of Employer',
          value: 'Bliss Media',
          key: '',
        },
        {
          lable: 'Years and months in service',
          value: '3 years, 2 months',
          key: '',
        },
        {
          lable: 'Job title',
          value: 'Project manager',
          key: '',
        },
        {
          lable: 'Industry',
          value: 'Marketing',
          key: '',
        },
        {
          lable: 'Office number',
          value: '112438949',
          key: '',
        },
      ];

      page.ras = [
        {
          lable: 'Block/House no',
          value: '112',
          key: '',
        },
        {
          lable: 'Apartment/condo name',
          value: 'Louis Apartment',
          key: '',
        },
        {
          lable: 'Street name',
          value: 'East Coast Road ',
          key: '',
        },
        {
          lable: 'Unit number',
          value: '#b1-13',
          key: '',
        },
        {
          lable: 'Years at address',
          value: '3',
          key: '',
        },
        {
          lable: 'Ownership status',
          value: 'Owner',
          key: '',
        },
      ];
      page.pinfo = [
        {
          key: '',
          value: 'Singapore',
          lable: 'Nationality',
        },
        {
          key: '',
          value: 'Singapore',
          lable: 'Country of Birth',
        },
        {
          key: '',
          value: 'Male',
          lable: 'Gender',
        },
        {
          key: '',
          value: 'Married',
          lable: 'Martial status',
        },
        {
          key: '',
          value: 'College',
          lable: 'Educational Level',
        },
        {
          key: '',
          value: '112438949',
          lable: 'Home phone number',
        },
      ];
      //appendnew_next_sd_HxdPtzcxd7AL4dqR
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_HxdPtzcxd7AL4dqR');
    }
  }

  navigateToReward(bh) {
    try {
      const page = this.page; // this.page.router.navigate(['home','reward']);

      this.page.router.navigate(['reward'], {
        relativeTo: this.page.activatedRoute.parent,
      });
      //appendnew_next_navigateToReward
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_cuynPU9EbJeo12Gb');
    }
  }

  //appendnew_node

  ngOnDestroy() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    this.__page_injector__.get(SDPageCommonService).deletePageFromMap(this);
  }

  errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;
    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_TermsReviewComponent_Catch
}
