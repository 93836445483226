/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
//CORE_REFERENCE_IMPORTS
//append_imports_start

import {
  Component,
  Injector,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ElementRef,
  HostListener,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core'; //_splitter_
import { SDBaseService } from 'app/n-services/SDBaseService'; //_splitter_
import { SDPageCommonService } from 'app/n-services/sd-page-common.service'; //_splitter_
import { __NEU_ServiceInvokerService__ } from 'app/n-services/service-caller.service'; //_splitter_
import { Router, ActivatedRoute } from '@angular/router'; //_splitter_
//append_imports_end

@Component({
  selector: 'bh-Retrieval',
  templateUrl: './Retrieval.template.html',
  providers: [
    //appendnew_element_providers
  ],
})
export class RetrievalComponent {
  page: any = { dep: {} };
  constructor(
    private __page_injector__: Injector,
    private sdService: SDBaseService,
    public __serviceInvoker__: __NEU_ServiceInvokerService__
  ) {
    this.__page_injector__.get(SDPageCommonService).addPageDefaults(this.page);
    this.registerListeners();
    //appendnew_element_inject
  }

  ngOnInit() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    {
      this.sd_KPwOLbhZQkCS10z2(bh);
    }
  }

  private registerListeners() {
    let bh = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);

    //append_listeners
  }

  sd_KPwOLbhZQkCS10z2(bh) {
    try {
      bh = this.sd_33aiiJWJNeLD2mTb(bh);
      bh = this.routerDep(bh);
      //appendnew_next_sd_KPwOLbhZQkCS10z2
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_KPwOLbhZQkCS10z2');
    }
  }

  myInfo(...others) {
    try {
      var bh: any = this.__page_injector__
        .get(SDPageCommonService)
        .constructFlowObject(this);
      bh.input = {};
      bh.local = {};
      bh = this.navigateToReview(bh);
      //appendnew_next_myInfo
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_KtGgSpHujfHSEENi');
    }
  }

  openScbLink(...others) {
    try {
      var bh: any = this.__page_injector__
        .get(SDPageCommonService)
        .constructFlowObject(this);
      bh.input = {};
      bh.local = {};
      bh = this.navigateToScbLink(bh);
      //appendnew_next_openScbLink
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_4sTlIW1pnLGXtsMg');
    }
  }

  //appendnew_flow_RetrievalComponent_start

  sd_33aiiJWJNeLD2mTb(bh) {
    try {
      //appendnew_next_sd_33aiiJWJNeLD2mTb
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_33aiiJWJNeLD2mTb');
    }
  }

  routerDep(bh) {
    try {
      this.page.router = this.__page_injector__.get(Router);
      this.page.activatedRoute = this.__page_injector__.get(ActivatedRoute);
      //appendnew_next_routerDep
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_ijTnvYFLPKLnexNx');
    }
  }

  navigateToReview(bh) {
    try {
      const page = this.page;
      this.page.router.navigate(['my-info-check'], {
        relativeTo: this.page.activatedRoute.parent,
      });
      //appendnew_next_navigateToReview
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_yyid1tkpdD37Qkza');
    }
  }

  navigateToScbLink(bh) {
    try {
      const page = this.page;
      window.open(
        'https://form-rewards.singsaver.com.sg/?q=MTM0MCNiYTI0ZTU1MDE2YWU5ZGE4YWQ3YzBlN2UyMmM2MmJiNQ',
        '_blank'
      );
      //appendnew_next_navigateToScbLink
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_XpSpVutghVMTYsmf');
    }
  }

  //appendnew_node

  ngOnDestroy() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    this.__page_injector__.get(SDPageCommonService).deletePageFromMap(this);
  }

  errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;
    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_RetrievalComponent_Catch
}
