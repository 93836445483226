/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
//CORE_REFERENCE_IMPORTS
//append_imports_start

import {
  Component,
  Injector,
  Input,
  Output,
  EventEmitter,
  ViewContainerRef,
  AfterViewInit,
  ViewChild,
  ViewChildren,
} from '@angular/core'; //_splitter_
import { SDBaseService } from 'app/n-services/SDBaseService'; //_splitter_
import { SDPageCommonService } from 'app/n-services/sd-page-common.service'; //_splitter_
import { __NEU_ServiceInvokerService__ } from 'app/n-services/service-caller.service'; //_splitter_
import { Router, ActivatedRoute } from '@angular/router'; //_splitter_
//append_imports_end

@Component({
  selector: 'bh-EditReview',
  templateUrl: './EditReview.template.html',
  providers: [
    //appendnew_element_providers
  ],
})
export class EditReviewComponent {
  page: any = { dep: {} };
  constructor(
    private __page_injector__: Injector,
    private sdService: SDBaseService,
    public __serviceInvoker__: __NEU_ServiceInvokerService__
  ) {
    this.__page_injector__.get(SDPageCommonService).addPageDefaults(this.page);
    this.registerListeners();
    //appendnew_element_inject
  }

  ngOnInit() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    {
      this.sd_i2VhhzEB53FvPQ51(bh);
    }
  }

  private registerListeners() {
    let bh = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);

    //append_listeners
  }

  sd_i2VhhzEB53FvPQ51(bh) {
    try {
      bh = this.sd_tsngUPb9VGOHF4gj(bh);
      //appendnew_next_sd_i2VhhzEB53FvPQ51
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_i2VhhzEB53FvPQ51');
    }
  }

  clickDownArrow(event: any = undefined, index: any = undefined, ...others) {
    try {
      var bh: any = this.__page_injector__
        .get(SDPageCommonService)
        .constructFlowObject(this);
      bh.input = { event: event, index: index };
      bh.local = {};
      bh = this.sd_MNxDF1dK60L1PNp6(bh);
      //appendnew_next_clickDownArrow
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_mLUE2wlqSxhby7Hs');
    }
  }

  gotoTerms(...others) {
    try {
      var bh: any = this.__page_injector__
        .get(SDPageCommonService)
        .constructFlowObject(this);
      bh.input = {};
      bh.local = {};
      bh = this.navigateToReview(bh);
      //appendnew_next_gotoTerms
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_xqz6pLGF9TcYdAXy');
    }
  }

  //appendnew_flow_EditReviewComponent_start

  sd_tsngUPb9VGOHF4gj(bh) {
    try {
      bh = this.routerDep(bh);
      bh = this.creditOrPersonal(bh);
      //appendnew_next_sd_tsngUPb9VGOHF4gj
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_tsngUPb9VGOHF4gj');
    }
  }

  routerDep(bh) {
    try {
      this.page.router = this.__page_injector__.get(Router);
      this.page.activatedRoute = this.__page_injector__.get(ActivatedRoute);
      //appendnew_next_routerDep
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_hpEjzIL0rMLJisVL');
    }
  }

  creditOrPersonal(bh) {
    try {
      const page = this.page;
      if (this.page.router.url.indexOf('/credit-card') > -1) {
        this.page.creditCardFlow = true;
      } else {
        this.page.creditCardFlow = false;
      }

      console.log('credit', this.page.creditCardFlow);
      //appendnew_next_creditOrPersonal
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_M2gRBqYrGz31HdD1');
    }
  }

  sd_MNxDF1dK60L1PNp6(bh) {
    try {
      const page = this.page;
      console.log(bh.input.event);

      console.log(bh.input.index);
      //appendnew_next_sd_MNxDF1dK60L1PNp6
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_MNxDF1dK60L1PNp6');
    }
  }

  navigateToReview(bh) {
    try {
      const page = this.page; // this.page.router.navigate(['home','terms']);

      this.page.router.navigate(['terms'], {
        relativeTo: this.page.activatedRoute.parent,
      });
      //appendnew_next_navigateToReview
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_ccP2lCkQJPP5gVHi');
    }
  }

  //appendnew_node

  ngOnDestroy() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    this.__page_injector__.get(SDPageCommonService).deletePageFromMap(this);
  }

  errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;
    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_EditReviewComponent_Catch
}
