/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
//CORE_REFERENCE_IMPORTS
//append_imports_start

import {
  Component,
  Injector,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ElementRef,
  HostListener,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core'; //_splitter_
import { SDBaseService } from 'app/n-services/SDBaseService'; //_splitter_
import { SDPageCommonService } from 'app/n-services/sd-page-common.service'; //_splitter_
import { __NEU_ServiceInvokerService__ } from 'app/n-services/service-caller.service'; //_splitter_
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms'; //_splitter_
//append_imports_end

@Component({
  selector: 'bh-Basicinformation',
  templateUrl: './Basicinformation.template.html',
  providers: [
    //appendnew_element_providers
  ],
})
export class BasicinformationComponent {
  page: any = { dep: {} };
  constructor(
    private __page_injector__: Injector,
    private sdService: SDBaseService,
    public __serviceInvoker__: __NEU_ServiceInvokerService__
  ) {
    this.__page_injector__.get(SDPageCommonService).addPageDefaults(this.page);
    this.registerListeners();
    this.page.dep.FormBuilder = this.__page_injector__.get(FormBuilder); //FormBuilder
    //appendnew_element_inject
  }

  ngOnInit() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    {
      this.sd_mQKTDg4bKNzlce4D(bh);
    }
  }

  private registerListeners() {
    let bh = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);

    //append_listeners
  }

  sd_mQKTDg4bKNzlce4D(bh) {
    try {
      bh = this.sd_gc45pO0JDS7AVpRJ(bh);
      bh = this.sd_c6ZrrBhv1OsUsuL0(bh);
      //appendnew_next_sd_mQKTDg4bKNzlce4D
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_mQKTDg4bKNzlce4D');
    }
  }

  //appendnew_flow_BasicinformationComponent_start

  sd_gc45pO0JDS7AVpRJ(bh) {
    try {
      //appendnew_next_sd_gc45pO0JDS7AVpRJ
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_gc45pO0JDS7AVpRJ');
    }
  }

  sd_c6ZrrBhv1OsUsuL0(bh) {
    try {
      bh = this.sd_yFgjYzZt8gsUfVlv(bh);
      //appendnew_next_sd_c6ZrrBhv1OsUsuL0
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_c6ZrrBhv1OsUsuL0');
    }
  }

  sd_yFgjYzZt8gsUfVlv(bh) {
    try {
      const page = this.page;
      page.form = new FormGroup({
        name: new FormControl('Evan Tan Young Shen', Validators.required),
        dob: new FormControl(new Date('12/03/1970'), Validators.required),
        residency: new FormControl('Singaporean'),
        uinfin: new FormControl('S1234567D', Validators.required),
        // passport: new FormControl(''),
        // passported: new FormControl(''),
        mobileno: new FormControl('81234567', [Validators.required]),
        email: new FormControl('shen@gmail.com', [
          Validators.required,
          Validators.email,
        ]),
      });

      console.log(page.form.value.residency);
      //appendnew_next_sd_yFgjYzZt8gsUfVlv
      return bh;
    } catch (e) {
      return this.errorHandler(bh, e, 'sd_yFgjYzZt8gsUfVlv');
    }
  }

  //appendnew_node

  ngOnDestroy() {
    const bh: any = this.__page_injector__
      .get(SDPageCommonService)
      .constructFlowObject(this);
    this.__page_injector__.get(SDPageCommonService).deletePageFromMap(this);
  }

  errorHandler(bh, e, src) {
    console.error(e);
    bh.error = e;
    bh.errorSource = src;
    if (
      false
      /*appendnew_next_Catch*/
    ) {
      return bh;
    } else {
      throw e;
    }
  }
  //appendnew_flow_BasicinformationComponent_Catch
}
