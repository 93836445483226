import { NeutrinosAuthGuardService } from 'neutrinos-oauth-client';
import { PageNotFoundComponent } from '../not-found.component';
import { LayoutComponent } from '../layout/layout.component';
import { ImgSrcDirective } from '../directives/imgSrc.directive';
import { APP_INITIALIZER } from '@angular/core';
import { NDataSourceService } from '../n-services/n-dataSorce.service';
import { environment } from '../../environments/environment';
import { NLocaleResource } from '../n-services/n-localeResources.service';
import { NAuthGuardService } from 'neutrinos-seed-services';
import { ArtImgSrcDirective } from '../directives/artImgSrc.directive';
import { localesService } from '../../../baseClasses/localesService';

window['neutrinos'] = {
  environments: environment,
};

//CORE_REFERENCE_IMPORTS
//CORE_REFERENCE_IMPORT-headerComponent
import { headerComponent } from '../components/header.component';
//CORE_REFERENCE_IMPORT-creditCardDetailsComponent
import { creditCardDetailsComponent } from '../components/creditCardDetails.component';
//CORE_REFERENCE_IMPORT-rewardComponent
import { rewardComponent } from '../components/reward.component';
//CORE_REFERENCE_IMPORT-MyInfoCheckComponent
import { MyInfoCheckComponent } from '../components/MyInfoCheck.component';
//CORE_REFERENCE_IMPORT-TermsReviewComponent
import { TermsReviewComponent } from '../components/TermsReview.component';
//CORE_REFERENCE_IMPORT-EditReviewComponent
import { EditReviewComponent } from '../components/EditReview.component';
//CORE_REFERENCE_IMPORT-fundDistributionComponent
import { fundDistributionComponent } from '../components/fundDistribution.component';
//CORE_REFERENCE_IMPORT-loanDetailsComponent
import { loanDetailsComponent } from '../components/loanDetails.component';
//CORE_REFERENCE_IMPORT-EmploymentDetailsComponent
import { EmploymentDetailsComponent } from '../components/EmploymentDetails.component';
//CORE_REFERENCE_IMPORT-ResidentialAddresSingaporeComponent
import { ResidentialAddresSingaporeComponent } from '../components/ResidentialAddresSingapore.component';
//CORE_REFERENCE_IMPORT-PersonaldetailsComponent
import { PersonaldetailsComponent } from '../components/Personaldetails.component';
//CORE_REFERENCE_IMPORT-BasicinformationComponent
import { BasicinformationComponent } from '../components/Basicinformation.component';
//CORE_REFERENCE_IMPORT-staticinfoemploymentService
import { staticinfoemploymentService } from '../services/staticinfoemployment/staticinfoemployment.service';
//CORE_REFERENCE_IMPORT-staticinfopersonalService
import { staticinfopersonalService } from '../services/staticinfopersonal/staticinfopersonal.service';
//CORE_REFERENCE_IMPORT-ReviewComponent
import { ReviewComponent } from '../components/Review.component';
//CORE_REFERENCE_IMPORT-RetrievalComponent
import { RetrievalComponent } from '../components/Retrieval.component';
//CORE_REFERENCE_IMPORT-rootComponent
import { rootComponent } from '../components/root.component';

/**
 * Reads datasource object and injects the datasource object into window object
 * Injects the imported environment object into the window object
 *
 */
export function startupServiceFactory(startupService: NDataSourceService) {
  return () => {
    return new Promise((resolve, reject) => {
      startupService.getDataSource().then(() => {
        localesService.init().then(() => {
          resolve(null);
        });
      });
    });
  };
}

/**
 *bootstrap for @NgModule
 */
export const appBootstrap: any = [LayoutComponent];

/**
 *declarations for @NgModule
 */
export const appDeclarations = [
  ImgSrcDirective,
  LayoutComponent,
  PageNotFoundComponent,
  ArtImgSrcDirective,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-headerComponent
  headerComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-creditCardDetailsComponent
  creditCardDetailsComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-rewardComponent
  rewardComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-MyInfoCheckComponent
  MyInfoCheckComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-TermsReviewComponent
  TermsReviewComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-EditReviewComponent
  EditReviewComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-fundDistributionComponent
  fundDistributionComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-loanDetailsComponent
  loanDetailsComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-EmploymentDetailsComponent
  EmploymentDetailsComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-ResidentialAddresSingaporeComponent
  ResidentialAddresSingaporeComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-PersonaldetailsComponent
  PersonaldetailsComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-BasicinformationComponent
  BasicinformationComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-ReviewComponent
  ReviewComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-RetrievalComponent
  RetrievalComponent,
  //CORE_REFERENCE_PUSH_TO_DEC_ARRAY-rootComponent
  rootComponent,
];

/**
 * provider for @NgModule
 */
export const appProviders = [
  NDataSourceService,
  NLocaleResource,
  {
    // Provider for APP_INITIALIZER
    provide: APP_INITIALIZER,
    useFactory: startupServiceFactory,
    deps: [NDataSourceService],
    multi: true,
  },
  NAuthGuardService,
  //CORE_REFERENCE_PUSH_TO_PRO_ARRAY
  //CORE_REFERENCE_PUSH_TO_PRO_ARRAY-staticinfoemploymentService
  staticinfoemploymentService,
  //CORE_REFERENCE_PUSH_TO_PRO_ARRAY-staticinfopersonalService
  staticinfopersonalService,
];

/**
 * Routes available for bApp
 */

// CORE_REFERENCE_PUSH_TO_ROUTE_ARRAY_START
export const appRoutes = [
  {
    path: 'personal-loan',
    component: rootComponent,
    children: [
      { path: 'retrieval', component: RetrievalComponent },
      { path: 'review', component: ReviewComponent },
      { path: 'edit-review', component: EditReviewComponent },
      { path: 'terms', component: TermsReviewComponent },
      { path: 'my-info-check', component: MyInfoCheckComponent },
      { path: 'reward', component: rewardComponent },
    ],
  },
  {
    path: 'credit-card',
    component: rootComponent,
    children: [
      { path: 'retrieval', component: RetrievalComponent },
      { path: 'review', component: ReviewComponent },
      { path: 'edit-review', component: EditReviewComponent },
      { path: 'terms', component: TermsReviewComponent },
      { path: 'my-info-check', component: MyInfoCheckComponent },
      { path: 'reward', component: rewardComponent },
    ],
  },
  { path: '', redirectTo: 'personal-loan/retrieval', pathMatch: 'full' },
  { path: '**', redirectTo: 'personal-loan' },
];
// CORE_REFERENCE_PUSH_TO_ROUTE_ARRAY_END
